import { Rating } from '@mui/material';
import React, { useState } from 'react'
import prof from "../image/prof.jpg"
import { backendURL } from '../../../../appVariable/variable';

const TeamCard = (props) => {
    const { teamData } = props;
    // const [value, setValue] = useState(2);
    return (
        <>
            <div className="team-card center flex-column  box-shodow" >
                <div className="prof-img">
                    <img alt="" srcSet=""
                        src={teamData?.profile_image ? `${backendURL}${teamData?.profile_image}` : prof} />
                </div>
                <div className=' text-center'><p>{teamData.first_name}</p><p>{teamData.agent_broker_license_title}</p> </div>

                <Rating name="simple-controlled" value={teamData.rating}
                // onChange={(event, newValue) => { setValue(newValue); }}
                />
            </div>
        </>
    )
}

export default TeamCard
