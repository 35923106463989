import React from "react";
// import "./selpersonCard.scss";
// import cardImg from "./images/Gentleman.jpg";
import { Rating } from "@mui/material";
import { backendURL } from "../../../../../../appVariable/variable";
import pro from "../../../../../pages/dashboard/components/mainSection/images/pro.jpg";
// import inputcheck from "./images/inputcheck.svg"
const BrokerTeam = (props) => {
  const { MemberDetail, selectedIds, setSelectedIds } = props;
  const checked = selectedIds?.includes(MemberDetail?.id);
  const onclickelement = (e) => {
    const tempSelectedIds = [...selectedIds];
    if (e.target.checked) {
      // setSelectedIds(tempSelectedIds.push(Number(e.target.id)));
      // selectedIds((state) => {
      //   return state;
      // });
      const temp = tempSelectedIds.push(Number(e.target.id));
      setSelectedIds(tempSelectedIds);
      setSelectedIds((state) => {
        return state;
      });
    } else {
      const arrayWithoutId = tempSelectedIds.filter((ele) => {
        return ele !== Number(e.target.id);
      });
      setSelectedIds(arrayWithoutId);
    }
  };
  return (
    <div className="selPersonCardWidth ">
      <div className="card card_border h-100">
        <div className="card-body p-2">
          <label className="checkbox w-100 pointer">
            <input
              type="checkbox"
              className="custum-checkbox checkbox__input"
              onChange={(e) => onclickelement(e)}
              id={MemberDetail?.id}
              checked={checked}
            />
            <span className="checkbox__inner"></span>
            <div className="card_center">
              <div className="pb-3 img_pad_bottom text-center">
                <img
                  src={
                    MemberDetail?.profile_image
                      ? `${backendURL}${MemberDetail?.profile_image}`
                      : pro
                  }
                  alt=""
                />
              </div>
              <p className=" text-center">{`${MemberDetail?.first_name} ${MemberDetail?.last_name}`}</p>
              <Rating
                name="read-only"
                value={MemberDetail?.rating ? MemberDetail?.rating : 0}
                readOnly
              />
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default BrokerTeam;
