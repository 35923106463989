import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import bed from "./images/bed.svg";
import pro from "./images/pro.jpg";
import { backendURL } from "../../../../appVariable/variable";
import { priceFormat } from "../../dashboard/components/mainSection/PropertyCard";
import { Opacity } from "@mui/icons-material";

// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import property from "./images/property.jpg"
// import { saveDefaultPropertyShow } from '../../dashboard/components/mainSection/server';
// import { getDefaultPropertyShow, removeDefaultPropertyShow, saveDefaultPropertyShow } from './server'

const SaveListingCard = (props) => {
  const { agentListingData, index, isClosed } = props; //agentKey removeAllButton defaultRemove commonId
  const [savePopup, setSavePopup] = useState(false);
  const [rating, setRating] = useState(0);
  const [noteValue, setNoteValue] = useState("");
  const handleClickRating = (value) => {
    setRating(value);
  };

  return (
    <>
      <div
        className="property-card d-flex flex-column justify-content-around pointer"
        //    onClick={props.setPropertypopup}
        key={index}
      >
        <div className="property-img position-relative">
          <img
            src={`${backendURL}${agentListingData?.property_details?.property_main_image}`}
            alt=""
            style={{ opacity: isClosed && "0.5" }}
          />

          {/* {
                        showRemove || removeAllButton || defaultRemove ? 
                        <div className="card-remove-btn center  position-absolute" style={{ cursor: "pointer" }} >
                            <FavoriteBorderIcon />
                            <p>Remove</p>
                            </div> : 
                        <div className="card-save-btn center  position-absolute" style={{ cursor: "pointer" }} onClick={showSavePopUp}><FavoriteBorderIcon /><p>save</p></div>
                    } */}
        </div>

        <div
          className="property-detail d-flex justify-content-between align-items-center"
          // onClick={(e) => {
          //   console.log("agentListingData", agentListingData.id);
          //   handelOpenPopUp(agentListingData.id);
          //   handelOpenPopUp((state) => {
          //     return state;
          //   });
          //   setPopup(true);
          //   setPopup((state) => {
          //     return state;
          //   });
          // }}
        >
          <div className="property-detail-left d-flex flex-column">
            <div className="d-flex align-items-center gap-1">
              <p className="price">
                $
                {priceFormat(
                  agentListingData?.property_details?.property_listing_amount
                )}
              </p>
              <div className="card-divider"></div>
              {/* bed&obr */}
              <div>
                <img className="bed-img" src={bed} alt="" />
              </div>
              {agentListingData?.property_details?.property_listing_type
                ?.property_listing_name === "Residential" ? (
                <p className="obr text-uppercase">{`${
                  agentListingData?.property_details.Bedrooms
                    ? agentListingData?.property_details.Bedrooms
                    : "--"
                }br`}</p>
              ) : null}
            </div>
            <div>
              <p className=" mb-1">
                {agentListingData?.property_details?.property_city.city_name}
              </p>
              <p>{agentListingData?.property_details?.property_address_1}</p>
            </div>
          </div>
          <div className="property-detail-right center flex-column gap-2">
            <div className="center">
              <img
                src={
                  agentListingData?.property_details?.user_profile
                    ?.profile_image === null
                    ? pro
                    : `${backendURL}${agentListingData?.property_details?.user_profile?.profile_image}`
                }
                alt=""
              />
            </div>
            <Button variant="outline-primary" className=" ">
              1 Year
            </Button>
          </div>
        </div>
      </div>

      {/* save notes and rating pop-up  start*/}
      <div>
        <Modal
          className="dashboard-filter-popup"
          size="lg"
          centered
          show={savePopup}
          onHide={() => setSavePopup(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            <div className="dashborad-filter-body d-flex flex-column gap-4">
              <div className="price-sec  filter-row ">
                <p className="filter-heading">Note</p>
                <div className="price-input-box justify-content-between">
                  <div
                    className="enter-input inner-shadow center flex-grow-1"
                    style={{ maxWidth: "none" }}
                  >
                    <input
                      type="textarea"
                      id=""
                      placeholder="Name"
                      name="filtername"
                      onChange={(e) => setNoteValue(e.target.value)}
                      value={noteValue}
                    />
                  </div>
                </div>
              </div>
              <div className="price-sec  filter-row ">
                <p className="filter-heading">Rating</p>
                <div>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span
                      key={value}
                      onClick={() => handleClickRating(value)}
                      style={{
                        color: value <= rating ? "gold" : "gray",
                        fontSize: "36px",
                        cursor: "pointer",
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>

              {/* <div className="filter-btn-grp d-flex gap-4 justify-content-center">
                                {
                                    userId ? <button className='btn' style={{ width: "317px" }} onClick={handelSaveProperty}>Save</button> : ""
                                }

                                <button className='btn' style={{ width: "317px" }}>Cancel</button>
                            </div> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SaveListingCard;
