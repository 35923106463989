import React from 'react'
import { useNavigate } from "react-router-dom";
import './security.scss'
import tooltrip from "./images/tooltrip.svg"
import { getToken } from "../../../../appVariable/variable"
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import close from "./images/close.svg"
import { useFormik } from 'formik';
import * as Yup from "yup";
import { changepassword } from './server';
import Validation from '../../Auth/Validation';
import { toast } from 'react-toastify';
import { SuspendAgentAccount, deleteAgentAccount } from '../../neighbourhoodspecialist/component/PlanAndBilling/server';

const Security = () => {
    const token = getToken()
    const userId = token?.userId;
    const isSocialLogin = token?.user_info?.is_social
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [isRevealOldPwd, setIsRevealOldPwd] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfPwd, setIsRevealConfPwd] = useState(false);
    const [suspendtext, setSuspendtext] = useState("");
    const [deletetext, setDeletetext] = useState("")
    const [suspendError, setSuspendError] = useState("")
    const [deleteError, setDeleteError] = useState("")
    const [loading, setLoading] = useState(false)

    let upperCase = /^(?=.*[A-Z])/;
    let Lowercase = /^(?=.*[a-z])/;
    let specialChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
    let digitRegex = /(?=.*?[0-9])/;

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            oldPassword: isSocialLogin === true ? "" : Yup.string().required("*Old Password required"),

            newPassword: Yup.string()
                .required("required*")
                .notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be the same as old password')
                .min(8, "Password must be 8 characters at minimum")
                .matches(upperCase, "please Enter One Uppercase Value")
                .matches(Lowercase, "please Enter One Lowercase Value")
                .matches(specialChar, "please Enter One SpecialChar Value")
                .matches(digitRegex, "please Enter One Char in number Value"),

            // newPassword: Yup.string().required("*New Password required"),    Must Contain 8 Characters 

            confirmPassword: Yup.string().required().oneOf([Yup.ref("newPassword"), null], "Confirm Password not matched"),
        }),
        onSubmit: (values) => {
            setLoading(true)
            let passwordObj = {
                "user_id": userId,
                "old_password": formik.values.oldPassword,
                "password1": formik.values.newPassword,
                "password2": formik.values.confirmPassword,
            }
            changepassword(passwordObj, (success) => {
                if (success.data.code === 200 && success.data.status === "success") {
                    toast.success(success.data.data, "sucess");
                }
                if (success.data.code === 400) {
                    toast.warning(success.data.data, "warning");
                }
                setLoading(false)
            });
        },
    });

    const SuspendAccountUser = async () => {
        if (suspendtext === "") {
            setSuspendError("Please Type SUSPEND")
        } else {
            await SuspendAgentAccount((success) => { console.log(success) });
            // GetUserBilling((success) => { setBillingInfo(success); })
            setSuspendModal(false)
            setSuspendtext("")
        }

    }

    const userDeleteAccount = async (event) => {
        event.preventDefault();
        console.log("testDele")
        if (deletetext === "") {
            setDeleteError("Please Type DELETE")
            return
        } else if (deletetext !== "DELETE") {
            setDeleteError("Please Type DELETE")
            return
        } else {
            await deleteAgentAccount(userId, (success) => { console.log(success) });
            localStorage.removeItem("userDetails");
            localStorage.removeItem("residentailCityId");
            localStorage.removeItem("add_on");
            localStorage.removeItem("sidebar");
            navigate("/");
        }

    }

    return (
        <>
            <div className='Security_page_pad'>
                <div className='pasword_sectext pasword_margine'>Password & security</div>
                <form method='post'>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 d-flex flex-column gap-4">
                            {isSocialLogin === true ? "" : (

                                <div>
                                    <div className="d-flex main_input">

                                        <div className="input_name grow-1 text-end input_namepad">
                                            Old Password<span className="red-star" >*</span>
                                        </div>
                                        <div className="input-sec d-flex">
                                            <input type={isRevealOldPwd ? "text" : "password"} className="form-control border-0 pb-0"
                                                name="oldPassword"
                                                onChange={formik.handleChange}
                                                value={formik.values.oldPassword}
                                            />
                                            <div style={{ cursor: "pointer" }} onClick={() => setIsRevealOldPwd(prevState => !prevState)}>
                                                {isRevealOldPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                            </div>

                                        </div>

                                    </div>
                                    {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                        <div className='validation-text'>{formik.errors.oldPassword}</div>
                                    ) : null}
                                </div>
                            )}
                            <div>
                                <div className="d-flex main_input">
                                    <div className="input_name grow-1 text-end input_namepad">
                                        New Password<span className="red-star" >*</span>
                                    </div>
                                    <div className="input-sec d-flex">
                                        <input className="form-control border-0 pb-0"
                                            type={isRevealNewPwd ? "text" : "password"}
                                            name="newPassword"
                                            onChange={formik.handleChange}
                                            value={formik.values.newPassword}
                                        />
                                        <div style={{ cursor: "pointer" }} onClick={() => setIsRevealNewPwd(prevState => !prevState)}>
                                            {isRevealNewPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                        </div>

                                    </div>
                                </div>


                                {formik.touched.newPassword && (formik.errors.newPassword)?.length < 25 ? (
                                    <div className='validation-text'>{formik.errors.newPassword}</div>
                                ) : null}
                            </div>
                            <Validation formikPass={formik.values.newPassword} />
                            <div>
                                <div className="d-flex main_input">
                                    <div className="input_name grow-1 text-end input_namepad">
                                        Confirm Password<span className="red-star" >*</span>
                                    </div>
                                    <div className="input-sec d-flex">
                                        <input type={isRevealConfPwd ? "text" : "password"} className="form-control border-0 pb-0"
                                            name="confirmPassword"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                        />
                                        <div style={{ cursor: "pointer" }} onClick={() => setIsRevealConfPwd(prevState => !prevState)}>
                                            {isRevealConfPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                        </div>

                                    </div>
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                    <div className='validation-text'>{formik.errors.confirmPassword}</div>
                                ) : null}
                            </div>
                            <div className='text-end'>
                                <button type="submit" className='save__btn savebtn_text'
                                    disabled={loading}
                                    onClick={formik.handleSubmit}>Save</button>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                </form>

                <div className='pasword_sectext suspend_margine'>
                    Suspend & Delete Account
                </div>
                <div className="row main_del_btn">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-6 text-center center">
                                <button type="" className="save__btn suspend_btn flex-grow-1" onClick={() => setSuspendModal(true)} >Suspend</button>
                                <div className='position-relative suspend'>
                                    <img src={tooltrip} alt="tooltrip" />
                                    <div className="suspend-tooltip position-absolute">
                                        <div className="tip"></div>
                                        <p>Account suspend for one yr but at 6 month all listings and saved searches are purged from account.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 text-center center">
                                <button type="" className="Delete__btn Delete_btn flex-grow-1" onClick={() => setModalShow(true)}>Delete</button>
                                <div className='position-relative delete_trip'>
                                    <img src={tooltrip} alt="tooltrip" className='img_hover' />
                                    <div className="suspend-tooltip position-absolute">
                                        <div className="tip"></div>
                                        <p>You can delete your AFISADO account at any time. What you should know beforehand, and what will happen when your account is deleted. When you delete your AFISADO account, you will immediately lose all access to the account, all saved information, and communication with listing providers will be deleted.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
            </div>

            {/* start suspend modal start*/}
            <div className="SecuRITY_modals">
                <Modal className='dashboard-filter-popup'
                    show={suspendModal}
                    onHide={() => setSuspendModal(false)}
                    size="md"
                    ria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton className="border-bottom-0 security_body gdgyg">
                        <Modal.Title id="contained-modal-title-vcenter " className="border-0">
                            <div className="forgot_title ps-2">
                                Suspend Account
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4 pt-0 security_body">


                        <div className="dashborad-filter-body d-flex flex-column gap-4">
                            <div className="price-sec  filter-row ">
                                <div className="forgot_small_text pb-2">
                                    Confirm you want to suspend this account. Kindly Enter "<span>SUSPEND</span> " below.
                                </div>
                                <div className="price-input-box justify-content-between">
                                    <div className="enter-input inner-shadow center flex-grow-1" style={{ maxWidth: "none" }}>
                                        <input type="text" id="" placeholder='Name'
                                            onChange={(e) => { setSuspendtext(e.target.value === "SUSPEND"); setSuspendError("") }}
                                        />
                                    </div>

                                </div>
                                {suspendError && <div className='validation-text'>{suspendError}</div>}
                            </div>


                            <div className="text-end">
                                <button type="submit" className="suspend_btn" onClick={() => SuspendAccountUser()}
                                    disabled={!suspendtext}>Suspend My Account</button>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
            {/* end suspend modal */}



            {/*start Delete Account modal */}
            <div className="SecuRITY_modals">
                <Modal className='dashboard-filter-popup'
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="md"
                    ria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton className="border-bottom-0 security_body gdgyg">
                        <Modal.Title id="contained-modal-title-vcenter " className="border-0">
                            <div className="forgot_title ps-2">
                                Delete Account
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4 pt-0 security_body">
                        {/* <div className="forgot_small_text pb-2">
                            Confirm you want to delete this account. Kindly Enter " <span className='red_texT'>DELETE</span> " below.
                        </div>

                        <div className='pb-3 mb-1'>
                            <input type="text" name="text" id="" className=" w-100 "
                                onChange={(e) => { setDeletetext(e.target.value === "DELETE"); console.log(deletetext) }}
                            />
                        </div>
                        {suspendError && <div className='validation-text'>{suspendError}</div>}
                        <div className="text-end">
                            <button type="submit" className="submit_btn" onClick={() => userDeleteAccount()}
                                disabled={!deletetext}>Delete</button>
                        </div> */}


                        <div className="dashborad-filter-body d-flex flex-column gap-4">
                            <div className="price-sec  filter-row ">
                                <div className="forgot_small_text pb-2">
                                    Confirm you want to delete this account. Kindly Enter " <span className='red_texT'>DELETE</span> " below.
                                </div>
                                <div className="price-input-box justify-content-between">
                                    <div className="enter-input inner-shadow center flex-grow-1" style={{ maxWidth: "none" }}>
                                        <input type="text" name="text" id="" className=" w-100 "
                                            // onChange={(e) => { setDeletetext(e.target.value === "DELETE");setDeleteError(""); console.log(deletetext) }}
                                            onChange={(e) => { setDeletetext(e.target.value); setDeleteError(""); console.log(deletetext) }}
                                        />
                                    </div>

                                </div>
                                {deleteError && <div className='validation-text'>{deleteError}</div>}
                            </div>


                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="submit_btn"
                                    onClick={userDeleteAccount}
                                // onClick={() => userDeleteAccount()}

                                // disabled={!deletetext}
                                >Delete</button>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
            {/*end Delete Account modal */}
        </>


    )
}

export default Security
