import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable"


export const SearchAgent = (seacrhObj, success) => {
    let token = getToken();
    axios.post(`${backendURL}/api/user/SalesPersonSearch/`, seacrhObj, { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
        .then((response) => { if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); } })
        .catch((error) => { console.log(error); });
}


export const SaveAgent = (paramObj, success) => {
    let token = getToken();
    axios.post(`${backendURL}/api/user/SalespersonSavedbyGuest/`, paramObj, { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
        .then((response) => { if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); } })
        .catch((error) => { console.log(error); });
}

export const GetSaveAgent = (success) => {
    let token = getToken();
    axios.get(`${backendURL}/api/user/SalespersonSavedbyGuest/${token.userId}`
    // , { headers: { 'Authorization': `Bearer ${token?.idToken}` } }
    )
        .then((response) => { if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); } })
        .catch((error) => { console.log(error); });
}