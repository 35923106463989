import React, { useEffect, useState } from "react";
import {
  Circle,
  FeatureGroup,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  Rectangle,
  TileLayer,
} from "react-leaflet";
import PropertyCard from "../../../dashboard/components/mainSection/PropertyCard";
// import { features } from './locationdata';
import "./mapView.scss";
import { getAllpropertyInfo } from "../../../dashboard/components/mainSection/server";
import PropertyDetailsPopUp from "../../../dashboard/propperty-datails/PropertyDetailsPopUp";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertyDatail } from "../../../dashboard/propperty-datails/PropertySlice";
// import icons from '../../../dashboard/components/sidebar/images/SidebarIcon';
import L, { marker } from "leaflet";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon } from "leaflet";
const Mapview = (props) => {
  //marker icons
  const commertialmarkerIcon = new Icon({
    iconUrl: require("./Image/comlocation.png"),
    // iconUrl: require(icons.commercialmarkerIcon),
    iconSize: [40, 40],
    // iconAnchor: [17, 46], //[left/right, top/bottom]
    // popupAnchor: [0, -46], //[left/right, top/bottom]
  });

  const residentialmarkerIcon = new Icon({
    iconUrl: require("./Image/reslocation.png"),
    iconSize: [40, 40],
  });

  const dispatch = useDispatch();
  const { latitude, longitude, allProperty } = props;
  // const [totalProperty, setTotalProperty] = useState(allProperty ? allProperty : features)
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [center, setCenter] = useState(
    allProperty
      ? [allProperty[0]?.latitude, allProperty[0]?.longitude]
      : [latitude ? latitude : 40.7128, longitude ? longitude : -74.006]
  );
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({});
  const customIcon = new Icon({
    iconUrl: require("./Image/marker-icon.png"),
    iconSize: [38, 38], // size of icon
  });

  const createCustomClusterIcon = (cluster) => {
    return new divIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster",
      iconSize: [33, 33],
    });
  };
  const rectangle = [
    [51.49, -0.08],
    [51.5, -0.06],
  ];

  useEffect(() => {
    if (latitude && longitude) {
      setCenter([latitude, longitude]);
    }
    if (allProperty?.length) {
      setCenter([allProperty[0]?.latitude, allProperty[0]?.longitude]);
    }
  }, [props]);

  let propertDetails = async (id) => {
    dispatch(
      updatePropertyDatail({
        propertyId: id,
        setshowPropertyPopup: true,
      })
    );
    await getAllpropertyInfo(id, (success) => {
      setPropertyDetailInfo(success);
    });
  };

  // onClick={() => {
  //     setActivePark(park);
  // }}
  // icon={icon}
  return (
    <>
      <div className=" map-view-container">
        <MapContainer
          center={center}
          zoom={allProperty !== undefined ? 16 : 14}
          scrollWheelZoom={true}
          className="mapcontent h-100"
          style={{ width: "100%", minHeight: "400px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {/* <LayersControl position="topright"> */}
          <LayersControl.Overlay name="Marker with popup">
            <Marker position={center}>
              <Popup>
                {" "}
                A pretty CSS3 popup. <br /> Easily customizable.{" "}
              </Popup>
            </Marker>
          </LayersControl.Overlay>

          {/* <LayersControl.Overlay checked name="Layer group with circles"> */}
          {/* <LayerGroup> */}
          {/* <Circle
              center={center}
              pathOptions={{ fillColor: "blue" }}
              radius={200}
            /> */}
          {/* <Circle
                  center={center}
                  pathOptions={{ fillColor: "red" }}
                  radius={100}
                  stroke={false}
                /> */}
          {!props?.marker && (
            <LayerGroup>
              <Circle
                center={center}
                // center={[51.51, -0.08]}
                pathOptions={{ color: "green", fillColor: "green" }}
                radius={100}
              />
            </LayerGroup>
          )}

          {/* </LayerGroup> */}
          {/* </LayersControl.Overlay> */}

          {/* <LayersControl.Overlay name="Feature group">
              <FeatureGroup pathOptions={{ color: "purple" }}>
                <Popup>Popup in FeatureGroup</Popup>
                <Circle
                  // center={center}
                  center={[51.51, -0.06]}
                  radius={200}
                />
                <Rectangle bounds={rectangle} />
              </FeatureGroup>
            </LayersControl.Overlay> */}
          {/* </LayersControl> */}
          {latitude && longitude && (
            <Marker
              position={[
                latitude ? latitude : 21.1458, //latitude
                longitude ? longitude : 79.088158, //longitude
              ]}
              icon={customIcon}
            >
              <Popup>
                <button className="btn-close" aria-label="Close"></button>
                <PropertyCard
                  agentListingData={props?.propertyData?.propertDetails}
                  handelOpenPopUp={propertDetails}
                />
              </Popup>
            </Marker>
          )}

          {/* {features?.map(park => (
                        <Marker key={park?.properties?.PARK_ID}
                            icon={residentialmarkerIcon}
                            position={[park?.geometry?.coordinates[1], park?.geometry?.coordinates[0]]}
                        >
                            <Popup><PropertyCard /></Popup>
                        </Marker>
                    ))} */}
          <MarkerClusterGroup
            chunkedLoading
            // iconCreateFunction={createCustomClusterIcon}
          >
            {allProperty !== undefined ? (
              allProperty?.map((park, id) => (
                <Marker
                  key={`park${id}`}
                  icon={
                    park.property_listing_type?.property_listing_name ===
                    "Residential"
                      ? residentialmarkerIcon
                      : commertialmarkerIcon
                  }
                  position={[park?.latitude, park?.longitude]}
                >
                  <Popup>
                    <PropertyCard
                      agentListingData={park}
                      handelOpenPopUp={propertDetails}
                    />
                  </Popup>
                </Marker>
              ))
            ) : (
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="Map data © OpenStreetMap contributors"
              />
            )}
          </MarkerClusterGroup>
        </MapContainer>
      </div>

      {/* property detail pop up  */}
      {propertyDetailInStore.setshowPropertyPopup &&
        Object.keys(propertyDetailInfo).length > 0 && (
          <PropertyDetailsPopUp
            propertyDetailInfo={propertyDetailInfo} // passing info about property
            showLocationButton={true}
          />
        )}
    </>
  );
};

export default Mapview;
