import React, { useState } from 'react';
import { toast } from 'react-toastify';
import "./ForgetPass.scss";
import { useFormik } from "formik";
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Footer from '../homepage/components/footer/Footer';
import Navbar from '../homepage/components/navbar/Navbar';
import { ForgetPassword } from './server';
import Loader from '../Loader/Loader';

// validation code
const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Required*';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

const ForgetPass = () => {
    const [forgetTab, setForgetTab] = useState(true)
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();
    // const [password, setPassword] = useState("");
    // const [confpassword, setConfPassword] = useState("")
    // const [error, setError] = useState("");
    // const [confPassError, setConfPassError] = useState("")
    // const [isRevealPwd, setIsRevealPwd] = useState(false);
    // const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);

    // on form submit
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            confirmpassword: "",
        },
        validate,
        onSubmit: (values) => {
            let userVal = { email: values.email }
            ForgetPassword(userVal, (success) => {
                setLoading(true)
                if (success.data.code == 200 && success.data.status == "success") {
                    toast.success("Your Email is Verify, Please Check your mail", success.data.data, "success");
                    setLoading(false)
                }
                if (success.data.code == 400 && success.data.status == "failed") {
                    setLoading(false)
                    toast.warning("Please Enter Registered Mail Id", success.data.data, "warning");
                }
            })
            setLoading(!loading)
        },
    });

    return (
        <>
            {loading ? <Loader /> : null}
            <div>
                <Navbar />
                <div className="loginpage_body forget-page">
                    <div className="loginpage_form center flex-column">
                        {forgetTab ?
                            <div>
                                <div className="loginpage_text">
                                    <div className="loginpage_text_head">Forgot Password</div>
                                    <div className="loginpage_text_subhead ">
                                        <p> Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                                    </div>
                                </div>
                                <div className="loginpage_inputs">
                                    <div className="input-container">
                                        <input className="input-field" type="email" placeholder="Email" name="email"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    {/* validation message */}
                                    {formik.touched.email && formik.errors.email ? (<div className='validation-text log-validation'>{formik.errors.email}</div>) : null}
                                </div>
                                <div className="loginpage_help_button justify-content-center mb-3 mx-auto">
                                    <div className="loginpage_signin">
                                        <button className="loginpage_signin_btn" style={{ padding: "27px", width: "100%" }} type="submit"
                                            onClick={formik.handleSubmit}
                                        >
                                            <span className="loginpage_signin_btn_text">Reset Password</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ForgetPass
