import React from 'react'

const CommunityCard = (props) => {
    return (
        <>
            <div className="community-card d-flex flex-column">
                <div className="card-img">
                    <img src={props.data.img} alt="" />
                </div>
                <div className="card-bottom text-uppercase pointer center">
                    <p>{props.data.button}</p>
                </div>
            </div>
        </>
    )
}

export default CommunityCard
