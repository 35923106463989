import React, { useState } from "react";
import "./EditContact.scss";
import InputBar from "./InputBar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getToken } from "../../../../../appVariable/variable";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import { EditCustomUserProfileById } from "../../server";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { SyncLoader } from "react-spinners";
import { getTeamMemberList } from "../../server";
import { useDispatch } from "react-redux";
import { teamList } from "../../AgentOfficeSlice";

export const backendURL = process.env.REACT_APP_BACKEND_URL;

const EditContact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docUploadState, setDocState] = useState(false);
  const memberDetail = useSelector(
    (state) => state.virtualOfficeInStore.memberDetails
  );
  const virtualOfficeId = useSelector(
    (state) => state.virtualOfficeInStore.officeId
  );
  const formik = useFormik({
    initialValues: {
      Name: memberDetail.name,
      DOB: memberDetail.dob,
      ContactNumber1: memberDetail.contact_no_1,
      ContactNumber2: memberDetail.contact_no_2,
      Email: memberDetail.email,
      TownsOfInterest: memberDetail.towns_of_interest,
      DateOfClose: memberDetail.date_of_close,
      CurrentAddress: memberDetail.current_address,
      Price: memberDetail.purchase_rental_price,
      MartialStatus: memberDetail.martial_status,
      RelationShip: memberDetail.relationship,
      SignedDocument: [],
      AddNote: memberDetail.add_note,
    },
    validationSchema: Yup.object().shape({
      Name: Yup.string()
        .required("first Name required")
        // .matches("^[a-zA-Z]*$", "Number not accepted")
        .nullable()
        .max(30, "Must be 30 characters or less"),
      DOB: Yup.date()
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true;
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        })
        .required("Fill this field"),
      ContactNumber1: Yup.string()
        .required("Contact Number is required")
        .matches(/^[0-9]+$/, "Enter valid Number")
        .min(10, "Enter valid Number")
        .max(10, "Enter valid Number")
        .nullable(),
      ContactNumber2: Yup.string()
        .required("Contact Number is required")
        .matches(/^[0-9]+$/, "Enter valid Number")
        .min(10, "Enter valid Number")
        .max(10, "Enter valid Number")
        .nullable(),
      Email: Yup.string()
        .email("Enter valid Email")
        .nullable()
        .required("Fill this field"),
      TownsOfInterest: Yup.string().nullable().required("Fill this field"),
      DateOfClose: Yup.date()
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true;
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        })
        .required("Fill this field"),
      CurrentAddress: Yup.string().required("Enter Address").nullable(),
      MartialStatus: Yup.string().required("Enter Martial Status").nullable(),
      RelationShip: Yup.string().required("Enter Relationship").nullable(),
      Price: Yup.string().required("Enter Price").nullable(),
      SignedDocument: Yup.array()
        .required("Upload File")
        // .min(5, "must only 5 files")
        // .max(5, "must only 5 files")
        .nullable(),
      //   PreSignedDocument: Yup.array()
      //     .required("Upload File")
      //     // .min(5, "must only 5 files")
      //     // .max(5, "must only 5 files")
      //     .nullable(),
      AddNote: Yup.string()
        .required("Enter Notes")
        .max(280, "Must be 280 characters or less")
        .nullable(),
    }),

    onSubmit: (values) => {
      setLoading(true);
      let formData = new FormData();
      let userDetails = getToken();

      formData.append("userid", userDetails.userId);
      formData.append("name", values.Name);
      formData.append("dob", values.DOB);
      formData.append("contact_no_1", values.ContactNumber1);
      formData.append("contact_no_2", values.ContactNumber2);
      formData.append("email", values.Email);
      formData.append("towns_of_interest", values.TownsOfInterest);
      formData.append("date_of_close", values.DateOfClose);
      formData.append("current_address", values.CurrentAddress);
      formData.append("martial_status", values.MartialStatus);
      formData.append("relationship", values.RelationShip);
      formData.append("purchase_rental_price", values.Price);
      formik.values.SignedDocument.forEach((image) => {
        formData.append("signed_documents", image);
      });
      formData.append("add_note", values.AddNote);

      //   console.table([...formData]);

      // setLoading(true)
      // submitProfileData(formData, (success) => {
      //     if (success === "error") {
      //         setLoading(false)
      //         toast.warning("Someting wrong pleasse try Again")
      //     } else {
      //         // props.setShowLicense(true)
      //         // props.ChangeTab("third")
      //         // setLoading(false)
      //     }
      // });
      const customerId = memberDetail.id;
      const data = formData;
      EditCustomUserProfileById(customerId, data, (success) => {
        if (success.data.code === 200 && success.data.status === "success") {
          setLoading(false);
          formik.resetForm();
          toast.success("Successfully Submitted", { autoClose: 2000 });
          // getTeamMemberList(
          //   id,
          //   (success) => {
          //     if (success.data.code == 200) {
          //       dispatch(teamList(success.data.data));
          //     } else {
          //       dispatch(teamList([]));
          //     }
          //   },
          //   (error) => {
          //     console.log(error);
          //   }
          // );
          getTeamMemberList(
            virtualOfficeId,
            (success) => {
              if (success.data.code == 200) {
                dispatch(teamList(success.data.data));
              } else {
                dispatch(teamList([]));
              }
            },
            (error) => {
              console.log(error);
            }
          );
          navigate(`/agent-virtual-office/contact/`);
        } else {
          toast.error("There's error while submitting", { autoClose: 2000 });
        }
      });
    },
  });

  // function UploadDocument(e) {
  //   const files = e.target.files;
  //   const fileResponsesArray = [];
  //   for (let i = 0; i < files.length; i++) {
  //     fileResponsesArray.push(files[i]);
  //   }

  //   setDocs(fileResponsesArray);
  //   if (fileResponsesArray !== []) {
  //     setDocState(true);
  //     //   formik.setFieldValue("SignedDocument", fileResponsesArray);
  //     formik.setFieldValue("SignedDocument", []);
  //   } else {
  //     formik.setFieldValue("SignedDocument", memberDetail.signed_documents);
  //   }
  // }

  return (
    <>
      <div
        className="d-flex flex-column w-100 edit-contact "
        style={{ marginTop: "80px" }}
      >
        {/* <Filterbar /> */}
        <div className=" d-flex flex-column gap-4">
          <p className="text-uppercase title">customer info</p>
          <div className="edit-form d-flex flex-column hide-scrollbar">
            <div className="d-flex justify-content-between gap-5">
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Name"
                  type="text"
                  name="Name"
                  onChange={formik.handleChange}
                  value={formik.values.Name}
                />
                {formik.errors.Name && formik.touched.Name ? (
                  <div className="validation-text text-end ">
                    {formik.errors.Name}
                  </div>
                ) : null}
              </div>
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="DOB"
                  type="date"
                  name="DOB"
                  onChange={formik.handleChange}
                  value={formik.values.DOB}
                />
                {formik.errors.DOB && formik.touched.DOB ? (
                  <div className="validation-text text-end">
                    {formik.errors.DOB}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-between gap-5">
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Contact Number 1"
                  type="number"
                  name="ContactNumber1"
                  onChange={formik.handleChange}
                  value={formik.values.ContactNumber1}
                />
                {formik.errors.ContactNumber1 &&
                formik.touched.ContactNumber1 ? (
                  <div className="validation-text text-end">
                    {formik.errors.ContactNumber1}
                  </div>
                ) : null}
              </div>
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Contact Number 2"
                  type="number"
                  name="ContactNumber2"
                  onChange={formik.handleChange}
                  value={formik.values.ContactNumber2}
                />
                {formik.errors.ContactNumber2 &&
                formik.touched.ContactNumber2 ? (
                  <div className="validation-text text-end">
                    {formik.errors.ContactNumber2}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-between gap-5">
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Email"
                  type="email"
                  name="Email"
                  onChange={formik.handleChange}
                  value={formik.values.Email}
                />
                {formik.errors.Email && formik.touched.Email ? (
                  <div className="validation-text text-end">
                    {formik.errors.Email}
                  </div>
                ) : null}
              </div>
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Towns of interest"
                  type="text"
                  name="TownsOfInterest"
                  onChange={formik.handleChange}
                  value={formik.values.TownsOfInterest}
                />
                {formik.errors.TownsOfInterest &&
                formik.touched.TownsOfInterest ? (
                  <div className="validation-text text-end">
                    {formik.errors.TownsOfInterest}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-between gap-5">
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Date of close"
                  type="date"
                  name="DateOfClose"
                  onChange={formik.handleChange}
                  value={formik.values.DateOfClose}
                />
                {formik.errors.DateOfClose && formik.touched.DateOfClose ? (
                  <div className="validation-text text-end">
                    {formik.errors.DateOfClose}
                  </div>
                ) : null}
              </div>
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Current Address"
                  type="text"
                  name="CurrentAddress"
                  onChange={formik.handleChange}
                  value={formik.values.CurrentAddress}
                />
                {formik.errors.CurrentAddress &&
                formik.touched.CurrentAddress ? (
                  <div className="validation-text text-end">
                    {formik.errors.CurrentAddress}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between gap-5">
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Martial Status"
                  type="text"
                  name="MartialStatus"
                  onChange={formik.handleChange}
                  value={formik.values.MartialStatus}
                />
                {formik.errors.MartialStatus && formik.touched.MartialStatus ? (
                  <div className="validation-text text-end">
                    {formik.errors.MartialStatus}
                  </div>
                ) : null}
              </div>
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Relation ship"
                  type="text"
                  name="RelationShip"
                  onChange={formik.handleChange}
                  value={formik.values.RelationShip}
                />
                {formik.errors.RelationShip && formik.touched.RelationShip ? (
                  <div className="validation-text text-end">
                    {formik.errors.RelationShip}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between gap-5">
              <div className=" flex-grow-1 input-box">
                <InputBar
                  label="Purchase/Rental Price"
                  type="number"
                  name="Price"
                  onChange={formik.handleChange}
                  value={formik.values.Price}
                />
                {formik.errors.Price && formik.touched.Price ? (
                  <div className="validation-text text-end">
                    {formik.errors.Price}
                  </div>
                ) : null}
              </div>
              <div
                className=" flex-grow-1 input-box d-flex gap-3 align-items-center"
                style={{ paddingLeft: "1%" }}
              >
                <p>Signed Document</p>

                {/* <div className="upload-doc">
                  <input
                    type="file"
                    name="SignedDocument"
                    id="uploadSignFile"
                    hidden
                    multiple={true}
                    // ref={floreImage}
                    accept="application/pdf"
                    onChange={(e) => {
                      UploadDocument(e);
                    }}
                    key={0}
                  />
                  <label className=" pointer" htmlFor="uploadSignFile">
                    <PostAddIcon style={{ color: "#315EFB" }} />
                  </label>
                </div> */}
                {docUploadState === true
                  ? docs.map((data, index) => (
                      <div className="view-doc">
                        <label
                          className=" pointer"
                          htmlFor="uploadSignFile"
                          key={`upload${index + 1}`}
                        >
                          <DescriptionIcon style={{ color: "#315EFB" }} />
                        </label>
                      </div>
                    ))
                  : memberDetail?.signed_documents?.map((data, index) => (
                      <div className="view-doc">
                        <label
                          className=" pointer"
                          htmlFor="uploadSignFile"
                          key={`view${index + 1}`}
                        >
                          <DescriptionIcon style={{ color: "#315EFB" }} />
                        </label>
                      </div>
                    ))}
              </div>
            </div>

            <div className="d-flex justify-content-between gap-5">
              <div className=" flex-grow-1 ">
                <div className="custume-inputbar d-flex ">
                  <div className="input-label d-flex pe-2 justify-content-end ">
                    <p className=" text-capitalize">Add Note</p>
                  </div>
                  <div className=" ps-1 flex-grow-1 ">
                    <textarea
                      className=" w-100"
                      name="AddNote"
                      onChange={formik.handleChange}
                      value={formik.values.AddNote}
                    ></textarea>
                  </div>
                </div>
                <div className="text-end">
                  <p style={{ fontSize: "12px", color: "#546781" }}>
                    {formik.values.AddNote ? formik.values.AddNote.length : 0}
                    /280 characters
                  </p>
                </div>
                {formik.errors.AddNote && formik.touched.AddNote ? (
                  <div className="validation-text text-end">
                    {formik.errors.AddNote}
                  </div>
                ) : null}
              </div>
            </div>
            {loading === true ? (
              <div className="center h-100">
                <SyncLoader color="#315efb" />
              </div>
            ) : null}

            <div className=" text-end">
              <button
                type="submit "
                className="blue__btn"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditContact;
