import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";



export async function getSaveSearchFilter(success) {
  const token = getToken()
  const userId = token?.userId;
  await axios.get(`${backendURL}/api/property/filtersavesearchget/${userId}`)
    .then((resp) => {
      // console.log("resp", resp.data);
      // if (resp.data.code == 200 && resp.data.status == success) {
      success(resp);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getSaveSearchFilterPropertyDetail(id, success) {
  const token = getToken()
  const userId = token?.userId;
  await axios.get(`${backendURL}/api/property/Filter/${id}?user_id=${userId}`, {
    headers: {
      Authorization: `Bearer ${token?.idToken}`,
    },
  })
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function DeleteSaveSearch(id, success) {
  await axios.delete(`${backendURL}/api/property/filtersavesearch/${id}`, {
    // headers: { Authorization: `Bearer ${token?.idToken}`, },
  })
    .then((resp) => {
      success(resp.data);
    })
    .catch((err) => { });
}