import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../../appVariable/variable";
// const types = ["success", "info", "warning", "error"]; toaster type
// let userlogin = getToken()

const ProtectedRoute = ({ component }) => {
  let userlogin = getToken();
  console.log("in ProtectedRoute");
  if (userlogin?.user_info.usertypeobj === 1) {
    return component;
  } else {
    return <Navigate to="/" />;
  }
};

export const AgentRoute = ({ component }) => {
  let userlogin = getToken();
  // console.log("in AgentRoute")
  if (
    userlogin?.user_info.usertypeobj === 2
    // && (userlogin?.user_info.agentsubscription === true)
  ) {
    return component;
  } else {
    return <Navigate to="/" />;
    // return <Navigate to="/subscription" />;
  }
};
export const SignRoute = ({ component }) => {
  let userlogin = getToken();
  console.log("in SignRoute");
  if (userlogin?.user_info.id) {
    return <Navigate to="/" />;
  } else {
    return component;
  }
};
export const OepnRoute = ({ component }) => {
  let userlogin = getToken();
  console.log(userlogin?.user_info.id);
  if (userlogin?.user_info.id) {
    return component;
  } else {
    return component;
  }
};

export const SubscriptionRoute = ({ component }) => {
  return component;
};

export const AgentProfileRoute = ({ component }) => {
  let userlogin = getToken();
  if (
    userlogin?.user_info.agent_profile === true &&
    (userlogin?.user_info.usertypeobj === 2 ||
      userlogin?.user_info.usertypeobj === 6)
  ) {
    // && userlogin?.user_info.billing === true) {
    return component;
  } else {
    if (userlogin?.user_info.billing === false) {
      return <Navigate to="/subscription" />;
    } else {
      return <Navigate to="/agent-dashboard/profile-settings" />;
    }
  }
};

export const AgentProfileSetting = ({ component }) => {
  let userlogin = getToken();
  if (userlogin?.user_info.usertypeobj === 2) {
    return component;
  }
  // if (userlogin?.user_info.usertypeobj === 2 && userlogin?.user_info.agentsubscription === true) { return component; }
  else {
    return <Navigate to="/subscription" />;
  }
};

export default ProtectedRoute;
