import React from 'react'
import './sellPerson.scss'
import filter from "./images/filter.svg";
import search from "./images/search.svg";
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import { SearchAgent } from '../server';
import { CircularProgress } from '@mui/material';
import { getToken } from '../../../../appVariable/variable';


const SelPerson = ({ setSetsearchPersons }) => {
    let token = getToken()
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false)
    let handleSearch = (e) => {
        e.preventDefault()
        let searchObj = { search: e.target[0].value, user: token.userId}
        setLoading(true)
        SearchAgent(searchObj, (success) => {
            setLoading(false)
            setSetsearchPersons(success)
        })
        // e.target.reset()
    }
    return (
        <>
            <div className='sel_person_section flex-grow-1 '>
                <div className="search-box d-flex flex-grow-1 justify-content-between">
                    <form className="search d-flex align-items-center justify-content-between flex-grow-1" onSubmit={handleSearch} >
                        <input type="text" className="trans-input" placeholder="Search Town or City" name="searchQuary" id="" autoComplete='off' />
                        <button type='submit' className="search-icon pointer border-0 bg-transparent d-flex" >
                            {loading ? <CircularProgress className=' h-auto' style={{ width: "20px" }} /> :
                                <img src={search} alt="" />}
                        </button>
                    </form>
                    <div onClick={() => setModalShow(true)} className="filter-icon pointer center"><img src={filter} alt="" /> </div>
                </div>

                {/* <div className=' d-flex gap-3'>
                    <button className='blue__btn all_savebtn'>Save Selected</button>
                    <button className='blue__btn all_savebtn'>Save All</button>
                </div> */}
            </div>

            {/*search modal */}
            <div className="forgot_modals">
                <Modal className="forgot_modals"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    ria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton className="border-bottom-0 forgotmodal_body pb-0">
                        {/* <Modal.Title id="contained-modal-title-vcenter " className="border-0"> </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="px-4 pt-0 forgotmodal_body">
                        {/* form section */}
                        <div className='form_selperson'>
                            <div className="card card_borders border-0">
                                <div className="card-body">
                                    <Row>
                                        <Col sm={6}>
                                            <div className="form-group pb-3 mb-1">
                                                <label htmlFor="" className='lable_text'>Full Name</label>
                                                <input type="text" className="form-control mt-1" />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="form-group pb-3 mb-1">
                                                <label htmlFor="" className='lable_text'>Email</label>
                                                <input type="text" className="form-control mt-1" />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="form-group pb-3 mb-1">
                                                <label htmlFor="" className='lable_text'>Zipcode</label>
                                                <input type="text" className="form-control mt-1" />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="form-group pb-3 mb-1">
                                                <label htmlFor="" className='lable_text'>Company </label>
                                                <Form.Select aria-label="Default select example" className='form_select mt-1'>
                                                    <option>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="form-group pb-3 mb-1 ">
                                                <label htmlFor="" className='lable_text'>Language</label>
                                                <Form.Select aria-label="Default select example" className='form_select mt-1'>
                                                    <option>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="form-group pb-3 mb-1">
                                                <label htmlFor="" className='lable_text'>Star Rating </label>
                                                <input type="text" className="form-control mt-1" />
                                            </div>
                                        </Col>
                                        <Col sm={12} className="text-end">
                                            <button className='apply_btn blue__btn'>Apply</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}

export default SelPerson
