import React, { useEffect, useState } from "react";
import "./Agentprofile.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Agentbioabout from "./Components/Agentbioabout";
import AgentCurrentlisting from "./Components/AgentCurrentlisting";
import AgentCloseListing from "./Components/AgentCloseListing";
import AgentTeams from "./Components/AgentTeams";
import { currentListingPropertyCount } from "../currentListing/server";
import { getAgentDetails } from "../profile/components/personal/server";
import {
  expireListingPropertyCount,
  totalListingcount,
} from "../expiredListing/server";
import { Hosturl, backendURL, getToken } from "../../../appVariable/variable";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import icons from "../dashboard/components/sidebar/images/SidebarIcon";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertyDatail } from "../dashboard/propperty-datails/PropertySlice";
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import { bgprogile, prifilepic } from "../profile/components/personal/Personal";

export default function Agentprofile() {
  let token = getToken();
  const params = useParams();
  let agentSlug = token?.profile.slug;
  let agentNameFromUrl = params.id;
  let paramObj = agentNameFromUrl ? agentNameFromUrl : agentSlug;
  const dispatch = useDispatch();
  const [currentListCount, setCurrentListCount] = useState([]);
  const [expireListingCount, setExpireListingCont] = useState([]);
  const [totalListingCounts, setTotalLiastingCounts] = useState([]);
  const [getAgentDetail, setGetAgentDetail] = useState([]);
  const [coverImg, setCoverImg] = useState(icons.coverphoto);
  const [profileImg, setProfileImg] = useState(icons.profile);
  const [linkCopied, setLinkCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );

  useEffect(() => {
    setLoading(true);
    currentListingPropertyCount(paramObj, (success) =>
      setCurrentListCount(success)
    );
    expireListingPropertyCount(paramObj, (success) =>
      setExpireListingCont(success)
    );
    totalListingcount(paramObj, (success) => setTotalLiastingCounts(success));
    getAgentDetails(paramObj, (success) => {
      setGetAgentDetail(success);
      setCoverImg(
        success[0]?.cover_image
          ? `${backendURL}${success[0]?.cover_image}`
          : coverImg
      );
      setProfileImg(
        success[0]?.profile_image
          ? `${backendURL}${success[0]?.profile_image}`
          : profileImg
      );
      setLoading(false);
    });
  }, []);

  return (
    <div className="agentbiomain w-100 container">
      {loading ? <Loader /> : null}
      {!loading ? (
        <main className=" pb-3 h-100 ">
          <div
            className=" h-100 hide-scrollbar agentbiomain-container "
            style={{ maxHeight: agentNameFromUrl ? "100vh" : "80vh" }}
          >
            {/* <span className="agentaboutbio">Work Bio</span> */}
            {!agentNameFromUrl && (
              <div className="table-btn mb-1">
                <Button
                  variant="outline-primary"
                  className="long-btn"
                  onClick={() => Navigate(-1)}
                >
                  Back
                </Button>
              </div>
            )}
            <section className="mb-5">
              <div className="bg-image rounded-bottom shadow-1-strong img-fluid headimg">
                <img
                  src={coverImg}
                  alt=""
                  srcSet=""
                  style={{ height: "100%" }}
                />
                <div className="d-flex justify-content-center">
                  <img
                    className="shadow-3-strong position-absolute profile img-fluid"
                    src={profileImg}
                    alt=""
                  />
                </div>
              </div>
            </section>

            <div className="d-flex justify-content-between agen-profile-listing mt-4">
              <div className="profile-name d-flex flex-column gap-2">
                <h6 className=" text-capitalize">{`${getAgentDetail[0]?.first_name} ${getAgentDetail[0]?.last_name}`}</h6>
                {getAgentDetail && <p>{getAgentDetail[5]?.lic_Type}</p>}
              </div>
              <div className="listing d-flex">
                <div className=" pointer fw-bold">
                  <CopyToClipboard
                    text={`${Hosturl}agents/${paramObj}`}
                    onCopy={() => {
                      setLinkCopied(true);
                    }}
                  >
                    <Tooltip
                      title={linkCopied ? "Link Copied" : "Share your profile"}
                      placement="top"
                    >
                      <ShareIcon />
                    </Tooltip>
                  </CopyToClipboard>
                </div>

                <div className="listing-info center flex-column ">
                  <p>Total Listing</p>
                  {totalListingCounts?.data?.code === 200 ? (
                    <p className="listing-info-price">
                      {totalListingCounts.data.data}
                    </p>
                  ) : (
                    <p className="listing-info-price">. . .</p>
                  )}
                </div>
                <div className="listing-info center flex-column ">
                  <p>Current Listing</p>
                  {currentListCount.length === 0 ? (
                    <p className="listing-info-price">. . .</p>
                  ) : (
                    <p className="listing-info-price">{currentListCount}</p>
                  )}
                </div>
                <div className="listing-info center flex-column ">
                  <p>Closed Listing</p>
                  {expireListingCount?.data?.code === 200 ? (
                    <p className="listing-info-price">
                      {expireListingCount.data.data}
                    </p>
                  ) : (
                    <p className="listing-info-price">. . .</p>
                  )}
                </div>
              </div>
            </div>

            <section className="agentbio_tabs">
              <Tabs
                defaultActiveKey="About"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="About"
                  title="About"
                  className="navbar_agentbio_tabs"
                >
                  <Agentbioabout getAgentDetails={getAgentDetail} />
                </Tab>
                {getAgentDetail[0]?.team_name && (
                  <Tab eventKey="Team" title="Team">
                    <AgentTeams getAgentDetails={getAgentDetail} />
                  </Tab>
                )}
                <Tab eventKey="Current Listing" title="Current Listing">
                  <AgentCurrentlisting listing={getAgentDetail[7]} />
                </Tab>
                <Tab eventKey="Closed Listing" title="Closed Listing">
                  <AgentCloseListing listing={getAgentDetail[8]} />
                </Tab>
              </Tabs>
            </section>
          </div>
        </main>
      ) : null}
    </div>
  );
}
