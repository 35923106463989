import React, { useState } from 'react'
import "./Sidebar.scss"
import Sidelink from './Sidelink'
import icons from "./images/SidebarIcon"
import Modal from 'react-bootstrap/Modal';
import { NavLink, useNavigate } from 'react-router-dom';
import { getToken } from '../../../../../appVariable/variable';
import { checkpassword, ProfileSettingForgetPassword } from '../../../profile/components/password/server';
// import { FormControl, IconButton, Input, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import Loader from '../../../Loader/Loader';
import { useFormik } from 'formik';
import { Passcheck } from '../../../profile/components/password/Password';


const Sidebar = (props) => {
    let userDetails = getToken()
    const [settingoption, setSettingoption] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [popupurl, setPopupurl] = useState('')
    const [checkPasswordInput, setCheckPasswordInput] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [forgetProfPass, setForgetProfPass] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    // const [profilePassEmail, setProfilePassEmail] = useState("");
    const Navigate = useNavigate()

    const formik = useFormik({
        initialValues: { email: "", },
        validationSchema: Yup.object({ email: Yup.string().email().required("required"), }),
        onSubmit: async (values, action) => {
            const userVal = { email: formik.values.email, }
            setLoading(true)
            await ProfileSettingForgetPassword(userVal, (success) => {
                if (success.data.code === 200 && success.data.status === "success") {
                    // Navigate('/agent-dashboard/profile-settings/reset-password')
                    setLoading(false)
                    setModalShow(false)
                    toast.success(success.data.data)
                }
                if (success.data.code === 400 && success.data.status === "failed") {
                    toast.error(success.data.data)
                    setLoading(false)
                }
            });
            action.resetForm();
        },
    });


    const gotopage = (status) => {
        if (status === "by popup") {
            let tempObj = {};
            tempObj["user_id"] = userDetails.userId;
            tempObj["profile_password"] = checkPasswordInput;
            console.log(tempObj)
            if (checkPasswordInput !== "") {
                setLoading(true)
                checkpassword(tempObj, (success) => {
                    if (success.data.code === 200 && success.data.status === "success") {
                        Navigate(popupurl)
                        setModalShow(false)
                        setSettingoption(!settingoption)
                        setLoading(false)
                        setCheckPasswordInput("")
                        setForgetProfPass(false)
                    }
                    if (success.data.code === 400 && success.data.status === "failed") {
                        console.log(success.data.data);
                        setLoading(false)
                        toast.error(success.data.data)
                        setSettingoption(!settingoption)
                        setCheckPasswordInput("")
                        // setModalShow(false)
                    }
                });
            }
            else {
                setError("*Plese Enter Password")
            }
        }
        if (status === "without popup") {
            Navigate(popupurl)
            setModalShow(false)
            setSettingoption(!settingoption)
        }
    }
    const Gotolink = (directto) => {
        setPopupurl(directto)
        let userDetails = getToken()
        if (userDetails?.profile.is_profilepassword) {
            setModalShow(true)
            setSettingoption(false)
        } else {
            // gotopage("without popup")
            Navigate(directto)
            setModalShow(false)
            setSettingoption(!settingoption)
        }
    }
    let currentpage = window.location.href;
    let result = currentpage.includes("agent-dashboard");

    return (
        <>
            {loading ? <Loader /> : null}
            {/* <div className="sidebar "> */}
            <div className={props.pointerEvent ? "sidebar" : "sidebar sidebar-disable"}>
                <div className="sub-sidebar d-flex flex-column  justify-content-between">
                    <div className='sidebar-links d-flex flex-column' onClick={() => setSettingoption(false)}>
                        {props.sidebardata?.map((link, i) =>
                            <Sidelink icon={link.icon} link={link.link} title={link.title}
                                sidebar={props.sidebar} key={`agent${i}`} />
                        )}
                    </div>
                    <div className={props.settingOption ? ' position-relative setting-option' : ' position-relative'}>
                        <div className="setting_Droupdown box-shodow flex-column gap-2 position-absolute" style={{ display: settingoption && result ? 'flex' : "none" }}>
                            <p onClick={() => Gotolink(`/agent-dashboard/profile-settings`)}>Profile Settings</p>
                            <p onClick={() => Gotolink(`/agent-dashboard/plan&billing`)}>Plan & Billing</p>
                        </div>
                        <div onClick={() => setSettingoption(!settingoption)} className=" pointer">
                            <Sidelink icon={icons.settings} link={'profile-setting'} title={'Settings'} sidebar={props.sidebar}
                            />
                        </div>

                    </div>
                </div>
            </div>

            <div className="setting-popup">
                <Modal size="lg" centered
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className='forgotmodal_body'>

                        {forgetProfPass ?
                            <div>
                                <div className="forgot_title mb-3">Forgot Password</div>
                                <div className="forgot_small_text pb-4">
                                    Lost your password? Please enter your username or email address.
                                    You will receive a link to create a new password via email.
                                </div>
                                <div className="d-flex main_input">
                                    <div className="input_name grow-1 text-end input_namepad">
                                        Email<span className="red-star">*</span>
                                    </div>
                                    <div className="input-sec">
                                        <input type="email" className="form-control border-0 pb-0"
                                            // name="profilePassEmail"
                                            autoComplete="off"
                                            name="email"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />

                                    </div>
                                    {formik.errors.email && formik.touched.email ? (
                                        <div className='validation-text'>{formik.errors.email}</div>
                                    ) : null}

                                </div>
                                <div className="text-end mb-3 mt-3">
                                    <button type="submit" className="submit_btn"
                                        // onClick={handelEmailVerification}
                                        onClick={formik.handleSubmit}
                                    >
                                        Reset Password
                                    </button>
                                    <p className=' mt-3 pointer '
                                        onClick={() => setForgetProfPass(false)}
                                    > <span>Continue with password</span></p>
                                </div>
                                <div>
                                    <NavLink to="/" className="text-decoration-none text-center mb-1">
                                        <p>Already have an account ? Click to <span>Log in</span> instantly</p>
                                    </NavLink>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="forgot_small_text pb-4">Enter Your Password</div>
                                <div className="d-flex main_input pb-0">
                                    <div className="input_name grow-1 text-end input_namepad">
                                        Password<span className="red-star">*</span>
                                    </div>
                                    <div className="input-sec d-flex">
                                        <input className="form-control border-0 pb-0"
                                            type={showPassword ? 'text' : 'password'}
                                            value={checkPasswordInput}
                                            onChange={(e) => { setCheckPasswordInput(e.target.value); setError("") }}
                                        />
                                        <div className=' pointer' onClick={handleClickShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    </div>
                                </div>
                                <div className='validation-text'>{error}</div>
                                <div className="text-end mb-3 forgotmodal_body mt-3">
                                    <button type="submit" className="submit_btn"
                                        onClick={() => gotopage("by popup")}
                                        disabled={!(Passcheck.test(checkPasswordInput))}
                                    >Submit</button>

                                    <p className=' mt-3 pointer '
                                        onClick={() => setForgetProfPass(true)}
                                    > <span>Forget password</span></p>
                                </div>
                            </div>
                        }

                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Sidebar
