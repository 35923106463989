import React, { useEffect, useState } from 'react'
import { getToken } from '../../../../appVariable/variable';
import { accountSetting, getAccounttype } from './server';
import './typeofAccount.scss'
const TypeofAccount = () => {
    let token = getToken()
    let userId = token?.userId;
    const [typeofAccount, setTypeofAccount] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [enote, setEnote] = useState(false);

    let updateaccount = (e, i) => {
        let accountobj = {}
        accountobj.user_id = userId
        switch (i) {
            case 1: {
                accountobj.type_allowed = e ? 1 : 0
                token.user_info.type_of_listing = e ? 1 : 0
                localStorage.setItem("userDetails", JSON.stringify(token));
                break;
            }
            case 2: {
                accountobj.email_notification = e ? 1 : 0
                break;
            }
            case 3: {
                accountobj.text_notification = e ? 1 : 0
                break;
            }
            default: return accountobj;

        }
        accountSetting(accountobj, (success) => {
            // console.log(success)
        })
    }
    useEffect(() => {
        getAccounttype((success) => {
            setTypeofAccount(success.type_allowed ? true : false)
            setEmailNotification(success.email_notification)
            setEnote(success.text_notification)

        }, 
        (error) => {
            console.log(error)
        });
    }, []);



    return (
        <div>
            <div className='typeof_account_pag'>
                <div className='Account_text'>Account Setting</div>

                <div className='d-flex justify-content-start flex-wrap radio_button'>
                    <span className='type_of_account'>Account :</span>
                    <span className='pe-3 resdent_text'>Residential</span>
                    <span className='pe-3'>
                        <div className="form-check form-switch pt-0">
                            <input className="form-check-input p-0" type="checkbox" id="flexSwitchCheckChecked"
                                checked={typeofAccount}
                                onChange={(e) => { setTypeofAccount(e.target.checked); updateaccount(e.target.checked, 1) }}
                            />
                        </div>
                    </span>
                    <span className='pe-3 resdent_text'>Commercial</span>
                </div>
                <div className='Account_text'>Notification Setting</div>


                <div className='row radio_button'>
                    <div className='col-sm-6 d-flex'>
                        <span className='type_of_account'>Email Notification :</span>
                        <span className='pe-3 resdent_text'>Yes</span>
                        <span className='pe-3'>
                            <div className="form-check form-switch pt-0">
                                <input className="form-check-input p-0" type="checkbox" id="flexSwitchCheckChecked"
                                    checked={emailNotification}
                                    onChange={(e) => {
                                        setEmailNotification(e.target.checked)
                                        updateaccount(e.target.checked, 2)
                                    }}
                                />
                            </div>
                        </span>
                        <span className='pe-3 resdent_text'>No</span>
                    </div>
                    <div className='col-sm-6 d-flex'>
                        <span className='type_of_account'>Text Notification :</span>
                        <span className='pe-3 resdent_text'>Yes</span>
                        <span className='pe-3'>
                            <div className="form-check form-switch pt-0">
                                <input className="form-check-input p-0" type="checkbox" id="flexSwitchCheckChecked"
                                    name="emailnote"
                                    checked={enote}
                                    onChange={(e) => {
                                        setEnote(e.target.checked);
                                        updateaccount(e.target.checked, 3)
                                    }}
                                />
                            </div>
                        </span>
                        <span className='pe-3 resdent_text'>No</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TypeofAccount
