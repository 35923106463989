import React from 'react'
import { backendURL } from '../../../../../appVariable/variable'
// import ad from "./images/ad.jpg"
// import { prifilepic } from '../../../profile/components/personal/Personal'
import { Rating } from '@mui/material'
import icons from '../sidebar/images/SidebarIcon'

const AdCard = (props) => {
    const { Memberinfo } = props
    // console.log(Memberinfo)

    return (
        <>
            <div className="ad-card position-relative center gap-2">
                <div className="ad-card-img">
                    <img src={Memberinfo.profile ? `${backendURL}${Memberinfo.profile}` : icons.profile} alt="" />
                </div>
                <div className=' center flex-column rating-div'>
                    <p className='member_name text-center text-capitalize'>{Memberinfo.name}</p>
                    <Rating name="read-only" className='rating' value={3} readOnly />
                </div>
            </div>
        </>
    )
}

export default AdCard
