import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";
import "./checkOut.scss"
// import master from "./image/master.jpg"
// import visa from "./image/master.jpg"
import MasterCard_Logo from "./image/MasterCard_Logo.png"
import add from "./image/add.svg"
import { useState } from 'react';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { getAllcity, getAllstate, getAllzipcode } from '../personal/personalInformation/Server';
import Select from "react-select";
import { getToken } from '../../../appVariable/variable';
import moment from 'moment';
import { AddnewCard, billAmmount, billCheckout, getuserCards } from './server';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/es/styles-compiled.css';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logo } from '../homepage/components/navbar/Navbar';

const CheckOut = () => {
    let token = getToken()
    let userId = token?.userId;
    const [modalShow, setModalShow] = useState(false);
    const [allZipcodes, setAllZipcodes] = useState();
    const [allstate, setAllstate] = useState();
    const [allcity, setAllcity] = useState();
    const [zipcodeid, setZipcodeid] = useState();
    const [stateid, setStateid] = useState();
    const [cityid, setCityid] = useState();
    const [totalammount, setTotalammount] = useState(0);
    const [billingPlans, setBillingPlans] = useState(0);
    const [savecards, setSavecards] = useState();
    const [loading, setLoading] = useState(false);
    // const [updatehero, setUpdatehero] = useState(true)
    // let { id } = useParams();
    const navigate = useNavigate();


    const formik = useFormik({
        initialValues: {
            firtName: "",
            LastName: "",
            PhoneNumber: "",
            AddressLine1: "",
            AddressLine2: "",
            Town: "",
            State: null,
            ZipCode: null,
            cardId: ""
        },
        validationSchema: Yup.object({
            firtName: Yup.string().required("*first Name required").matches("^[a-zA-Z]*$", 'Number & Special character not allowed').max(20, "Must be 20 characters or less"),
            LastName: Yup.string().required("*Last Name required").matches("^[a-zA-Z]*$", 'Number & Special character not allowed').max(20, "Must be 20 characters or less"),
            PhoneNumber: Yup.string().min(10, "Enter correct Number").max(10, "Enter correct Number").required("*Phone Number required").nullable(),
            AddressLine1: Yup.string().required("*Address required"),
            Town: Yup.string().required("*Town required"),
            cardId: Yup.string().required("Please add and select card"),
        }),
        onSubmit: (values) => {
            let billingobj = {
                "user_id": userId,
                "card_id": 1,
                "first_name": values.firtName,
                "last_name": values.LastName,
                "phone_number": `${values.PhoneNumber}`,
                "address_line_1": values.AddressLine1,
                "address_line_2": values.AddressLine2,
                "town": values.Town,
                "state": values.State,
                "zipcode": values.ZipCode,
                "total_amount": totalammount,

            }
            // console.log(billingobj)
            setLoading(true)
            billCheckout(billingobj, (success) => {
                if (success.code == 200 && success.status == "success") {
                    let add_on = { add_on: success.data[1]?.add_on }
                    localStorage.setItem("add_on", JSON.stringify(add_on));
                    token.user_info.billing = true
                    token.user_info.agentsubscription = true
                    localStorage.setItem("userDetails", JSON.stringify(token));
                    toast.success(`${success.data[0]} !`, "success")
                    setLoading(false)
                    navigate("/agent-dashboard/profile-settings")
                }
                else {
                    setLoading(false)
                    toast.error("Something Went Wrong")
                }
            });
        },
    });

    const NewCardformik = useFormik({
        initialValues: {
            CardHolderName: "",
            CardNumber: "",
            ExpiryDate: "",
            cvc: "",
        },
        validationSchema: Yup.object({
            // CardHolderName: Yup.string().required("*Card Holder Name required"),
            CardHolderName: Yup.string().required("*first Name required").matches("^[a-zA-Z]*$", 'Number & Special character not allowed'),
            CardNumber: Yup.string().min(16, "please enter correct number").max(16, "please enter correct number").required("*Card Number required"),
            ExpiryDate: Yup.date().required("*Expiry Date required")
                .test('valid-expiry', 'Your Card is Expire', function (value) {
                    const currentMonth = new Date().getMonth() + 1;
                    const currentYear = new Date().getFullYear() % 100;
                    const [expiryMonth, expiryYear] = moment(value).format("MM/YY").split('/');
                    const expiryMonthInt = parseInt(expiryMonth, 10);
                    const expiryYearInt = parseInt(expiryYear, 10);
                    // console.log("currentMonth", currentMonth)
                    // console.log("currentYear", currentYear)
                    // console.log("expiryMonthInt", expiryMonthInt)
                    // console.log("expiryYearInt", expiryYearInt)
                    if (expiryYearInt < currentYear) {
                        return false;
                    } else if (expiryYearInt === currentYear && expiryMonthInt < currentMonth) {
                        return false;
                    }
                    return true;
                }),
            cvc: Yup.string().min(3, "please enter correct cvv").max(3, "please enter correct cvv").required("*cvc required"),
        }),
        onSubmit: (values, action) => {
            let cardobj = {
                "user_id": userId,
                "card_name": values.CardHolderName,
                "card_number": `${values.CardNumber}`,
                "expirary_date": moment(values.ExpiryDate).format("MM/YY"),
                "cvv": values.cvc
            }
            // console.log(cardobj)
            setLoading(true)
            AddnewCard(cardobj, (success) => {
                // console.log("", success);
                if (success.code == 200 && success.status == "success") {
                    // swal("", ` ${success.data} `, "success")
                    toast.success(success.data, "success")
                    action.resetForm();
                    setLoading(false)
                    setModalShow(false)
                    getuserCards((success) => { setSavecards(success) })
                }
                if (success.code == 400 && success.status == "failed") {
                    // swal("", ` ${success.data} `, "error")
                    toast.error(success.data, "error")
                    action.resetForm();
                    setLoading(false)
                }

            });
        },
    });

    useEffect(() => {
        getAllstate((success) => {
            let newarr = success.map((item) => { return { label: `${item.state_name}`, id: item.id, }; });
            setAllstate([...newarr]);
        })
        getAllzipcode((success) => {
            let newarr = success.map((item) => { return { label: `${item.Zipcode}`, id: item.id, }; });
            setAllZipcodes([...newarr]);
        })
        getAllcity((success) => {
            let newarr = success.map((item) => { return { label: `${item.city_name}`, id: item.id, }; });
            setAllcity([...newarr]);
        })
        let billamt = { "user_id": userId }
        billAmmount(billamt, (success) => { setBillingPlans(success[0].plan); setTotalammount(success[1].total) });
        getuserCards((success) => { setSavecards(success) })
        // deletecard
    }, [])

    return (
        <>
            {loading ? <Loader /> : null}
            <div className='navbar d-flex justify-content-between'><div className="logo pointer" ><img src={logo} alt="" /></div></div>

            <div className='CheckOut_agent_form hide-scrollbar'>
                <div className='card card_border'>
                    <div className="card-body">
                        <Row>
                            <Col sm={8} className="left_section">
                                <h6 className='heding'>Billing</h6>
                                <div className="card text-start border-0">
                                    <div className="card-body">
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad">
                                                First Name<span className="red-star">*</span>
                                            </div>
                                            <div className="input-sec d-flex align-items-center">
                                                <input type="text" className="form-control border-0 pb-0"
                                                    name="firtName"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.firtName}
                                                />
                                                {formik.errors.firtName ? (
                                                    <div className='validation-text'>{formik.errors.firtName}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad">
                                                Last Name<span className="red-star">*</span>
                                            </div>
                                            <div className="input-sec d-flex align-items-center">
                                                <input type="text" className="form-control border-0 pb-0"
                                                    name="LastName"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.LastName}
                                                />
                                                {formik.errors.LastName ? (
                                                    <div className='validation-text'>{formik.errors.LastName}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad">
                                                Phone Number.<span className="red-star">*</span>
                                            </div>
                                            <div className="input-sec d-flex align-items-center">
                                                <input type="number" className="form-control border-0 pb-0"
                                                    name="PhoneNumber"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.PhoneNumber}
                                                />
                                                {formik.errors.PhoneNumber ? (
                                                    <div className='validation-text'>{formik.errors.PhoneNumber}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad">
                                                Address Line 1<span className="red-star">*</span>
                                            </div>
                                            <div className="input-sec d-flex align-items-center">
                                                <input type="text" className="form-control border-0 pb-0"
                                                    name="AddressLine1"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.AddressLine1}
                                                />
                                                {formik.errors.AddressLine1 ? (
                                                    <div className='validation-text'>{formik.errors.AddressLine1}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad pe-3">
                                                Address Line 2
                                            </div>
                                            <div className="input-sec d-flex align-items-center">
                                                <input type="text" className="form-control border-0 pb-0"
                                                    name="AddressLine2"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.AddressLine2}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad">
                                                Town<span className="red-star">*</span>
                                            </div>
                                            <div className="input-sec d-flex align-items-center">
                                                {/* <input type="text" className="form-control border-0 pb-0"
                                                    name="Town"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.Town}
                                                />
                                                {formik.errors.Town ? (
                                                    <div className='validation-text'>{formik.errors.Town}</div>
                                                ) : null} */}
                                                <Select options={allcity}
                                                    className="custum-react-select select-wrapper w-100"
                                                    classNamePrefix="select"
                                                    value={[{ label: cityid?.label, id: cityid?.id }]}
                                                    onChange={(e) => {
                                                        setCityid(e)
                                                        formik.setFieldValue("Town", e.id);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad pe-3">State</div>
                                            <div className="input-sec d-flex align-items-center">
                                                <Select options={allstate}
                                                    className="custum-react-select select-wrapper w-100"
                                                    classNamePrefix="select"
                                                    value={[{ label: stateid?.label, id: stateid?.id }]}
                                                    onChange={(e) => {
                                                        //  console.log(e); console.log(stateid); 
                                                        setStateid(e)
                                                        formik.setFieldValue("State", e.id.toString());
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex main_input">
                                            <div className="input_name grow-1 input_namepad pe-3">Zip Code</div>
                                            <div className="input-sec d-flex align-items-center">
                                                <Select options={allZipcodes}
                                                    className="custum-react-select select-wrapper w-100 ps-0"
                                                    classNamePrefix="select"
                                                    value={[{ label: zipcodeid?.label, id: zipcodeid?.id }]}
                                                    onChange={(e) => {
                                                        setZipcodeid(e)
                                                        formik.setFieldValue("ZipCode", e.label);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} className="right_section">
                                <h6>Billing Cycle</h6>
                                <div className="card card_border">
                                    <div className="card-body">
                                        <div className='d-flex flex-column'>
                                            {Object.keys(billingPlans).map((bill, i) => (
                                                <div className='d-flex justify-content-between Commercial_text' key={`billing${i}`}>
                                                    <div><p className=''>{bill} -</p></div>
                                                    <div>$ {billingPlans[bill]}</div>
                                                </div>
                                            ))}
                                            {/* <div className='d-flex justify-content-between Commercial_text'>
                                                <div>Commercial- Monthly Plan</div>
                                                <div>$ 75</div>
                                            </div> */}
                                            <div className='d-flex justify-content-between sub_total_text'>
                                                <div><p>Sub Total</p></div>
                                                <div><p>$ {totalammount}</p></div>
                                            </div>
                                            <div className=' border-bottom d-flex justify-content-between discount_text'>
                                                <div><p>Discount</p></div>
                                                <div><p>$ 0</p></div>
                                            </div>
                                            <div className=' d-flex justify-content-between total_text'>
                                                <div><p>Total</p></div>
                                                <div><p>$ {totalammount}</p></div>
                                            </div>
                                            <div className='d-flex justify-content-between coupon_text'>
                                                <div><p>Have a coupon code ?</p></div>
                                            </div>
                                            <div className='enter_code_input mt-3'>
                                                <div className="table_sell">
                                                    <div className='left'><input type="text" className='form_control input_border' /> </div>
                                                    <div className='right text-end '><button className='apply_btn'>Apply</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className='left_section'>
                            {formik.errors.cardId ? (
                                <div className='validation-text'>{formik.errors.cardId}</div>
                            ) : null}
                            <div className='payment_card d-flex flex-wrap'>
                                {savecards?.map((card, index) => (
                                    <div className="d-flex align-items-center select-card" style={{ gap: "7px" }} key={`usercards${index}`} >
                                        <input type="radio" name="cardId" id={`usercard${index}`}
                                            value={card.id}
                                            onChange={formik.handleChange}
                                            hidden
                                        />
                                        <label htmlFor={`usercard${index}`} className="formtext ">
                                            <Cards
                                                number={card.card_number}
                                                name={card.card_name}
                                                expiry={card.month <= 9 ? `0${card.month}${card.year}` : `${card.month}${card.year}`}
                                            />
                                        </label>
                                    </div>
                                ))}


                                <div className='third_img center flex-column card_border add_new_cards pointer'
                                    style={{ maxWidth: "270px", minHeight: '180px' }}
                                    onClick={() => setModalShow(true)}>
                                    <img src={add} alt="" className=" add-car-img mb-2" />
                                    {/* <img src={add} alt="" style={{ width: "42px" }} className="pb-2 mb-1" /> */}
                                    <p className='d-inline'>Add New Card</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8 d-grid pay_now" >
                                    <button className='btn-block' type="submit" onClick={formik.handleSubmit}>Pay Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*start Check Out Page Add New Card Popup */}
                <div className="forgot_modals">
                    <Modal className="forgot_modals" size="lg" centered show={modalShow}
                        onHide={() => setModalShow(false)}
                        ria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton className="border-bottom-0">
                            <Modal.Title id="contained-modal-title-vcenter " className="border-0 "> </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="CheckOutPage_body">
                            <div className='card_img_end pb-3'>
                                <img src={MasterCard_Logo} alt="" className='MasterCard_img' />
                            </div>
                            <div className="d-flex main_input">
                                <div className="input_name grow-1 text-end input_namepad">
                                    Card Holder Name<span className="red-star">*</span>
                                </div>
                                <div className="input-sec d-flex align-items-center">
                                    <input type="text" className="form-control border-0 pb-0"
                                        name="CardHolderName"
                                        onChange={NewCardformik.handleChange}
                                        value={NewCardformik.values.CardHolderName}
                                    />
                                    {NewCardformik.errors.CardHolderName ? (
                                        <div className='validation-text'>{NewCardformik.errors.CardHolderName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex main_input">
                                <div className="input_name grow-1 text-end input_namepad">
                                    Card Number<span className="red-star">*</span>
                                </div>
                                <div className="input-sec d-flex align-items-center">
                                    <input type="number" className="form-control border-0 pb-0"
                                        name="CardNumber"
                                        onChange={NewCardformik.handleChange}
                                        value={NewCardformik.values.CardNumber}
                                    />
                                    {NewCardformik.errors.CardNumber ? (
                                        <div className='validation-text'>{NewCardformik.errors.CardNumber}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className='d-flex gap_input'>
                                <div className="flex-grow-1">
                                    <div className="d-flex main_input pt">
                                        <div className="input_name grow-1 text-end input_namepad Expiry_Date_width">
                                            Expiry Date<span className="red-star">*</span>
                                        </div>
                                        <div className="input-sec d-flex align-items-center">
                                            <input type="month" className="form-control border-0 pb-0"
                                                name="ExpiryDate"
                                                onChange={NewCardformik.handleChange}
                                                value={NewCardformik.values.ExpiryDate}
                                            />
                                            {NewCardformik.errors.ExpiryDate ? (
                                                <div className='validation-text'>{NewCardformik.errors.ExpiryDate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex main_input">
                                        <div className="input_name grow-1 text-end input_namepad cvc_width">
                                            CVV<span className="red-star">*</span>
                                        </div>
                                        <div className="input-sec d-flex align-items-center">
                                            <input type="number" className="form-control border-0 pb-0"
                                                name="cvc"
                                                onChange={NewCardformik.handleChange}
                                                value={NewCardformik.values.cvc}
                                            />
                                            {NewCardformik.errors.cvc ? (
                                                <div className='validation-text'>{NewCardformik.errors.cvc}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 d-grid">
                                    <button type="submit" className="submit_btn btn-block"
                                        onClick={NewCardformik.handleSubmit}>Add Card
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                {/*end Check Out Page Add New Card Popup */}
            </div>
        </>
    )
}

export default CheckOut
