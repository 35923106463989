import { backendURL, getToken } from "../../../appVariable/variable";
import axios from "axios";
import moment from "moment/moment";

export function signUp(email, password, type, success, error) {
  let tempObj = {};
  tempObj["username"] = email;
  tempObj["password"] = password;
  tempObj["user_type"] = type;
  axios
    .post(`${backendURL}/api/user/register/`, tempObj)
    .then((resp) => {
      // if (resp.data.code == 200 && resp.data.status == success) {
      //   success(resp.data.mesg);
      // }

      success(resp);
    })
    .catch((err) => {
      error(err);
    });
}


export function saveTokenInLocalStorage(tokenDetails) {
  try {
    let currentDate = new Date();
    tokenDetails.expireDate = currentDate;
    tokenDetails.expireDate = new Date(
      new Date().getTime() + tokenDetails.expiresIn * 1000
    );
    localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
  }
  catch (err) {
    console.log("err", err)
  }

}
export function logout(time) {
  setTimeout(() => {
    alert("timeout")
    localStorage.removeItem('userDetails');

  }, time)

}
export function login(email, password, extraData, success, errorD) {
  let date = new Date();
  let userlog = {
    action_type: "True",
    date_time: moment(date).format("YYYY-MM-DD HH:MM:ss"),
    ip_address: extraData.IPv4,
    mac_address: "00-14-7B-EE-19-F8",
    latitude: extraData.latitude,
    longitude: extraData.longitude,
    location: `${extraData.city} ${extraData.country_name}`,
  };
  let tempObj = {};
  tempObj["username"] = email;
  tempObj["password"] = password;
  tempObj["user_log"] = userlog;

  axios
    .post(`${backendURL}/api/user/login/`, tempObj)
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        let tempObj = {};
        tempObj["userId"] = response.data.data.user_info.id;
        tempObj["user_info"] = response.data.data.user_info;
        tempObj["profile"] = response.data.data.profile;
        tempObj["expiresIn"] = "96000";
        tempObj["idToken"] = response.data.data.token.access;
        tempObj["refreshToken"] = response.data.data.token.refresh;
        tempObj["accessToken"] = response.data.data.token.access;
        saveTokenInLocalStorage(tempObj);

        let add_on = { add_on: response.data.data.user_info.add_on }
        localStorage.setItem("add_on", JSON.stringify(add_on));

        let sidebar = { sidebar: response.data.data.user_info.agent_profile }
        localStorage.setItem("sidebar", JSON.stringify(sidebar));

        logout(tempObj.expiresIn * 1000,);
        success(response.data.data);
      }
      else (
        success(response.data.data)
      )
    })
    .catch((err) => {
      errorD(err);
    });
}

export function getGeolocationData(success) {
  axios.get("https://geolocation-db.com/json/").then((resp) => {
    success(resp.data);
  });
}

export function ForgetPassword(userVal, success) {
  let token = getToken()
  axios
      .post(`${backendURL}/api/user/forgetpassword/`, userVal, {
          
      })
      .then((response) => {
        success(response);
          
      })
      .catch((error) => {
          console.log(error);
      });
}

export function restPassword(userVal, success) {
  let token = getToken()
  axios
      .post(`${backendURL}/api/user/reset-password/`, userVal, {
        
          
      })
      .then((response) => {
        success(response);
         
      })
      .catch((error) => {
          console.log(error);
      });
}


