import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import "react-toastify/dist/ReactToastify.css";
// import Topbar from "../dashboard/components/topbar/Topbar";
import Modal from "react-bootstrap/Modal";
import "./Signup.scss";
import Footer from "../homepage/components/footer/Footer";
import Navbar from "../homepage/components/navbar/Navbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";
import { signUp } from "./server";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import Loader from "../Loader/Loader";
import Validation from "./Validation";
import { Divider } from "@mui/material";
import mailIcon from "./image/MailIcon.svg";
import Lock from "./image/Lock.svg";
import { NavLink } from "react-router-dom";

export default function Signup() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const handleClose = () => setShow(false);
  const [typeOfUser, setTypeOfUser] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  let upperCase = /^(?=.*[A-Z])/;
  let Lowercase = /^(?=.*[a-z])/;
  let specialChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
  let digitRegex = /(?=.*?[0-9])/;
  // handel Submit
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address format")
        .required("Required*"),
      password: Yup.string()
        .min(8, "Password must be 8 characters at minimum")
        .required("Required*")
        .matches(upperCase, "please Enter One Uppercase Value")
        .matches(Lowercase, "please Enter One Lowercase Value")
        .matches(specialChar, "please Enter One SpecialChar Value")
        .matches(digitRegex, "please Enter One Char in number Value"),
    }),
    onSubmit: (values) => {
      setLoading(true);

      // setShow(true)
      // signUp(
      //   values.email,
      //   values.password,
      //   typeOfUser,
      //   (success) => {
      //     handleClose();
      //     // swal(success).then(() => {
      //     //   navigate("/login");
      //     // });
      //     setLoading(false)
      //     swal("success!", "Plese Check Your Mail !", "success").then(() => {
      //       navigate("/login");
      //     });
      //   },
      //   (error) => {
      //     toast.warning("A user with this username already exists.")
      //     setLoading(false)
      //   }
      // );
    },
  });

  // handel submit for pop-up show
  // const handleShow=()=> {
  //   setShow(true)
  // }
  const handleShow = () => {
    signUp(
      formik.values.email,
      formik.values.password,
      typeOfUser,
      (success) => {
        handleClose();
        // swal(success).then(() => {
        //   navigate("/login");
        // });
        setLoading(false);
        if (success.data.code === 200) {
          swal("Success!", "Please Check Your E-Mail !", "success").then(() => {
            navigate("/login");
          });
          // toast.success("Please Check Your E-Mail !", "success")
        }
        if (success.data.code === 400) {
          toast.warning(
            "A user with this username already exists. Please try with different username"
          );
          setLoading(false);
        }
      },
      (error) => {
        toast.warning("Something Went Wrong please try again");
        setLoading(false);
      }
    );
    // let upperCase = /^(?=.*[A-Z])/;
    // let Lowercase = /^(?=.*[a-z])/;
    // let specialChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
    // let digitRegex = /(?=.*?[0-9])/;

    // if (formik.values.password === "") {
    //   setError("please Enter Password ")
    // } else if (formik.values.password.length < 8) {
    //   setError("please Enter Password max 8 char");
    // } else if (!upperCase.test(formik.values.password)) {
    //   setError("please Enter One Uppercase Value");
    // } else if (!Lowercase.test(formik.values.password)) {
    //   setError("please Enter One Lowercase Value");
    // } else if (!specialChar.test(formik.values.password)) {
    //   setError("please Enter One SpecialChar Value");
    // } else if (!digitRegex.test(formik.values.password)) {
    //   setError("please Enter One Char in number Value");
    // } else {

    //   setShow(true)
    //   formik.values.password("")
    // }

    // if (formik.values.email === "") {
    //   setError("please Enter Password ");
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formik.values.email)) {
    //   setError('Invalid email address');
    //   return
    // }else {

    //   // setShow(true)
    //  formik.values.email("")
    // }
  };
  return (
    <div>
      {loading ? <Loader /> : null}
      {/* <Navbar /> */}
      <div className="signuppage_body">
        <div className="signup_body">
          <div className="signup_body_left">
            <img
              src="vertical_logo.png"
              alt="Afisado Logo"
              className="login-logo"
            />
          </div>
          <div className="signup_body_right">
            <div className="signuppage_form">
              <div className="loginpage_form" style={{ maxWidth: "450px" }}>
                <div className="signuppage_social_signup_button">
                  <button className="signuppage_fb_signup">
                    <i
                      className="fa fa-facebook-square"
                      aria-hidden="true"
                      style={{
                        color: "#4568F1",
                        fontSize: "1.8rem",
                        width: "35px",
                      }}
                    ></i>
                    <span>Log in with Facebook</span>
                  </button>
                  <button className="signuppage_google_signup">
                    <i
                      className="fa fa-google fa-fw"
                      style={{ color: "#4568F1", fontSize: "1.8rem" }}
                    ></i>
                    <span>Log in with Google</span>
                  </button>
                </div>
                <Divider
                  className="mx-auto"
                  style={{ maxWidth: "485px", color: "white" }}
                  sx={{ "&::before, &::after": { borderColor: "white" } }}
                >
                  or
                </Divider>
                <div
                  className="loginpage_inputs"
                  style={{ maxWidth: "500px", margin: "auto" }}
                >
                  <div className="input-container">
                    <span>
                      Email address{" "}
                      <span
                        style={{
                          fontSize: 12,
                          margin: 0,
                          color: "#CFF4FF",
                        }}
                      >
                        (Industry professionals use your business gmail)
                      </span>
                    </span>
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Email"
                      autoComplete="off"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="validation-text log-validation">
                      {formik.errors.email}
                    </div>
                  ) : null}
                  <div className="input-container">
                    <span>Password</span>
                    <input
                      className="input-field"
                      type={isRevealPwd ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                  </div>
                  {(formik.touched.password && formik.errors.password) ||
                  error ? (
                    <div className="validation-text log-validation">
                      {formik.errors.password}
                    </div>
                  ) : null}

                  <Validation formikPass={formik.values.password} />
                </div>

                <div className="loginpage_disclaimer">
                  <span className="loginpage_disclaimer-text">
                    {" "}
                    By signing in you agree to AFISADO’s{" "}
                    <NavLink
                      className="text-decoration-none"
                      to="/Terms&Conditions"
                    >
                      terms of use
                    </NavLink>{" "}
                    and{" "}
                    <NavLink
                      className="text-decoration-none"
                      to="/PrivacyPolicy"
                    >
                      privacy policy
                    </NavLink>
                    .
                  </span>
                </div>
                <select
                  onChange={(e) => setTypeOfUser(Number(e.target.value))}
                  class="form-select form-select-lg mb-3 signup_account_select"
                  aria-label=".form-select-lg example"
                >
                  <option selected value="0">
                    Select type of Account
                  </option>
                  <option value="1">Customer (Amazing You)</option>
                  <option value="2">Agent & Broker</option>
                  <option value="5">Landlord & Property Managers</option>
                  <option value="3">Developers & Property Investor</option>
                  <option value="4">FRBHO's & FSBHO's</option>
                  <option value="6">Advertisers</option>
                </select>
                <div className="signuppage_help_button">
                  <button
                    className="signuppage_signin_btn"
                    onClick={() => {
                      formik.handleSubmit();
                      handleShow();
                    }}
                    disabled={typeOfUser === 0}
                  >
                    <span className="signuppage_signin_btn_text">Register</span>
                  </button>
                  <div>
                    <Modal show={show} onHide={handleClose} size="lg" centered>
                      <Modal.Header className=" justify-content-end">
                        <AiOutlineClose
                          size={28}
                          onClick={() => {
                            setTypeOfUser(0);
                            handleClose();
                          }}
                        />
                      </Modal.Header>
                      <Modal.Body className=" py-0">
                        <div>
                          <div className="signupmodal">
                            <div className="signupmodal_container">
                              <input
                                type="radio"
                                className="userradio"
                                name="userType"
                                id="firstUser"
                              />

                              <label
                                htmlFor="firstUser"
                                className="signupmodal_box1 user-type"
                                onClick={() => {
                                  setTypeOfUser(1);
                                }}
                              >
                                <div className="signupmodal_box1_shape1">
                                  <div className="signupmodal_box1_subshape1">
                                    <div className="signupmodal_box1_subshape_img"></div>
                                  </div>
                                </div>
                                <div className="signupmodal_box1_text">
                                  <span>Customer (Amazing You)</span>
                                </div>
                              </label>

                              <input
                                type="radio"
                                className="userradio"
                                name="userType"
                                id="secondUser"
                              />
                              <label
                                htmlFor="secondUser"
                                className="signupmodal_box2 user-type"
                                onClick={() => {
                                  setTypeOfUser(2);
                                }}
                              >
                                <div className="signupmodal_box2_shape1">
                                  <div className="signupmodal_box2_subshape1">
                                    <div className="signupmodal_box2_subshape_img"></div>
                                  </div>
                                </div>
                                <div className="signupmodal_box2_text">
                                  <span>Broker or Agent</span>
                                </div>
                              </label>

                              <input
                                type="radio"
                                className="userradio"
                                name="userType"
                                id="thirdUser"
                              />
                              <label
                                htmlFor="thirdUser"
                                className="signupmodal_box3 user-type"
                                onClick={() => {
                                  setTypeOfUser(5);
                                }}
                              >
                                <div className="signupmodal_box3_shape1">
                                  <div className="signupmodal_box3_subshape1">
                                    <div className="signupmodal_box3_subshape_img"></div>
                                  </div>
                                </div>
                                <div className="signupmodal_box3_text">
                                  <p className=" text-nowrap">
                                    Property Manager{" "}
                                  </p>
                                  <p>or</p> <p>Landlord</p>
                                </div>
                              </label>

                              <input
                                type="radio"
                                className="userradio"
                                name="userType"
                                id="fourthUser"
                              />
                              <label
                                htmlFor="fourthUser"
                                className="signupmodal_box4 user-type"
                                onClick={() => {
                                  setTypeOfUser(3);
                                }}
                              >
                                <div className="signupmodal_box4_shape1">
                                  <div className="signupmodal_box4_subshape1">
                                    <div className="signupmodal_box4_subshape_img"></div>
                                  </div>
                                </div>
                                <div className="signupmodal_box1_text">
                                  <p>Developer</p>
                                  <p>or</p> <p>Property Investor</p>
                                </div>
                              </label>

                              <input
                                type="radio"
                                className="userradio"
                                name="userType"
                                id="fiftUser"
                              />
                              <label
                                htmlFor="fiftUser"
                                className="signupmodal_box5 user-type"
                                onClick={() => {
                                  setTypeOfUser(4);
                                }}
                              >
                                <div className="signupmodal_box5_shape1">
                                  <div className="signupmodal_box5_subshape1">
                                    <div className="signupmodal_box5_subshape_img"></div>
                                  </div>
                                </div>
                                <div className="signupmodal_box1_text">
                                  <span>FRBHO or FSBHO</span>
                                </div>
                              </label>
                            </div>

                            <div className="signupmodal_action_button">
                              <button
                                className="signupmodal_button"
                                type="submit"
                                onClick={handleShow}
                                disabled={typeOfUser === 0}
                              >
                                <span className="signupmodal_button_text">
                                  Continue
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      {/* <Modal.Footer></Modal.Footer> */}
                    </Modal>
                  </div>
                </div>

                <div className="signuppage_signup_help mb-1">
                  <span className="loginpage_signup_text">
                    Already have an account ?
                    <span
                      className="loginpage_signup_link pointer"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      {" "}
                      Sign in
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
