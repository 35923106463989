import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Filterbar from "../editContact/Filterbar";
import "./../virtualOffice.scss";
import Loader from "../../../Loader/Loader";
import { currentListingProperty } from "../../../currentListing/server";
import { backendURL, getToken } from "../../../../../appVariable/variable";
import Mapview from "../../../PropertyDetails/componants/map-view/Mapview";
import PropertyCard from "../../../dashboard/components/mainSection/PropertyCard";
import { getAllpropertyInfo } from "../../../dashboard/components/mainSection/server";
import { toast } from "react-toastify";
import {
  interestedProperty,
  notinterestedProperty,
  sendPorperty,
  showProperty,
} from "../../server";
import { updatePropertyDatail } from "../../../dashboard/propperty-datails/PropertySlice";
import VirtualOfficePropertyModal from "../../../virtual-office/propertyModal/VirtualOfficePropertyModal";
import { typeOf } from "react-is";
import NoteModal from "../../../guestVirtualOffice/components/noteModal/NoteModal";
import { sendAgentNote } from "../../../guestVirtualOffice/server";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import PopUpFilter from "../../../dashboard/components/filter-popup/PopUpFilter";
import { getProperties } from "../../../guestVirtualOffice/server";
import CommertialMapFilter from "../../../mapview-filter/components/CommertialMapFilter";
import ResidentialMapFilter from "../../../mapview-filter/components/ResidentialMapFilter";
import { getuserSubscription } from "../../../agent-dash-sales/server";
import { postFilteredData } from "../../../guestVirtualOffice/server";
import PropertySavePopUp from "../../../dashboard/components/mainSection/PropertySavePopUp";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };

const AgentVirtualDashboard = () => {
  const [listing, setlisting] = useState([]);
  const [templisting, setTemplisting] = useState([]);
  const [loading, setloading] = useState(false);
  const [popUpModalId, setPopUpModalId] = useState(null);
  const [noteValue, setNoteValue] = useState("");
  const [addNotePopup, setAddNotePopup] = useState(false);
  const [selectedProperty, setselectedProperty] = useState([]);
  const [ShowProperty, setShowProperty] = useState(false);
  const [property, setproperty] = useState({});
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const [viewValue, setView] = useState(false);
  const virtualOfficeId = useSelector(
    (state) => state.virtualOfficeInStore.officeId
  );
  const selectAll = useRef();
  const dispatch = useDispatch();
  const [clickedbutton, setClickedButton] = useState("show");
  const [shownote, setshownote] = useState(false);
  const [currentproperty, setcurrentproperty] = useState({});
  const [viewsetting, setViewsetting] = useState(false);
  const [filteredListing, setFilteredListing] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [types, setTypes] = useState(null);
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const getSubscritionType = async () => {
    await getuserSubscription((success) => {
      setTypes(success[0][0].type_of_listing);
      setTypes((state) => {
        return state;
      });
    });
  };
  useEffect(() => {
    setloading(true);
    getSubscritionType();
    getcurrentListing();
    if (filteredListing?.length === 0) {
      setFilteredListing(listing);
    }
  }, [virtualOfficeId]);
  const handlefilter = (listingGroup) => {
    try {
      postFilteredData(listingGroup, (success) => {
        setFilteredListing(success.data.data.property);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getcurrentListing = () => {
    getProperties(
      virtualOfficeId,
      (resp) => {
        if (resp.data.code == 200) {
          console.log("Listing Data:", resp.data.data);
          // let properties = resp.data.data.map((prop) => prop.propertyid);
          setlisting(resp.data.data);
          setlisting((state) => {
            return state;
          });
          setFilteredListing(resp.data.data);
        } else if (resp.data.data === "Data Not Found") {
          setlisting([]);
          setlisting((state) => {
            return state;
          });
          setFilteredListing([]);
        } else {
          setlisting([]);
          setlisting((state) => {
            return state;
          });
          setFilteredListing([]);
        }
        setloading(false);
      },
      (error) => {
        setlisting([]);
        setlisting((state) => {
          return state;
        });
        setFilteredListing([]);

        setloading(false);
      }
    );
    // currentListingProperty((success) => {
    //   if (success.length > 0) {
    //     setloading(false);
    //     setlisting(success);
    //   } else {
    //     setlisting([]);
    //   }
    // })
  };

  const sendPropertyInVirtualOffice = (props, type) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let obj = {};
    if (type == "singletype") {
      obj.propertyid = [props];
    } else {
      obj.propertyid = props;
    }
    obj.virtual_id = parseInt(virtualOfficeId);
    obj.user_id = userDetails.userId;
    obj.note = noteValue;
    if (type == "singletype") {
      sendPorperty(
        obj,
        (response) => {
          if (response.data.code == 200) {
            setselectedProperty([]);
            // toast.success("Property send Successfully", "success")
          } else {
            // toast.error("please select virtual office", "error")
          }
        },
        (error) => {}
      );
    } else if (type == "alltype" && props?.length > 0) {
      sendPorperty(
        obj,
        (response) => {
          if (response.data.code == 200) {
            console.log(
              selectAll.current.checked,
              "selectAll.current.checkedselectAll.current.checked"
            );
            selectAll.current.checked = false;
            setselectedProperty([]);
            // toast.success("Property send Successfully", "success")
          } else {
            // toast.error("please select virtual office", "error")
          }
        },
        (error) => {}
      );
    } else {
      // toast.error("please select property", "error")
    }
  };

  const showPropertyInVirtualOffice = (props, type) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let obj = {};
    if (type == "singletype") {
      obj.propertyid = props;
    } else {
      obj.propertyid = props;
    }
    obj.virtual_id = parseInt(virtualOfficeId);
    obj.user_id = userDetails.userId;
    obj.note = noteValue;
    obj.is_show = clickedbutton == "show" ? "True" : "False";
    if (type == "singletype") {
      showProperty(
        obj,
        (response) => {
          if (response.data.code == 200) {
            setselectedProperty([]);
            getcurrentListing();
            // toast.success("Property send Successfully", "success")
          } else {
            // toast.error("please select virtual office", "error")
          }
        },
        (error) => {}
      );
    } else if (type == "alltype" && props?.length > 0) {
      showProperty(
        obj,
        (response) => {
          if (response.data.code == 200) {
            console.log(
              selectAll.current.checked,
              "selectAll.current.checkedselectAll.current.checked"
            );
            selectAll.current.checked = false;
            setselectedProperty([]);
            // toast.success("Property send Successfully", "success")
          } else {
            // toast.error("please select virtual office", "error")
          }
        },
        (error) => {}
      );
    } else {
      // toast.error("please select property", "error")
    }
  };
  const sendNote = (text) => {
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = currentproperty.id;
    obj.note = text;
    obj.virtual_ofice = virtualOfficeId;
    sendAgentNote(
      obj,
      (resp) => {
        if (resp.data.code == 200) {
          setshownote(false);
          getcurrentListing();
          setloading(false);
          toast.success("Successfully Submitted", "success");
        } else {
          toast.error("something went wrong", "error");
        }
      },
      (err) => {
        // toast.error("something went wrong", "error")
      }
    );
  };
  const AddNotePopUp = (e, prop) => {
    e.stopPropagation();
    setNoteValue(prop.note);
    setPopUpModalId(prop.id);
    setAddNotePopup(true);
  };
  const showcontent = (e, prop, arg) => {
    e.stopPropagation();

    setPopUpModalId(prop.id);
    if (arg === "show") {
      showPropertyInVirtualOffice(popUpModalId, "singletype");
      setClickedButton("hide");
    } else {
      showPropertyInVirtualOffice(popUpModalId, "singletype");
      setClickedButton("show");
    }
  };
  const chekckedProperty = (e, property) => {
    if (e.target.checked === true) {
      let selected = [...selectedProperty];
      selected.push(property.id);
      setselectedProperty(selected);
    } else {
      let selected = [...selectedProperty];
      let index = selected.indexOf(property.id);
      selected.splice(index, 1);
      setselectedProperty(selected);
    }
  };

  const selectAllProperty = (e) => {
    if (e.target.checked) {
      if (listing?.length > 0) {
        let propertiesId = listing.map((property) => property.id);
        setselectedProperty(propertiesId);
      }
    } else {
      setselectedProperty([]);
    }
  };

  const interested = (prop) => {
    setloading(true);
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = prop.id;
    obj.like = 1;
    obj.virtual_ofice = virtualOfficeId;

    interestedProperty(
      obj,
      (resp) => {
        setloading(false);
        if (resp.data.code == 200) {
          getcurrentListing();
          // toast.success("Thanks for showing interest", "success")
        } else {
          // toast.error("something went wrong", "error")
        }
      },
      (err) => {
        // toast.error("something went wrong", "error")
      }
    );
  };

  const notinterested = (prop) => {
    setloading(true);
    let token = getToken();
    let obj = {};
    obj.user_id = token.userId;
    obj.propertyid = prop.id;
    obj.dislike = 1;
    obj.virtual_ofice = virtualOfficeId;

    notinterestedProperty(
      obj,
      (resp) => {
        setloading(false);
        if (resp.data.code == 200) {
          getcurrentListing();
          // toast.success("Thanks for showing interest", "success")
        } else {
          // toast.error("something went wrong", "error")
        }
      },
      (err) => {
        // toast.error("something went wrong", "error")
      }
    );
  };

  let propertDetails = async (id) => {
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    setloading(true);
    await getAllpropertyInfo(id, (success) => {
      dispatch(updatePropertyDatail({ PropertyDetailInfo: success }));
      setPropertyDetailInfo(success);
      setloading(false);
    });
  };

  // add note modal view

  // save note button of dialog
  const handleSaveNote = (e) => {
    sendPropertyInVirtualOffice(popUpModalId, "singletype");
    setAddNotePopup(false);
    setNoteValue("");
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="agent-virtual-office-dashboard flex-grow-1">
        <div className="d-flex justify-content-between">
          <div className=" MainCard_container">
            <div className="filter-search_a">
              <Filterbar
                className="Fil_bar"
                setShowFilter={setShowFilter}
                setfilterView={false}
              />
            </div>
          </div>
          <div className="quik-map center gap-1">
            <p>Quick View</p>
            <div className="form-check-switch pt-0">
              <PinkSwitch
                {...label}
                onChange={(e) => {
                  setViewsetting(e.target.checked);
                  setViewsetting((state) => {
                    return state;
                  });
                  setView(!viewValue);
                }}
                checked={viewsetting}
                style={{ color: "#315EFB" }}
              />
              {/* <input
                className="form-check-input p-0 pointer"
                type="checkbox"
                id="quikviewswitch"
                checked={props.viewsetting}
                onChange={(e) => {
                  props.setViewsetting(e.target.checked);
                }}
                style={{
                  backgroundSize: "22px",
                }}
              /> */}
            </div>
            <p>Map View</p>
          </div>
        </div>
        <div
          className="d-flex mt-5 flex-grow-1 main-content scrollS"
          style={{ zIndex: "400" }}
        >
          <div>
            <div
              className="left-section scrollS"
              style={
                viewValue
                  ? { height: "100%" }
                  : { maxHeight: "64vh", height: "100%" }
              }
              // style={{ height: "100%" }}
            >
              {listing?.length > 0 && viewValue === false ? (
                listing?.map((prop, index) => {
                  return (
                    <div
                      className="property-virtual-card "
                      key={`virtualcard${index}`}
                    >
                      <img
                        src={backendURL + prop.property_main_image}
                        alt=""
                        className="property-main-image"
                      />
                      <div className="select-property">
                        <label className="container">
                          <input
                            type="checkbox"
                            onChange={(e) => chekckedProperty(e, prop)}
                            checked={
                              selectedProperty.includes(prop.id) ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        {/* <input type="checkbox" name="" id="" className='check-property'  onChange={(e) => chekckedProperty(e, prop)} checked={selectedProperty.includes(prop.id) ? true : false}/> */}
                      </div>
                      <div
                        className="details"
                        role="button"
                        onClick={() => {
                          setShowProperty(true);
                          setproperty(prop);
                          propertDetails(prop.slug);
                        }}
                      >
                        <div className="details-left">
                          <h5 className="amount mt-1">
                            ${prop.property_listing_amount}
                          </h5>
                          <p className="area-name">
                            {prop.property_area.area_name}
                          </p>
                          <p className="area-name">{prop.property_address_1}</p>
                        </div>
                        <div className="details-right">
                          <div className="profile-image-parent">
                            <img
                              src={
                                backendURL + prop?.user_profile?.profile_image
                              }
                              alt=""
                              className="profile-image"
                            />
                          </div>
                          {/* <button className='profile-image-button' style={{opacity:"10"}}>1 year</button> */}
                        </div>
                      </div>
                      <div className="action-buttons">
                        <button
                          className={`buttons${
                            prop.like === 1 ? " interested_btn" : ""
                          }`}
                          onClick={() => interested(prop)}
                          style={{ borderRadius: "20px" }}
                        >
                          Interested
                        </button>
                        <button
                          className={`buttons${
                            prop.like === 2 ? " interested_btn" : ""
                          }`}
                          onClick={() => notinterested(prop)}
                          style={{ borderRadius: "20px" }}
                        >
                          Don't Like
                        </button>
                      </div>
                      {/* <div className='noteshow-buttons'>
                        <button className='add-note w-100' onClick={(e) => AddNotePopUp(e, prop)} >Add Note</button>
                        <button className='add-note w-100' onClick={(e) => showcontent(e, prop,clickedbutton)} >{prop.is_show === true ? "Hide" : "Show"}</button>
                      </div> */}
                      <button
                        className="add-note w-100"
                        onClick={() => {
                          setshownote(true);
                          setcurrentproperty(prop);
                        }}
                      >
                        {" "}
                        Send
                      </button>
                      {/* <button
                          className="add-note w-100"
                          onClick={() =>
                            sendPropertyInVirtualOffice(prop.id, "singletype")
                          }
                        >
                          Send
                        </button> */}
                    </div>
                  );
                })
              ) : loading || viewValue === false ? null : types === 1 ? (
                <ResidentialMapFilter
                  groupByFilter={handlefilter}
                  currentUserType={0}
                  virtualOfficeId={virtualOfficeId}
                />
              ) : (
                <CommertialMapFilter
                  groupByFilter={handlefilter}
                  currentUserType={3}
                  virtualOfficeId={virtualOfficeId}
                />
              )}
            </div>
            {!viewValue && (
              <div className="listing-footer">
                <div className="select-all">
                  <input
                    type="checkbox"
                    ref={selectAll}
                    name=""
                    id=""
                    onChange={(e) => selectAllProperty(e)}
                  />
                  <label htmlFor="">
                    <div style={{ marginLeft: "10px" }}>Select All</div>
                  </label>
                </div>
                <div className="send-review">
                  <button
                    className=""
                    onClick={() =>
                      sendPropertyInVirtualOffice(selectedProperty, "alltype")
                    }
                  >
                    Send to review
                  </button>
                </div>
              </div>
            )}
          </div>
          {loading === false ? (
            filteredListing?.length > 0 ? (
              <div
                className="right-section flex-grow-1"
                style={{
                  border: "1px solid #315EFB",
                  borderRadius: "4px",
                  overflowY: "hidden",
                }}
              >
                <Mapview allProperty={filteredListing} />
                {/* <Mapview allProperty={listing} /> */}
              </div>
            ) : (
              <div
                className="right-section flex-grow-1"
                style={{
                  border: "1px solid #315EFB",
                  borderRadius: "4px",
                  overflowY: "hidden",
                }}
              >
                <Mapview />
              </div>
            )
          ) : null}
        </div>
      </div>
      {ShowProperty &&
      !shownote &&
      propertyDetailInfo?.propertDetails?.property_address_1 !== undefined &&
      !loading &&
      !propertyDetailInStore.setSavePropertyPopup ? (
        <VirtualOfficePropertyModal
          setShowProperty={setShowProperty}
          ShowProperty={ShowProperty}
          propertyDetailInfo={propertyDetailInfo}
          setPropertyDetailInfo={setPropertyDetailInfo}
          virtualOfficeId={virtualOfficeId}
          // showLocationButton={true}
        />
      ) : null}
      {propertyDetailInStore.setSavePropertyPopup && (
        <PropertySavePopUp setDetailShowProperty={setShowProperty} />
      )}
      {shownote && !ShowProperty ? (
        <NoteModal
          sendNote={sendNote}
          setshownote={setshownote}
          data={currentproperty}
        />
      ) : null}
      {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}
      {/* save note pop up */}
    </>
  );
};

export default AgentVirtualDashboard;
