import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { IconButton } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import PropertyCard from '../../dashboard/components/mainSection/PropertyCard';
import MsgOutgoing from './MsgOutgoing';
import MsgIncoming from './MsgIncoming';
import profilepic from "../image/profilepic.jpg"
import { Button, Form, Modal, Table } from 'react-bootstrap';
import EmojiPicker from 'emoji-picker-react';
import deleteicon from "../image/delete.svg"

let tdata = [1, 2, 3]

const ChatSection = () => {
    const [inputStr, setInputStr] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const onEmojiClick = (event) => {
        console.log(event)
        setInputStr(prevInput => prevInput + event.emoji);
        setShowPicker(false);
    };
    return (
        <>
            <div className="chat-section flex-grow-1 position-relative">
                <div className="chat-section-header d-flex justify-content-between align-items-center ">
                    <div className=' d-flex align-items-center gap-4'>
                        <div className="chat-profile"><img src={profilepic} alt="" srcSet="" /></div>
                        <div className="prrson-name"><p>Tom Holden</p></div>
                    </div>
                    <div className="more-option"><IconButton onClick={() => setModalShow(true)}><MoreVertIcon /></IconButton></div>
                </div>

                <div className="chat-main-box d-flex flex-column hide-scrollbar">
                    <MsgIncoming message='Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti aliquid modi, asperiores et numquam ex incidunt nobis fugit. Vitae consequatur voluptas quidem, repellat ullam eligendi.' />
                    <MsgOutgoing message='Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti aliquid modi, asperiores et uptas quidem, repellat ullam eligendi.' />
                    {/* <MsgIncoming message={<PropertyCard />} /> */}
                    <MsgIncoming message='Lorem ipsum dolor sit amet consectugit. Vitae consequatur voluptas quidem,eligendi.' />
                    <MsgOutgoing message='Lorem ipsum dolpellat ullam eligendi.' />
                    {/* <MsgOutgoing message={<PropertyCard />} /> */}
                    <MsgIncoming message='Lorem ipsum dolor sit amet uglam eligendi.' />
                    <MsgOutgoing message='Lorem ipsum dolor sit amet consectetur adipisicing elit. ligendi.' />
                    <MsgOutgoing message='Sawan kinare ❤️ ye fukare' />
                </div>

                <div className="send-msg  ">
                    <div className="message-sec  ">
                        <div className="message-box d-flex justify-content-between ">
                            <input type="text" className="trans-input" name="" id="" placeholder="Add your message"
                                value={inputStr} 
                                onChange={e => setInputStr(e.target.value)} />
                            <div className="d-flex file-select">
                                <input type="file" hidden name="" id="msgfile" />
                                <label htmlFor="msgfile" className='msgfile pointer'><AttachFileIcon /></label>
                                <p className=' pointer' onClick={() => setShowPicker(val => !val)}><SentimentSatisfiedAltIcon /></p>
                                <Button variant="primary" className='send-button pointer'>Send</Button>
                                {showPicker &&
                                    <div className=' emoji-picker-div position-absolute'>
                                        <EmojiPicker onEmojiClick={onEmojiClick} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="chat-modal">
                <Modal
                    className='chat-modal-main'
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className='chat-modal-header p-0 ps-3' closeButton>
                        {/* <Modal.Title id="contained-modal-title-vcenter"></Modal.Title> */}
                        <p >Invite Family & Friends</p>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='chat-modal-body'>
                            
                            <div>
                                <div className='forgotmodal_body d-flex gap-5 '>
                                    <div className="d-flex main_input flex-grow-1 align-items-center">
                                        <div className="input_name grow-1 text-end input_namepad w-auto">
                                            Phone Number<span className="red-star">*</span>
                                        </div>
                                        <div className="input-sec">
                                            <Form.Select aria-label="Default select example">
                                                <option> </option>
                                                <option value="1">9988776655</option>
                                                <option value="2">9988776655</option>
                                                <option value="3">9988776655</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="text-end mb-3">
                                        <button type="submit" className="submit_btn">Invite</button>
                                    </div>
                                </div>
                                <div className="chat-table">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Phone Number</th>
                                                <th className='text-center'>Invitation Request</th>
                                                <th>Account Permission</th>
                                                <th className='text-center'>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tdata.map((t) =>
                                                <tr key={`tablerow${t}`}>
                                                    <td>9988776655</td>
                                                    {/* <td className=' text-center '><CheckCircleIcon style={{ color:'green' }}/>  </td> */}
                                                    <td className=' text-center '><WarningAmberIcon style={{ color: '#4E62B1' }} />  </td>
                                                    <td>
                                                        <Form.Select aria-label="Is Decision Maker">
                                                            <option>Is Decision Maker</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </Form.Select>
                                                    </td>
                                                    <td className=' text-center pointer'><IconButton><img src={deleteicon} /></IconButton></td>
                                                </tr>

                                            )}


                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer></Modal.Footer> */}
                </Modal>
            </div>
        </>
    )
}

export default ChatSection
