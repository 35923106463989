import React from 'react'
import profilepic from "../image/profilepic.jpg"
const MsgOutgoing = (props) => {
    return (
        <>
            <div className="outgoing-msg d-flex justify-content-end w-100 position-relative">
                <div className="msg-text position-relative">
                    <p>{props.message}</p>
                    <div className="msg-time position-absolute end-0"><p>10.25 PM</p></div>
                </div>
                <div className="chat-profile"><img src={profilepic} alt="" srcSet="" /></div>
            </div>
        </>
    )
}

export default MsgOutgoing
