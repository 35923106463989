// import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { backendURL } from "../../../../../appVariable/variable";
import { toast } from "react-toastify";
// const userlogin = getToken();
// const userId = userlogin?.userId;

export async function getTypeOfProperties(success) {
  await axios
    .get(`${backendURL}/api/property/propertylistingtypeview/`)
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getLocations(val, success) {
  let tempObj = {};
  tempObj["location"] = val;
  axios
    .post(`${backendURL}/api/master/searchforhome/`, tempObj)
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      } else {
        success(resp.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
export function getCategories(id, success) {
  let tempObj = {};
  tempObj["Property_listing_type_id"] = id;
  axios
    .post(`${backendURL}/api/property/propertylisting_typeview/`, tempObj)
    .then((resp) => {
      if (resp.data.code == 200 && resp.data.status == "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export function getTypesBasedOnCategory(id, success) {
  let tempObj = {};
  tempObj["Property_listing_type_id"] = id;
  axios
    .post(
      `${backendURL}/api/property/propertymaicategorytypehomeview/`,
      tempObj
    )
    .then((resp) => {
      if (resp.data.code == 200 && resp.data.status == "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export function getTypesBasedOnTypeLeisure(id, success) {
  let tempObj = {};
  tempObj["Property_main_category_id"] = id;
  axios
    .post(
      `${backendURL}/api/property/propertysubcategorytypehomeview/`,
      tempObj
    )
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      console.log(err);
    });
}
export function saveSearch(tempObj, success) {
  axios
    .post(`${backendURL}/api/property/filtersavesearch/`, tempObj)
    .then((resp) => {
      if (resp.data.code == 200 && resp.data.status == "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {});
}

export function ApplySearch(tempObj, success) {
  axios
    .post(`${backendURL}/api/property/search/`, tempObj)
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      } else {
        toast.error("No Property Availble for this search");
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
