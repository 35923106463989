import React, { useEffect, useState } from "react";
import icons from "../../../dashboard/components/sidebar/images/SidebarIcon";
import { Autocomplete, TextField } from "@mui/material";
import {
  ApplySearch,
  getLocations,
} from "../../../homepage/components/hero/server";
import { useDispatch, useSelector } from "react-redux";
import { updateFilterData } from "../../../homepage/components/hero/FilterSlice";
import { useNavigate } from "react-router-dom";
import searchIcon from "./images/search.svg";
import PopUpFilter from "../../../dashboard/components/filter-popup/PopUpFilter";

const Filterbar = ({ setShowFilter, setfilterView }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const [optionsList, setOptionsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [listing, setlisting] = useState([]);
  const [filteredListing, setFilteredListing] = useState([]);
  // location option
  const handlefilter = (listingGroup) => {
    try {
      const trueKeys = Object.entries(listingGroup.Type)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      if (listingGroup !== undefined) {
        const trueKeys_amenities = Object.entries(listingGroup.Amenities)
          .filter(([key, value]) => value === true)
          .map(([key]) => key);

        const filteredListing = listing.filter(
          (element) =>
            element.Bathrooms === listingGroup.bathrooms &&
            element.Bedrooms === listingGroup.bedrooms &&
            trueKeys.indexOf(
              element.property_main_category.Main_category.toLowerCase()
            ) !== -1 &&
            (listingGroup.feeType === "Fee"
              ? typeof element.fees === "string"
              : typeof element.fees !== "string") &&
            parseInt(listingGroup.minSqft) <= element.Square_sqft &&
            element.Square_sqft <= parseInt(listingGroup.maxSqft) &&
            parseInt(listingGroup.minPrice) <=
              element.property_listing_amount &&
            element.property_listing_amount <=
              parseInt(listingGroup.maxPrice) &&
            (trueKeys_amenities.length !== 0
              ? element.amenity_list
                  .map((item) => item.amenities_value)
                  .some((item) => trueKeys_amenities?.includes(item))
              : element.Bathrooms === listingGroup.bathrooms)
        );
        setFilteredListing(filteredListing);
      } else {
        const filteredListing = [...listing];
        setFilteredListing(filteredListing);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let timerOut = setTimeout(() => {
      getLocations(searchQuery, (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master?.city_name +
              ", " +
              item.city_master.state_master?.state_name +
              "," +
              item.city_master.state_master.country_master?.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item?.city_name +
              "," +
              item?.state_master?.state_name +
              "," +
              item?.state_master.country_master.country_name
            }`,
            value: {
              city_id: item?.id,
              state_id: item?.state_master.id,
              country_id: item?.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item?.state_name + "," + item?.country_master.country_name
            }`,
            value: {
              state_id: item?.id,
              country_id: item?.country_master.id,
            },
          };
        });
        setOptionsList([...newarr, ...newarr1, ...newarr2]);
      });
    }, 1000);
    return () => clearTimeout(timerOut);
  }, [searchQuery]);

  // location onChange
  const locationOnChange = (e) => {
    dispatch(
      updateFilterData({
        locationText: e?.label,
        area: e?.value?.area_id ? e?.value?.area_id : null,
        city: e?.value?.city_id,
        state: e?.value?.state_id,
        country: e?.value?.country_id,
      })
    );
  };

  let SearchByLoaction = () => {
    if (
      FilterDataInStore.property_listing &&
      FilterDataInStore.category &&
      FilterDataInStore.city &&
      FilterDataInStore.state
    ) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...FilterDataInStore };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      ApplySearch(searchSchema, (success) => {
        dispatch(
          updateFilterData({
            Filter_responce: success.property,
            Neighborhood: success.Neighborhood,
          })
        );
        setlisting(FilterDataInStore.Filter_responce);
        if (success?.page === 2) {
          localStorage.setItem(
            "FilterSearchSchema",
            JSON.stringify({
              ...searchSchema,
              locationText: FilterDataInStore.locationText,
            })
          );
          navigate(`/dashboard/property-details`);
        }
      });
    }
  };

  return (
    <>
      {/* <div className="filter-sec">
                <div className="search-box d-flex flex-grow-1">
                    <div className="search d-flex align-items-center justify-content-between flex-grow-1">
                        <input type="text" className="trans-input" placeholder="Search Town or City" name="" id="" />
                        <div className="search-icon pointer">
                            <img src={icons.search} alt="" />
                        </div>
                    </div>
                    <div className="filter-icon pointer d-flex align-items-center justify-content-center"
                    // onClick={filterHandler}
                    >
                        <img src={icons.filter} alt="" />
                    </div>
                </div>
            </div> */}
      <div className="filter-sec">
        <div className="search-box d-flex flex-grow-1">
          <div className="search d-flex align-items-center justify-content-between flex-grow-1">
            <Autocomplete
              className="hide-downBtn"
              id="combo-box-demo"
              disablePortal
              options={optionsList}
              // value={getCategoriesVal[0]?.location?.label}
              onChange={(e, value) => {
                locationOnChange(value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              style={{ minWidth: "250px", width: "100%", zIndex: 9999 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  style={{ zIndex: 9999 }}
                />
              )}
            />
            <div
              className="search-icon pointer"
              onClick={() => SearchByLoaction()}
            >
              {" "}
              <img src={searchIcon} alt="" />
            </div>
          </div>
          {setfilterView === false ? null : (
            <div
              className="filter-icon pointer d-flex align-items-center justify-content-center"
              onClick={() => setShowFilter(true)}
            >
              {" "}
              <img src={icons.filter} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Filterbar;
