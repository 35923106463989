import React, { useEffect, useState } from 'react'
import Mapview from '../../PropertyDetails/componants/map-view/Mapview'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgentDetails } from '../../profile/components/personal/server';
import { backendURL, getToken } from '../../../../appVariable/variable';

const MapViewCurrentListing = () => {
    let token = getToken()
    let agentSlug = token?.profile.slug;
    const [agentDetails, setAgentDetails] = useState()
    const navigate = useNavigate();
    const currentListingDetailSlice = useSelector(state => state.currentListingDetailSlice);
    useEffect(() => {
        getAgentDetails(agentSlug, (success) => {
            setAgentDetails(success)
        });

        const element = document.documentElement;
        // if (element.requestFullscreen) {
        //     element.requestFullscreen();
        // }

        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                navigate(-1) // Call your function here when exiting fullscreen
                console.log('Exited fullscreen');
            }
        };
        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => { document.removeEventListener('fullscreenchange', handleFullscreenChange); };

    }, []);

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            navigate(-1)
        }
    };

    return (
        <div className=' w-100' style={{ maxHeight: '77vh' }}>
            {/* quik-view map-view */}
            <div className="quik-map d-flex justify-content-end gap-3 mb-3">
                <p>Quick View</p>
                <div className="form-check form-switch pt-0">
                    <input className="form-check-input p-0 pointer" type="checkbox" id="quikviewswitch"
                        defaultChecked={true}
                        onChange={(e) => { exitFullscreen() }}
                    />
                </div>
                <p>Map View</p>
            </div>

            <div className="current-map-view position-relative">
                <div className=" position-absolute agent-profile-mapview center flex-column">
                    <div className="profile-pic">
                        <img src={`${backendURL}${agentDetails?.[0]?.profile_image}`} alt="" srcSet="" className=' w-100 h-100' />
                    </div>

                    <div className="name text-capitalize text-center"><p>{`${agentDetails?.[0]?.first_name} ${agentDetails?.[0]?.last_name}`}</p></div>
                    {agentDetails && <p className=' lience'>{agentDetails?.[5]?.lic_Type}</p>}
                </div>
                <Mapview allProperty={currentListingDetailSlice} />
            </div>
        </div>
    )
}

export default MapViewCurrentListing