import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { getToken } from '../../../../../appVariable/variable';
import { accountSetting } from '../../../personal/typeOfAccount/server';
// import { getCategories } from '../../../homepage/components/hero/server';
// import { CircularProgress } from '@mui/material';

let guestAccType = ["Residential", "Commercial"]

const SelectGeustAccType = (props) => {
    const { setshowSetAccPopup } = props
    let userlogin = getToken();
    let userId = userlogin?.userId;
    const [typeOfAccount, setTypeOfAccount] = useState(null);


    const SetAccType = () => {
        let accountobj = { user_id: userId, type_allowed: typeOfAccount }
        if (!(typeOfAccount === null)) {
            accountSetting(accountobj, (success) => {
                setshowSetAccPopup(false)
                userlogin.user_info.type_of_listing = typeOfAccount
                localStorage.setItem("userDetails", JSON.stringify(userlogin));
            });
        }
        // getCategories(typeOfAccount, (success) => {
        //     let newarr = success.map((item) => { return { label: `${item.listing_type}`, id: item.id, }; });
        //     setCategoryListOptions([...newarr]);
        //     dispatch(updateFilterOptions({ categotyOptions: newarr }));
        // });
    }
    return (
        <Modal className=' select-acc-type' show={true} //  onHide={handleClose}
            size="lg" centered>
            <Modal.Header className="p-0 ps-3 w-100 align-items-center d-flex" > <p className=' mt-3 pri-text-clr text-center'>Slect Account type</p> </Modal.Header>
            <Modal.Body>
                <div className=' d-flex w-100 justify-content-evenly'>
                    {guestAccType.map((type, i) =>
                        <div key={type}>
                            <input type="radio" className="userradio" name="userType" id={`${type}${i}`} hidden />
                            <label htmlFor={`${type}${i}`} className="signupmodal_box1 mt-0 user-type pointer" style={{ width: "152px" }} onClick={() => { setTypeOfAccount(i); }} >
                                <div className={`signupmodal_box${i + 1}_shape1`}><div className={`signupmodal_box${i + 1}_subshape1`}><div className={`signupmodal_box${i + 1}_subshape_img`}></div> </div> </div>
                                <div className={`signupmodal_box${i + 1}_text`}><span>{type}</span></div>
                            </label>
                        </div>
                    )}
                </div>
                <div className=' text-center my-3 media_pading p-0'>
                    <button className='save_btn '
                        onClick={SetAccType}
                    > {/* <CircularProgress /> */}
                     Continue</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SelectGeustAccType
