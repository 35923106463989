import axios from "axios";
import { backendURL, getToken } from "../../../../appVariable/variable";

export function getagentaminities(success) {
  let token = getToken();
  axios
    .get(`${backendURL}/api/property/AminitiesView/`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTearmsOffers(success) {
  axios
    .get(`${backendURL}/api/property/GetTermsOffer/`)
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getStateList(success) {
  axios
    .get(`${backendURL}/api/property/StateDashboard/`)
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function GetCityList(stateId, success) {
  axios
    .get(`${backendURL}/api/property/CityDependentState/?state=${stateId}`)
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function GetAreaList(CityId, success) {
  axios
    .get(`${backendURL}/api/property/AreaDependentCity/?city=${CityId}`)
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getPet(success) {
  let token = getToken();
  axios
    .get(`${backendURL}/api/master/PetViewAPI/`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((resp) => {
      if (resp.data.code === 200 && resp.data.status === "success") {
        success(resp.data.data);
      } else {
        success(resp.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function AddProperty(formData, success) {
  let token = getToken();
  axios
    .post(`${backendURL}/api/property/propertydetail/`, formData, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function GetProperty(slug, success) {
  let token = getToken();
  axios
    .get(`${backendURL}/api/property/propertydetail/${slug}`, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function EditListedProperty(formData, id, success) {
  let token = getToken();
  axios
    .put(`${backendURL}/api/property/propertydetail/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token?.accessToken}`,
        // 'Authorization': `Bearer ${token?.idToken}`
      },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function ZipCodeAddress(zipObj, success) {
  let token = getToken();
  axios
    .post(`${backendURL}/api/property/FindAreaCityState/`, zipObj, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}
