import axios from "axios"
import { backendURL, getToken } from "../../../../../../appVariable/variable"


export function getamminity(success) {

    let token = getToken()
    let userId = token?.userId;
    // console.log(token.idToken)
    axios
        .get(`${backendURL}/api/property/AminitiesView/`, {
            // headers: {
            //     'Authorization': `Bearer ${token?.idToken}`
            // }
        })
        .then((response) => {
            if (response.data.code == 200 && response.data.status == "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}