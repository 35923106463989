import axios from "axios";
import { backendURL } from "../../../../../appVariable/variable";

export function getResidentialPropertyListings(lat, long, success) {
  let tempObj = {};
  tempObj["latitude"] = lat;
  tempObj["longitude"] = long;
  axios
    .post(`${backendURL}/api/property/Residential_Nearest_property/`,)
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data)

      }
    })
    .catch((error) => {
      console.log("error on home server Residential_Nearest_property")

    });
}
export function getCommerciaPropertyListings(lat, long, success) {
  let tempObj = {};
  tempObj["latitude"] = lat;
  tempObj["longitude"] = long;
  axios
    .post(`${backendURL}/api/property/Commercial_Nearest_property/`)
    .then((response) => {

      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data)

      }
    })
    .catch((error) => {
      console.log(error)
      console.log("error on home server 39")
    });
}

export function checkingworkin( success) {
  success("in testing")
}
