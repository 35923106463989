import ListingCard from "./Listing-card/ListingCard";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (<ArrowBackIosIcon {...props} className="arrowleft slider-arrow" />);
export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (<ArrowForwardIosIcon {...props} className="arrowright slider-arrow" />);

export const Slidersettings = {
  dots: false, infinite: true, speed: 500, slidesToShow: 5, slidesToScroll: 5,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
};

const CardSlider = (props) => {
  const { heading, listing } = props;

  // const scrollRef = useRef(null);
  // const scroll = (direction) => {
  //   const { current } = scrollRef;

  //   if (direction === "left") {
  //     current.scrollLeft -= 150;
  //   } else {
  //     current.scrollLeft += 150;
  //   }
  // };

  return (
    <>
        <div className="card-slider-box d-flex flex-column gap-2">
          <p className="listing-subheading">{heading}</p>
          {/* <div className=" position-relative">
          <div className="card-slider d-flex hide-scrollbar" ref={scrollRef}>
            {listing.map((list, i) => (
              <ListingCard key={`recent${i}`} data={list} />
            ))}
          </div>
          <div
            className="arrow left-arrow pointer center position-absolute"
            onClick={() => scroll("left")}
          >
            <ChevronLeftIcon />
          </div>
          <div
            className="arrow right-arrow pointer center position-absolute"
            onClick={() => scroll("right")}
          >
            <ChevronRightIcon />
          </div>
        </div> */}
            <Slider {...Slidersettings}>
              {listing.map((list, i) => (<ListingCard key={`cdc${i}`} data={list} />))}
            </Slider>
        </div>
    </>
  );
};

export default CardSlider;
