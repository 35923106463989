import React, { useEffect, useState } from "react";
import imgone from "./images/imgone.jpg";
import imgthree from "./images/imgthree.jpg";
import imgtwo from "./images/imgone.jpg";
import { getForceClosureResidentialData, getIndustrialCommercialData, getJustListedCommercialData, getJustListedResedentialData, getLuxuryRentalResidentialData, getLuxurySalesResidentialData, getMixedUseCommercialData, getNoFeeResidentialData, getOfficeCommercialData, getPetFriendlyResidentialData, getRetailCommercialData, getVacationResidentialData, } from "../../Services/Server";
import CardSlider from "./components/CardSlider";
import "./recentListing.scss";
// import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Aos from "aos";

// call all apis here

const RecentListing = () => {
  const [justListedResedentialData, setJustListedResedentialData] = useState(); //404
  const [petFriendlyResidentialData, setPetFriendlyResidentialData] = useState();
  const [noFeeResidentialData, setNoFeeResidentialData] = useState();
  const [vacationResidentialData, setVacationResidentialData] = useState();
  const [forceClosureResidentialData, setForceClosureResidentialData] = useState();
  const [luxurySalesResidentialData, setLuxurySalesResidentialData] = useState();
  const [luxuryRentalResidentialData, setLuxuryRentalResidentialData] = useState();
  const [justListedCommercialData, setJustListedCommercialData] = useState();
  const [OfficeCommercialData, setOfficeCommercialData] = useState();
  const [retailCommercialData, setRetailCommercialData] = useState();
  const [mixedUseCommercialData, setMixedUseCommercialData] = useState();
  const [industrialCommercialData, setIndustrialCommercialData] = useState();

  useEffect(() => {
    getJustListedResedentialData(setJustListedResedentialData);
    getPetFriendlyResidentialData(setPetFriendlyResidentialData);
    getNoFeeResidentialData(setNoFeeResidentialData);
    getVacationResidentialData(setVacationResidentialData);
    getForceClosureResidentialData(setForceClosureResidentialData);
    getLuxurySalesResidentialData(setLuxurySalesResidentialData);
    getLuxuryRentalResidentialData(setLuxuryRentalResidentialData);
    // commercial
    getJustListedCommercialData(setJustListedCommercialData);
    getOfficeCommercialData(setOfficeCommercialData);
    getRetailCommercialData(setRetailCommercialData);
    getMixedUseCommercialData(setMixedUseCommercialData);
    getIndustrialCommercialData(setIndustrialCommercialData);
    Aos.init({ duration: 2000 })
  }, []);


  let residentialListing = [
    {
      img: imgone,
      title: "JUST LISTED",
      data: justListedResedentialData ? justListedResedentialData : 0,
    },
    {
      img: imgthree,
      title: "pet-friendly",
      data: petFriendlyResidentialData ? petFriendlyResidentialData : 0,
    },
    {
      img: imgtwo,
      title: "NO FEE",
      data: noFeeResidentialData ? noFeeResidentialData : 0,
    },
    {
      img: imgthree,
      title: "Vacation",
      data: vacationResidentialData ? vacationResidentialData : 0,
    },
    {
      img: imgtwo,
      title: "Foreclosure",
      data: forceClosureResidentialData ? forceClosureResidentialData : 0,
    },
    {
      img: imgtwo,
      title: "Luxury Rental",
      data: luxuryRentalResidentialData ? luxuryRentalResidentialData : 0,
    },
    {
      img: imgtwo,
      title: "Luxury Sales",
      data: luxurySalesResidentialData ? luxurySalesResidentialData : 0,
    },
  ];

  let commercialListing = [
    {
      img: imgone,
      title: "JUST LISTED",
      data: justListedCommercialData ? justListedCommercialData : 0,
    },
    {
      img: imgthree,
      title: "Office",
      data: OfficeCommercialData ? OfficeCommercialData : 0,
    },
    {
      img: imgtwo,
      title: "Retail",
      data: retailCommercialData ? retailCommercialData : 0,
    },
    {
      img: imgone,
      title: "Mixed Use",
      data: mixedUseCommercialData ? mixedUseCommercialData : 0,
    },
    {
      img: imgthree,
      title: "Industrial",
      data: industrialCommercialData ? industrialCommercialData : 0,
    },
  ];

  return (
    <>
      <div className="layout span-to-top pt-2">
        <h3 className="section recent-listing-heading pt-3">RECENT LISTINGS</h3>
        <div className="section">
          <div className=" recent-listing-sec gap-3 d-flex flex-column pb-5">
            <CardSlider heading="Residential" listing={residentialListing} />
            <CardSlider heading="Commercial" listing={commercialListing} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentListing;
