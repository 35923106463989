import React from "react";
import "./Reviewcards.scss";
import profileimg from "./images/prifilepic.png";
import { backendURL } from "../../../../appVariable/variable";
import { Rating } from "@mui/material";

export default function Reviewcards(props) {
  return (
    <>
      <div className="reviews_card_main">
        <div className="card card_border inner_card">
          <div className="d-flex card-body card_pading">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={
                  props.member?.profile
                    ? `${backendURL}${props.member?.profile}`
                    : profileimg
                }
                alt=""
              />

              <div>
                <h6 className="text-center text-capitalize">
                  {props.member?.name !== null ? props.member?.name : "-----"}
                </h6>
                <Rating
                  name="read-only"
                  className="rating"
                  value={props.member?.rating}
                  readOnly
                />
              </div>


              {!props.gotReview ? <div className="pt-8 mt-2">
                <button
                  className="Review_btn blue__btn"
                  onClick={() => {
                    props.setModalShow(true);
                    props.setCurrentuser(props.member);
                  }}
                >
                  Review
                </button>
              </div> : null}

              {!props.gotReview ? <p className="pt-2 pointer">Not Interested In Review</p> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
