import axios from "axios"
import { backendURL, getToken } from "../../../../appVariable/variable"


export function getGuestDetails(success) {

    let token = getToken()
    let userId = token?.userId;
    // console.log(token.idToken)
    axios
        .get(`${backendURL}/api/user/guest-User-Profile/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                console.log(response.data.data);
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function submitProfileData(formData, success) {
    let token = getToken()
    axios.put(`${backendURL}/api/user/guest-User-Profile/`, formData, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllstate(success) {

    let token = getToken()
    let userId = token?.userId;
    // console.log(token.idToken)
    axios
        .get(`${backendURL}/api/master/state/`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                // console.log(response.data.data);
                // response.data.data.map((i)=> console.log(i.state_name))
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}


export async function getAllzipcode(success) {
    let token = getToken()
    await axios
        .get(`${backendURL}/api/master/zipcode/`, {
            headers: { 'Authorization': `Bearer ${token?.idToken}`}
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                // console.log(response.data.data);
                // response.data.data.map((i)=> console.log(i.state_name))
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllcity(success) {
    let token = getToken()
    let userId = token?.userId;
    axios
        .get(`${backendURL}/api/master/city/`, {
            headers: { 'Authorization': `Bearer ${token?.idToken}` }
        })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                // console.log(response.data.data);
                // response.data.data.map((i)=> console.log(i.state_name))
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllArea(success) {
    let token = getToken()
    // let userId = token?.userId;
    axios
        .get(`${backendURL}/api/master/area/`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            console.log(response)
            if (response.data.code === 200 && response.data.status === "success") {
                // console.log(response.data.data);
                // response.data.data.map((i)=> console.log(i.state_name))
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}