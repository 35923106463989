import React from 'react'
import { backendURL } from '../../../../../appVariable/variable'
import ad from "./images/ad.jpg"

const AdCardGuest = (props) => {
    // const userlogin = getToken()
    const { Memberinfo } = props
    return (
        <div className="ad-cardUser position-relative">
            <img className=' h-100' src={Memberinfo ? `${backendURL}${Memberinfo?.profile_image}` : ad} alt="" />
            <div className="ad-name position-absolute w-100 center"><p>{Memberinfo ? `${Memberinfo?.first_name} ${Memberinfo?.last_name}` : ""}</p></div>
            {/* <div className="ad-name position-absolute w-100 center"><p>Aniket ghodmare</p></div> */}
            <div className="ad-title position-absolute w-100 center"><p>(
                {Memberinfo?.user_type?.user_type === 2 ? "Advertisment" : "User"}
                )</p></div>
        </div>
    )
}

export default AdCardGuest
