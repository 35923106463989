import axios from "axios";
import { backendURL, getToken } from "../../../../../appVariable/variable";

export function createTeam(tempObj, success) {
  let token = getToken()
  axios
    .put(`${backendURL}/api/user/createteam/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        console.log(response.data.data);
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function inviteTeamMember(tempObj, success) {
  console.log("yes")
  let token = getToken()
  await axios
    .post(`${backendURL}/api/property/Invitation_link/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      success(response)
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function inviteListTeamMember(tempObj, success) {
  let token = getToken()
  await axios
    .post(`${backendURL}/api/property/BluckInvitation_View/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      success(response)
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function EditTeamMember(id, tempObj, success) {
  let token = getToken()
  await axios.put(`${backendURL}/api/property/UpdateTeamMember/${id}`, tempObj, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((response) => {
      success(response)
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTeamMember(tempObj, success) {
  let token = getToken()
  axios
    .post(`${backendURL}/api/user/createteam/`, tempObj, {
      headers: {
        'Authorization': `Bearer ${token?.idToken}`
      }
    })
    .then((response) => {
      success(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTeamDetails(success) {
  let token = getToken()
  let userslug = token?.profile.slug;
  axios.get(`${backendURL}/api/user/agentuserprofile/${userslug}`, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); }
    })
    .catch((error) => {console.log(error);});
}


export async function RemoveTeamMember(deleteid, success) {
  let token = getToken()
  // let accessToken = token?.accessToken;
  await axios.delete(`${backendURL}/api/property/RemoveTeamMember/${deleteid}`,
    { headers: { 'Authorization': `Bearer ${token?.accessToken}` }, })
    .then((response) => {
      success(response);
    })
    .catch((error) => { console.log(error); });
}


export const getTeamMemberProfile = (paramObj,success) => {
  let token = getToken()
  let userId = token?.userId;
  axios.get(`${backendURL}/api/property/teammerberprofile/${paramObj}`, {
      headers: { 'Authorization': `Bearer ${token?.idToken}`}
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response);
      }
    })
    .catch((error) => { console.log(error);});
}

export async function DeleteTeam(success) {
  let token = getToken()
  // let accessToken = token?.accessToken;
  await axios.delete(`${backendURL}/api/property/RemoveTeam/${token?.userId}`)
    .then((response) => { success(response.data); })
    .catch((error) => { console.log(error); });
}




