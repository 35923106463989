import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./Savesearchmain.scss";
import PropertyCard from "../dashboard/components/mainSection/PropertyCard";
import { SyncLoader } from "react-spinners";
import deleteimg from "./images/delete.svg";
import { useState } from "react";
import removeimg from "./images/remove.svg";
import {
  getAllpropertyInfo,
  //saveDefaultPropertyShow, getAgentTime, getDefaultPropertyAmenityDetailPopup, getDefaultPropertyDetailPopup, getDefaultPropertyImagesDetailPopup, getDefaultPropertySalesFeatures, getDefaultPropertySpacesDetailPopup, getTeamMemberShow
} from "../dashboard/components/mainSection/server";
import {
  DeleteSaveSearch,
  getSaveSearchFilter,
  getSaveSearchFilterPropertyDetail,
} from "./Server";
// import { getToken } from "../../../appVariable/variable";
import PropertySavePopUp from "../dashboard/components/mainSection/PropertySavePopUp";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import PropertyDetailsPopUp from "../dashboard/propperty-datails/PropertyDetailsPopUp";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertyDatail } from "../dashboard/propperty-datails/PropertySlice";

export default function Savesearchmain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saveListing, deleteSaveListing] = useState(false);
  const [getSaveSearchFilters, setGetSaveSearchFilters] = useState([]);
  const [getFilterSaveProperty, setGetFilterSaveProperty] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [id, setId] = useState();
  const [searchId, setSearchId] = useState();
  const [activeSearch, setActiveSearch] = useState();
  const [savePopup, setSavePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );

  let DeleteListing = () => {
    // setLoading(true)
    DeleteSaveSearch(searchId, (success) => {
      if (success.code === 200) {
        deleteSaveListing(false);
        toast.success(success.data);
        showSaveSearchFilter();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  let propertDetails = async (id) => {
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    await getAllpropertyInfo(id, (success) => {
      // getting proprty datails
      setPropertyDetailInfo(success);
    });
  };

  const showSaveSearchFilter = () => {
    getSaveSearchFilter((success) => {
      if (success.data.code === 200) {
        setGetSaveSearchFilters(success.data.data);
        handelClickOnCard(success.data.data[0].id);
        setActiveSearch(success.data.data[0].id);
      } else {
        setGetSaveSearchFilters([]);
        setGetFilterSaveProperty([{ code: 400, data: "No previous searches" }]);
      }
    });
  };

  const handelClickOnCard = (id) => {
    setActiveSearch(id);
    setId(id);
    setGetFilterSaveProperty([]);
    setTotalCount(0);
    getSaveSearchFilterPropertyDetail(id, (success) => {
      if (success.data.code === 400 || success.data.status === "failed") {
        setGetFilterSaveProperty([{ code: 400, data: "No Data Found" }]);
        setTotalCount(0);
      } else if (success.data.code === 200) {
        setGetFilterSaveProperty(success.data.data.property.slice(0, 6));
        setTotalCount(success.data.data.property.length);
      }
    });
  };

  const ShowAllSaveSearch = () => {
    if (getFilterSaveProperty[0].code === 400) {
      // navigate("/dashboard/saved-search")
    } else {
      navigate("/dashboard/quik-view/" + id);
    }
  };

  useEffect(() => {
    showSaveSearchFilter();
    // const firstGetsaveSearch = showSaveSearchFilter();
    // return () => { firstGetsaveSearch.cancel() };
  }, []);
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="saved_search_section">
        <div className="save_serch_text">
          <div className="">
            {" "}
            <h6>Saved Search</h6>
          </div>
          <div className="">
            <p> Listing</p>{" "}
          </div>
        </div>
        <Row>
          <Col
            sm={7}
            style={{ overflowY: "auto", maxHeight: "80vh" }}
            className=" hide-scrollbar"
          >
            {/* listing card */}
            <div className="listing__card">
              {getSaveSearchFilters.length > 0 ? (
                getSaveSearchFilters.map((data, i) => (
                  <div
                    className={`card card_border pointer ${
                      activeSearch === data.id ? "activeSearch" : ""
                    }`}
                    key={`savecardmdked${i}`}
                    onClick={() => handelClickOnCard(data.id)}
                  >
                    <div className="card-body ">
                      <div className="d-flex justify-content-between card_style align-atims-center">
                        <div className="center">
                          <p>{data && data.filterName}</p>
                        </div>
                        <div className="pe-2">
                          <div className="d-flex delete_droupdown">
                            <div className="btn-group dropstart bg-transparent ms-auto  tool_target">
                              <button
                                type="button"
                                className="dropdown-toggle border-0 bg-transparent"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {" "}
                                <MoreVertIcon />
                              </button>
                              <ul className="dropdown-menu tool_border tool-aero bg-white py-1">
                                <li
                                  className="ps-3 delete_text d-flex align-items-center pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteSaveListing(true);
                                    setSearchId(data.id);
                                  }}
                                >
                                  <img
                                    src={deleteimg}
                                    alt=""
                                    className="delete_img"
                                  />
                                  <p>Remove Listing</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No previous searches have been saved.</p>
              )}
            </div>
          </Col>

          <Col sm={5}>
            <div className="right_card">
              <div className="card_border">
                <div className="">
                  <p className="top_text">Top 6 Property</p>
                  <div className="card_space_between hide-scrollbar">
                    {getFilterSaveProperty.length > 0 ? (
                      getFilterSaveProperty?.map((city, id) => (
                        <div key={`saveprop${id}`}>
                          {city.code === 400 ? (
                            <p className="center w-100  ">{city.data}</p>
                          ) : (
                            <PropertyCard
                              key={`saveprop${id}`}
                              agentListingData={city}
                              index={id}
                              handelOpenPopUp={propertDetails}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="center w-100">
                        <SyncLoader color="#315efb" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 d-grid">
                    <button
                      className="btn-block  View_All_btn blue__btn "
                      onClick={ShowAllSaveSearch}
                    >
                      View All Property ({totalCount})
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="delete_save_listing">
          <Modal
            className="delete_save_listing"
            show={saveListing}
            onHide={() => deleteSaveListing(false)}
            size="lg"
            ria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              {/* <Modal.Title id="contained-modal-title-vcenter"></Modal.Title> */}
            </Modal.Header>
            <Modal.Body className="px-5 mt-4   save_listing_body">
              <div className="text-center">
                <img src={removeimg} alt="" />
              </div>

              <h3 className="pb-3 mb-1 text-center">Are you sure?</h3>

              <p className="text-center pb-4 mb-1">
                You want to delete the saved listing!
              </p>
              <div className="row">
                <div className="col-sm-6 d-grid">
                  <button
                    type="submit"
                    className="cancel_btn btn-block"
                    onClick={() => deleteSaveListing(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-sm-6 d-grid">
                  <button
                    type="submit"
                    className="save__btns btn-block"
                    onClick={() => DeleteListing()}
                  >
                    Yes
                  </button>
                </div>
              </div>
              <div className="text-end mb-3"></div>
            </Modal.Body>
          </Modal>
        </div>
        {/* end You want to delete the saved listing! */}
      </div>

      {/* property detail pop up  */}
      {propertyDetailInStore.setshowPropertyPopup && (
        <PropertyDetailsPopUp
          propertyDetailInfo={propertyDetailInfo} // passing info about property
        />
      )}

      {
        <PropertySavePopUp
          savePopup={savePopup}
          setSavePopup={setSavePopup}
          // handelSaveIndividualPropertyPopUp={handelSaveIndividualPropertyPopUp}
        />
      }
    </>
  );
}
