import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable"


export const BoostMarketingDashboard = (id, success) => {
    let token = getToken();
    axios.post(`${backendURL}/boostmarketingdashboardapi/`, id, { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
        .then((response) => { if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); } })
        .catch((error) => { console.log(error); });
}


export function BoostMarkrtingForm(formData, success) {
    let token = getToken()
    axios.post(`${backendURL}/boostmarket/planmarketingform/`, formData, { headers: { 'Authorization': `Bearer ${token?.accessToken}` } })
        .then((response) => { success(response) })
        .catch((error) => { console.log(error); });
}

export function TermsAndServices(id, success) {
    axios.get(`${backendURL}/TermsAndServices/${id}`)
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function AgentMarketingUrl(success) {
    let token = getToken()
    axios.get(`${backendURL}/api/property/ListingSlugid/${token?.userId}`)
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}