import React, { useEffect, useState } from 'react'
import profile from "./image/profile-pic.png"
import uplaod from "./image/upload.svg"
import "./corporateInformation.scss"
import { useFormik } from 'formik'
import * as Yup from "yup";
import { backendURL, getToken } from '../../../../../appVariable/variable'
import { toast } from 'react-toastify'
import { getAllzipcode } from '../../../personal/personalInformation/Server'
import ProfileEdit from '../../../profile/components/personal/ProfileEdit'
import { AllCity, AllStates, Allzipcode, LandloardProfileSetting, getAgentLandloardProfile } from '../../server'
import Select from "react-select";
import { getStates, getTowns } from '../../../profile/components/personal/server'
import Loader from '../../../Loader/Loader'

const CorporateInformation = () => {
    let token = getToken()
    let agentSlug = token?.profile.slug;
    const [previewProfileImg, setPreviewProfileImg] = useState(profile);
    const [propfileEditModal, setPropfileEditModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allZipcodes, setAllZipcodes] = useState([])
    const [states, setStates] = useState([]);
    const [towns, setTowns] = useState([]);
    const [defaultState, setDefaultState] = useState({});
    const [defaultTown, setDefaultTown] = useState({});
    const [defaultZipcode, setDefaultZipcode] = useState({})

    const nameValidationSchema = Yup.string()
        .required("*First Name required")
        .matches("^[a-zA-Z]*$", 'Number not accepted')
        .nullable()
        .max(20, "Must be 20 characters or less");

    const numberValidationSchema = Yup.string()
        // .required("Number required")
        .matches(/^[0-9]+$/, 'Enter valid Number')
        .nullable()
        .max(10, "Enter valid Number").min(10, "Enter valid Number");


    const formik = useFormik({
        initialValues: {
            profileImg: null,
            business_name: "",
            first_name: "",
            last_name: "",
            email: token.user_info?.username,
            work_number: "",
            corp_address: "",
            cell_number: "",
            work_city_id: "",
            work_state_id: "",
            work_zipcode: "",
            additional_seats: false,
            no_of_seats: 0,
        },
        validateOnChange: true, validateOnBlur: false,
        validationSchema: Yup.object().shape({
            first_name: nameValidationSchema,
            last_name: nameValidationSchema,
            work_number: numberValidationSchema,
            cell_number: numberValidationSchema,
            // cellNumber: Yup.string().required("*Cell Number is required").matches(/^[0-9]+$/, 'Please Added Cell Number').nullable(),
            // cityId: Yup.string().ensure().required("Topic is required!"),
            // languageSpeak: Yup.array().required("*Fill this field").nullable(),
            // cellNumber: Yup.string().required("*Cell Number is required").min(10, "Enter valid Number").max(10, "Enter valid Number").nullable(),
            // work_city_id: Yup.string().required("Fill this field").nullable(),
            // work_state_id: Yup.string().required("Fill this field").nullable(),
            // work_zipcode: Yup.string().required("Fill this field").nullable(),
            // work_area_id: Yup.string().required("Fill this field").nullable(),
        }),


        onSubmit: (values) => {
            let formData = new FormData();
            let userDetails = getToken();
            formData.append("user_id", userDetails.userId);
            formData.append("profile_image", values.profileImg);
            formData.append("first_name", values.first_name);
            formData.append("last_name", values.last_name);
            formData.append("work_number", values.work_number);
            formData.append("cell_number", values.cell_number);
            formData.append("work_city_id", values.work_city_id);
            formData.append("work_state_id", values.work_state_id);
            formData.append("work_zipcode", values.work_zipcode);
            formData.append("business_name", values.business_name);
            formData.append("corp_address", values.corp_address);
            formData.append("email", values.email);
            formData.append("additional_seats", values.additional_seats);
            formData.append("no_of_seats", values.no_of_seats);
            formData.append("slug", `${userDetails.userId}-${values.first_name}-${values.last_name}`);
            setLoading(true)
            LandloardProfileSetting(formData, (success) => {
                if (success === "error") {
                    toast.warning("Someting wrong pleasse try Again")
                } else {
                    setLoading(false)
                    // updating local stoarge
                    let updateuserDetails = userDetails
                    updateuserDetails.profile.first_name = values.first_name
                    updateuserDetails.profile.slug = `${userDetails.userId}-${values.first_name}-${values.last_name}`
                    localStorage.setItem("userDetails", JSON.stringify(updateuserDetails));
                    toast.success(success)
                    // dispatch(updatePersonalInfo({ firstName: values.first_name, lastName: values.last_name, bannerImg: values.bannerImg, profileImg: values.profileImg }));
                    // props.setShowLicense(true)
                    // props.ChangeTab("third")
                }
            });
        },
    });


    let getData = async () => {
        const [zipcodes, states, Totaltowns, infodata] = await Promise.all([
            Allzipcode(), AllStates(), AllCity(),
            getAgentLandloardProfile(agentSlug)
        ]);

        let newarr = zipcodes.map((item) => { return { label: `${item.Zipcode}`, value: item.id, }; });
        setAllZipcodes([...newarr]);
        let filterZipcode = [...newarr]?.filter((item) => item.label === infodata.zip_code);
        setDefaultZipcode(filterZipcode[0]);

        let stateList = states.map((item) => { return { label: item.state_name, value: item?.id }; });
        setStates(stateList);
        let filterstate = stateList?.filter((item) => item.value === infodata.state?.id);
        setDefaultState(filterstate[0])


        let alltowns = Totaltowns.map((item) => { return { value: item.id, label: item.city_name }; });
        setTowns(alltowns);
        let filterTown = alltowns?.filter((item) => item.value === infodata.citymaster?.id);
        setDefaultTown(filterTown[0]);
        // console.log("object", zipcodes, "states", states, "Totaltowns", Totaltowns, "infodata", infodata)
        formik.setFieldValue("business_name", infodata?.name_of_business_listing);
        formik.setFieldValue("first_name", infodata?.first_name);
        formik.setFieldValue("last_name", infodata?.last_name);
        formik.setFieldValue("work_number", infodata?.work_number_1);
        formik.setFieldValue("cell_number", infodata?.cell_number);
        formik.setFieldValue("corp_address", infodata?.address_line_1);
        formik.setFieldValue("work_city_id", infodata?.citymaster?.id);
        formik.setFieldValue("work_state_id", infodata?.state.id);
        formik.setFieldValue("work_zipcode", infodata?.zip_code);
        formik.setFieldValue("no_of_seats", infodata?.number_user);
        formik.setFieldValue("additional_seats", infodata?.addition_user === 'true' ? true : false);
        console.log("additional_seats", infodata?.addition_user === 'true' ? true : false);
        if (infodata?.profile_image !== null) { setPreviewProfileImg(`${backendURL}${infodata?.profile_image}`); }
    };

    useEffect(() => {
        getData();
    }, [])





    function handleUploadProfileImg(e) {
        if (e.target.files[0]) {
            let fileSize = e.target.files[0].size;
            if (fileSize <= 5250000) {
                const file = e.target.files[0];
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = () => {
                    setPropfileEditModal(true)
                    setPreviewProfileImg(fileReader.result);
                    formik.setFieldValue("profileImg", file);
                };
            } else {
                // swal("", "Image size must be under 5mb", "warning");
            }
        }
    }

    return (
        <>
            {loading ? <Loader /> : null}

            <div className='CorporateInformation_sec'>
                <div className='text-center pb-3'>
                    <img src={previewProfileImg} alt="" className='profile_pic' />
                </div>
                <div className='uplada_btn center m-auto'>
                    <label htmlFor="se" className='ps-2 pointer'> <img src={uplaod} alt="" className='upload_img me-2' />Profile Picture</label>
                    <input type="file" id='se' className='opacity-0' hidden
                        accept="image/png, image/jpeg" onChange={(e) => { handleUploadProfileImg(e); }}
                    />
                </div>
                <p> For best result, use a square photo showing your face</p>

                <div className="row">
                    <div className='col-md-12 last_name_width'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">Business Name</div>
                            <div className="input-sec">
                                <input type="text" className="form-control border-0 pb-0"
                                    name='business_name'
                                    onChange={formik.handleChange}
                                    value={formik.values.business_name}
                                />
                                <span className='validation-text'>{formik.errors.business_name}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">First Name<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec">
                                <input type="text" className="form-control border-0 pb-0"
                                    name='first_name'
                                    onChange={formik.handleChange}
                                    value={formik.values.first_name}
                                />
                                <span className='validation-text'>{formik.errors.first_name}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                Last Name<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec">
                                <input type="text" className="form-control border-0 pb-0"
                                    name='last_name'
                                    onChange={formik.handleChange}
                                    value={formik.values.last_name}
                                />
                                <span className='validation-text'>{formik.errors.last_name}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                Email<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec">
                                <input type="text" className="form-control border-0 pb-0"
                                    name='email'
                                    // onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                Work Number 1<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec">
                                <input type="number" className="form-control border-0 pb-0"
                                    name='work_number'
                                    onChange={formik.handleChange}
                                    value={formik.values.work_number}
                                />
                                <span className='validation-text'>{formik.errors.work_number}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">
                                Corp. Address
                            </div>
                            <div className="input-sec">
                                <input type="text" className="form-control border-0 pb-0"
                                    name='corp_address'
                                    onChange={formik.handleChange}
                                    value={formik.values.corp_address}
                                />
                                <span className='validation-text'>{formik.errors.corp_address}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">Cell Number<span className="red-star" >*</span>
                            </div>
                            <div className="input-sec">
                                <input type="number" className="form-control border-0 pb-0"
                                    name='cell_number'
                                    onChange={formik.handleChange}
                                    value={formik.values.cell_number}
                                />
                                <span className='validation-text'>{formik.errors.cell_number}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">Town</div>
                            <div className="input-sec">
                                <Select
                                    className="custum-react-select select-wrapper"
                                    classNamePrefix="select"
                                    options={towns}
                                    value={[{ label: defaultTown?.label, value: defaultTown?.value },]}
                                    onChange={(e) => {
                                        setDefaultTown(e);
                                        formik.setFieldValue("work_city_id", e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">State</div>
                            <div className="input-sec">
                                <Select
                                    className="custum-react-select select-wrapper"
                                    classNamePrefix="select"
                                    options={states}
                                    value={[{ label: defaultState?.label, value: defaultState?.value },]}
                                    onChange={(e) => {
                                        setDefaultState(e);
                                        formik.setFieldValue("work_state_id", e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">Zip code</div>
                            <div className="input-sec">
                                <Select
                                    className="custum-react-select select-wrapper"
                                    classNamePrefix="select"
                                    options={allZipcodes}
                                    value={[{ label: defaultZipcode?.label, value: defaultZipcode?.value },]}
                                    onChange={(e) => {
                                        setDefaultZipcode(e)
                                        formik.setFieldValue("work_zipcode", e.label);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ps-3'> </div>

                    <div className='col-md-6 world_text'>
                        <span className='pb-2 mb-1'>Would you like to add additional Seats?</span>
                        <div className='d-flex'>
                            <div className="form-check center  me-1 ps-0">
                                <input type="radio" value="" name="yes" id="flexyes"
                                    checked={formik.values.additional_seats}
                                    onChange={(e) => formik.setFieldValue("additional_seats", true)}
                                />
                                <label className="form-check-labels  ms-2" htmlFor="flexyes"> yes </label>
                            </div>
                            <div className="form-check center">
                                <input type="radio" value="" name="yes" id="flexNo"
                                    checked={!(formik.values.additional_seats)}
                                    onChange={(e) => {
                                        formik.setFieldValue("additional_seats", false);
                                        formik.setFieldValue("no_of_seats", 0);
                                    }}
                                />
                                <label className="form-check-labels ms-2" htmlFor="flexNo">No</label>
                            </div>
                        </div>


                    </div>
                    <div className='col-md-6 ps-3'>
                        <div className="d-flex main_input">
                            <div className="input_name grow-1 text-end">No of Seats<span className="red-star" >*</span></div>
                            <div className="input-sec">
                                <input type="number" className="form-control border-0 pb-0"
                                    name='no_of_seats'
                                    onChange={formik.handleChange}
                                    value={formik.values.no_of_seats}
                                    disabled={!(formik.values.additional_seats)}
                                />
                            </div>
                        </div>

                    </div>

                </div>
                <div className='col-sm-12 text-end pt-1'>
                    <button type="submit" className='save_btN' onClick={formik.handleSubmit}>save</button>
                </div>
            </div>

            {/* profile image adjucement section */}
            <ProfileEdit
                formik={formik}
                src={previewProfileImg}
                setPreview={setPreviewProfileImg}
                modalOpen={propfileEditModal}
                setModalOpen={setPropfileEditModal}
            />
        </>
    )
}

export default CorporateInformation
