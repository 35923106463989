import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";
import moment from "moment";

export function currentListingProperty(virtualOfficeId, success) {
  let token = getToken();
  let userId = token?.userId;
  let dateobj = {
    user_id: userId,
    date: moment(new Date()).format("YYYY-MM-DD"),
    virtual_office_id: virtualOfficeId,
  };
  axios
    .post(`${backendURL}/api/property/UnExpiredListingProperty/`, dateobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      } else {
        success([]);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function RefreshCurrentListing(dataobj, success) {
  await axios
    .post(
      `${backendURL}/api/property/ReafreshAPI/`,
      dataobj
      // { headers: { 'Authorization': `Bearer ${token?.idToken}` } }
    )
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function currentListingPropertyCount(paramObj, success) {
  let token = getToken();
  axios
    .get(`${backendURL}/api/property/currentlistingcount/${paramObj}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function currentListingPropertyExpire(accountobj, success) {
  let token = getToken();
  await axios
    .put(`${backendURL}/api/property/Update_is_property_expired/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function CloseListing(accountobj, success) {
  let token = getToken();
  await axios
    .put(`${backendURL}/api/property/ispropertyopen/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        success(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function agentProfileCurrentListingProperty(paramObj, success) {
  let token = getToken();
  let userId = token?.userId;
  // console.log(token.idToken)
  axios
    .get(`${backendURL}/api/property/currentlisting/${paramObj}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        // console.log(response.data.data);
        // response.data.data.map((i)=> console.log(i.state_name))
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function closeCurrentListingProperty(accountobj, success) {
  let token = getToken();
  axios
    .put(`${backendURL}/api/property/ispropertyopen/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        console.log(response.data.data);
        success(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function deleteCurrentListingProperty(accountobj, success) {
  let token = getToken();
  await axios
    .post(`${backendURL}/api/property/deleteproperty/`, accountobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        console.log(response.data.data);
        success(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
