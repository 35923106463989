import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./PropertyImages.scss";
import ProperytCard from "../../../../PropertyDetails/componants/properyt-card/ProperytCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { backendURL, getToken } from "../../../../../../appVariable/variable";
import IamgeShow from "../property-images/IamgeShow";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertyDatail } from "../../PropertySlice";
import { removeDefaultPropertyShow } from "../../../components/mainSection/server";
import { toast } from "react-toastify";
import { updateFilterData } from "../../../../homepage/components/hero/FilterSlice";
import PopupPropertyDetails from "../../../../PropertyDetails/componants/popup-properyty-details/PopupPropertyDetails";
import SelpersonCard from "../../../../allSelPerson/components/SelpersonCard";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import BrokerTeam from "./BrokerTeam";
import { contactNowGroupMessage } from "../../../components/mainSection/server";

const PropertyImages = (props) => {
  const { propertDetails, setPropertyDetailInfo } = props;
  const mainBrokerId = propertDetails.propertDetails?.user_profile?.id;
  const teamgroupIds = [];
  for (const eachmember in propertDetails?.propertyTeamMember) {
    teamgroupIds.push(eachmember.id);
  }
  const [selectedIds, setSelectedIds] = useState([]);
  // const propertyDetailInStore = useSelector(state => state.propertyDetailInStore);
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  let token = getToken();
  let userId = token?.userId;
  const scrollRef = useRef(null);
  const TeamscrollRef = useRef(null);
  const [mainImg, setMainImg] = useState(
    propertDetails.propertDetails?.property_main_image
  );
  const [showImgPopup, setShowImgPopup] = useState(false);
  const [proppertyAllImages, setProppertyAllImages] = useState([]);
  // const [isPropertySave, setIsPropertySave] = useState(false)
  const [isPropertySave, setIsPropertySave] = useState(
    propertDetails.propertDetails?.guest_users_save_listing
  );
  const dispatch = useDispatch();
  const [contactModal, setContactModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const handleSelectAll = (e) => {
    teamgroupIds.unshift(mainBrokerId);
    propertDetails?.propertyTeamMember?.map((ele) => {
      teamgroupIds.unshift(ele.id);
    });
    e.target.checked === true
      ? setSelectedIds(teamgroupIds)
      : setSelectedIds([]);
  };
  const handleContact = (e) => {
    setContactModal(!contactModal);
    setContactModal((state) => {
      return state;
    });
    contactModal === true ? setShowModal(false) : setShowModal(true);
  };
  const handlemessageSent = (e) => {
    contactNowGroupMessage(userId, selectedIds, message, (success) => {
      handleContact(false);
      success.data.code === 200 && toast.success("Successfully sent message");
    });
  };
  const handleSaveProperty = () => {
    dispatch(
      updatePropertyDatail({
        propertyId: propertDetails?.propertDetails?.id,
        setshowPropertyPopup: false, // close property datail pop up
        setSavePropertyPopup: true, //open property  save pop up
      })
    );
  };

  const handelRemoveProperty = (e) => {
    setIsPropertySave(false);
    removeDefaultPropertyShow(propertDetails?.propertDetails?.id, (success) => {
      if (success.code === 200) {
        setPropertyDetailInfo((prevState) => ({
          ...prevState,
          propertDetails: {
            ...prevState.propertDetails,
            guest_users_save_listing: false,
          },
        }));
        toast.success(success.data);
        updadateProprtyListInStore(false);
      }
    });
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const updadateProprtyListInStore = (value) => {
    let updatedData = FilterDataInStore.Filter_responce.map((property) =>
      property.id === propertDetails?.propertDetails?.id
        ? { ...property, guest_users_save_listing: value }
        : property
    );
    dispatch(updateFilterData({ Filter_responce: updatedData }));
  };

  useEffect(() => {
    setMainImg(propertDetails.propertDetails?.property_main_image);
    let CombineAllImg = () => {
      const mainImageUrl = `${backendURL}${propertDetails.propertDetails?.property_main_image}`; //  main image URL
      // console.log("proppertyAllImages", mainImageUrl)
      const subImageUrls = propertDetails?.propertyImg?.map(
        (subImage) => `${backendURL}${subImage.property_image}`
      ); //  the sub-image URL
      // console.log("proppertyAllImages", subImageUrls)
      const allImageUrls = [mainImageUrl, ...subImageUrls]; // combine main image URL & the sub-image URL
      // console.log("proppertyAllImages", allImageUrls)
      setProppertyAllImages(allImageUrls);
    };
    CombineAllImg();
    propertDetails.propertDetails?.guest_users_save_listing
      ? setIsPropertySave(true)
      : setIsPropertySave(false);
  }, [props]);

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === "left") {
      current.scrollLeft -= 150;
    } else {
      current.scrollLeft += 150;
    }
  };

  const Teamscroll = (direction) => {
    const { current } = TeamscrollRef;
    if (direction === "left") {
      current.scrollLeft -= 150;
    } else {
      current.scrollLeft += 150;
    }
  };

  return (
    <>
      <Row className="property-top m-0">
        <Col xs={6}>
          <div className="property-Img h-100">
            <div
              className="property-main-img h-100 skeleton pointer"
              onClick={() => {
                setShowImgPopup(true);
              }}
            >
              <img
                alt=""
                srcSet=""
                className="w-100 h-100"
                src={`${backendURL}${mainImg}`}
              />
            </div>
          </div>
        </Col>

        <Col xs={6}>
          <div className="prop-text-info ">
            <p className="property-name">
              {propertDetails?.propertDetails?.property_title} |
              {propertDetails?.propertDetails?.is_property_exclusive && (
                <span>
                  {propertDetails?.propertDetails?.property_address_1}
                </span>
              )}
            </p>

            {propertDetails.propertDetails?.property_listing_type
              ?.property_listing_name === "Commercial" ? (
              <div className="other-info mt-3">
                <Row>
                  <Col xs={6} className=" d-flex flex-column gap-3">
                    <div className="property-price">
                      <p className="price">
                        $
                        {
                          propertDetails?.propertDetails
                            ?.property_listing_amount
                        }
                        /Month
                      </p>
                      {/* <p className="label">
                        <span>Get</span>
                      </p> */}
                    </div>
                    <div className="property-price">
                      <p className="label">Square Feet</p>
                      <p className="price">
                        {propertDetails?.propertDetails?.Square_sqft} SF
                      </p>
                    </div>
                  </Col>
                  <Col xs={6} className=" d-flex justify-content-between">
                    <div className=" d-flex flex-column gap-3">
                      <div className="property-price">
                        <p className="label">Bedrooms</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails
                            ?.property_main_category?.Bedrooms
                            ? propertDetails?.propertDetails
                                ?.property_main_category?.Bedrooms
                            : "--"}
                        </p>
                      </div>
                      <div className="property-price">
                        <p className="label">Terms</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails
                            ?.property_main_category?.property_terms
                            ? propertDetails?.propertDetails
                                ?.property_main_category?.property_terms
                            : "--"}
                        </p>
                      </div>
                    </div>
                    <div className=" d-flex flex-column gap-3">
                      <div className="property-price">
                        <p className="label">Bathrooms</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails
                            ?.property_main_category?.Bedrooms
                            ? propertDetails?.propertDetails
                                ?.property_main_category?.Bedrooms
                            : "--"}
                        </p>
                      </div>
                      <div className="property-price">
                        <p className="label text-center">Fee</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails?.fees
                            ? propertDetails?.propertDetails?.fees
                            : "No Fee"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="other-info mt-3">
                <Row className="">
                  <Col xs={4}>
                    <div className="d-flex flex-column gap-3 justify-content-between">
                      <div
                        className="property-price"
                        style={{ marginTop: "7px" }}
                      >
                        <p className="price">
                          $
                          {
                            propertDetails?.propertDetails
                              ?.property_listing_amount
                          }
                          /Month
                        </p>
                        {/* <p className="label">Est ${propertDetails?.propertDetails?.property_listing_amount}/Month</p> */}
                      </div>
                      <div
                        className="property-price"
                        style={{ marginTop: "10px" }}
                      >
                        <p className="label">Square Feet</p>
                        <p className="price">
                          {propertDetails?.propertDetails?.Square_sqft} SF
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={8} className=" d-flex justify-content-between">
                    <div className=" d-flex flex-column gap-3">
                      <div className="property-price">
                        <p className="label">Bathrooms</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails?.Bathrooms
                            ? propertDetails?.propertDetails?.Bathrooms
                            : "0"}
                        </p>
                      </div>
                      <div className="property-price">
                        <p className="label text-center">Terms</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails?.property_terms
                            ? propertDetails?.propertDetails?.property_terms
                            : "No Terms"}
                        </p>
                      </div>
                      {/* <div className="property-price">
                        <p className="label">Offer</p>
                        <p className="price">
                          {propertDetails?.propertDetails?.property_offer
                            ? propertDetails?.propertDetails?.property_offer
                            : "No Offer"}
                        </p>
                      </div> */}
                    </div>

                    <div className=" d-flex flex-column gap-3">
                      <div className="property-price">
                        <p className="label">Bedrooms</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails?.Bedrooms
                            ? propertDetails?.propertDetails?.Bedrooms
                            : "0"}
                        </p>
                      </div>
                      <div className="property-price">
                        <p className="label text-center">Fee</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails?.fees
                            ? propertDetails?.propertDetails?.fees
                            : "No Fee"}
                        </p>
                      </div>
                      {/* <div className="property-price">
                        <p className="label text-center">Terms</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails?.property_terms
                            ? propertDetails?.propertDetails?.property_terms
                            : "No Terms"}
                        </p>
                      </div> */}
                    </div>

                    <div className=" d-flex flex-column gap-3">
                      {/* <div className="property-price">
                                                <p className="label"> </p>
                                                <p className="price text-center">
                                                    {propertDetails?.propertDetails?.Bedrooms ?
                                                        propertDetails?.propertDetails?.Bedrooms : "0"}
                                                </p>
                                            </div> */}
                      {/* <div className="property-price">
                        <p className="label text-center">Fee</p>
                        <p className="price text-center">
                          {propertDetails?.propertDetails?.fees
                            ? propertDetails?.propertDetails?.fees
                            : "No Fee"}
                        </p>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            <div className=" position-relative property-Img TeamscrollRef">
              <div
                style={{ left: "-15px" }}
                className="arrow left-arrow pointer center position-absolute "
                onClick={() => Teamscroll("left")}
              >
                {" "}
                <ChevronLeftIcon />{" "}
              </div>
              <div
                className="team-member mt-2 d-flex gap-2 hide-scrollbar pb-3"
                ref={TeamscrollRef}
              >
                <ProperytCard
                  MemberDetail={propertDetails.propertDetails?.user_profile}
                />
                {propertDetails?.propertyTeamMember?.map((MemberDetail, i) => (
                  <ProperytCard
                    key={`propertyMember${i}`}
                    MemberDetail={MemberDetail}
                  />
                ))}
              </div>
              <div
                style={{ right: "-5px" }}
                className="arrow right-arrow pointer center position-absolute"
                onClick={() => Teamscroll("right")}
              >
                {" "}
                <ChevronRightIcon />{" "}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="property-top mt-1 m-0 position-relative">
        <Col xs={6}>
          <div className=" h-100 d-flex flex-column gap-3 justify-content-center">
            {propertDetails?.propertyImg?.length > 0 && (
              <div className="property-Img position-relative">
                <div
                  className="property-sub-img d-flex gap-4 position-relative hide-scrollbar "
                  ref={scrollRef}
                >
                  {propertDetails?.propertyImg?.map((SubImg, i) => (
                    <div
                      className="sub-img pointer skeleton"
                      key={`subimg${i}`}
                      onClick={() => setMainImg(SubImg?.property_image)}
                    >
                      <img
                        srcSet=""
                        className=" w-100 h-100"
                        alt=""
                        src={`${backendURL}${SubImg?.property_image}`}
                      />
                    </div>
                  ))}
                </div>

                <div
                  style={{ left: "-10px" }}
                  className="arrow left-arrow pointer center position-absolute "
                  onClick={() => scroll("left")}
                >
                  <ChevronLeftIcon />
                </div>

                <div
                  style={{ right: "-10px" }}
                  className="arrow right-arrow pointer center position-absolute"
                  onClick={() => scroll("right")}
                >
                  <ChevronRightIcon />
                </div>
              </div>
            )}

            {userId ? (
              isPropertySave ? (
                //  if user login and property is save show remove listing option
                <p
                  className="save pointer"
                  onClick={(e) => {
                    handelRemoveProperty(e);
                  }}
                >
                  <FavoriteIcon style={{ color: "red" }} /> Remove Listing
                </p>
              ) : (
                //  if user login and property is not  save show save listing option
                <p
                  className="save pointer"
                  onClick={(e) => handleSaveProperty(e)}
                >
                  <FavoriteBorderIcon /> Save Listing
                </p>
              )
            ) : (
              //  if user not login & click on save show login pop up
              <p
                className="save pointer"
                onClick={(e) => {
                  alert("Hello! to save this property please login!!");
                }}
              >
                <FavoriteBorderIcon /> Save Listing
              </p>
            )}
          </div>
        </Col>

        <Col xs={6} className=" d-flex flex-column gap-3">
          {/* {propertDetails?.propertyTeamMember?.length > 0 && */}
          <div>
            <button
              className=" w-100 blue__btn"
              onClick={(e) => handleContact(e)}
            >
              Contact Now
            </button>{" "}
          </div>
          {/* } */}
          {propertDetails.propertDetails?.property_listing_type
            ?.property_listing_name === "Commercial" ? (
            <div className=" text-end ">
              <button
                className="blue__btn"
                // onClick={() => {
                //   window?.open(
                //     `${backendURL}${propertDetails.propertDetails?.property_main_floor_plan}`,
                //     "_blank"
                //   );
                // }}
                style={{ marginBottom: "10px" }}
              >
                OM/Fl Plan
              </button>
            </div>
          ) : (
            <div className=" text-end ">
              <button
                className="blue__btn"
                // onClick={() => {
                //   window?.open(
                //     `${backendURL}${propertDetails.propertDetails?.property_main_floor_plan}`,
                //     "_blank"
                //   );
                // }}
                style={{ marginBottom: "10px" }}
              >
                Floor Plan
              </button>
            </div>
          )}
        </Col>
      </Row>
      {contactModal === true ? (
        <Modal
          className=""
          //   show={modalShow}
          //   onHide={() => setModalShow(false)}
          show={showModal}
          center
          size="xl"
          ria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClose}
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 contact_modal_header"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title">
                <p>Contact Now </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="contact_body">
            <div
              className="d-flex flex-wrap gap-4 scrollS"
              style={{ overflowX: "scroll" }}
            >
              {
                <BrokerTeam
                  key={`BrokerTeam`}
                  MemberDetail={propertDetails.propertDetails?.user_profile}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              }
              {propertDetails?.propertyTeamMember?.map((MemberDetail, i) => (
                <BrokerTeam
                  key={`BrokerTeam${i}`}
                  MemberDetail={MemberDetail}
                  MainBroker={propertDetails.propertDetails?.user_profile}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              ))}
              {/* <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard /> */}
            </div>

            <div className="select_chakebox">
              <div className="form-check center justify-content-start ps-0">
                <input
                  className=""
                  type="checkbox"
                  value=""
                  id="flexCheck"
                  onChange={(e) => {
                    handleSelectAll(e);
                  }}
                />
                <label className="form-check-label ps-2" htmlFor="flexCheck">
                  Select All
                </label>
              </div>
            </div>

            <div className="message_input">
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Message (Eg: I would like to see this place) "
                  onChange={(e) => {
                    handleMessageChange(e);
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <div className="border-0 pt-0 contact_footER">
            <div className="d-flex  justify-content-between">
              <div>
                <Button
                  className="Submit_btn blue__btn"
                  onClick={(e) => {
                    handlemessageSent(e);
                  }}
                >
                  {" "}
                  Submit
                </Button>
              </div>
              <div>
                <button
                  //   onClick={() => setModalShow(false)}
                  className="cancel_btn gray__btn"
                  onClick={(e) => setContactModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          className=""
          //   show={modalShow}
          //   onHide={() => setModalShow(false)}
          show={false}
          center
          size="xl"
          ria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 contact_modal_header"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title">
                <p>Contact Now </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="contact_body">
            <div className="d-flex flex-wrap gap-4">
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
              <SelpersonCard />
            </div>

            <div className="select_chakebox">
              <div className="form-check center justify-content-start ps-0">
                <input className="" type="checkbox" value="" id="flexCheck" />
                <label className="form-check-label ps-2" htmlFor="flexCheck">
                  Select All
                </label>
              </div>
            </div>

            <div className="message_input">
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Message (Eg: I would like to see this place) "
                />
              </div>
            </div>
          </Modal.Body>
          <div className="border-0 pt-0 contact_footER">
            <div className="d-flex  justify-content-between">
              <div>
                <Button className="Submit_btn blue__btn"> Submit</Button>
              </div>
              <div>
                <button
                  //   onClick={() => setModalShow(false)}
                  className="cancel_btn gray__btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* property images full screen popup */}
      {showImgPopup && (
        <IamgeShow
          setShowImgPopup={setShowImgPopup}
          proppertyAllImages={proppertyAllImages}
        />
      )}
    </>
  );
};

export default PropertyImages;
