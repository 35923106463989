import React from 'react'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { useState } from 'react';
import "./customRange.scss";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { getToken } from '../../../../../../../appVariable/variable';
import { CustumStarWatch } from '../../../../server';

const CustomRange = () => {
    let token = getToken()
    let userId = token?.userId;
    const [modalShow, setModalShow] = useState(false);
    const [custumDatePayload, setCustumDatePayload] = useState(null)
    const [custumWatch, setCustumWatch] = useState(null)
    const [dateSelected, setDateSelected] = useState({})
    const [state, setState] = useState([{ startDate: new Date(), endDate: addDays(new Date(), 7), key: 'selection', }]);

    const handleDateRangeChange = (dateRange) => {
        const { selection } = dateRange;
        const rawStartdate = moment(selection.startDate);
        const Startdate = rawStartdate.format('YYYY-MM-DD');
        const rawEndDate = moment(selection.endDate);
        const endDate = rawEndDate.format('YYYY-MM-DD');
        let watchobj = { "user_id": userId, "Start_date": Startdate, "End_date": endDate, }
        setDateSelected(watchobj)
        setCustumDatePayload(watchobj)
        setState([selection])
    };

    let SubmitCustumRange = () => {
        if (custumDatePayload) {
            CustumStarWatch(custumDatePayload, (success) => {
                if (success.data.code === 200 && success.data.status === "success") {
                    setCustumWatch(success.data.data); setModalShow(false)
                }
                if (success.data.code === 400 && success.data.status === "failed") { setModalShow(false) }
            });
        } else {
            console.log("error")
        }
    }



    return (
        <>
            <div className='main_customeRange_section position-relative'>
                {/* selected date range */}
                <div className='position_absulute end-0 '>
                    <p className='d-inline me-4'>{dateSelected?.Start_date}</p>
                    <p className='d-inline'>{dateSelected?.End_date}</p>
                </div>

                <div className='text-end mt-4'>
                    <button onClick={() => setModalShow(true)} className="SelectDate blue__btn ">Select Date</button>
                </div>

                {custumWatch ?
                    custumWatch === {} ?
                        <div >
                            <div className='daily_tab_section scrol_width w-100'>
                                <div className='row_heading'>
                                    <div className="col_width"><p>Start Date</p></div>
                                    <div className="col_width"><p>End Date</p></div>
                                    <div className="col_width"><p>Total Number</p></div>
                                </div>
                                <div className='scrolls_stopwatch'>
                                    <div className='WEElks_outer_border '>
                                        {custumWatch?.map((day, i) =>
                                            <div className='row_headings pad_top_bottom' key={`custum${i}`}>
                                                <div className="col_width"> <p>{custumDatePayload.Start_date}</p></div>
                                                <div className="col_width"> <p>{custumDatePayload.End_date} </p> </div>
                                                <div className="col_width"> <p>{day.count}</p> </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <p className='data-not-found'>No Data Found</p>
                    : <p className='data-not-found'>Select Date Range</p>
                }
            </div>

            <div >
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
                    centered className='costome_range_modal'
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> <p className='costome_text'>Costome Range</p></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-0'>
                        <div className='modalBodycalanDER_section'>
                            <DateRangePicker
                                onChange={handleDateRangeChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={state}
                                direction="horizontal"
                                preventSnapRefocus={true}
                                calendarFocus="backwards"
                                rangeColors={["rgba(49, 94, 251, 0.2"]}

                            />

                            <div className='text-end mt-3'>
                                <button className='save__btn blue__btn text-capitalize' onClick={SubmitCustumRange}>Save</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default CustomRange
