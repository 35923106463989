import ChatSection from "./components/ChatSection"
import Person from "./components/Person"
import "./message.scss"
let persons = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
const Message = () => {
  return (
    <>
      <div className="message-box box-shodow d-flex flex-grow-1">
        <div className="chat-list d-flex flex-column">
          {persons.map((per, i) => <Person key={`chatperson${i}`} />)}
        </div>
        <ChatSection />
      </div>
    </>
  )
}

export default Message
