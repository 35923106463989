import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable"

export function getsubcriptionPlan(success) {
  let token = getToken()
  let userType= token.user_info.usertypeobj
  axios.get(`${backendURL}/api/master/subscrptionplan/?user_type=${userType}`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
    .then((resp) => {
      if (resp.data.code == 200 && resp.data.status == "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => { console.log(err); });
}

export function getSubscriptionDetails(success) {
  let token = getToken()
  let userType= token.user_info.usertypeobj
  axios.get(`${backendURL}/api/master/service/?user_type=${userType}`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
    .then((resp) => {
      if (resp.data.code == 200 && resp.data.status == "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => { console.log(err); });
}

export function getServicesDetails(serviceId, success) {
  let token = getToken()
  let tempObj = {}
  tempObj["service_id"] = serviceId
  axios.post(`${backendURL}/api/master/subscriptionservices/`, tempObj,
    { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
    .then((resp) => {
      if (resp.data.code == 200 && resp.data.status == "success") {
        success(resp.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export async function selectedplan(plandetail, success) {
  let token = getToken()
  // console.log("token",token)
  // let tempObj = {}
  // tempObj["service_id"] = serviceId
  await axios.post(`${backendURL}/api/user/agentapprovedsubscriptionplanview/`, plandetail, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((resp) => {
      console.log("resp", resp.data);
      if (resp.data.code == 200 && resp.data.status == "success") {
        success(resp.data.data);
      }
      else {
        success(resp.data.data)
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
