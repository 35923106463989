import React from "react";
import "./Reviewcardshorizontal.scss";
import gentleman from "../images/Gentleman.jpg";
import { backendURL } from "../../../../appVariable/variable";
import { Rating } from "@mui/material";
export default function Reviewcardshorizontal(props) {
  return (
    <>
      <div>
        <div className="main_review_left_card">
          <div className="right_cars card_border d-flex">
            <div className="pro-img">
              <img
                className="reviewcard_horizontal_img_data h-100"
                src={props.userRating?.AgentUser?.profile_image ?
                  `${backendURL}${props.userRating?.AgentUser?.profile_image}` :
                  gentleman}
                alt=""
              />
            </div>
            <div className="right_description">
              <h6 className=" text-capitalize">{`${props.userRating?.AgentUser.first_name} ${props.userRating?.AgentUser.last_name}`}</h6>
              <div className="d-flex pt-2">
                <div className="position-relative tool_trip ">
                  {/* <StarBorderIcon className="me-1" /> */}
                  <Rating name="read-only" className='rating' value={props.userRating?.Recommend} readOnly />

                  <div className="suspend-tooltip position-absolute">
                    <div className="tip"></div>
                    <div className="tooltrip_pading">
                      <div className="d-flex justify-content-between align-items-center pb-1">
                        <div className="like_text">Knowledge</div>
                        <div className="star_orange">
                          <Rating name="read-only" className='rating' value={props.userRating?.Recommend} readOnly />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <span className="date ps-3">{props.userRating?.created_date}</span>
                </div>
              </div>
              <p className="pt-1">{props.userRating?.experience}</p>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
