import React, { useEffect } from "react";
// import Mapview from '../PropertyDetails/componants/map-view/Mapview';
// import FilterForm from './components/FilterForm';
// import filter from "./images/filter.svg";
import FilterResultMap from "./components/FilterResultMap";
import ResidentialMapFilter from "./components/ResidentialMapFilter";
import search from "./images/search.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { postFilteredData } from "../guestVirtualOffice/server";
import Mapview from "../PropertyDetails/componants/map-view/Mapview";
import CommertialMapFilter from "./components/CommertialMapFilter";
import { getToken } from "../../../appVariable/variable";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };

const MapviewFilter = (props) => {
  const [listing, setlisting] = useState([]);
  const [filteredListing, setFilteredListing] = useState([]);
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const handlefilter = (listingGroup) => {
    try {
      postFilteredData(listingGroup, (success) => {
        setFilteredListing(success.data.data.property);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const credential = getToken();
  const currentUserType = credential?.user_info?.type_of_listing;
  useEffect(() => {
    console.log(filteredListing);
    setlisting(FilterDataInStore.Filter_responce);
    setFilteredListing(FilterDataInStore.Filter_responce);
  }, []);

  return (
    <>
      <div className=" d-flex flex-grow-1 flex-column gap-4">
        <div className="filter-sec  d-flex justify-content-between">
          {/* chech box */}
          <div className="d-flex flex-row gap-3" style={{ width: "450px" }}>
            <div className="buy-rent gap-3 d-flex align-items-center">
              <div className="d-flex">
                <input
                  type="checkbox"
                  name="PropType"
                  className="Prop"
                  id="Buy"
                  value="Buy"
                />
                <label htmlFor="Buy" className="pointer">
                  Buy
                </label>
              </div>
              <div className="d-flex">
                <input
                  type="checkbox"
                  name="PropType"
                  className="Prop"
                  id="Rent"
                  value="Rent"
                />
                <label htmlFor="Rent" className="pointer">
                  Rent
                </label>
              </div>
            </div>

            {/* Search box */}
            <div className="search-box d-flex flex-grow-1">
              <div className="search d-flex align-items-center justify-content-between flex-grow-1">
                <input
                  type="text"
                  className="trans-input"
                  placeholder="Search Town or City"
                  name=""
                  id=""
                />
                <div className="search-icon pointer">
                  <img src={search} alt="" />
                </div>
              </div>
              {/* <div className="filter-icon pointer d-flex align-items-center justify-content-center">
                            <img src={filter} alt="" />
                        </div> */}
            </div>
          </div>

          {/* quik-view map-view */}
          <div className="quik-map center">
            <p>Quick View</p>
            <div className=" pt-0 gap-1">
              <PinkSwitch
                {...label}
                onChange={(e) => {
                  props.setViewsetting(e.target.checked);
                }}
                checked={props.viewsetting}
                style={{ color: "#315EFB" }}
              />
            </div>

            <p>Map View</p>
          </div>
        </div>

        <div className="h-100 w-100 d-flex mt-2">
          {currentUserType === 0 ? (
            <ResidentialMapFilter
              groupByFilter={handlefilter}
              currentUserType={currentUserType}
            />
          ) : (
            <CommertialMapFilter
              groupByFilter={handlefilter}
              currentUserType={currentUserType}
            />
          )}
          <div className=" flex-grow-1" style={{ marginRight: "5px" }}>
            {filteredListing.length > 0 ? (
              <Mapview onclick={handlefilter} allProperty={filteredListing} />
            ) : (
              <Mapview />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MapviewFilter;
