import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";
let token = getToken()

export function getFullAddress(address, success) {
  let tempObj = {}
  tempObj['location'] = address;
  let token = getToken();
  axios
    .post(`${backendURL}/api/master/AreaSearch/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getuserSubscription(success) {
  let token = getToken()
  let userId = token?.userId;
  let tempObj = { "user_id": userId }
  await axios
    .post(`${backendURL}/api/user/agentdashboardwithlistingtype/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getRadioButtons(radioObj, success) {
  let token = getToken()
  await axios.post(`${backendURL}/api/property/propertymaicategorytypehomeview/`, radioObj, {
      headers: { Authorization: `Bearer ${token?.idToken}`,},
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getTeammerberProfile(success) {
  let token = getToken()
  // let userId = token?.userId;
  let agentSlug = token?.profile.slug;
  axios.get(`${backendURL}/api/property/teammerberprofile/${agentSlug}`, {
      headers: { Authorization: `Bearer ${token?.idToken}`,},
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
      else {
      }
    })
    .catch((error) => {
    });
}
export function getNblSpecial(city_id, success) {
  axios.get(`${backendURL}/api/property/GetNeibourhoodBasedOnLocation/?cityid=${city_id}`, {
    // headers: { Authorization: `Bearer ${token?.idToken}`, },
  })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}