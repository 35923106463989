import React from 'react'
import profilepic from "../image/profilepic.jpg"

const Person = () => {
    return (
        <>
            <div className="chat-person  d-flex align-items-center position-relative pointer">
                <div className="chat-profile"><img src={profilepic} alt="" /></div>
                <div className="prrson-name">
                    <p>Tom Holden</p>
                    <p className='chat-summery mt-2'>Forest Hills | 62-59 108th st </p>
                    </div>
                <div className="message-time position-absolute"><p>15.25 PM</p></div>
            </div>
        </>
    )
}

export default Person
