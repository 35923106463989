import React, { useEffect, useState } from "react";
import {
  backendURL,
  getToken,
} from "../../../../../../../appVariable/variable";
import Loader from "../../../../../Loader/Loader";
import { saveloadCityAreaView } from "../../../../server";
import "./Manhatten.scss";
import { toast } from "react-toastify";
import CustumeFileInput from "../../../../../agent-dash-sales/components/custumeFileInput/CustumeFileInput";
const Manhatten = (getCityArea) => {
  // console.log(getCityArea.getNeighburHood.area_id)
  const [saveCiryAgent, setSaveCiryAgent] = useState(
    getCityArea.getNeighburHood.area_id
      ? getCityArea.getNeighburHood.area_id
      : []
  );
  // console.log(getCityArea.ChangeTab)
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [checked, setChecked] = useState(
    getCityArea.getNeighburHood.area_id
      ? getCityArea.getNeighburHood.area_id
      : []
  );
  const [loading, setLoading] = useState(false);
  const [valError, setValError] = useState("");
  const [valErrorImg, setValErrorImg] = useState("");
  const [fileResponses, setFileResponses] = useState([]);
  const [allCitiesArea, setAllCitiesArea] = useState([]);

  useEffect(() => {
    const cityData = getCityArea?.getCityArea;
    const selectedCityIds = getCityArea.getNeighburHood.area_id
      ? getCityArea.getNeighburHood.area_id
      : [];

    // Find the selected city in the cityData array
    const selectedCity = cityData.filter((city) =>
      selectedCityIds.includes(city.id)
    );

    // Remove the selected city from the cityData array
    const remainingCities = cityData.filter(
      (city) => !selectedCityIds.includes(city.id)
    );

    // // Sort the remaining cities alphabetically by area_name
    // remainingCities.sort((a, b) => a.area_name.localeCompare(b.area_name));

    // Combine the selected city and the remaining cities
    const allCities = selectedCity.concat(remainingCities);
    setAllCitiesArea(allCities);
  }, []);

  // multiple image upload logic
  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(image));
    setImageURLs(newImageUrls);
  }, [images]);

  // onChaange handler of image upload
  const onImageChange = async (e) => {
    setImages([...e.target.files]);
    const files = e.target.files;
    const fileResponsesArray = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      fileResponsesArray.push(file);
    }
    setFileResponses(fileResponsesArray);
  };

  // multiple checkbox value add and remove onchange handler
  const handleChecked = (e, index) => {
    let prev = checked;
    let itemIndex = prev.indexOf(index);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(index);
    }
    console.log("checked after", prev);
    setChecked([...prev]);
  };

  // api call on save button
  const formSave = () => {
    let formData = new FormData();
    let userDetails = getToken();
    formData.append("user", userDetails.userId);
    formData.append("area_id", JSON.stringify(checked));
    formData.append("doc1", fileResponses?.[0]);
    formData.append("doc2", fileResponses?.[1]);
    formData.append("doc3", fileResponses?.[2]);
    if (checked.length === 0) {
      setValError("Please Check Atlest One Checkbox ");
      return;
    }
    if (checked.length > 6) {
      setValError("You can select up to six towns only");
      return;
    }
    if (imageURLS.length === 3) {
      setValErrorImg("");
      setLoading(!loading);
      saveloadCityAreaView(formData, (success) => {
        setLoading(loading);
        toast.success(success, "success");
        getCityArea.setUpdateCity((prev) => !prev);
        // getCityArea?.ChangeTab?.("second")
      });
    } else {
      setValErrorImg("Please upload 3 images");
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="Manhatten_sectioNS d-flex flex-column gap-4">
        <div className="d-flex justify-content-between  scrollS flex-grow-1">
          <div className="d-flex flex-wrap gap-3 justify-content-between flex-grow-1 flex-column">
            {getCityArea &&
              allCitiesArea?.map((onecheck, i) => (
                <div className="form-check" key={`manhatte${i}`}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={cityId}
                    id={`${onecheck.area_name}${i}`}
                    onChange={(e) => handleChecked(e, onecheck.id)}
                    defaultChecked={saveCiryAgent.includes(onecheck.id)}
                  />
                  <label
                    className="form-check-label ms-2 pointer"
                    htmlFor={`${onecheck.area_name}${i}`}
                  >
                    {onecheck.area_name}
                  </label>
                </div>
              ))}
            {/* {getCityArea && getCityArea?.getCityArea?.map((onecheck, i) =>
                            <div className="form-check" key={`manhatte${i}`}>
                                <input className="form-check-input" type="checkbox" value={cityId} id={`${onecheck.area_name}${i}`} onChange={(e) => handleChecked(e, onecheck.id)}
                                    defaultChecked={saveCiryAgent.includes(onecheck.id)}
                                />
                                <label className="form-check-label ms-2 pointer" htmlFor={`${onecheck.area_name}${i}`}>{onecheck.area_name}</label>
                            </div>
                        )} */}
          </div>
        </div>
        {valError ? (
          <div className="validation-text log-validation">{valError}</div>
        ) : null}
        <div>
          <p className="upload_minimun_text text-capitalize">
            Upload 3 property file to get approval
          </p>
          {valErrorImg ? (
            <div className="validation-text log-validation">{valErrorImg}</div>
          ) : null}
          <div className="mt-3 fileupload  w-50">
            <CustumeFileInput
              id="NBSPfile"
              multiple={true}
              accept="image/*"
              onChange={(e) => {
                onImageChange(e);
              }}
            />
          </div>
          <div className="link-conatiner d-flex flex-column">
            {getCityArea.getNeighburHood.doc1 && (
              <a
                href={`${backendURL}${getCityArea.getNeighburHood.doc1}`}
                target="_blank"
                rel="noopener noreferrer"
                className="link_text"
              >{`${backendURL}${getCityArea.getNeighburHood.doc1}`}</a>
            )}
            {getCityArea.getNeighburHood.doc2 && (
              <a
                href={`${backendURL}${getCityArea.getNeighburHood.doc2}`}
                target="_blank"
                rel="noopener noreferrer"
                className="link_text"
              >{`${backendURL}${getCityArea.getNeighburHood.doc2}`}</a>
            )}
            {getCityArea.getNeighburHood.doc3 && (
              <a
                href={`${backendURL}${getCityArea.getNeighburHood.doc3}`}
                target="_blank"
                rel="noopener noreferrer"
                className="link_text"
              >{`${backendURL}${getCityArea.getNeighburHood.doc3}`}</a>
            )}
          </div>
          <div className="text-end">
            <button className="save___btns" type="submit" onClick={formSave}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manhatten;
