import React from 'react'
import profile from "../image/profile.jpg"

const MsgIncoming = (props) => {
    return (
        <>
            <div className="incoming-msg d-flex ">
                <div className="chat-profile"><img src={profile} alt="" srcSet="" /></div>
                <div className="msg-text position-relative">
                    <p >{props.message}</p>
                    <div className="msg-time position-absolute start-0"><p>10.25 PM</p></div>
                </div>
            </div>
        </>
    )
}

export default MsgIncoming
