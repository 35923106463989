import axios from "axios"
import { backendURL, getToken } from "../../../../appVariable/variable"


export async function getAccounttype(success, error) {
    let token = getToken()
    let userId = token?.userId;
    if(userId !== undefined){
        await axios.get(`${backendURL}/api/user/account-setting/${userId}`, {
            headers: { 'Authorization': `Bearer ${token?.idToken}` }
        })
            .then((response) => {
                if (response.data.code === 200 && response.data.status === "success") {
                    success(response.data.data);
                }
            })
            .catch((err) => { error(err) });
    } else {
        error("can't find userId to get account type")
    }
}



export function accountSetting(accountobj, success) {
    let token = getToken()
    axios.put(`${backendURL}/api/user/account-setting/`, accountobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                console.log(response.data.data); success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

