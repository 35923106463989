import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./GuestVirtualPopup.scss"

function GuestVirtualPopup() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'><h6>Do You What To Exit For This Virtual Office</h6></Modal.Body>
        <Modal.Footer className="justify-center">
          <div className="d-flex" style={{justifyContent:"space-evenly", width:"100%"}}>
            <div>
              <Button className='rd_btn red__btn'  onClick={handleClose}>Yes</Button>       
            </div>
            <div>
              <Button className='grn_btn green__btn' onClick={handleClose}>No</Button>
            </div>
          </div>        
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GuestVirtualPopup;


























