import axios from "axios";
import { backendURL, getToken } from "../../../../../appVariable/variable";

export function ThirtyMin(tempObj, success) {
    let token = getToken();
    axios.post(`${backendURL}/api/user/min_30_view/`, tempObj, { headers: { Authorization: `Bearer ${token?.idToken}`, }, })
        .then((response) => {
            success(response)
            // if (response.data.code == 200 && response.data.status == "success") {
            //     console.log(response.data.data);
            //     success(response.data.data);
            // }
        })
        .catch((error) => {
            console.log(error);
        });
}

export function DeactivateTime(success) {
    let token = getToken();
    axios.put(`${backendURL}/api/user/ActivateDeactivate30Min/${token?.profile.slug}`, { headers: { Authorization: `Bearer ${token?.idToken}`, }, })
        .then((response) => { success(response.data) })
        .catch((error) => { console.log(error); });
}


export function getMinutedata(success) {
    let token = getToken()
    let userSlug = token?.profile.slug;
    axios.get(`${backendURL}/api/user/min_30_view/${userSlug}`, { headers: { 'Authorization': `Bearer ${token?.idToken}` } })
        .then((response) => {
            if (response.data.code == 200 && response.data.status == "success") {
                success(response.data.data);
            } else {
                success(null)
            }
        })
        .catch((error) => {
            console.log(error);
        });
}
