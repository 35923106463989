import axios from "axios";
import { backendURL, getToken } from "../../../../../appVariable/variable";

 export function submitSocialMediaData1(tempObj,success) {
    console.log("yes")
    let token=getToken()
    axios
      .put(`${backendURL}/api/user/socialmedialink/`,tempObj,{
        headers:{
            'Authorization':`Bearer ${token?.idToken}`
        }
      })
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
          console.log(response.data.data);
          success(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  export function getAgentDetails(success) {
    let token=getToken()
    let userslug = token?.profile.slug;
    axios.get(`${backendURL}/api/user/agentuserprofile/${Boolean(userslug) ? userslug : `?id=${token?.userId}`}`,{
        headers:{
            'Authorization':`Bearer ${token?.idToken}`
        }
      })
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
        //   console.log(response.data.data);
          success(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }