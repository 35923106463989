import React, { useEffect, useState } from 'react'
import "./NeighborhoodSpecialist.scss";
import { Tab, Tabs } from 'react-bootstrap';
import { getNeighburhoodSave, getloadCityAreaView } from '../../server';
import Manhatten from './componants/CityArea/Manhatten';
// import Manhatten from './componants/manhatten/Manhatten';



const NeighborhoodSpecialist = (props) => {
    const [getCity, setGetCity] = useState([]);
    const [getNeighburHood, setGetNeighburHood] = useState("")
    const [updateCity, setUpdateCity] = useState(false)

    useEffect(() => {
        setGetCity([])
        setGetNeighburHood([])
        getloadCityAreaView((success) => { setGetCity(success) })
        getNeighburhoodSave((success) => {
            setGetNeighburHood(success.data.data)
        })
    }, [updateCity]);
    // console.log("getCity", getCity)
    return (
        <>
            <div className="Neighborhood_Specialist_section w-100">
                {/* <p className='select_towns'>You can select up to six towns where you want to be known as the local real estate specialist</p> */}
                <p className="select_towns"> Choose up to 6 towns, where you have recently closed a property, and can provide 3 commission transaction reports. Approval takes upto 48 hours after submission.</p>
                {/* <p className="info_text"> Choose up to 6 towns, where you have recently closed a property, and can provide 3 commission transaction reports. Approval takes upto 48 hours after submission.</p> */}

                <Tabs defaultActiveKey={getCity[0]?.city_name} id="uncontrolled-tab-example"
                    className="mb-3 justify-content-between mt-3"
                >
                    { getCity && getCity.map((resp, i) => {
                            return (
                                <Tab key={i} eventKey={resp.city_name} title={resp.city_name}>
                                    <Manhatten getCityArea={resp.area} getNeighburHood={getNeighburHood}
                                        setUpdateCity={setUpdateCity} // ChangeTab={props.ChangeTab}                         
                                    />
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>
        </>
    )
}

export default NeighborhoodSpecialist
