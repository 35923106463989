import swal from "sweetalert";
import "../documentUpload.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { DropzoneArea } from "mui-file-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import LinearProgress from "@mui/material/LinearProgress";
import DescriptionIcon from "@mui/icons-material/Description";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getToken } from "../../../../appVariable/variable";
import { uploadPDF } from "../server";
import { async } from "q";

const DocumentUpload = () => {
  const navigate = useNavigate();
  const [docs, setDocs] = useState([]);
  const [sent, setSent] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const dropzoneText = "Drop your files here or Browse";
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [deselectedIndex, setDeselectedIndex] = useState([]);

  // useEffect(() => {
  //   if (sent === true) {
  //     setDocs()
  //   }
  // }, [docs])

  useEffect(() => {}, [docs]);

  const formik = useFormik({
    initialValues: {
      Documents: null,
    },

    onSubmit: async (values) => {
      if (!docs.length) {
        toast.warning(`You didn't select any documents.`, "warning");
        return;
      }

      let formData = new FormData();
      let userDetails = getToken();

      await Promise.all(
        docs.map(async (doc, index) => {
          const deselected = !!deselectedIndex.filter((id) => id === index)
            .length;

          if (deselected) return;

          formData.delete("files");
          formData.append("files", doc);
          await uploadPDF(
            formData,
            (progress) => {
              const docsCopy = [...docs];
              docsCopy[index].progress = progress;
              setDocs(docsCopy);
            },
            (success) => {
              if (
                success.data.code == 200 &&
                success.data.status == "success"
              ) {
                toast.success(success.data.data, "success");
              }
              if (success.data.code == 400 && success.data.status == "failed") {
                toast.warning(success.data.data, "warning");
              }
            }
          );
        })
      );

      formik.resetForm();
      setSent(true);
      navigate(-1);
    },
  });

  const handleUploadPdf = (files) => {
    files.forEach((file) => {
      file.progress = 0;
    });

    setDocs(files);
  };

  // const handleUpload = () => {
  //   const data = new FormData()
  //   data.append('file', pdfFiles)
  //   axios
  //     .post(endpoint, data, {
  //       onUploadProgress: ProgressEvent => {
  //         this.setState({
  //           loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
  //         })
  //       },
  //     })
  //     .then(res => {
  //       console.log(res.statusText)
  //     })
  // }

  const handleCancel = () => {
    navigate(-1);
  };

  const handleDeselectDoc = (docIndex) => {
    if (!deselectedIndex.filter((id) => id === docIndex).length)
      setDeselectedIndex([...deselectedIndex, docIndex]);
  };

  return (
    <div id="FileUpload">
      <div className="wrapper">
        <div className="upload_bar">
          <p className="upload_bar__title">Upload File</p>
          <DropzoneArea
            name="Documents"
            id="uploadDocument"
            filesLimit={100}
            acceptedFiles={["application/pdf"]}
            dropzoneText={dropzoneText}
            dropzoneClass="custom-dropzone"
            onChange={(e) => handleUploadPdf(e)}
            showAlerts={false}
          />
          {/* <div className="upload">
            <p>
              Drag files here or <span className="upload__button">Browse</span>
            </p>
          </div> */}
        </div>
        <div className="progress_bar" style={{ overflowY: "auto" }}>
          {docs?.map((doc, index) => {
            const deselected = !!deselectedIndex.filter((id) => id === index)
              .length;
            return deselected ? null : (
              <div className="uploading pointer" key={index}>
                <div style={{ marginRight: "5px" }}>
                  <DescriptionIcon
                    style={{ color: "blue", fontSize: "45px" }}
                  />
                </div>

                <div className="file">
                  <div className="file__name">
                    <p>{doc.name}</p>
                  </div>

                  {doc?.progress < 100 ? (
                    <div className="progress">
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress
                          variant="determinate"
                          style={{ marginTop: "5px" }}
                          value={doc?.progress}
                        />
                      </Box>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  {doc?.progress < 100 ? (
                    <CancelIcon
                      style={{
                        marginTop: "-25px",
                        marginLeft: "5px",
                        color: "red",
                      }}
                      onClick={() => handleDeselectDoc(index)}
                    />
                  ) : doc?.progress > 110 ? (
                    <div></div>
                  ) : (
                    <CheckCircleIcon
                      style={{
                        marginTop: "-25px",
                        marginLeft: "5px",
                        color: "green",
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <div className="uploaded__bottom">
            <Button className="cancel_btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="send_btn" onClick={formik.handleSubmit}>
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
