import DeleteIcon from '@mui/icons-material/Delete';
import addplus from "./images/add.svg"
import { IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const SlotBook = (prps) => {
    let { handleChange, uniquName, time, handleaddclick, handleremove, last, day, error } = prps
    return (
        <>
            <div className='row' >
                <div className='col-sm-5 pe-md-3'>
                    <div className="d-flex main_input">
                        <div className="input_name grow-1 text-end input_namepad">
                            Start Time
                        </div>
                        <div className="input-sec">
                            <input type="time" className="form-control border-0 pb-0"
                                name={`${uniquName}.start_time`}
                                onChange={handleChange}
                                value={time.start_time ? time.start_time : ""}
                            />
                        </div>
                    </div>
                    {error?.start_time && <div className='validation-text text-center'>{error?.start_time}</div>}

                </div>
                <div className='col-sm-5 ps-md-3'>
                    <div className="d-flex main_input">
                        <div className="input_name grow-1 text-end input_namepad">End Time</div>
                        <div className="input-sec">
                            <input type="time" className="form-control border-0 pb-0"
                                name={`${uniquName}.end_time`}
                                onChange={handleChange}
                                value={time.end_time ? time.end_time : ""}

                            />
                        </div>
                    </div>
                    {error?.end_time && <div className='validation-text text-center'>{error?.end_time}</div>}
                </div>
                <div className='col-sm-2 text-end  d-flex justify-content-evenly align-items-center '>
                    {/* {!last && <IconButton onClick={() => handleremove(time, day)}><DeleteIcon /></IconButton>}
                    {last ? <img src={addplus} alt="" className='addplus pointer'
                        onClick={() => handleaddclick(day)}
                    /> : <p></p>} */}

                    {last ?
                        <AddCircleOutlineIcon className="pointer add-btn text-success"
                            onClick={() => handleaddclick(day)}
                        />
                        :
                        <RemoveCircleOutlineIcon className="pointer remove-btn text-danger"
                            onClick={() => handleremove(time, day)}
                        />
                    }




                </div>
            </div >

        </>
    )
}

export default SlotBook