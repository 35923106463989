import React, { useEffect, useState } from "react";
import "./allSelPerson.scss";
import SelpersonCard from "./components/SelpersonCard";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SelPerson from "./components/SelPerson";
import { GetSaveAgent, SaveAgent } from "./server";
import { getToken } from "../../../appVariable/variable";
// import AddYourOwnSalesperson from './components/AddYourOwnSalesperson';
// import Sonnet from '../../components/Sonnet';

const AllSelPerson = () => {
  let token = getToken();
  const [setsearchPersons, setSetsearchPersons] = useState([]);
  const [selectForSave, SetselectForSave] = useState([]);
  const [setselectForDelete, setSetselectForDelete] = useState([]);
  const [setsaveAgents, setSetsaveAgents] = useState([]);

  let selctPerson = (e) => {
    let { checked, id, name } = e.target;
    if (name === "save") {
      setSetselectForDelete([]);
      let setcheckItems = [];
      if (checked) {
        setcheckItems = [...selectForSave, parseInt(id)];
      } else {
        setcheckItems = selectForSave.filter((v) => v !== parseInt(id));
      }
      SetselectForSave(setcheckItems);
    }
    if (name === "delete") {
      SetselectForSave([]);
      let setcheckItems = [];
      if (checked) {
        setcheckItems = [...setselectForDelete, parseInt(id)];
      } else {
        setcheckItems = setselectForDelete.filter((v) => v !== parseInt(id));
      }
      setSetselectForDelete(setcheckItems);
    }
  };

  let saveSelected = () => {
    if (selectForSave.length > 0) {
      let paramObj = { user_id: token.userId, sales_person_id: selectForSave };
      // setLoading(true)
      SaveAgent(paramObj, (success) => {});
    } else {
      alert("Please select persons to save");
    }
  };

  let saveAllPerson = () => {
    let setallCheck = [];
    for (let per of setsearchPersons) {
      setallCheck = [...setallCheck, per.id];
    }
    if (setallCheck.length) {
      let paramObj = { user_id: token.userId, sales_person_id: setallCheck };
      SaveAgent(paramObj, (success) => {
        SetselectForSave([]);
      });
    }
  };

  useEffect(() => {
    GetSaveAgent((success) => {
      setSetsaveAgents(success);
    });
  }, []);

  return (
    <>
      <div className="AllSelPerson_page">
        <div className="main_selperson_tab">
          <div>
            <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="home" title="All Sealesperson">
                <div className="sel_person_section  d-flex justify-content-between mb-4">
                  <SelPerson setSetsearchPersons={setSetsearchPersons} />
                  <div className=" d-flex gap-3">
                    <button
                      className="blue__btn all_savebtn"
                      onClick={saveSelected}
                    >
                      Save Selected
                    </button>
                    <button
                      className="blue__btn all_savebtn"
                      onClick={() => saveAllPerson()}
                    >
                      Save All
                    </button>
                  </div>
                </div>

                <div
                  className="d-flex flex-wrap card_gap scrollS"
                  style={{ maxHeight: "70vh" }}
                >
                  {setsearchPersons?.map((person) => (
                    <SelpersonCard
                      key={person.slug}
                      info={person}
                      onclick={selctPerson}
                      name="save"
                    />
                  ))}
                </div>
              </Tab>

              <Tab eventKey="SavePerson" title="Saved Salesperson">
                <div className="sel_person_section  d-flex justify-content-between mb-4">
                  <div></div>
                  <div className=" d-flex gap-3">
                    <button
                      className="blue__btn all_savebtn"
                      // onClick={saveSelected}
                    >
                      Delete Selected
                    </button>
                    <button
                      className="blue__btn all_savebtn"
                      // onClick={() => saveAllPerson()}
                    >
                      Delete All
                    </button>
                  </div>
                </div>
                <div
                  className="d-flex flex-wrap card_gap scrollS"
                  style={{ maxHeight: "70vh" }}
                >
                  {/* {setsaveAgents?.map((person) =>
                                        <SelpersonCard key={person.slug} info={person} onclick={selctPerson} name='delete' />

                                    )} */}
                </div>
              </Tab>

              {/* <Tab eventKey="AddPerson" title="Add Your Own Salesperson">
                                <AddYourOwnSalesperson />
                            </Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllSelPerson;
