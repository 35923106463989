import Button from "react-bootstrap/Button";
import "./listingCard.scss";

const ListingCard = (props) => {
  return (
    <>
      <div className="listing-Card d-flex flex-column justify-content-center gap-2 align-items-center">
        <div className="card-img">
          <img src={props.data.img} alt="" />
        </div>
        <p className="card-title text-uppercase">{props.data.title}</p>
        <Button variant="outline-primary">
          <b>{props.data.data}</b> Properties
        </Button>
      </div>
    </>
  );
};

export default ListingCard;
