import axios from "axios";
import { backendURL, getToken } from "../../../../../appVariable/variable";

export function submitBrokerageDetails(tempObj, success) {
  console.log("yes")
  let token = getToken()
  axios.post(`${backendURL}/api/user/brokerageLic/`, tempObj, {
    headers: {
      'Authorization': `Bearer ${token.idToken}`
    }
  })
    .then((response) => {
      success(response);
      // if (response.data.code == 200 && response.data.status == "success") {
      //   console.log(response.data.data);
      //   success(response.data.data);
      // } else {
      //   success(response.data);
      //   // console.log(response.data);
      // }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getBrokerageDetails(success) {
  let token = getToken()
  let userId = token?.userId;
  let userSlug = token?.profile.slug;

  axios.get(`${backendURL}/api/user/brokerageLic/${userSlug}`, {
    headers: {
      Authorization: `Bearer ${token?.idToken}`,
    },
  })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        // console.log(response.data.data[0])
        success(response.data.data);
      }
      else {
        console.log(response.data.data)
      }
    })
    .catch((error) => {
      console.log(error);
    });
}