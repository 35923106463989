import React from "react";
import { Col, Row } from "react-bootstrap";
// import gestuser from "./images/gestuser.svg"
import deleteimg from "./images/delete.svg";
import { backendURL } from "../../../appVariable/variable";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarGroup } from "@mui/material";
// import { Avatar, AvatarGroup } from '@mui/material'
import { setLogInOfficeId } from "../agentVirtualOffice/AgentOfficeSlice";
import { useDispatch } from "react-redux";
const VirtualCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { info, DeleteVirtualOffice } = props;
  // console.log(info)() => navigate("/guest-virtual-office")
  const handleVirtualLogin = (e) => {
    console.log(props?.info?.id);
    dispatch(setLogInOfficeId(props?.info?.id));
    navigate(`/agent-virtual-office/customer-info`);
  };
  return (
    <>
      <div className="virtualoffice-card">
        <div className="p-2 left_carD card_border">
          <div
            className="text-end pointer"
            onClick={() => DeleteVirtualOffice(info.id)}
          >
            <img
              src={deleteimg}
              alt=""
              style={{ width: "16px" }}
              className=""
            />
          </div>

          <div className="ps-4">
            <span className="Virtual_Office pe-3"> Virtual Office Name :</span>
            <span className="leo_text">{info.virtual_office_name}</span>
          </div>
          <Row className="ps-4 mt-3 pt-1 pb-3 mb-3">
            <Col md="4">
              <div className="pb-2 mb-1">
                <span className="user_text">Guest User</span>
              </div>
              <img
                src={`${backendURL}${info.userprofile_main?.user_image.profile_image}`}
                alt=""
                style={{ width: "45px" }}
              />
            </Col>

            <Col md="8">
              <div className="pb-2 mb-1">
                <span className="user_text">Friends & Family</span>
              </div>

              <div className="d-flex gap-2">
                {/* {info.virtual_team?.map((member, i) =>
                                    member.is_status && <img src={`${backendURL}${member.user_email[0]?.profile_image}`} srcSet='' alt="" style={{ width: "45px", aspectRatio: "1/1", borderRadius: '50%' }} key={`profImg${i}`} />
                                )} */}
                <AvatarGroup total={info?.virtual_team.length} max={4}>
                  {info.virtual_team?.map(
                    (member, i) =>
                      member.is_status && (
                        <Avatar
                          alt={member.email}
                          key={`teamMember${i}`}
                          src={`${backendURL}${member?.user_image?.profile_image}`}
                          style={{ width: "45px", height: "45px" }}
                        />
                      )
                  )}
                </AvatarGroup>
              </div>
            </Col>
          </Row>
        </div>
        <div className="row">
          <div className="col-sm-12 d-grid ">
            <button
              className="btn-blockbtn blue__btn btn-block save_btnn"
              onClick={(e) => handleVirtualLogin(e)}
              // onClick={() => navigate(`/agent-virtual-office/contact/${info.slug}`)}
            >
              {" "}
              Log in
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualCard;
