import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./hero.scss";
// import samplevideo from "./image/bgvideohigh.mp4"
import samplevideo from "./image/herobg.mp4";
import filter from "./image/filetrIconsm.png";
// import filter from "./image/filter.svg";
import {
  ApplySearch,
  getCategories,
  getLocations,
  getTypeOfProperties,
  getTypesBasedOnCategory,
  getTypesBasedOnTypeLeisure,
} from "./server";
import Modal from "react-bootstrap/Modal";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import PopUpFilter from "../../../dashboard/components/filter-popup/PopUpFilter";
import {
  accountSetting,
  getAccounttype,
} from "../../../personal/typeOfAccount/server";
import { getToken } from "../../../../../appVariable/variable";
import { useDispatch, useSelector } from "react-redux";
import SaveSearchModel from "../../../dashboard/components/filter-popup/SaveSearchModel";
import { updateFilterData } from "./FilterSlice";
import { updateFilterOptions } from "./FilterOptionSlice";
import { handleAmountChange } from "../../../agent-dash-sales/CommonForm";
import Navbar from "../navbar/Navbar";
export const numberWithCommas = (x) => {
  const numericValue = x?.toString().replace(/[^0-9]/g, "");
  return numericValue?.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
};

const Hero = (props) => {
  let userlogin = getToken();
  let userId = userlogin?.userId;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const FilterOptionsInStore = useSelector(
    (state) => state.FilterOptionsInStore
  );
  const saveSearchPopupVisible = useSelector(
    (state) => state.search.saveSearchPopupVisible
  );
  const [typeOfProperties, setTypeOfProperties] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  // const [categoryListOptions, setCategoryListOptions] = useState([]);
  // const [typesBasedOnCategory, setTypesBasedOnCategory] = useState([]);
  // const [leisureType, setLeisureType] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [accounttype, setAccounttype] = useState(0);
  const [isactiveType, setIsactiveType] = useState(0);
  const [show, setShow] = useState(false);
  const [typeOfProperty, setTypeOfProperty] = useState(0);
  const [leisureTypeShow, setLeisureTypeShow] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [errors, setErrors] = useState({ category: false, type: false });
  // const searchDataSelector = useSelector((state) => state.search.searchShema);
  const handleClose = () => setShow(false);
  const locationOnChange = (e) => {
    dispatch(
      updateFilterData({
        locationText: e?.label,
        area: e?.value?.area_id,
        city: e?.value?.city_id,
        state: e?.value?.state_id,
        country: e?.value?.country_id,
      })
    );
  };

  const getType = (id) => {
    getTypesBasedOnCategory(id, (success) => {
      let newarr = success.map((item) => {
        return { label: `${item.Main_category}`, id: item.id };
      });
      // setTypesBasedOnCategory([...newarr]);
      dispatch(updateFilterOptions({ typeOptions: newarr }));
    });
  };

  const subTypeValue = (id) => {
    getTypesBasedOnTypeLeisure(Number(id), (sucess) => {
      if (sucess.data.code === 200) {
        setLeisureTypeShow(true);
        let newarr = sucess?.data?.data?.map((item) => {
          return { label: `${item.property_sub_category_Name}`, id: item.id };
        });
        // setLeisureType([...newarr])
        dispatch(updateFilterOptions({ SubTypeOptions: newarr }));
      } else {
        setLeisureTypeShow(false);
      }
    });
  };

  const minPriceHandle = (event) => {
    const result = handleAmountChange(event.target.value);
    setMinPrice(result);
    const AmtWithoutComma = result.replace(/,/g, "");
    dispatch(updateFilterData({ pricemin: +AmtWithoutComma }));
  };

  const maxPriceHandle = (event) => {
    const result = handleAmountChange(event.target.value);
    setMaxPrice(result);
    const AmtWithoutComma = result.replace(/,/g, "");
    dispatch(updateFilterData({ pricemax: +AmtWithoutComma }));
  };

  function getCategory(id) {
    dispatch(
      updateFilterData({ property_listing: id, category: null, type: null })
    );
    // setTypesBasedOnCategory([])
    // setLeisureType([])
    setLeisureTypeShow(false);
    setMinPrice("");
    setMaxPrice("");
    getCategories(id, (success) => {
      let newarr = success.map((item) => {
        return { label: `${item.listing_type}`, id: item.id };
      });
      // setCategoryListOptions([...newarr]);
      dispatch(updateFilterOptions({ categotyOptions: newarr }));
    });
  }

  useEffect(() => {
    let timerOut = setTimeout(() => {
      getLocations(searchQuery, (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master.city_name +
              ", " +
              item.city_master.state_master.state_name +
              ", " +
              item.city_master.state_master.country_master.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item.city_name +
              ", " +
              item.state_master.state_name +
              ", " +
              item.state_master.country_master.country_name
            }`,
            value: {
              city_id: item.id,
              state_id: item.state_master.id,
              country_id: item.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item.state_name + ", " + item.country_master.country_name
            }`,
            value: {
              state_id: item.id,
              country_id: item.country_master.id,
            },
          };
        });
        setOptionsList([...newarr, ...newarr1, ...newarr2]);
      });
    }, 1000);
    return () => clearTimeout(timerOut);
  }, [searchQuery]);

  async function getPropertiesType() {
    dispatch(
      updateFilterData({
        area: null,
        city: null,
        state: null,
        country: null,
        type: null,
        pricemin: null,
        pricemax: null,
      })
    );
    let intialusertype;
    await getTypeOfProperties((success) => {
      setTypeOfProperties(success);
      setIsactiveType(success[0].id);
      getCategory(success[0].id);
      intialusertype = success;
    });

    getAccounttype(
      (success) => {
        // goes inside if user  login
        let typeOfuser = userlogin?.user_info?.usertypeobj;
        if (success.type_allowed === null && typeOfuser === 1) {
          // sethow true open popup to when user come forst time after login to select the property type
          setShow(true);
        }
        setAccounttype(success.type_allowed);
        getCategory(intialusertype[success.type_allowed].id);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    getPropertiesType();
  }, [props.updatehero]);

  const activeType = { backgroundColor: "#43A4F6", color: "#FFF" };
  // api call on selecting property type at the strart
  const updateaccount = () => {
    let accountobj = { user_id: userId, type_allowed: typeOfProperty };
    accountobj.user_id = userId;
    accountSetting(accountobj, (success) => {
      setShow(false);
    });

    getAccounttype(
      (success) => {
        setAccounttype(success.type_allowed);
        getCategory(typeOfProperties[success.type_allowed].id);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  let searchFilter = () => {
    if (
      FilterDataInStore.property_listing &&
      FilterDataInStore.category &&
      FilterDataInStore.type
    ) {
      if (FilterDataInStore.pricemax >= FilterDataInStore.pricemin) {
        const blockedKeys = [
          "locationText",
          "Filter_responce",
          "filter_name",
          "Neighborhood",
        ];
        const searchSchema = { ...FilterDataInStore };
        blockedKeys.forEach((key) => delete searchSchema[key]);
        ApplySearch(searchSchema, (success) => {
          localStorage.setItem(
            "FilterSearchSchema",
            JSON.stringify(searchSchema)
          );
          dispatch(
            updateFilterData({
              Filter_responce: success.property,
              Neighborhood: success.Neighborhood,
            })
          );
          if (success?.page === 1) {
            navigate("/dashboard/quik-view");
          }
          if (success?.page === 2) {
            navigate(`/dashboard/property-details`);
          }
        });
      } else {
        alert("price error");
      }
    } else {
      setErrors((prevObject) => ({
        ...prevObject,
        type: "Select type",
        category: "select categoty",
      }));
    }
  };

  return (
    <>
      <div className="section hero-sec d-flex flex-column justify-content-center position-relative span-to-top">
        <div className="bgImg-animation position-absolute">
          <video className="videoTag w-100 h-100" autoPlay loop muted>
            <source src={samplevideo} type="video/mp4" />
          </video>
        </div>
        <Navbar
          updatehero={props.updatehero}
          setUpdatehero={props.setUpdatehero}
        />
        <div className="tag-line d-flex flex-column">
          <h1>Find your next Perfect Property</h1>
          <p>Changing how property shoppers navigate</p>
        </div>
        <div className="hero-filter d-flex flex-column">
          <div className="property-category d-flex flex-wrap">
            {userlogin?.idToken ? (
              <div className="center pointer">
                <p>{typeOfProperties[accounttype]?.property_listing_name}</p>
              </div>
            ) : (
              typeOfProperties?.map((item, index) => {
                return (
                  <div
                    className="center pointer"
                    key={index}
                    style={index + 1 === isactiveType ? activeType : {}}
                    onClick={() => {
                      getCategory(item.id);
                      setIsactiveType(item.id);
                      localStorage.setItem("accounttype", item.id);
                    }}
                  >
                    <p>{item.property_listing_name} </p>
                  </div>
                );
              })
            )}
          </div>
          <div className="hero-property-serach d-flex justify-content-between flex-wrap gap-3">
            <div className="filter-input d-flex  flex-wrap ">
              <div className="one-field Location">
                <p className="titile ">Location</p>
                <Autocomplete
                  className="hide-downBtn"
                  disablePortal
                  id="combo-box-demo"
                  options={optionsList}
                  style={{ minWidth: "250px", width: "100%" }}
                  onChange={(e, value) => {
                    locationOnChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  )}
                />
              </div>

              <div className="one-field Category ">
                <p className={errors.category ? "typeRError" : "titile "}>
                  Category
                </p>
                <Select
                  className="hide-downBtn hero-select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Category"
                  value={FilterDataInStore.category}
                  onChange={(e) => {
                    dispatch(updateFilterData({ category: e.target.value }));
                    getType(e.target.value); // setTypesBasedOnCategory([]);
                    setErrors((prevObject) => ({
                      ...prevObject,
                      category: "",
                    }));
                  }}
                  sx={{ width: 180 }}
                  variant="standard"
                >
                  {FilterOptionsInStore.categotyOptions.length > 0 ? (
                    FilterOptionsInStore.categotyOptions?.map((item, index) => (
                      <MenuItem key={`category${index}`} value={item.id}>
                        {" "}
                        {item.label}{" "}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={""}>No Option</MenuItem>
                  )}
                </Select>
              </div>

              <div className="one-field Type">
                <p className={errors?.type ? "typeRError" : "titile "}>Type</p>
                <Select
                  className="hide-downBtn hero-select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Types"
                  value={FilterDataInStore.type}
                  onChange={(e) => {
                    subTypeValue(e.target.value);
                    dispatch(updateFilterData({ type: e.target.value }));
                    setErrors((prevObject) => ({ ...prevObject, type: "" }));
                  }}
                  variant="standard"
                >
                  {FilterOptionsInStore.typeOptions.length > 0 ? (
                    FilterOptionsInStore.typeOptions?.map((item, index) => (
                      <MenuItem key={`type${index}`} value={item.id}>
                        {item?.label}{" "}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={""}>Select Category</MenuItem>
                  )}
                </Select>
              </div>

              {leisureTypeShow ? (
                <div className="one-field Type">
                  <p className={errors.subtype ? "typeRError" : "titile "}>
                    Sub Type
                  </p>
                  <Select
                    className="hide-downBtn hero-select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="subTypes"
                    value={FilterDataInStore.Sub_category}
                    onChange={(e) => {
                      dispatch(
                        updateFilterData({ Sub_category: e.target.value })
                      );
                    }}
                    variant="standard"
                  >
                    {FilterOptionsInStore?.SubTypeOptions.length > 0 ? (
                      FilterOptionsInStore?.SubTypeOptions?.map(
                        (item, index) => (
                          <MenuItem key={`l${index}`} value={item.id}>
                            {item.label}{" "}
                          </MenuItem>
                        )
                      )
                    ) : (
                      <MenuItem value={""}>No Option</MenuItem>
                    )}
                  </Select>
                </div>
              ) : (
                ""
              )}

              <div className="one-field Price justify-content-between">
                <p className="titile">Price</p>
                <div className="d-flex ">
                  <input
                    type="text"
                    placeholder="Min"
                    name="pricemin"
                    min="0"
                    value={minPrice}
                    onChange={(e) => minPriceHandle(e)}
                  />
                  <input
                    type="text"
                    name="pricemax"
                    min="0"
                    placeholder="Max"
                    value={maxPrice}
                    onChange={(e) => maxPriceHandle(e)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-button center">
              <div
                className="icon-button center pointer h-100"
                onClick={() => setShowFilter(true)}
              >
                <img src={filter} alt="" />
              </div>
              <Button
                variant="primary"
                className=" h-100"
                type="submit"
                onClick={() => searchFilter()}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}

      {/* <SaveSearchpopup /> */}
      {saveSearchPopupVisible && <SaveSearchModel />}

      {/* pop-up for selecting property type start */}
      <div>
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header className=" justify-content-end"></Modal.Header>
          <Modal.Body>
            <div>
              <div className="signupmodal">
                <div className="signupmodal_container">
                  <input
                    type="radio"
                    className="userradio"
                    name="userType"
                    id="firstUser"
                  />
                  <label
                    htmlFor="firstUser"
                    className="signupmodal_box1 user-type"
                    onClick={() => {
                      setTypeOfProperty(0);
                    }}
                  >
                    <div className="signupmodal_box1_shape1">
                      <div className="signupmodal_box1_subshape1">
                        <div className="signupmodal_box1_subshape_img"></div>
                      </div>
                    </div>
                    <div className="signupmodal_box1_text">
                      <span>Residential</span>
                    </div>
                  </label>

                  <input
                    type="radio"
                    className="userradio"
                    name="userType"
                    id="secondUser"
                  />
                  <label
                    htmlFor="secondUser"
                    className="signupmodal_box2 user-type"
                    onClick={() => {
                      setTypeOfProperty(1);
                    }}
                  >
                    <div className="signupmodal_box2_shape1">
                      <div className="signupmodal_box2_subshape1">
                        <div className="signupmodal_box2_subshape_img"></div>
                      </div>
                    </div>
                    <div className="signupmodal_box2_text">
                      {" "}
                      <span>Commercial</span>
                    </div>
                  </label>
                </div>

                <div className="signupmodal_action_button">
                  <button
                    className="signupmodal_button"
                    type="submit"
                    onClick={updateaccount}
                  >
                    <span className="signupmodal_button_text">Continue</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Hero;
