import "./KeyFeature.scss";
import { Col, Row } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import car from "../images/car.svg";
import plus from "../images/plus.svg";
const KeyFeature = (props) => {
  const { propertDetails } = props;

  return (
    <>
      <div className="property-feature mt-3">
        {/* {showLocationButton === true && (
          <div className="add-location pointer">
            <p style={{ color: "rgba(49, 94, 251, 1)" }}>Add Location</p>
            <button
              style={{
                border: "2px solid",
                width: "161px",
                height: "40px",
                borderImageSource:
                  "linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 217.5%)",
              }}
            >
              Home
            </button>
            <p style={{ color: "rgba(67, 164, 246, 1)" }}>Add Destination</p>
          </div>
        )} */}
        {/*  */}
        <div style={{ marginTop: "20px", marginBottom: "10px" }}>
          <p className="MustSee">Must See Now</p>
        </div>

        <Row className=" mt-3">
          {/* left section */}
          <Col xs={4}>
            <p className="heading" style={{ fontSize: "14px", color: "black" }}>
              Sales Features
            </p>
            <div className=" d-flex flex-column gap-1">
              <div className="feature ">
                <p className="">Available : </p>
                <p className=" text-center">
                  {propertDetails.SalesFeatures?.Available
                    ? propertDetails.SalesFeatures?.Available
                    : "--"}
                </p>
              </div>

              {propertDetails.propertDetails?.property_listing_type
                ?.property_listing_name === "Residential" && (
                <div className="feature ">
                  <p className="">Type : </p>
                  <p className=" text-center">
                    {propertDetails.SalesFeatures?.Type
                      ? propertDetails.SalesFeatures?.Type
                      : "--"}
                  </p>
                </div>
              )}

              {/* if property is commertial exclusive then show time on afisado */}
              {propertDetails.propertDetails?.property_listing_type
                ?.property_listing_name === "Commercial" &&
              propertDetails.propertDetails?.is_property_exclusive ? (
                <div className="feature ">
                  <p className="">Time on AFISADO : </p>
                  <p className=" text-center">
                    {propertDetails.SalesFeatures?.Time_on_AFISADO
                      ? `${propertDetails.SalesFeatures?.Time_on_AFISADO} days`
                      : "--"}
                  </p>
                </div>
              ) : null}
            </div>
          </Col>

          {/* right  section */}
          <Col xs={8}>
            {/*for rental property if schedule then show if open house not there then not show */}
            {propertDetails.propertDetails?.open_house && (
              <p className="heading">Open House Showing</p>
            )}
            {propertDetails.propertDetails?.open_house && (
              <table className="custom-table w-100">
                <thead>
                  <tr>
                    <th className="small-col sub-heading">Date</th>
                    <th className="small-col sub-heading">Day</th>
                    <th className="large-col sub-heading">Time</th>
                  </tr>
                </thead>
                <tbody>
                  {propertDetails.propertDetails?.open_house_property.Sunday
                    ?.length > 0 && (
                    <tr>
                      <td className="small-col">04/O5/2022</td>
                      <td className="small-col">Sunday</td>
                      <td className="large-col">
                        {propertDetails.propertDetails?.open_house_property.Sunday?.map(
                          (time, i) => (
                            <p key={`Sunday${i}`}>
                              {time.start_time} - {time.end_time}{" "}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {propertDetails.propertDetails?.open_house_property.Monday
                    ?.length > 0 && (
                    <tr>
                      <td className="small-col">04/O5/2022</td>
                      <td className="small-col">Monday</td>
                      <td className="large-col">
                        {propertDetails.propertDetails?.open_house_property.Monday?.map(
                          (time, i) => (
                            <p key={`Monday${i}`}>
                              {time.start_time} - {time.end_time}{" "}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {propertDetails.propertDetails?.open_house_property.Tuesday
                    ?.length > 0 && (
                    <tr>
                      <td className="small-col">04/O5/2022</td>
                      <td className="small-col">Tuesday</td>
                      <td className="large-col">
                        {propertDetails.propertDetails?.open_house_property.Tuesday?.map(
                          (time, i) => (
                            <p key={`Tuesday${i}`}>
                              {time.start_time} - {time.end_time}{" "}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {propertDetails.propertDetails?.open_house_property.Wednesday
                    ?.length > 0 && (
                    <tr>
                      <td className="small-col">04/O5/2022</td>
                      <td className="small-col">Wednesday</td>
                      <td className="large-col">
                        {propertDetails.propertDetails?.open_house_property.Wednesday?.map(
                          (time, i) => (
                            <p key={`Wednesday${i}`}>
                              {time.start_time} - {time.end_time}{" "}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {propertDetails.propertDetails?.open_house_property.Thursday
                    ?.length > 0 && (
                    <tr>
                      <td className="small-col">04/O5/2022</td>
                      <td className="small-col">Thursday</td>
                      <td className="large-col">
                        {propertDetails.propertDetails?.open_house_property.Thursday?.map(
                          (time, i) => (
                            <p key={`Thursday${i}`}>
                              {time.start_time} - {time.end_time}{" "}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {propertDetails.propertDetails?.open_house_property.Friday
                    ?.length > 0 && (
                    <tr>
                      <td className="small-col">04/O5/2022</td>
                      <td className="small-col">Friday</td>
                      <td className="large-col">
                        {propertDetails.propertDetails?.open_house_property.Friday?.map(
                          (time, i) => (
                            <p key={`Friday${i}`}>
                              {time.start_time} - {time.end_time}{" "}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {propertDetails.propertDetails?.open_house_property.Saturday
                    ?.length > 0 && (
                    <tr>
                      <td className="small-col">04/O5/2022</td>
                      <td className="small-col">Saturday</td>
                      <td className="large-col">
                        {propertDetails.propertDetails?.open_house_property.Saturday?.map(
                          (time, i) => (
                            <p key={`Saturday${i}`}>
                              {time.start_time} - {time.end_time}{" "}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {/*for rental property  if amenities is not there then not show */}
            {propertDetails?.amenities?.length > 0 && (
              <Row className="">
                <p
                  className="heading"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  Amenities
                </p>
                <div className="aminity-container d-flex flex-wrap gap-2">
                  {propertDetails?.amenities?.map((aminity) => (
                    <div
                      className="aminity"
                      key={`aminity${aminity?.amenites_master?.amenities_name}`}
                    >
                      <p>
                        <CheckIcon />
                        {aminity?.amenites_master?.amenities_name}
                      </p>
                    </div>
                  ))}
                </div>
              </Row>
            )}

            {/*for Commertial property  if Spaces is not there then not show */}
            {propertDetails.propertDetails?.property_listing_type
              ?.property_listing_name === "Commercial" && (
              <p className="heading">Space Available</p>
            )}

            {/* {propertDetails.SpaceAvailable?.length > 0 && */}
            {propertDetails.propertDetails?.property_listing_type
              ?.property_listing_name === "Commercial" && (
              <table className="custom-table w-100">
                <thead>
                  <tr>
                    <th className="small-col sub-heading">Space</th>
                    <th className="small-col sub-heading">Size</th>
                    <th className="small-col sub-heading">Term</th>
                    <th className="small-col sub-heading">Rate</th>
                    <th className="small-col sub-heading">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {propertDetails.SpaceAvailable?.map((available, i) => (
                    <tr key={`spaceRoW${i}`}>
                      <td className="small-col">{available.space}</td>
                      <td className="small-col">{available.size}</td>
                      <td className="small-col">{available.term}</td>
                      <td className="small-col">{available.rate}</td>
                      <td className="small-col">{available.type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
      </div>

      {/* //property Description section */}
      <div className="property-feature mt-3">
        <p
          className="heading mb-0"
          style={{ fontSize: "14px", color: "black" }}
        >
          Description
        </p>
        <p>{propertDetails.propertDetails?.propert_description}</p>
      </div>
    </>
  );
};

export default KeyFeature;
