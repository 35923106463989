import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./../components/pages/homepage/components/hero/HeroSlice";
import PropertydetailSlice from "../components/pages/dashboard/propperty-datails/PropertySlice";
import currentListingDetailSlice from "../components/pages/agentbio/AgentBioSlice";
import PersonalInfoSlice from "../components/pages/profile/components/personal/PersonalinfoSlice";
import FilterDataSlice from "../components/pages/homepage/components/hero/FilterSlice";
import FilterOptionSlice from "../components/pages/homepage/components/hero/FilterOptionSlice";
import virtualOfficeSlice from "../components/pages/agentVirtualOffice/AgentOfficeSlice";
import DashboardSlice from "../components/pages/dashboard/DashboardSlice";
import GuestVirtualOfficeSlice from "../components/pages/guestVirtualOffice/GuestVirtualOfficeSlice";
import GuestVirtualOfficeDocsignSlice from "../components/pages/guestVirtualOffice/GuestVirtualOfficeDocsignSlice";
export const Store = configureStore({
  reducer: {
    search: searchReducer,
    propertyDetailInStore: PropertydetailSlice,
    currentListingDetailSlice: currentListingDetailSlice,
    PersonalInfoInStore: PersonalInfoSlice,
    FilterDataInStore: FilterDataSlice,
    FilterOptionsInStore: FilterOptionSlice,
    virtualOfficeInStore: virtualOfficeSlice,
    DashboardSliceInStore: DashboardSlice,
    GuestVirtualOfficeSliceInStore: GuestVirtualOfficeSlice,
    GuestVirtualOfficeDocsignInStore: GuestVirtualOfficeDocsignSlice,
  },
});
