import React, { useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "./ForgetPass.scss";
import swal from "sweetalert";
import { useFormik } from "formik";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Footer from '../homepage/components/footer/Footer'
import Navbar from '../homepage/components/navbar/Navbar'
import Validation from './Validation';
import { restPassword } from './server';
import Loader from '../Loader/Loader';

const ResetPass = () => {
    const navigate = useNavigate();
    const [forgetTab, setForgetTab] = useState(true)
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confpassword, setConfPassword] = useState("")
    const [error, setError] = useState("");
    const [confPassError, setConfPassError] = useState("")
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false)


    // const location = useParams();
    // const {uid , token} = useParams(window.location.search);
    // const location = useParams();
    // const URL = window.location.href.split("?")
    // const searchParam = new URLSearchParams(window.location.search);
    // const urlUid = location.get("uid");
    // const urlToken = location.get("token");
    // const urlUid = uid;
    // const urlToken = token

    const urlString = window.location.href

    const paramString = urlString.split('?')[1];

    let queryString = new URLSearchParams(paramString);
    const urlUid = queryString.get("uid");
    const urlToken = queryString.get("token");

    const handleConfirmPassword = () => {
        let upperCase = /^(?=.*[A-Z])/;
        let Lowercase = /^(?=.*[a-z])/;
        let specialChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
        let digitRegex = /(?=.*?[0-9])/;

        if (password === "") {
            setError("please Enter Password ");
            return;
        } else {
            setError(" ");
        }
        if (password.length < 8) {
            setError("please Enter Password mim 8 char");
            return;
        } else {
            setError(" ");
        }
        if (!upperCase.test(password)) {
            setError("please Enter One Uppercase Value");
            return;
        } else {
            setError(" ");
        }
        if (!Lowercase.test(password)) {
            setError("please Enter One Lowercase Value");
            return;
        } else {
            setError(" ");
        }
        if (!specialChar.test(password)) {
            setError("please Enter One SpecialChar Value");
            return;
        } else {
            setError(" ");
        }
        if (!digitRegex.test(password)) {
            setError("please Enter One Char in number Value");
            return;
        } else {
            setError(" ");
        }


        // else {
        //     swal("success!", "Plese Check Your Mail !", "success").then(() => {
        //         navigate("/login");
        //     });
        // }

        let userVal = {
            password: password,
            password1: confpassword,
            token: urlToken,
            uid: urlUid,


        }


        if (confpassword !== password) {
            setConfPassError("Please Match The Password")
        } else {
            setConfPassError("");
            restPassword(userVal, (success) => {
                setLoading(true)
                if (success.data.code == 200 && success.data.status == "success") {
                    toast.success(success.data.data, "success");
                    setLoading(false)
                    navigate("/login");
                }
                if (success.data.code == 400 && success.data.status == "failed") {
                    setLoading(false)
                    toast.warning(success.data.data, "warning");
                }
                setLoading(!loading)

            })
        }






    };
    return (
        <div>
            {loading ? <Loader /> : null}
            <div>
                <Navbar />
                <div className="loginpage_body forget-page">
                    <div className="loginpage_form center flex-column">
                        <div className=' w-100'>
                            <div className="loginpage_text">
                                <div className="loginpage_text_head">Forgot Password</div>
                                {/* <div className="loginpage_text_subhead "></div> */}
                            </div>
                            <div className="loginpage_inputs" style={{ maxWidth: "500px", margin: "auto" }}>
                                <div className="input-container">
                                    <i className="fa fa-envelope icon"></i>
                                    <span className='login-divider'></span>
                                    <input className="input-field" type={isRevealPwd ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        autoComplete="off"
                                        onChange={(e) => setPassword(e.target.value)}
                                        // onBlur={formik.handleBlur}
                                        value={password} />

                                    {/* view icon of password */}
                                    <div style={{ cursor: "pointer" }} onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                        {isRevealPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                    </div>


                                </div>
                                {error ? (
                                    <div className='validation-text log-validation'>{error}</div>
                                ) : null}
                                {/* validation code start */}
                                <Validation formikPass={password} />

                                {/* validation code End */}

                                <div className="input-container">
                                    <i className="fa fa-key icon"></i>
                                    <span className='login-divider'></span>
                                    <input className="input-field" type={isRevealConfirmPwd ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        name="confirmpassword"
                                        autoComplete="off"
                                        onChange={(e) => setConfPassword(e.target.value)}

                                        value={confpassword} />
                                    {/* view icon of password */}
                                    <div style={{ cursor: "pointer" }} onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}>
                                        {isRevealConfirmPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                    </div>
                                </div>
                                {confPassError ? (
                                    <div className='validation-text log-validation'>{confPassError}</div>
                                ) : null}
                            </div>

                            <div className="loginpage_help_button justify-content-center mb-3 mx-auto">
                                <div className="loginpage_signin">
                                    <button className="loginpage_signin_btn" type="submit"
                                        onClick={handleConfirmPassword}
                                    >
                                        <span className="loginpage_signin_btn_text">Reset</span>
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ResetPass
