import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";

export function getloadCityAreaView(success) {
    let token = getToken()
    // let userId = token?.userId;
    // console.log(token.idToken)
    axios.get(`${backendURL}/api/master/loadcityareaView/`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                // console.log(response.data.data);
                // response.data.data.map((i)=> console.log(i.state_name))
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function saveloadCityAreaView(formData, success) {
    let token = getToken()
    // let userId = token?.userId;
    // console.log(token.idToken)
    axios.post(`${backendURL}/api/user/neighborhoodspecialisareasaved/`, formData, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                // console.log(response.data.data);
                // response.data.data.map((i)=> console.log(i.state_name))
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}


export function BillingHistory(success) {
    let token = getToken()
    let userId = token?.userId;
    axios
        .get(`${backendURL}/api/user/billing/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token?.idToken}`
            }
        })
        .then((response) => {
            if (response.data.code == 200 && response.data.status == "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

// startwatch api

export function DayStarWatch(watchobj, success) {
    let token = getToken()
    axios.post(`${backendURL}/api/property/daywise/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
}

export function WeekStarWatch(watchobj, success) {
    let token = getToken()
    axios.post(`${backendURL}/api/property/weekwise/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
}

export function MonthStarWatch(watchobj, success) {
    let token = getToken()
    axios.post(`${backendURL}/api/property/monthwise/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
}

export function CustumStarWatch(watchobj, success) {
    let token = getToken()
    axios.post(`${backendURL}/api/property/coustomdate/`, watchobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
};

export function getNeighburhoodSave( success) {
    let token = getToken();
    // let userId = token?.userId;
    let userSlug = token?.profile.slug;
    axios.get(`${backendURL}/api/user/neighborhoodspecialisareasaved/${userSlug}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => { console.log(error); });
};

