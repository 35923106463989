import React, { useEffect, useState } from "react";
import { backendURL, getToken } from "../../../../../appVariable/variable";
import "./community.scss";
import CommunitySlider from "./components/CommunitySlider";
import { checkingworkin, getCommerciaPropertyListings, getResidentialPropertyListings } from "./server";

// import brooklyn from "./images/brooklyn.jpg";
// import manhattan from "./images/manhattan.png";
// import queens from "./images/queens.jpg";
// import suffolk from "./images/suffolk.jpg";
// let communities = [
//   { img: brooklyn, button: "suffolk county" },
//   { img: manhattan, button: "brooklyn" },
//   { img: queens, button: "queens" },
//   { img: suffolk, button: "manhattan" },
//   { img: brooklyn, button: "suffolk county" },
//   { img: manhattan, button: "brooklyn" },
//   { img: queens, button: "queens" },
//   { img: suffolk, button: "manhattan" },
//   { img: brooklyn, button: "suffolk county" },
//   { img: manhattan, button: "brooklyn" },
//   { img: queens, button: "queens" },
//   { img: suffolk, button: "manhattan" },
//   { img: brooklyn, button: "suffolk county" },
//   { img: manhattan, button: "brooklyn" },
//   { img: queens, button: "queens" },
//   { img: suffolk, button: "manhattan" },
// ];

const Community = () => {
  const token = getToken();
  const userType = token?.user_info?.usertypeobj;
  const [communityResidentialData, setCommunityResidentialData] = useState([])
  const [communityCommercialData, setCommunityCommercialData] = useState([])
  function getCommunityListings() {
    let lat
    let long
    navigator.geolocation.getCurrentPosition((position) => {
      lat = position.coords.latitude;
      long = position.coords.longitude;
    });

    getResidentialPropertyListings(lat, long, (success) => {

      let residentialList = []
      residentialList = success.map((item) => {
        return ({
          img: `${backendURL}${item.city_image}`,
          button: item.city_name,
          cityId: item.id
        })
      })

      // localStorage.setItem("residentialCityId", )
      setCommunityResidentialData(residentialList)
    });
    getCommerciaPropertyListings(lat, long, (success) => {
      let commercialList = []
      commercialList = success.map((item) => {
        return ({
          img: `${backendURL}${item.city_image}`,
          button: item.city_name,
          cityId: item.id
        })
      })
      setCommunityCommercialData(commercialList)
    });


    checkingworkin((success) => {

    });
  }

  localStorage.setItem("residentailCityId", (userType === 1 ? JSON.stringify(communityResidentialData[0]?.cityId) : null))


  // userType === 1 ?( localStorage.setItem("residentailCityId", JSON.stringify(communityResidentialData[0]?.cityId) )) : null



  useEffect(() => {
    // localStorage.setItem("residentailCityId", JSON.stringify(communityResidentialData[0].cityId)) 
    getCommunityListings();

    // if( userType === 1){
    //   localStorage.setItem("residentailCityId", JSON.stringify(communityResidentialData[0]?.cityId) )
    // }else{
    //   null
    // }

  }, []);
  return (
    <>
      <div className=" layout span-to-top">
        <div className="section community-section d-flex flex-column">
          <h4>COMMUNITIES</h4>
          <div className="community-inner d-flex flex-column">
            <CommunitySlider heading="Some of our Best Residentail properties available"
              communities={communityResidentialData}
            />
            <CommunitySlider heading="Some of our Best Commercial properties available"
              communities={communityCommercialData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Community;
