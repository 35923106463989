import React from 'react'
import Topbar from '../dashboard/components/topbar/Topbar'
import icons from '../dashboard/components/sidebar/images/SidebarIcon'
import Sidebar from '../dashboard/components/sidebar/Sidebar'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'

const LandLoadDashboard = () => {
    const [sidebar, setsidebar] = useState(true);
    const [theme, settheme] = useState(true);
    const sidebardata = [
        { icon: icons.Dashboard, link: 'add-property', title: 'dashboard', },
        { icon: icons.current, link: 'current-listing', title: 'CURRENT LISTING', },
        { icon: icons.expire, link: 'expire-listing', title: 'EXPIRED LISTING', },
        { icon: icons.message, link: 'message', title: 'MESSAGE', },
        { icon: icons.boost, link: 'boost-marketing', title: 'BOOST MARKETING', },
        { icon: icons.office, link: 'virtual-office', title: 'VIRTUAL OFFICE', },
        { icon: icons.documents, link: 'document', title: 'Document', },
        { icon: icons.analytics, link: 'analytic', title: 'ANALYTIC', },
        { icon: icons.Reviews, link: 'review', title: 'Review', },
        { icon: icons.support, link: 'support', title: 'Support', },
    ]
    return (
        <div className="main-dashboard">
            <div className={`dashboard-inner ${true ? "white-theme" : "blue-theme"}`} >
                <Topbar
                    sidebar={sidebar} setsidebar={setsidebar}
                    theme={theme} settheme={settheme}
                />
                <div className="dashboard-middle d-flex h-100">
                    <Sidebar sidebar={sidebar} sidebardata={sidebardata} pointerEvent={true} />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default LandLoadDashboard