import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";



export async function getSaveListing(success) {
  const token = getToken()
  const userId = token?.userId;
  await axios.get(`${backendURL}/api/user/guest_user_save_listing/${userId}`, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      // console.log(err);
    });
}

export async function EditSaveListing(editSchema, success) {
  const token = getToken()
  await axios.put(`${backendURL}/api/property/UpdateSaveListing/`, editSchema, {
    headers: { 'Authorization': `Bearer ${token?.idToken}` }
  })
    .then((resp) => { success(resp.data); })
    .catch((err) => {
      // console.log(err);
    });
}