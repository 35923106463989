import React from 'react'
import "./properytCard.scss"
// import StarIcon from '@mui/icons-material/Star';
import property from "./images/property.jpg";
import pro from '../../../../pages/dashboard/components/mainSection/images/pro.jpg';
import { backendURL } from '../../../../../appVariable/variable';
import { Rating } from '@mui/material';
const ProperytCard = (props) => {
    const { MemberDetail } = props
    return (
        <>
            {/* <div className='cards_property'>
                <div className="card ">
                    <div className="cardBody">
                        <div className='d-flex flex-column'>
                            <div className='d-flex flex-column center'>
                                <div>
                                    <img src={MemberDetail?.profile_image ? `${backendURL}${MemberDetail?.profile_image}` : pro} alt="" />
                                </div>
                                <div className='Tom_text text-capitalize'>
                                    <p className=''> {`${MemberDetail?.first_name} ${MemberDetail?.last_name}`}</p>
                                </div>
                                <div className='text_Advert'><p className=''>Advertisement</p></div>
                                <div className='text_Advert text-center'>
                                    <p>{MemberDetail?.agent_broker_license_title}</p>
                                </div>

                                <div className='Star_icon'>
                                    <Rating name="read-only" value={MemberDetail.rating ? MemberDetail.rating : 0} readOnly />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
            <div className="d-flex align-items-center p-2 property-team-member justify-content-evenly flex-column" >
                <div className=' prof-img'>
                    <img src={MemberDetail?.profile_image ? `${backendURL}${MemberDetail?.profile_image}` : pro} alt="" />
                </div>
                <p className="name text-capitalize text-center">
                    {`${MemberDetail?.first_name} ${MemberDetail?.last_name}`}
                </p>
                <div className='user_type'><p className=' text-center'>Advertisement</p></div>
                <p className='license_title text-center'>{MemberDetail?.agent_broker_license_title}</p>
                <div className='Star_icon'>
                    <Rating name="read-only" value={MemberDetail?.rating ? MemberDetail?.rating : 0} readOnly />
                </div>
            </div>
        </>
    )
}

export default ProperytCard
