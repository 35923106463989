import axios from "axios";
import { backendURL } from "../../../../appVariable/variable";
import moment from "moment/moment";

// Residential
// Just Listed
// main : https://devapi.afisado.com/

export function getJustListedResedentialData(setData) {
  // https://devapi.afisado.comapi/property/residentialjustlisted/ 405
  try {
    axios
      .post(backendURL + `/api/property/residentialjustlisted/`, { current_date: moment(new Date()).format("YYYY-MM-DD") })
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
          setData(response.data.data);
        } else {
          setData(response.data.message);
        }
      });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}

// Pet-Friendly
export function getPetFriendlyResidentialData(setData) {
  // https://devapi.afisado.comapi/property/petfriendly/ ok
  try {
    axios.get(backendURL + `/api/property/petfriendly/`).then((response) => {
      console.log(response);
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}

// No Fee
export function getNoFeeResidentialData(setData) {
  // https://devapi.afisado.comapi/property/nofee/ ok
  try {
    axios.get(backendURL + `/api/property/nofee/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}

// Vacation
export function getVacationResidentialData(setData) {
  // backendapi/property/vacation/ ok
  try {
    axios.get(backendURL + `/api/property/vacation/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}

// Foreclosure
export function getForceClosureResidentialData(setData) {
  // https://devapi.afisado.comapi/property/foreclouser/ ok
  try {
    axios.get(backendURL + `/api/property/foreclouser/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}
// Luxury Sales
export function getLuxurySalesResidentialData(setData) {
  // https://devapi.afisado.comapi/property/salesamt/ ok
  try {
    axios.get(backendURL + `/api/property/salesamt/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}
// Luxury Rentals

export function getLuxuryRentalResidentialData(setData) {
  // https://devapi.afisado.comapi/property/rentamt/ ok
  try {
    axios.get(backendURL + `/api/property/rentamt/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}

// Commercial

// Just Listed
export function getJustListedCommercialData(setData) {
  //
  try {
    axios.get(backendURL + `/api/property/petfriendly/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}

// Office
export function getOfficeCommercialData(setData) {
  // https://devapi.afisado.comapi/property/office/ ok
  try {
    axios.get(backendURL + `/api/property/office/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}
// Retail
export function getRetailCommercialData(setData) {
  // https://devapi.afisado.comapi/property/retail/ ok
  try {
    axios.get(backendURL + `/api/property/retail/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}
// Mixed Use
export function getMixedUseCommercialData(setData) {
  // https://devapi.afisado.comapi/property/mixed_use/ ok
  try {
    axios.get(backendURL + `/api/property/mixed_use/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}
// Industrial
export function getIndustrialCommercialData(setData) {
  // https://devapi.afisado.comapi/property/industrial/ ok
  try {
    axios.get(backendURL + `/api/property/industrial/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}
// Land
export function getLandCommercialData(setData) {
  // https://devapi.afisado.comapi/property/land/ 404 error
  try {
    axios.get(backendURL + `/api/property/land/`).then((response) => {
      if (response.data.code === 200 && response.data.status === "success") {
        setData(response.data.data);
      } else {
        setData(response.data.message);
      }
    });
  } catch (error) {
    console.log("🚀 ~ error", error);
  }
}
