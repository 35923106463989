// import { getDefaultPropertyShow } from "../mainSection/server";
import AdCard from "./AdCard";
import AdCardGuest from "./AdCardGuest";
import "./advertise.scss";
import banner from "./images/banner.jpg";
import { getToken } from "../../../../../appVariable/variable";
import { useSelector } from "react-redux";


const Advertise = (props) => {
    let userlogin = getToken()
    const FilterDataInStore = useSelector(state => state.FilterDataInStore);

    const handleChange = (e) => {
        const { checked, id } = e.target;
        let onlyid = parseInt(id.substring(6));
        let ids = props.selectedTeamMember
        if (checked) {
            ids = [...ids, onlyid]
        } else {
            ids = (props.selectedTeamMember.filter((v) => v !== onlyid));
        }
        console.log(ids)
        props.setSelectedTeamMember(ids)
    };

    return (
        <>
            <div className="advertise d-flex flex-column flex-grow-1">
                <div className="banner"><img src={banner} alt="" /></div>
                <div className="ad-feed d-flex flex-column">
                    <p className=' text-center text-uppercase'>{props.title}</p>
                    <div className="ad-feed-container d-flex flex-wrap justify-content-evenly pb-3">
                        {
                            userlogin?.user_info.usertypeobj === 2 ?
                                (
                                    props.teamMember?.map((Memberinfo, index) =>
                                        <div className="d-flex align-items-center  select-member" style={{ gap: "7px" }} key={`usercards${index}`} >
                                            <input type="checkbox" name="cardId"
                                                id={`member${Memberinfo.id}`}
                                                // value={card.id}
                                                onChange={handleChange}
                                                checked={props.selectedTeamMember.includes(Memberinfo.id)}
                                                hidden
                                            />
                                            <label htmlFor={`member${Memberinfo.id}`} className="formtext ">
                                                <AdCard key={`adcard${index + 1}`} Memberinfo={Memberinfo} />
                                            </label>
                                        </div>
                                    )
                                )
                                :
                                FilterDataInStore?.Neighborhood?.map((memberinfo, i) => (
                                    <AdCardGuest key={i} Memberinfo={memberinfo} />
                                ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Advertise;
