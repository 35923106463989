import React from 'react'
import "./footer.scss"
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className="section  footer d-flex flex-column gap-4 justify-content-center span-to-top">
                <div className="footer-top d-flex justify-content-evenly justify-content-md-between">
                    <div className='about-us d-flex flex-column gap-4'>
                        <h4 className='footer-text'>About us</h4>
                        <p>AFISADO is committed to delivering a high-level property shopping experience for residential and commercial listings. We focus on ease of use and attention to details.</p>
                    </div>
                    <div><NavLink className='footer-text' to='/Terms&Conditions'>Terms & Conditions</NavLink></div>
                    <div><NavLink className='footer-text' to='/PrivacyPolicy'>Privacy Policy</NavLink></div>
                    <div><NavLink className='footer-text' to='/advertisement'>Marketing with Us</NavLink></div>
                </div>
                <div className="footer-bottom text-center"><p>Copyright © AFISADO</p></div>
            </div>
        </>
    )
}

export default Footer
