import React, { useEffect } from "react";
import { useState } from "react";
import MapviewFilter from "../../mapview-filter/MapviewFilter";
import Advertise from "../components/advertise/Advertise";
import Main from "../components/mainSection/Main";
import { getNblSpecial } from "../../agent-dash-sales/server";
import { alpha, styled } from "@mui/material/styles";

const QuikView = () => {
  const [viewsetting, setViewsetting] = useState(false);
  const [nblSpecial, setNblSpecial] = useState([]);

  const TeammerberProfile = () => {
    getNblSpecial(11, (success) => {
      setNblSpecial(success.neighbourhood);
      // let info = success?.map((person) => {
      //     return {
      //         id: person.id,
      //         profile: person.profile_image,
      //         name: `${person.first_name} ${person.last_name}`,
      //         rating: person.rating
      //     }
      // })
      // nblSpecial(info)
    });
  };

  useEffect(() => {
    TeammerberProfile();
  }, []);

  return (
    <>
      <div className=" flex-grow-1">
        {viewsetting ? (
          <div className=" flex-grow-1">
            <MapviewFilter
              setViewsetting={setViewsetting}
              viewsetting={viewsetting}
            />
          </div>
        ) : (
          <div className=" d-flex gap-2">
            <Main setViewsetting={setViewsetting} viewsetting={viewsetting} />
            <Advertise
              title="NEIGHBORHOOD SPECIALIST"
              nblSpecial={nblSpecial}
              userType="guest"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default QuikView;
