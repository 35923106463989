import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "../../../../../appVariable/variable";
let userlogin = getToken();

export const FilterDataSlice = createSlice({
  name: "filterpayload",
  initialState: {
    user_id: userlogin ? userlogin?.userId : null,
    property_listing: null,
    category: null,
    type: null,
    Sub_category: null,
    area: null,
    city: null,
    state: null,
    country: null,
    pricemin: null,
    pricemax: null,
    cost_type: 0,
    filter_obj: false,
    Bedrooms: null,
    Bathrooms: null,
    Squft_min: null,
    Squft_max: null,
    amenity_list: [],
    Amenities_filter: [],
    units: null,
    room: null,
    block: null,
    lot: null,
    Zone: null,
    lot_diamensions: null,
    building_diamensions: null,
    stories: null,
    far: null,
    Assessment: null,
    Annual_Taxes: null,
    Available_Air_Rights: null,
    locationText: "",
    Filter_responce: [],
    Neighborhood: [],
    filter_name: "", //save note of save search popup
  },
  reducers: {
    updateFilterData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateFilterData } = FilterDataSlice.actions;

export default FilterDataSlice.reducer;
