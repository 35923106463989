import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Badge, Table } from "react-bootstrap";
import "./teams.scss";
import EditIcon from "@mui/icons-material/Edit";
// import logo from "./images/logo.jpg"
import deleteimg from "./images/delete.svg";
import { useFormik } from "formik";
import { getToken } from "../../../../../appVariable/variable";
import {
  createTeam,
  DeleteTeam,
  EditTeamMember,
  getTeamDetails,
  getTeamMember,
  inviteListTeamMember,
  RemoveTeamMember,
} from "./server";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loader from "../../../Loader/Loader";
import * as Yup from "yup";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Teams = (props) => {
  let userDetails = getToken();
  const [modalShow, setModalShow] = useState(false);
  const [displayTeamSection, setDisplayTeamSection] = useState(false);
  const [displayTeamList, setDisplayTeamList] = useState(null);
  const [teamName, setTeamName] = useState(null);
  // const [teamList, setTeamList] = useState([])
  const [loading, setLoading] = useState(false);
  const [editMemberId, setEditMemberId] = useState();
  // const [inviteTeamMemberForm, setInviteTeamMemberForm] = useState([{ first_name: '', last_name: '', license: "", email: '' }])
  // const [updateTeam, setUpdateTeam] = useState(false)
  // const [teamMemberCount, setTeamMemberCount] = useState(1)

  function createNewTeam() {
    let tempObj = {};
    let userDetails = getToken();
    tempObj["user_id"] = userDetails.userId;
    tempObj["create_team"] = true;
    tempObj["Team_name"] = teamName;
    createTeam(tempObj, (success) => {
      toast.success(success, "success");
      setDisplayTeamList(true);
    });
  }
  function getData() {
    let userDetails = getToken();
    let tempObj = {};
    tempObj["user_id"] = userDetails?.userId;
    getTeamMember(tempObj, (success) => {
      if (success.data.code === 200 && success.data.status === "success") {
        // setTeamList(success.data.data)
        // setInviteTeamMemberForm(success.data.data)
        dynamicTeamformik.setFieldValue("TeamMember", success.data.data);
      }
      if (success.data.code === 400 && success.data.status === "failed") {
        // setInviteTeamMemberForm([{ first_name: '', last_name: '', license: "", email: '' }])
        dynamicTeamformik.setFieldValue("TeamMember", [
          { first_name: "", last_name: "", license: "", email: "" },
        ]);
        setDisplayTeamList(null);
      } else {
        // console.log(success.data.data)
      }
    });

    getTeamDetails((success) => {
      // console.log("in team deatials", success[0].create_team)
      setDisplayTeamSection(success[0].create_team);
      setTeamName(success[0].team_name);
      setDisplayTeamList(success[0].team_name);
    });
  }

  // eidt team member
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      license: "",
      is_accept: 0,
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email required"),
      first_name: Yup.string()
        .required("first Name required")
        .matches("^[a-zA-Z]*$", "Number & Special character not allowed"),
      last_name: Yup.string().required("Last Name required"),
      license: Yup.string().required("License required"),
      is_accept: Yup.number("is_accept required"),
    }),
    onSubmit: async (values, action) => {
      setLoading(true);
      await EditTeamMember(editMemberId, values, (success) => {
        if (success.data.code === 200 && success.data.status === "success") {
          setModalShow(false);
          toast.success(success.data.data, "success");
          setLoading(false);
          getData();
        }
        if (success.data.code === 400 && success.data.status === "failed") {
          setModalShow(false);
          toast.warning(success.data.data, "warning");
        }
        setLoading(false);
      });
      action.resetForm();
    },
  });

  // dynamic inputs of invite team form
  const dynamicTeamformik = useFormik({
    initialValues: {
      TeamMember: [{ first_name: "", last_name: "", license: "", email: "" }],
    },

    validateOnChange: false,
    // validateOnBlur: false,

    validationSchema: Yup.object({
      TeamMember: Yup.array(
        Yup.object({
          first_name: Yup.string()
            .required("First name needed")
            .nullable()
            .test("no-numbers", "Dont add numbers", (value) =>
              /^[^\d]+$/.test(value)
            ),
          // .min(3, 'start time needs to be at least 3 characters')
          // .max(8, 'enter valid time'),
          last_name: Yup.string()
            .required("Last name  needed")
            .nullable()
            .test("no-numbers", "Dont add numbers", (value) =>
              /^[^\d]+$/.test(value)
            ),

          license: Yup.string()
            .test("is-unique", "Licence Number must unique", function (value) {
              return (
                dynamicTeamformik.values.TeamMember.filter(
                  (input) => input.license === value
                ).length === 1
              );
            })
            .required("License  needed")
            .nullable(),

          email: Yup.string()
            .email("Enter valid Email")
            .test("is-unique", "Try different Mail Id", function (value) {
              return (
                dynamicTeamformik.values.TeamMember.filter(
                  (input) => userDetails.user_info.username !== value
                ).length >= 1
              );
            })
            .required("Enter Email")
            .nullable(),
          //all emails must be unique and not add self email
        })
      )
        .min(1, "You need to provide at least 1 team member")
        .max(12, "You can add only 12 team member"),
    }),
    onSubmit: async (values, action) => {
      let tempObj = {
        user_id: userDetails.userId,
        team_list: values.TeamMember,
      };
      // console.log(tempObj)
      setLoading(true);
      inviteListTeamMember(tempObj, (success) => {
        if (success.data.code === 200 && success.data.status === "success") {
          setModalShow(false);
          toast.success(success.data.data, "success");
          getData();
          setLoading(false);
        }
        if (success.data.code === 400 && success.data.status === "failed") {
          setModalShow(false);
          toast.warning(success.data.data, "warning");
          setLoading(false);
        }
        // props.ChangeTab("fifth")
      });
    },
  });

  let DeleteMenber = (deleteid) => {
    setLoading(true);
    RemoveTeamMember(deleteid, (success) => {
      if (success.data.code === 200 && success.data.status === "success") {
        getData();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  let DeletAgentTeam = () => {
    DeleteTeam((success) => {
      if (success.code === 200 && success.status === "success") {
        getData();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleaddclickNewform = () => {
    if (dynamicTeamformik.values.TeamMember.length + 1 <= 12) {
      dynamicTeamformik.setFieldValue("TeamMember", [
        ...dynamicTeamformik.values.TeamMember,
        { first_name: "", last_name: "", license: "", email: "" },
      ]);
    } else {
      toast.warning("Team member limit exceeded", "warning");
    }
  };

  const handleRemoveclick = (currentObj) => {
    let filterTime = dynamicTeamformik.values.TeamMember.filter(
      (x) =>
        x.first_name !== currentObj.first_name &&
        x.last_name !== currentObj.last_name &&
        x.license !== currentObj.license &&
        x.email !== currentObj.email
    );
    dynamicTeamformik.setFieldValue(
      "TeamMember",
      filterTime.length
        ? filterTime
        : [{ first_name: "", last_name: "", license: "", email: "" }]
    );
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="main_team_section">
        <div className="row">
          <div className="col-sm-12">
            <span className="creat_text">Do you want to create a team?</span>
          </div>
          <div className="row">
            <div className="col-sm-12 d-flex radio_buttonpad">
              <div className="">
                <input
                  type="radio"
                  id="choice"
                  name="choice"
                  checked={displayTeamSection ? true : false}
                  onChange={(e) => {
                    setDisplayTeamSection(e.target.checked);
                  }}
                />
                <span className="yes_no">Yes</span>{" "}
              </div>
              <div className="">
                <input
                  type="radio"
                  id="choice"
                  name="choice"
                  checked={displayTeamSection ? false : true}
                  onChange={(e) => {
                    setDisplayTeamSection(!e.target.checked);
                    setDisplayTeamList(null);
                    DeletAgentTeam();
                  }}
                />
                <span className="yes_no">No</span>{" "}
              </div>
            </div>
          </div>
          {displayTeamSection ? (
            <>
              <div className="row mb-4 p-0">
                <div className="col-sm-9">
                  <div className="d-flex main_input h-100">
                    <div className="input_name grow-1 text-end ps-4 h-100">
                      Team Name<span className="red-star">*</span>
                    </div>
                    <div className="input-sec d-flex align-items-end">
                      <input
                        type="email"
                        className="form-control border-0 pb-0"
                        value={teamName}
                        onChange={(e) => {
                          setTeamName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 text-end p-0">
                  <button
                    className="btn Save_button"
                    onClick={() => {
                      createNewTeam();
                    }}
                    disabled={!teamName}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          ) : null}

          {displayTeamList ? (
            <>
              <div className="main_user_table scrollS pe-0">
                <Table className="user_tame_table">
                  <thead>
                    <tr className="input_namES">
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>License No</th>
                      <th>Email</th>
                      <th className=" text-center">Status</th>
                      <th className="">Edit</th>
                      <th className=" text-end">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dynamicTeamformik.values.TeamMember?.map((row, index) => (
                      <tr key={`teamlistrow${index}`}>
                        <td>
                          <div className="border-line">
                            <input
                              type="text"
                              className=" team-form-input border-0 pb-0"
                              placeholder="First Name"
                              name={`TeamMember.${index}.first_name`}
                              onChange={dynamicTeamformik.handleChange}
                              value={row.first_name ? row.first_name : ""}
                            />
                          </div>
                          {dynamicTeamformik.errors.TeamMember ? (
                            <p className="validation-text text-danger">
                              {
                                dynamicTeamformik.errors.TeamMember[index]
                                  ?.first_name
                              }
                            </p>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div className="border-line">
                            <input
                              type="text"
                              className=" team-form-input border-0 pb-0"
                              placeholder="Last Name"
                              name={`TeamMember.${index}.last_name`}
                              onChange={dynamicTeamformik.handleChange}
                              value={row.last_name ? row.last_name : ""}
                            />
                          </div>
                          {dynamicTeamformik.errors.TeamMember ? (
                            <p className="validation-text text-danger">
                              {" "}
                              {
                                dynamicTeamformik.errors.TeamMember[index]
                                  ?.last_name
                              }{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div className="border-line">
                            <input
                              type="text"
                              className=" team-form-input border-0 pb-0"
                              name={`TeamMember.${index}.license`}
                              onChange={dynamicTeamformik.handleChange}
                              placeholder="License Number"
                              value={row.license ? row.license : ""}
                            />
                          </div>
                          {dynamicTeamformik.errors.TeamMember ? (
                            <p className="validation-text text-danger">
                              {" "}
                              {
                                dynamicTeamformik.errors.TeamMember[index]
                                  ?.license
                              }
                            </p>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div>
                            <input
                              type="email"
                              className=" team-form-input border-0 pb-0 w-100"
                              // name="email"
                              name={`TeamMember.${index}.email`}
                              onChange={dynamicTeamformik.handleChange}
                              placeholder="Email"
                              value={row.email ? row.email : ""}
                            />
                          </div>
                          <div className="border-line w-100"></div>
                          {/* {row.email ? emailRegex.test(row.email) ? null :
                              <div className='validation-text text-danger'>Enter valid E-mail Id</div>
                              : null} */}
                          <p className="validation-text text-danger">
                            {dynamicTeamformik.errors.TeamMember
                              ? dynamicTeamformik.errors.TeamMember[index]
                                  ?.email
                              : ""}
                          </p>
                        </td>
                        <td className=" text-center">
                          <div>
                            {
                              <Badge bg={row.is_accept ? "success" : "danger"}>
                                {row.is_accept ? "Approve" : "Pending"}
                              </Badge>
                            }
                          </div>
                        </td>

                        <td className=" text-center">
                          <EditIcon
                            className=" pointer"
                            onClick={() => {
                              setModalShow(true);
                              setEditMemberId(row.id);
                              formik.setFieldValue("email", row.email);
                              formik.setFieldValue(
                                "first_name",
                                row.first_name
                              );
                              formik.setFieldValue("last_name", row.last_name);
                              formik.setFieldValue("license", row.license);
                              formik.setFieldValue(
                                "is_accept",
                                row.is_accept ? 1 : 0
                              );
                            }}
                          />
                        </td>

                        <td className=" d-flex justify-content-end gap-2 pe-0">
                          <div className="deleteimg pointer">
                            <img
                              src={deleteimg}
                              alt=""
                              onClick={() => {
                                row.id
                                  ? DeleteMenber(row.id)
                                  : handleRemoveclick(row);
                              }}
                            />
                          </div>

                          {dynamicTeamformik.values.TeamMember.length - 1 <=
                          index ? (
                            dynamicTeamformik.values.TeamMember.length - 1 ===
                            11 ? (
                              ""
                            ) : (
                              <>
                                <AddCircleOutlineIcon
                                  className=" pointer"
                                  onClick={() => handleaddclickNewform()}
                                />
                              </>
                            )
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className=" d-flex justify-content-end align-items-center p-0">
                <button
                  className="invite_teambtn align-self-end mb-0"
                  onClick={dynamicTeamformik.handleSubmit}
                >
                  {" "}
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="invite_modals">
        <Modal
          className="invite_modals"
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          ria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 invite_body gdgyg"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title">Edit Team Member</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite_body">
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_namepad">
                First Name<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input
                  type="text"
                  className="form-control border-0 pb-0"
                  name="first_name"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                />
                {formik.errors.first_name && formik.touched.first_name ? (
                  <div className="validation-text">
                    {formik.errors.first_name}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_namepad">
                Last Name<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input
                  type="text"
                  className="form-control border-0 pb-0"
                  name="last_name"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                />
                {formik.errors.last_name && formik.touched.last_name ? (
                  <div className="validation-text">
                    {formik.errors.last_name}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_namepad">
                License No<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input
                  type="text"
                  className="form-control border-0 pb-0"
                  name="license"
                  onChange={formik.handleChange}
                  value={formik.values.license}
                />
                {formik.errors.license && formik.touched.license ? (
                  <div className="validation-text">{formik.errors.license}</div>
                ) : null}
              </div>
            </div>
            <div className="d-flex main_input">
              <div className="input_name grow-1 text-end input_namepad">
                Email<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <input
                  type="email"
                  className="form-control border-0 pb-0"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="validation-text">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="text-end mb-3">
              <button
                type="submit"
                className="submit_btn"
                onClick={formik.handleSubmit}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Teams;
