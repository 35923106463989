import axios from "axios";
import { backendURL, getToken } from "../../../../../appVariable/variable";

export function changePassword(tempObj, success) {
  let token = getToken();
  axios
    .post(`${backendURL}/api/user/changepassword/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      success(response)
      // if (response.data.code == 200 && response.data.status == "success") {
      //   console.log(response.data.data);
      //   success(response.data.data);
      // }
    })
    .catch((error) => {
      console.log(error);
    });
}
export function checkpassword(tempObj, success) {
  let token = getToken();
  axios
    .post(`${backendURL}/api/user/checkpassword/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      success(response);
      // if (response.data.code == 200 && response.data.status == "success") {
      //   console.log(response.data.data);
      //   success(response.data.data);
      // }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function submitProfilePassword(tempObj, success) {
  console.log("called");
  let token = getToken();
  axios
    .post(`${backendURL}/api/user/profilepassword/`, tempObj, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        console.log(response.data.data);
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getProfilePasswordStatus(success) {
  let token = getToken();
  let userslug = token?.profile.slug;
  axios.get(`${backendURL}/api/user/agentuserprofile/${userslug}`, {
    headers: { Authorization: `Bearer ${token?.idToken}`, },
  })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") { success(response.data.data); }
    })
    .catch((error) => { console.log(error); });
}

export function removePasssword(tempObj, success) {
  let token = getToken();
  axios.post(`${backendURL}/api/user/RemovePropefilePassword/`, tempObj, {
    headers: { Authorization: `Bearer ${token?.idToken}`, },
  })
    .then((response) => {
      success(response.data);
      // if (response.data.code == 200 && response.data.status == "success") {
      //   success(response.data.data);
      // }
    })
    .catch((error) => {
      console.log(error);
    });
};

export function ProfileSettingForgetPassword(userVal, success) {
  let token = getToken()
  axios
    .post(`${backendURL}/api/user/ForgetProfilePassword/`, userVal, {

    })
    .then((response) => {
      success(response);

    })
    .catch((error) => {
      console.log(error);
    });
}

export const profileSettingResetPassword = (userVal, success) => {
  axios.post(`${backendURL}/api/user/ResetProfilePassword/`, userVal, {}).then((response) => {
    success(response)
  }).catch((error) => {
    console.log(error)
  })

}
