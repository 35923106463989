import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";

export function AddnewCard(cardinfo, success) {
  let token = getToken();
  axios
    .post(`${backendURL}/api/user/card_create/`, cardinfo, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
      // if (response.data.code == 200 && response.data.status == "success") {
      //     console.log(response.data.data, response.data.status);
      //     success(response.data.data);
      // }
      // if (response.data.code == 400 && response.data.status == "failed") {
      //     console.log(response.data.data);
      //     success(response.data.data);
      // }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function billCheckout(billingobj, success) {
  let token = getToken();
  axios
    .put(`${backendURL}/api/user/billing/`, billingobj, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
      // if (response.data.code == 200 && response.data.status == "success") {
      //     success(response.data.data);
      // }
      // if (response.data.code == 400 && response.data.status == "failed") {
      //     console.log(response.data.data);
      //     success(response.data.data);
      // }
    })
    .catch((error) => {
      console.log(error);
      // success("something went Wrong")
    });
}

export function billAmmount(billamt, success) {
  let token = getToken();
  axios
    .post(
      `${backendURL}/api/user/agentapprovedsubscriptionplanchoices/`,
      billamt,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        // console.log(response.data.data);
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getuserCards(success) {
  let token = getToken();
  let userId = token?.userId;
  axios
    .get(`${backendURL}/api/user/card_create/${userId}`, {
      headers: {
        Authorization: `Bearer ${token?.idToken}`,
      },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      } else {
        success(null);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function deletecard(cardid, success) {
  let token = getToken();
  let accessToken = token?.accessToken;
  await axios
    .delete(`${backendURL}/api/user/card_create/${cardid}`, {
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      } else {
        // console.log(response.data.data);
        success(null);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
