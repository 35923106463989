import React, { useEffect, useState } from "react";
import "./topbar.scss";
import { Button } from "react-bootstrap";
// import newlogo from "./image/newlogo.svg"
// import { ReactComponent as Newlogo } from './image/newlogo.svg';
import logo from "./image/logo.jpg";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { getToken } from "../../../../../appVariable/variable";
import { Rating } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updatePersonalInfo } from "../../../profile/components/personal/PersonalinfoSlice";
import { backendURL } from "../../../agentVirtualOffice/components/editContact/EditContact";

const Topbar = (props) => {
  const dispatch = useDispatch();
  const userDetails = getToken();
  const userType = userDetails?.user_info?.usertypeobj;
  const propertyDetailInStore = useSelector(
    (state) => state.PersonalInfoInStore
  );
  const navigate = useNavigate();
  const [greet, setGreet] = useState("Morning");
  // const [totalListing, setTotalListing] = useState(0)
  function userLogout() {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("add_on");
    localStorage.removeItem("sidebar");
    localStorage.removeItem("residentailCityId");
    localStorage.removeItem("FilterSearchSchema");
    navigate("/");
  }

  useEffect(() => {
    // let userDetails = getToken();
    // setTotalListing(userDetails?.profile?.listing_count)
    let myDate = new Date();
    let hrs = myDate.getHours();
    if (hrs < 12) {
      setGreet("Morning");
    } else if (hrs >= 12 && hrs <= 17) {
      setGreet("Afternoon");
    } else if (hrs >= 17 && hrs <= 24) {
      setGreet("Evening");
    }

    dispatch(
      updatePersonalInfo({
        firstName: userDetails?.profile?.first_name,
        profile_image: userDetails?.profile?.profile_image,
      })
    );
  }, []);

  return (
    <>
      <div className="topbar d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-around gap-3">
          <div style={{ minWidth: "175px" }}>
            <div className="logo pointer" onClick={() => navigate("/")}>
              <img src={logo} alt="" srcSet="" />
              {/* <LogoImg /> */}
            </div>
          </div>
          <div className="user-info center gap-4">
            <MenuIcon
              className="pointer"
              style={{ color: "#315EFB" }}
              onClick={() => props.setsidebar(!props.sidebar)}
            />
            <img
              src={`${backendURL}${propertyDetailInStore.profile_image}`}
              alt=""
              srcSet=""
              style={{ borderRadius: "50%", width: "55px" }}
            />
            <div style={{ marginLeft: "-10px" }}>
              {userDetails === null ? (
                "Afisado "
              ) : (
                <p>{`Good ${greet} ${
                  propertyDetailInStore.firstName
                    ? propertyDetailInStore.firstName
                    : "Afisado"
                }`}</p>
              )}
              {userType === 2 && (
                <Rating
                  className="agent-rating"
                  name="read-only"
                  value={userDetails?.profile?.rating}
                  readOnly
                />
              )}
            </div>
          </div>
        </div>

        <div className=" d-flex center gap-5">
          {/* onClick={() => props.settheme(!(props.theme))} */}
          {/* {props.totalListing && <p className="listing-count">Total listing {totalListing}/300</p>} */}
          {userType === 2 ? (
            <p className="listing-count text-capitalize ">
              Total listing {props.agentTotaListing}/300
            </p>
          ) : // totalListingcount(paramObj, (success) => setTotalLiastingCounts(success));
          null}

          {userDetails === null ? (
            <>
              {userDetails?.idToken ? (
                <div className="sign-in">
                  <Button
                    variant="primary"
                    onClick={() => {
                      userLogout(); //navigate('/')
                    }}
                  >
                    Logout
                  </Button>
                </div>
              ) : (
                <div className="sign-in">
                  <Button variant="primary" onClick={() => navigate("/login")}>
                    Sign in / Register
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div
              className="power-icon  center pointer third"
              onClick={() => {
                userLogout();
              }}
            >
              <PowerSettingsNewIcon style={{ color: "#315EFB" }} />{" "}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Topbar;
