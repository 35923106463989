import React from "react";
import { Col, Row } from "react-bootstrap";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import icons from "../../dashboard/components/sidebar/images/SidebarIcon";
import { useSelector } from "react-redux";
import { backendURL } from "../../../../appVariable/variable";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";

const AllDetails = () => {
  const memberDetail = useSelector(
    (state) => state.virtualOfficeInStore.memberDetails
  );

  const navigate = useNavigate();
  return Boolean(memberDetail.name) ? (
    <div
      className="d-flex flex-column justify-content-center all-details"
      style={{ marginBottom: "120px" }}
    >
      <div className="person-info d-flex gap-2 justify-content-center flex-column">
        <div style={{ display: "flex", marginTop: "25px" }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="prof-img">
              <img
                src={
                  memberDetail?.user_image?.profile_image
                    ? `${backendURL + memberDetail?.user_image?.profile_image}`
                    : icons.profile
                }
                alt=""
                srcSet=""
              />
            </div>

            <p
              className="name"
              style={{ paddingTop: "6px", paddingBottom: "6px" }}
            >
              {memberDetail.name !== null ? memberDetail.name : "----"}
            </p>
            <Button
              style={{
                fontFamily: "Poppins",
                fontSize: "15px",
                lineHeight: "18px",
                marginTop: "5px",
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
              className="blue__btn"
              // variant="outlined"
              size="large"
              onClick={() => navigate(`/agent-virtual-office/contact/edit`)}
            >
              Edit
            </Button>
          </div>
          <div style={{ width: "60%" }}></div>
        </div>
      </div>

      <div
        className="d-flex "
        style={{ flexDirection: "row", marginTop: "20px" }}
      >
        <div
          className="details-info d-flex flex-column"
          style={{ width: "45%", fontSize: "30px" }}
        >
          <Row>
            <Col
              lg={3}
              className="title-label d-flex flex-start  justify-content-between"
            >
              <p>Name</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.name !== null ? memberDetail.name : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>DOB</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.dob !== null ? memberDetail.dob : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Contact Number 1</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.contact_no_1 !== null
                ? memberDetail.contact_no_1
                : "----"}
            </Col>
          </Row>
          <Row>
            <Col
              lg={3}
              className="title-label d-flex  justify-content-between flex-wrap"
            >
              <p>Email</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.email !== null ? memberDetail.email : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Towns of interest</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.towns_of_interest !== null
                ? memberDetail.towns_of_interest
                : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Date of Close</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.date_of_close !== null
                ? memberDetail.date_of_close
                : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Current Address</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.current_address !== null
                ? memberDetail.current_address
                : "----"}
            </Col>
          </Row>
        </div>
        <div
          style={{
            marginTop: "-5px",
            height: "220px",
            borderRight: "1px solid",
            width: "5%",
          }}
        ></div>
        <div
          className="details-info d-flex flex-column "
          style={{ width: "45%", marginLeft: "50px" }}
        >
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Martial Status</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.martial_status !== null
                ? memberDetail.martial_status
                : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>RelationShip</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.relationship !== null
                ? memberDetail.relationship
                : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Purchase/ Rental Price</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.purchase_rental_price !== null
                ? memberDetail.purchase_rental_price
                : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Docs.</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {" "}
              {memberDetail.signed_documents.map((data, i) => (
                <DescriptionIcon
                  className="pointer"
                  style={{ color: "#315EFB" }}
                  key={`doc${i}`}
                />
              ))}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>VirtualOffice</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.virtualoffice !== null
                ? memberDetail.virtualoffice
                : "----"}
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="title-label d-flex  justify-content-between">
              <p>Add Note</p>
              <p>:</p>
            </Col>
            <Col lg={9} className="title-label ps-4">
              {memberDetail.add_note !== null ? memberDetail.add_note : "----"}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  ) : (
    <p className="align-self-center">This accout is not accepted yet.</p>
  );
};

export default AllDetails;
