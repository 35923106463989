import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";
import { LocalHospital } from "@mui/icons-material";
let token = getToken();

export async function uploadPDF(formData, progress, success) {
  return new Promise((resolve, reject) => {
    let token = getToken();
    axios
      .post(`${backendURL}/api/property/DocumentsAPI/`, formData, {
        headers: { Authorization: `Bearer ${token?.idToken}` },
        onUploadProgress: (ProgressEvent) => {
          progress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        resolve(response.data);
        success(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}
export function docSign(id, pdf, success) {
  let token = getToken();
  const data = { doc_id: id, file: pdf };
  axios
    .post(`${backendURL}/api/property/SignDocumentAPI/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function changeDocTitle(id, title, success) {
  let token = getToken();
  const data = { doc_id: id, title: title };
  axios
    .put(`${backendURL}/api/property/DocTitleRenameAPI/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function changeOrderDoc(updatedDoc, success) {
  let token = getToken();
  axios
    .put(`${backendURL}/api/property/DocumentOrderAPI/`, updatedDoc, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getPDFList(success) {
  let token = getToken();
  let userType = token.user_info.usertypeobj;
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails.userId;
  axios
    .get(
      // `${backendURL}/api/property/DocumentsAPI/?user_type=${userType}&user_id=${userId}`,
      `${backendURL}/api/property/DocumentsAPI/`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function updatePDFList(data, success) {
  let token = getToken();
  axios
    .put(`${backendURL}/api/property/DocumentsAPI/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function deleteList(doc_id, success) {
  let token = getToken();
  axios
    .delete(`${backendURL}/api/property/DocumentsAPI/${doc_id}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function updatePDFShowStatus(data, success) {
  let token = getToken();
  axios
    .put(`${backendURL}/api/property/DocumentShareStatusAPI/`, data, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getPDFForCustomer(virtualofficeid, success) {
  let token = getToken();
  let userType = token.user_info.usertypeobj;
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails.userId;
  const data = { user_id: userId, virtualteam_id: virtualofficeid };
  axios
    .get(`${backendURL}/api/property/ShareDocumentAPI/`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
