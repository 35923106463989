import React from 'react'
import "./selpersonCard.scss"
import cardImg from "./images/Gentleman.jpg"
import { Rating } from '@mui/material';
import { backendURL } from '../../../../appVariable/variable';
// import inputcheck from "./images/inputcheck.svg"
const SelpersonCard = ({ info, onclick, name }) => {
    return (
        <div className='selPersonCardWidth '>
            <div className='card card_border h-100'>
                <div className="card-body p-2">
                    <label className="checkbox w-100 pointer" >
                        <input type="checkbox" className="custum-checkbox checkbox__input" onChange={onclick} id={info?.id} name={name} />
                        <span className="checkbox__inner"></span>
                        <div className='card_center'>
                            <div className='pb-3 img_pad_bottom text-center'><img src={info?.profile_image ? `${backendURL}${info?.profile_image}` : cardImg} alt="" /></div>
                            <p className=' text-center'>{`${info?.first_name} ${info?.last_name}`}</p>
                            <Rating name="read-only" value={info?.rating} readOnly />
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default SelpersonCard