import axios from "axios";
import { backendURL } from "../../../../../appVariable/variable";

export function getTitleDetails(success) {
  axios
    .get(`${backendURL}/api/property/homecardtitle/`)
    .then((response) => {
      if (response.data.code == 200 && response.data.status == "success") {
        // console.log(response);
        success(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
export function getCardDetails(success) {
    axios
      .get(`${backendURL}/api/property/homecard/`)
      .then((response) => {
        if (response.data.code == 200 && response.data.status == "success") {
        //   console.log(response);
          success(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

