import Select from "react-select";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getAllzipcode } from "../../../personal/personalInformation/Server";
import { ZipCodeAddress } from "../server";

const AddressFeild = (props) => {
  const { formik } = props;
  const [allZipcodes, setAllZipcodes] = useState([]);
  const [fullAddress, setFullAddress] = useState({
    AllTowns: [],
    AllCity: [],
    AllStates: [],
  });

  let getFullAddress = (zipCode) => {
    ZipCodeAddress(zipCode, (success) => {
      if (success.data.code === 200 && success.data.status === "success") {
        let townList = success.data.data.town?.map((item) => {
          return { label: `${item.area_name}`, id: item.id };
        });
        let cityList = success.data.data.city?.map((item) => {
          return { label: `${item.city_name}`, id: item.id };
        });
        let stateList = success.data.data.state?.map((item) => {
          return { label: `${item.state_name}`, id: item.id };
        });
        setFullAddress({
          AllTowns: townList,
          AllCity: cityList,
          AllStates: stateList,
        });
        // console.log("townList", townList); console.log("cityList", cityList); console.log("stateList", stateList);
        // if only one option available sekct 1st option and if id selected by default in edit case then leave
        if (!formik.values?.areaId) {
          !(townList.length > 1) &&
            formik.setFieldValue("areaId", townList[0]?.id);
        }
        if (!formik.values?.cityId) {
          !(cityList.length > 1) &&
            formik.setFieldValue("cityId", cityList[0]?.id);
        }
        if (!formik.values?.stateId) {
          !(stateList.length > 1) &&
            formik.setFieldValue("stateId", stateList[0]?.id);
        }
      }
    });
  };
  useEffect(() => {
    getAllzipcode((success) => {
      let newarr = success.map((item) => {
        return { label: `${item.Zipcode}` };
      });
      setAllZipcodes([...newarr]);
    });
    formik.values.zipcode && getFullAddress({ zipcode: formik.values.zipcode });
  }, []);

  return (
    <>
      <Row className="second-row mx-0 align-items-center">
        <Col sm className="second-row-in me-2">
          <div className="d-flex align-items-center inputbox ">
            <p className={`formtext inputLable`}>Zipcode</p>
            <Select
              options={allZipcodes}
              className=" w-100 ps-0 custum-react-select select-wrapper"
              classNamePrefix="select"
              value={allZipcodes?.filter(
                (option) => option.label === formik.values.zipcode
              )}
              onChange={(e) => {
                getFullAddress({ zipcode: e.label });
                formik.setFieldValue("zipcode", e.label);
              }}
            />
          </div>
          {formik.errors.zipcode && formik.touched.zipcode ? (
            <div className="validation-text">{formik.errors.zipcode}</div>
          ) : null}
        </Col>

        <Col sm className="second-row-in  me-2">
          <div className="d-flex align-items-center inputbox ">
            <p className={`formtext inputLable`}>Town</p>
            {fullAddress?.AllTowns.length > 1 ? (
              <Select
                options={fullAddress?.AllTowns}
                className=" w-100 ps-0 custum-react-select select-wrapper"
                classNamePrefix="select"
                value={fullAddress?.AllTowns?.filter(
                  (option) => option.id === formik.values.areaId
                )}
                onChange={(e) => {
                  formik.setFieldValue("areaId", e.id);
                }}
              />
            ) : (
              <p className="w-75 formtext">{fullAddress?.AllTowns[0]?.label}</p>
            )}
          </div>
          {formik.errors.areaId && formik.touched.areaId ? (
            <div className="validation-text">{formik.errors.areaId}</div>
          ) : null}
        </Col>
        {/* <Col sm className="second-row-in me-2">
                    <div className="d-flex align-items-center inputbox ">
                        <p className={`formtext inputLable`}>City</p>
                        {fullAddress?.AllCity.length > 1 ?
                            <Select options={fullAddress?.AllCity} className=' w-100 ps-0 custum-react-select select-wrapper' classNamePrefix="select"
                                value={fullAddress?.AllCity?.filter(option => option.id === formik.values.cityId)}
                                onChange={(e) => { formik.setFieldValue("cityId", e.id); }}
                            /> :
                            <p className="w-75 formtext">{fullAddress?.AllCity[0]?.label}</p>
                        }
                    </div>
                    {formik.errors.cityId && formik.touched.cityId ? <div className='validation-text'>{formik.errors.cityId}</div> : null}
                </Col> */}
        <Col sm className="second-row-in ">
          <div className="d-flex align-items-center inputbox ">
            <p className={`formtext inputLable`}>State</p>
            {fullAddress?.AllStates.length > 1 ? (
              <Select
                options={fullAddress?.AllStates}
                className=" w-100 ps-0 custum-react-select select-wrapper"
                classNamePrefix="select"
                value={fullAddress?.AllStates?.filter(
                  (option) => option.id === formik.values.stateId
                )}
                onChange={(e) => {
                  formik.setFieldValue("stateId", e.id);
                }}
              />
            ) : (
              <p className="w-75 formtext">
                {fullAddress?.AllStates[0]?.label}
              </p>
            )}
          </div>
          {formik.errors.stateId && formik.touched.stateId ? (
            <div className="validation-text">{formik.errors.stateId}</div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default AddressFeild;
