import axios from "axios";
import { backendURL, getToken } from "../../../../../appVariable/variable";

export function GetUserBilling(success) {
    let token = getToken()
    let userId = token?.userId;
    axios.get(`${backendURL}/api/user/plan_and_billing/${userId}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export async function deleteAgentAccount(userid, success) {
    let token = getToken()
    // let accessToken = token?.accessToken;
    await axios.delete(`${backendURL}/api/user/delete_user_account/${userid}`,
        { headers: { 'Authorization': `Bearer ${token?.accessToken}` }, })
        .then((response) => {
            if (response.data.code == 200 && response.data.status == "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function SuspendAgentAccount(success) {
    let token = getToken()
    let userId = token?.userId;
    axios.get(`${backendURL}/api/user/suspend_user_account/${userId}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code == 200 && response.data.status == "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}