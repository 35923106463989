import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getToken } from "../../../appVariable/variable";
import Advertise from "../dashboard/components/advertise/Advertise";
import CommonForm from "./CommonForm";
import {
  getRadioButtons,
  getTeammerberProfile,
  getuserSubscription,
} from "./server";

const AgentAddProperty = () => {
  let token = getToken();
  let licenseApprove = token?.user_info.license_approve;
  const [types, setTypes] = useState([]);
  const [radiobuttns, setRadiobuttns] = useState([]);
  const [propertyListingTypeId, setPropertyListingTypeId] = useState();
  const [residentialUser, setResidentialUser] = useState(true);
  const [commercialUser, setCommercialUser] = useState(false);
  const [teamMember, setTeamMember] = useState();
  const [noFee, setNoFee] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  const radioButton = (e, id, type) => {
    let iscommercial;
    if (e) {
      iscommercial = type.includes("Commercial");
    }
    setPropertyListingTypeId(id);
    let radioObj = { Property_listing_type_id: id };
    getRadioButtons(radioObj, (success) => {
      setRadiobuttns(success);
    });

    if (iscommercial) {
      setResidentialUser(false);
      setCommercialUser(true);
    } else {
      setResidentialUser(true);
      setCommercialUser(false);
    }
    if (type === "Rental") {
      setNoFee(true);
    } else {
      setNoFee(false);
    }

    if (type === "Commercial Leasing") {
      setNoFee(true);
    } else {
      setNoFee(false);
    }
  };

  const getSubscritionType = async () => {
    await getuserSubscription((success) => {
      setTypes(success[0]);
      if (success[0][0].listing_type.includes("Commercial")) {
        radioButton(true, success[0][0].id, success[0][0].listing_type);
      } else {
        radioButton(false, success[0][0].id, success[0][0].listing_type);
      }
    });
  };

  const TeammerberProfile = () => {
    getTeammerberProfile((success) => {
      let info = success?.map((person) => {
        return {
          id: person.id,
          profile: person.profile_image,
          name: `${person.first_name} ${person.last_name}`,
          rating: person.rating,
        };
      });
      setTeamMember(info);
    });
  };
  useEffect(() => {
    getSubscritionType();
    TeammerberProfile();
  }, []);
  return (
    <>
      <div className="agent-rental d-flex flex-grow-1 gap-4">
        <div className=" agent-main flex-grow-1 hide-scrollbar">
          {/* radio-btn-group */}
          <div className=" d-flex justify-content-between">
            <div className="radio-btn-bar d-flex gap-3 align-items-center mb-1">
              {types?.map((type, i) => (
                <div
                  className="d-flex align-items-center gap-2 "
                  key={`radio${i}`}
                >
                  <input
                    type="radio"
                    name="type"
                    id={`${type.listing_type}`}
                    className="listing-category"
                    onClick={(e) => radioButton(e, type.id, type.listing_type)}
                    defaultChecked={i === 0}
                  />
                  <label
                    htmlFor={`${type.listing_type}`}
                    className="label-text pointer"
                  >
                    {type.listing_type}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="rent-sale-box">
            <CommonForm
              radiobuttns={radiobuttns}
              propertyListingTypeId={propertyListingTypeId}
              residentialUser={residentialUser}
              commercialUser={commercialUser}
              licenseApprove={licenseApprove}
              noFee={noFee}
              selectedTeamMember={selectedTeamMember}
              setSelectedTeamMember={setSelectedTeamMember}
            />
          </div>
        </div>
        <Advertise
          title="TEAM"
          teamMember={teamMember}
          setSelectedTeamMember={setSelectedTeamMember}
          selectedTeamMember={selectedTeamMember}
        />
      </div>
    </>
  );
};

export default AgentAddProperty;
