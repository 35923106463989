import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";

export async function addsupportticket(formData, success) {

    let token = getToken()
    console.log('running')
    await axios.post(`${backendURL}/api/user/supportticketview/`, formData, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function getSupportticket(success) {
    let token = getToken()
    let userId = token?.userId;
    // let accessToken = token?.accessToken;
    await axios.get(`${backendURL}/api/user/supportticketview/${userId}`, {
        headers: {
            'Authorization': `Bearer ${token?.idToken}`
        }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                console.log(response.data.data);
                success(response.data.data);
            } else {
                success(null)
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function deleteSupportticket(deleteid, success) {
    let token = getToken()
    // let accessToken = token?.accessToken;
    await axios.delete(`${backendURL}/api/user/supportticketview/${deleteid}`,
        { headers: { 'Authorization': `Bearer ${token?.accessToken}` }, })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                console.log(response.data.data);
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function GetIssueType(success) {
    axios.get(`${backendURL}/api/user/AddIssuePriorityType/`)
        .then((response) => {
            if (response.data.code == 200 && response.data.status == "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}