import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";
import { YouTube } from "@mui/icons-material";

export const getProperties = (id, success, error) => {
  let token = getToken();
  axios
    .get(
      `${backendURL}/api/virtual_office/PropertyUserShowVirtualOffice/${token.userId}?Virtual_id=${id}`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export async function postSaveListing(id, success) {
  const token = getToken();
  await axios
    .post(
      `${backendURL}/api/user/guest_user_save_listing/`,
      { property_details_id: String(id) },
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      console.log(err);
    });
}
export async function postFilteredData(FilteredData, success) {
  const token = getToken();
  await axios
    .post(`${backendURL}/api/property/PropertyFilterAPI/`, FilteredData, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((resp) => {
      success(resp);
    })
    .catch((err) => {
      console.log(err);
    });
}

export const interestedProperty = (prop, success, error) => {
  let token = getToken();
  axios
    .post(`${backendURL}/api/virtual_office/PropertyInterestinAPI/`, prop, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const notinterestedProperty = (prop, success, error) => {
  let token = getToken();
  axios
    .post(`${backendURL}/api/virtual_office/PropertyDislikeinAPI/`, prop, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const sendAgentNote = (prop, success, error) => {
  let token = getToken();
  axios
    .post(`${backendURL}/api/virtual_office/NotePropertyVirtualOfiice/`, prop, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const getInterestedPropertiesServer = (virtualid, success, error) => {
  let token = getToken();
  axios
    .get(
      `${backendURL}/api/virtual_office/PropertyInterestinAPI/${virtualid}`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const getnotInterestedPropertiesServer = (virtualid, success, error) => {
  let token = getToken();
  axios
    .get(`${backendURL}/api/virtual_office/PropertyDislikeinAPI/${virtualid}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const getInterestedCustomers = (virtualid, success, error) => {
  let token = getToken();
  axios
    .get(
      `${backendURL}/api/virtual_office/PropertyInterestinAPI/${virtualid}`,
      {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      }
    )
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};
export const getDislikedCustomers = (virtualid, success, error) => {
  let token = getToken();
  axios
    .get(`${backendURL}/api/virtual_office/PropertyDislikedAPI/${virtualid}`, {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const deleteInterested = (id, success, error) => {
  let token = getToken();
  axios
    .get(
      `${backendURL}/api/virtual_office/DeleteVirtualOfficeProperty/${id}`
      // {
      //     headers: { Authorization: `Bearer ${token?.idToken}`, },
      // }
    )
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export const getAgentInfo = (id, success, error) => {
  let token = getToken();
  axios
    .get(
      `${backendURL}/api/virtual_office/GuestAgentProfileView/${id}`
      // {
      //     headers: { Authorization: `Bearer ${token?.idToken}`, },
      // }
    )
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};
