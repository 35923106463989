import React, { useEffect, useState } from "react";
import WedoCard from "./WedoCard";
import "./whatWedo.scss";
import { getCardDetails, getTitleDetails } from "./server";
import { backendURL } from "../../../../../appVariable/variable";
import axios from "axios";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Fade } from "react-awesome-reveal";
// import icons from "./images/WedoIcons";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { getByTitle } from "@testing-library/dom";
// import { Slidersettings } from "../recent-listing/components/CardSlider";
// import { SlickArrowLeft, SlickArrowRight } from "../recent-listing/components/CardSlider";



const WhatWedo = () => {
  const [data, setData] = useState()
  const [cardsData, setCardsData] = useState([])
  // const scrollRef = useRef(null);
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (<ArrowBackIosIcon {...props} className="arrowleft slider-arrow" />);
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (<ArrowForwardIosIcon {...props} className="arrowright slider-arrow" />);
  const Slidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,

    responsive: [
      {
        breakpoint: 1000,
        Slidersettings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 480,
        Slidersettings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]

  };

  // const scroll = (direction) => {
  //   const { current } = scrollRef;
  //   if (direction === "left") {
  //     current.scrollLeft -= 375;
  //   } else { current.scrollLeft += 375; }
  // };

  function getTitle() {
    getTitleDetails((success) => { setData(success) });
  }
  function getCardsData() {
    getCardDetails((success) => {
      let listedCardsData = [];
      listedCardsData = success.map((item, index) => {
        return (
          {
            id: item.id,
            icon: `${backendURL}${item.Icon}`,
            title: item.Card_Title,
            description: item.Card_body
          }
        )
      })
      setCardsData(listedCardsData)
    })
  }
  useEffect(() => {
    getTitle();
    getCardsData()
  }, []);
  
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const ipAddress = response.data.ip;
        const locationResponse = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
        const { latitude, longitude } = locationResponse.data;
      } catch (error) {
        console.error(error);
      }
    };
    fetchLocation();
  }, []);

  return (
    <>
      <div className=" what-wedo-sec span-to-top">
        <div className="what-wedo-sec-inner layout section d-flex flex-column position-relative">
          <div className="what-wedo-heading gap-4 d-flex flex-column ps-3">
            <h1 className=" text-uppercase">{data?.Title}</h1>
            <p className="">{data?.Subtitle}</p>
          </div>
          {/* <div className="card-container-box position-absolute">
            <div
              className="card-container d-flex
                        
                          hide-scrollbar"
              ref={scrollRef}
            >
              {cardsData.map((card, i) => (
                <div>
                  <WedoCard key={`wedoCard${i}`} cardinfo={card} />
                </div>
              ))}
            </div>
            <div
              className="arrow left-arrow pointer center position-absolute"
              onClick={() => scroll("left")}
            >
              {" "}
              <ChevronLeftIcon />
            </div>
            <div
              className="arrow right-arrow pointer center position-absolute"
              onClick={() => scroll("right")}
            >
              {" "}
              <ChevronRightIcon />
            </div>
          </div> */}
          <div className="card-container-box position-absolute">
            <Slider {...Slidersettings}>
              {cardsData.map((card, i) =>
                <Fade direction="up" delay={parseInt(i + "00")} key={`wedo${i}`}>
                  <WedoCard key={`wedoCardd${i}`} cardinfo={card} />
                </Fade>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWedo;
