import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import icons from "../components/sidebar/images/SidebarIcon";
import { getToken, sidebarValue } from "../../../../appVariable/variable";
import { useEffect } from "react";
import { totalListingcount } from "../../expiredListing/server";
import { updateTotalListing } from "../DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
// import CommonState from "../../../../appVariable/CommonState";

const sidebardata = [
  { icon: icons.Dashboard, link: "add-property", title: "dashboard" },
  { icon: icons.current, link: "current-listing", title: "CURRENT LISTING" },
  { icon: icons.expire, link: "expire-listing", title: "EXPIRED LISTING" },
  { icon: icons.message, link: "message", title: "MESSAGE" },
  { icon: icons.boost, link: "boost-marketing", title: "BOOST MARKETING" },
  { icon: icons.office, link: "virtual-office", title: "VIRTUAL OFFICE" },
  { icon: icons.documents, link: "document", title: "Document" },
  { icon: icons.analytics, link: "analytic", title: "ANALYTIC" },
  { icon: icons.Reviews, link: "review", title: "Review" },
  { icon: icons.support, link: "support", title: "Support" },
];

const AgentDashboard = () => {
  //   let userDetails = getToken();
  const dispatch = useDispatch();
  const totalListing = useSelector(
    (state) => state.DashboardSliceInStore.totalListing
  );
  const [sidebar, setsidebar] = useState(true);
  const [theme, settheme] = useState(true);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [updatesidebar, setUpdatesidebar] = useState(true);
  //   const [agentTotaListing, setAgentTotaListing] = useState(
  //     userDetails?.profile?.listing_count
  //   );
  let token = getToken();
  let agentSlug = token?.profile.slug;
  let paramObj = agentSlug;
  //   const [totalListingCounts, setTotalLiastingCounts] = useState([]);
  // let sidebaractive = JSON.parse(localStorage.getItem('sidebar'));
  // totalListingcount(paramObj, (success) => setTotalLiastingCounts(success));

  useEffect(() => {
    totalListingcount(paramObj, (success) => {
      dispatch(updateTotalListing(success.data.data));
      //   setTotalLiastingCounts(success.data.data);
    });
    let sidebarIs = sidebarValue();
    setSidebarStatus(sidebarIs.sidebar);
  }, [updatesidebar, totalListing]);
  // console.log(updatesidebar)

  return (
    <>
      <div className="main-dashboard">
        <div
          className={`dashboard-inner ${theme ? "white-theme" : "blue-theme"}`}
        >
          <Topbar
            sidebar={sidebar}
            setsidebar={setsidebar}
            theme={theme}
            settheme={settheme}
            totalListing={50}
            agentTotaListing={totalListing}
          />
          {/* <CommonState setUpdatesidebar={setUpdatesidebar} updatesidebar={updatesidebar} /> */}
          <div className="dashboard-middle d-flex h-100">
            <Sidebar
              sidebar={sidebar}
              sidebardata={sidebardata}
              pointerEvent={sidebarStatus}
              settingOption={true}
            />
            <Outlet
              context={{
                updatesidebar: updatesidebar,
                setUpdatesidebar: setUpdatesidebar,
                // setAgentTotaListing: setAgentTotaListing,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentDashboard;
