import React, { useState } from "react";
import "../dashboard/dashboard.scss";
import AreYouSurePopup from "../saved-listings/components/AreYouSurePopup";
import "./Support.scss";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
// import upload from "./Images/card-img.png";
// import upload from "./Images/delete.svg";
// import deleteimg from "./Images/delete.svg";
// import uploadInput from "./Images/upload-input.svg";
import * as Yup from "yup";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useFormik } from "formik";
import swal from "sweetalert";
import { getToken, backendURL } from "../../../appVariable/variable";
import { GetIssueType, addsupportticket, deleteSupportticket, getSupportticket } from "./supportsurver";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import moment from "moment";

export default function Support() {
  let currentDate = new Date();
  const [allticket, setAllticket] = useState([])
  const [surepopup, setSurepopup] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const [loading, setLoading] = useState(false)
  const [typeOfIssue, setTypeOfIssue] = useState()
  // const [updatelist, setUpdatelist] = useState(true)
  // const [dataloading, setDataloading] = useState(false)
  // const [confirmdelete, setConfirmdelete] = useState();
  // const serchrow = [1, 1, 1]
  let token = getToken()
  let userId = token?.userId;

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      issueType: "",
      priority: "",
      reportDate: moment(currentDate).format("YYYY-MM-DD"),
      // ticketComment: "",
      ticketImg: null
    },
    validationSchema: Yup.object({
      title: Yup.string().required("*required"),
      description: Yup.string().required("*required"),
      issueType: Yup.string().required("*required"),
      priority: Yup.string().required("*required"),
      ticketImg: Yup.mixed().required("Upload File").nullable(),
      // reportDate: Yup.date().test('four-digits', 'Invalid Date', function (value) {
      //   if (!value) {
      //     return true; // Let Yup handle empty values
      //   }
      //   const year = new Date(value).getFullYear();
      //   return year.toString().length === 4;
      // }).required("*required"),
      // ticketComment: Yup.string().required("*required"),
    }),
    onSubmit: (values, action) => {
      let formData = new FormData();
      formData.append("user", userId);
      formData.append("issue_type", formik.values.issueType);
      formData.append("title", formik.values.title);
      formData.append("description", formik.values.description);
      formData.append("priority", formik.values.priority);
      formData.append("status", 0);
      formData.append("image", formik.values.ticketImg);
      formData.append("reported_date", moment(currentDate).format("YYYY-MM-DD"));
      // formData.append("comments", formik.values.ticketComment);
      setLoading(true)
      addsupportticket(formData, (success) => {
        // setUpdatelist(!updatelist)
        GetAllTicket()
        setModalShow(false)
        setLoading(false)
        action.resetForm();
      });
    },
  });

  function handleUploadTicketImg(e) {
    let fileSize = e.target.files[0].size;
    console.log(fileSize)
    if (fileSize <= 5250000) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        formik.setFieldValue("ticketImg", file);
        console.log("object", file.name)
      };
    } else { swal("", "Image size must be under 5mb", "warning"); }
  }
  let GetAllTicket = () => {
    getSupportticket((success) => {
      setAllticket(success ? success.reverse() : null);
    });
  }

  useEffect(() => {
    GetAllTicket()
    GetIssueType((success) => {
      setTypeOfIssue(success);
    })
  }, []);
  // }, [updatelist]);

  let confirmdelete = async () => {
    await deleteSupportticket(deleteid, (success) => { GetAllTicket() });
    // setUpdatelist(!updatelist)
  }

  return (
    <div className="w-100">
      {loading ? <Loader /> : null}
      <AreYouSurePopup surepopup={surepopup} setSurepopup={setSurepopup} confirmdelete={confirmdelete} />
      <div className="w-100 support_pagE">
        <div className="supportmain">
          <div onClick={setModalShow} className="text-end">
            <button className="support_modal_btn blue__btn">New Tickets</button>
          </div>
          <div className="support_table text-right">
            <div className=" border-0">
              {/* <SupportModal /> */}
            </div>
            <Table className="support_table">
              <thead>
                <tr >
                  <th className="pb-0"> # Ticket No</th>
                  <th className="pb-0">Issue Type</th>
                  <th className="pb-0">Title</th>
                  <th className="pb-0">Description</th>
                  <th className="pb-0">Priority</th>
                  <th className="pb-0 pdf_format" >Upload </th>
                  <th className="pb-0">Status</th>
                </tr>
              </thead>
              <tbody tbody className=" hide-scrollbar">
                {allticket?.map((onerow, i) =>
                  <tr className="table_row border_blue vertical-align-middle" key={`ticket${i}`}>
                    <td>{onerow.ticket_no}</td>
                    <td>{onerow.issue_type}</td>
                    <td>{onerow.title}</td>
                    <td>{onerow.description}</td>
                    <td>{onerow.priority}</td>
                    {/* <td>{onerow.priority == "True" ? "High" : "Low"}</td> */}
                    <td className="pdf_format">
                      <img src={`${backendURL}${onerow.image}`} className="upload_img" alt="" />
                    </td>
                    <td>
                      <div className="d-flex delete_droupdown align-items-center">
                        <div className=" ">{onerow.status == true ? "Complete" : "Pending"}</div>
                        <div className="btn-group dropstart bg-transparent ms-auto">
                          <button type="button" className="dropdown-toggle border-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="false">
                            <MoreVertIcon />
                          </button>
                          <ul className="dropdown-menu tool_border bg-white py-1 tool-aero">
                            <li className="ps-3 delete_text d-flex align-items-center pointer" onClick={() => { setSurepopup(true); setDeleteid(onerow.id) }}>
                              <p>Delete</p></li>
                          </ul>
                        </div>
                      </div>

                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>



        {/*start modal */}
        <div className="forgot_modals">
          <Modal className="forgot_modals" show={modalShow} size="lg" centered
            ria-labelledby="contained-modal-title-vcenter" onHide={() => setModalShow(false)}>
            <Modal.Header closeButton className="border-bottom-0 supportmodal_header gdgyg" >
              <Modal.Title id="contained-modal-title-vcenter " className="border-0" ><p>Add Issue</p></Modal.Title>
            </Modal.Header>
            <Modal.Body className="supportmodal_body ">
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">Title<span className="red-star">*</span></div>
                <div className="input-sec">
                  <input type="text" className="form-control border-0 pb-0"
                    name="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                  />
                  {formik.errors.title && formik.touched.title ? (<div className='validation-text'>{formik.errors.title}</div>) : null}
                </div>
              </div>
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  Description<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input type="text" className="form-control border-0 pb-0"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                  {formik.errors.description && formik.touched.description ? (<div className='validation-text'>{formik.errors.description}</div>) : null}
                </div>
              </div>
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  Issue Types<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  {/* <input type="text" className="form-control border-0 pb-0"
                    name="issueType"
                    onChange={formik.handleChange}
                    value={formik.values.issueType}
                  /> */}
                  <select name="issueType" aria-label="Default select example" className=" w-100"
                    onChange={(e) => { formik.setFieldValue("issueType", e.target.value); }}
                  >
                    <option className=" d-none">Select Issue Type</option>
                    {typeOfIssue?.Issues?.map((issue) =>
                      <option key={issue.issue_type} value={issue.issue_type}>{issue.issue_type}</option>
                    )}
                  </select>
                  {formik.errors.issueType && formik.touched.issueType ? (<div className='validation-text'>{formik.errors.issueType}</div>) : null}
                </div>
              </div>
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  Priority<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <select aria-label="Default select example" name="priority" className=" w-100"
                    onChange={(e) => { formik.setFieldValue("priority", e.target.value); }} >
                    <option className=" d-none">Set priority</option>
                    {typeOfIssue?.Priority?.map((prioty) =>
                      <option key={prioty.priority} value={prioty.priority}>{prioty.priority}</option>
                    )}
                  </select>
                  {formik.errors.priority ? (<div className='validation-text'>{formik.errors.priority}</div>) : null}
                </div>
              </div>

              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  Files<span className="red-star">*</span>
                </div>
                <div className="input-sec  input_on_file position-relative">
                  <label htmlFor="input_img" className="pointer w-100">
                    <p className=" ps-2">{formik.values.ticketImg ? formik.values.ticketImg.name : "Upload Image"}</p>
                  </label>
                  <input type="file" className="form-control  opacity-0 border-0 pb-0 d-none" id="input_img"
                    onChange={(e) => { handleUploadTicketImg(e); }} accept="image/*" />
                  {formik.errors.ticketImg && formik.touched.ticketImg ? (<div className='validation-text'>{formik.errors.ticketImg}</div>) : null}
                </div>
              </div>
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">Reported Date<span className="red-star">*</span></div>
                <div className="input-sec"><p className=" ps-2">{moment(currentDate).format("MM-DD-YYYY")}</p></div>
              </div>

              {/* <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  Comments<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input type="email" className="form-control border-0 pb-0"
                    name="ticketComment"
                    onChange={formik.handleChange}
                    value={formik.values.ticketComment}
                  />
                  {formik.errors.ticketComment && formik.touched.ticketComment ? (<div className='validation-text'>{formik.errors.ticketComment}</div>) : null}
                </div>
              </div> */}

              <div className="text-end mb-3">
                <button type="submit" className="submit_btn blue__btn"
                  onClick={(e) => { formik.handleSubmit(); }}>Create Issue </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        {/* end modal */}
      </div>
    </div >
  );
}
