import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./main.scss";
import filter from "./images/filter.svg";
import search from "./images/search.svg";
import Button from "react-bootstrap/Button";
import PropertyCard from "./PropertyCard";
import PopUpFilter from "../filter-popup/PopUpFilter";
import {
  getAllpropertyInfo,
  saveDefaultPropertyShow,
  MessageForAgent,
  // getDefaultPropertyShow,getAgentTime, getDefaultPropertyAmenityDetailPopup, getDefaultPropertyDetailPopup, getDefaultPropertyDetailPopupWithOutLogin, getDefaultPropertyImagesDetailPopup, getDefaultPropertySalesFeatures, getDefaultPropertySpacesDetailPopup, getSeenPropertyPopup, getTeamMemberShow, removeDefaultPropertyShow,
} from "./server";
import PropertyPopupExclusive from "./PropertyPopupExclusive";
import { getToken } from "../../../../../appVariable/variable";
import {
  //  getTypeOfProperties, saveSearch,
  ApplySearch,
  getLocations,
  getCategories,
  getTypesBasedOnCategory,
  getTypeOfProperties,
} from "../../../homepage/components/hero/server";
import { getSaveSearchFilterPropertyDetail } from "../../../saveSearch/Server";
import { useDispatch, useSelector } from "react-redux";
import PropertySavePopUp from "./PropertySavePopUp";
import SaveSearchModel from "../filter-popup/SaveSearchModel";
import { Autocomplete, TextField } from "@mui/material";
import PropertyDetailsPopUp from "../../propperty-datails/PropertyDetailsPopUp";
import { updatePropertyDatail } from "../../propperty-datails/PropertySlice";
import { updateFilterData } from "../../../homepage/components/hero/FilterSlice";
import { toast } from "react-toastify";
import SelectGeustAccType from "../select-guestAcc-type/SelectGeustAccType";
import { getAccounttype } from "../../../personal/typeOfAccount/server";
import Loader from "../../../Loader/Loader";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  ".MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
  "span.MuiSwitch-track .css-1yjjitx-MuiSwitch-track": {
    opacity: "1",
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
// import { async } from "q";
// const loaderRef = useRef(null);
const Main = (props) => {
  // const [exclusivePop, setExclusivePop] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const token = getToken();
  const userId = token?.userId;
  const [showFilter, setShowFilter] = useState(false);
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const saveSearchPopupVisible = useSelector(
    (state) => state.search.saveSearchPopupVisible
  );
  const getCategoriesVal = useSelector((state) => state.search.category);
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [allProperties, setAllProperties] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSetAccPopup, setshowSetAccPopup] = useState(false);

  // storing responce for chckbox to show
  const [buyRentFilter, setbuyRentFilter] = useState([]);
  const [checkBoxActiveFiletr, setCheckBoxActiveFiletr] = useState({});

  // this state store value of perticuler property to show in property detail popup
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const [loading, setloading] = useState(false);
  // remove unsave listinh btn state
  const [removeUnsaveBtn, setRemoveUnsaveBtn] = useState(false);
  const params = useParams();
  const [selectAllMsg, setSelectAllMsg] = useState(false);
  const [messageForAdmin, setMessageForAdmin] = useState("");

  let ListofProperties = async () => {
    let FilterSearchSchema = JSON.parse(
      localStorage.getItem("FilterSearchSchema")
    );
    const blockedKeys = [
      "locationText",
      "Filter_responce",
      "filter_name",
      "Neighborhood",
    ];
    const searchSchema = { ...FilterSearchSchema };
    blockedKeys.forEach((key) => delete searchSchema[key]);

    if (params.id) {
      // if user login and come from save listing page then this function run
      await fetchDataSaveSearch();
      console.log(
        "objectdata",
        "if user login and come from save listing page then this function run"
      );
    } else {
      if (userId) {
        if (FilterSearchSchema === null) {
          // if user login and come directly on dashboard without filter show data by 1st type & 1st category
          console.log(
            "objectdata",
            "if user login and come directly on dashboard without filter show data 1st type & 1st category"
          );

          fetchDefaultPropertyData();
        } else {
          console.log("++++++++++");
          ApplySearch(searchSchema, (success) => {
            console.log("success::", success);
            dispatch(
              updateFilterData({
                Filter_responce: success.property,
                Neighborhood: success.Neighborhood,
              })
            );
            setAllProperties(success.property);
            dispatch(updateFilterData(FilterSearchSchema));
          });
          CheckFilterValue(searchSchema.property_listing);
        }
      } else {
        // if user not login come on this page show data based on filter data
        console.log("----------");
        ApplySearch(searchSchema, (success) => {
          console.log("success::", success);
          dispatch(
            updateFilterData({
              Filter_responce: success.property,
              Neighborhood: success.Neighborhood,
            })
          );
          setAllProperties(success.property);
        });
        CheckFilterValue(searchSchema?.property_listing);
        console.log(
          "objectdata",
          "if user not login come on this page show data based on filter data"
        );
      }
    }
  };

  async function getPropertiesType() {
    // dispatch(updateFilterData({ area: null, city: null, state: null, country: null, type: null, pricemin: null, pricemax: null, }))
    // let intialusertype
    let typeOfuser = token?.user_info?.usertypeobj;
    let type_of_listing = token?.user_info?.type_of_listing;
    if (type_of_listing === null && typeOfuser == 1) {
      setshowSetAccPopup(true);
    }

    // await getTypeOfProperties((success) => {
    //   // setTypeOfProperties(success);
    //   // setIsactiveType(success[0].id);
    //   // getCategory(success[0].id)
    //   console.log("object", success)
    //   intialusertype = success
    // });

    // getAccounttype((success) => {
    //   // goes inside if user  login
    //   let typeOfuser = userlogin?.user_info?.usertypeobj;
    //   let type_of_listing = userlogin?.user_info?.type_of_listing;
    //   if (type_of_listing === null && typeOfuser == 1) {
    //     console.log("object 2 ")
    //     // sethow true open popup to when user come forst time after login to select the property type
    //     // setShow(true);
    //   }
    //   // setAccounttype(success.type_allowed);
    //   // getCategory(intialusertype[success.type_allowed].id)
    // })
  }

  useEffect(() => {
    setloading(true);
    ListofProperties();
    getPropertiesType();
  }, []);
  useEffect(() => {
    setloading(true);
    let timerOut = setTimeout(() => {
      getLocations(searchQuery, (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master?.city_name +
              ", " +
              item.city_master.state_master?.state_name +
              "," +
              item.city_master.state_master.country_master?.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item?.city_name +
              "," +
              item?.state_master?.state_name +
              "," +
              item?.state_master.country_master.country_name
            }`,
            value: {
              city_id: item?.id,
              state_id: item?.state_master.id,
              country_id: item?.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item?.state_name + "," + item?.country_master.country_name
            }`,
            value: {
              state_id: item?.id,
              country_id: item?.country_master.id,
            },
          };
        });
        // setOptionsList([...newarr, ...newarr1, ...newarr2]);
        setOptionsList([...newarr]);
      });
      setloading(false);
    }, 1000);

    return () => clearTimeout(timerOut);
  }, [searchQuery]);

  const fetchDefaultPropertyData = async () => {
    let tempObj = { ...FilterDataInStore };
    let property_type = token?.user_info?.usertypeobj;
    CheckFilterValue(property_type);
    tempObj["user_id"] = userId;
    tempObj["property_listing"] = property_type;
    dispatch(
      updateFilterData({ property_listing: property_type, user_id: userId })
    );
    await new Promise((resolve, reject) => {
      getCategories(
        property_type,
        (success) => {
          tempObj["category"] = success[0]?.id;
          dispatch(updateFilterData({ category: success[0]?.id }));
          resolve(success);
        },
        reject
      );
    });

    await new Promise((resolve, reject) => {
      getTypesBasedOnCategory(
        tempObj["category"],
        (success) => {
          tempObj["type"] = success[0]?.id;
          dispatch(updateFilterData({ type: success[0]?.id }));
          resolve(success);
        },
        reject
      );
    });

    if (tempObj.property_listing && tempObj.category && tempObj.type) {
      // dispatch(updateFilterData(tempObj))
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...tempObj };
      console.log("tempObj", tempObj);
      blockedKeys.forEach((key) => delete searchSchema[key]);
      console.log("searchSchema", searchSchema);
      ApplySearch(searchSchema, (success) => {
        console.log("success", success);
        dispatch(
          updateFilterData({
            Filter_responce: success.property,
            Neighborhood: success.Neighborhood,
          })
        );
      });
    }
  };

  // allsave listing handler
  const handelAllSaveListingProperty = () => {
    if (userId) {
      let filteredArray = [...FilterDataInStore.Filter_responce];
      let savepayload = {
        user_id: userId,
        notes: null,
        rating: 0,
        property_details_id: filteredArray.map((property) => property.id),
      };
      saveDefaultPropertyShow(savepayload, (success) => {
        if (success.code === 200) {
          //  ListofProperties()
          const updatedData = FilterDataInStore.Filter_responce.map(
            (property) => {
              return { ...property, guest_users_save_listing: true };
            }
          );
          dispatch(updateFilterData({ Filter_responce: updatedData }));
        }
      });
    } else {
      alert("please login");
    }
  };

  let RemoveUnsave = async () => {
    if (userId) {
      if (removeUnsaveBtn) {
        dispatch(updateFilterData({ Filter_responce: allProperties }));
        setRemoveUnsaveBtn((pre) => !pre);
      } else {
        setRemoveUnsaveBtn((pre) => !pre);
        let allProperty = [...FilterDataInStore.Filter_responce];
        setAllProperties(allProperty);
        const filteredData = allProperty.filter(
          (obj) => obj.guest_users_save_listing === true
        );
        dispatch(updateFilterData({ Filter_responce: filteredData }));
      }
    } else {
      alert("please login");
    }
  };

  const fetchDataSaveSearch = () => {
    let id = params.id;
    CheckFilterValue(token?.user_info?.usertypeobj);
    getSaveSearchFilterPropertyDetail(id, (success) => {
      if (success.data.code === 400 || success.data.status === "failed") {
        dispatch(updateFilterData({ Filter_responce: [] }));
        setAllProperties([]);
      } else if (success.data.code === 200) {
        dispatch(
          updateFilterData({ Filter_responce: success.data.data.property })
        );
        setAllProperties(success.data.data.property);
      }
    });
  };

  // when click on proprty card this func run getting all data about property and pass to property detail pop-up
  let propertDetails = async (id) => {
    setloading(true);
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    await getAllpropertyInfo(id, (success) => {
      dispatch(updatePropertyDatail({ PropertyDetailInfo: success }));
      setPropertyDetailInfo(success);
      setPropertyDetailInfo((state) => {
        return state;
      });
      setloading(false);
    });
  };

  // infinity scroll
  // const handelInfiniteScroll = async () => {
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       setLoading(true);
  //       currentPage((prev) => prev + 1);
  //     }
  //   } catch (error) {
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handelInfiniteScroll);
  //   return () => window.removeEventListener("scroll", handelInfiniteScroll);
  // }, []);
  // infinity scroll

  // const getSearchData = () => {
  //   // setGuestDefaultPropertyShow(searchGet?.data?.property)
  //   setAllProperties(searchGet?.data?.property)
  // }

  // const getSearchDataLogin = () => {
  //   // setGuestDefaultPropertyShow(searchGetWithLogin?.data?.property)
  //   setAllProperties(searchGetWithLogin?.data?.property)
  // }

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     entries => {
  //       if (entries[0].isIntersecting) {
  //         setCurrentPage(prevPageNumber =>
  //           prevPageNumber === maxPageNumber ? 1 : prevPageNumber + 1
  //         );
  //       }
  //     },
  //     { root: containerRef.current, rootMargin: '0px', threshold: 1.0 }
  //   );

  //   if (containerRef.current) {
  //     observer.observe(containerRef.current);
  //   }

  //   return () => {
  //     if (containerRef.current) {
  //       observer.unobserve(containerRef.current);
  //     }
  //   };
  // }, [maxPageNumber]);

  // const showRemoveButton = (savlistingId, propertiesDetailId) => {
  //   propertiesDetailId.forEach((e1) =>
  //     savlistingId.forEach((e2) => {
  //       if (e1 === e2) {
  //         setDefaultRemove(true)
  //       } else {
  //         setDefaultRemove(false)
  //       }
  //     })
  //   );
  // };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    let filteredArray = [...FilterDataInStore.Filter_responce];
    if (value === "lowToHigh") {
      filteredArray.sort(
        (a, b) => a.property_listing_amount - b.property_listing_amount
      );
    } else if (value === "highToLow") {
      filteredArray.sort(
        (a, b) => b.property_listing_amount - a.property_listing_amount
      );
    }
    dispatch(updateFilterData({ Filter_responce: filteredArray }));
  };

  const CheckFilterValue = (id) => {
    getCategories(id, (success) => {
      setbuyRentFilter(success);
    });
  };

  const handleCheckFilter = (event) => {
    const { checked, value } = event.target;
    let chechboxvalue = checkBoxActiveFiletr;
    if (checked) {
      // if checkbox check add value in array
      const newState = { ...chechboxvalue, [value]: checked };
      chechboxvalue = newState;
    } else {
      // if checkbox uncheck remove value in array
      const newState = { ...chechboxvalue, [value]: checked };
      delete newState[value];
      chechboxvalue = newState;
    }
    // update array of selected check box
    setCheckBoxActiveFiletr(chechboxvalue);
    const checkedTypes = Object.keys(chechboxvalue);
    if (checkedTypes.length === 0) {
      // if No checkboxes are checked, show all
      dispatch(updateFilterData({ Filter_responce: allProperties }));
    } else {
      // if checkboxes are checked, show filter  properties
      let properties = allProperties;
      let filterProperty = properties.filter((property) =>
        checkedTypes.includes(property?.propertylisting_type?.user_listing_type)
      );
      dispatch(updateFilterData({ Filter_responce: filterProperty }));
    }
  };

  const SendSmgToagent = () => {
    if (selectAllMsg) {
      if (messageForAdmin) {
        let filteredArray = [...FilterDataInStore.Filter_responce];
        let obj = {
          Agent_id: filteredArray.map((property) => property.user_profile?.id),
          user_id: 2,
          message: messageForAdmin,
          property_id: filteredArray.map((property) => property.id),
        };
        MessageForAgent(obj, (success) => {
          if (success.code === 200) {
            toast.success(success.data);
            setMessageForAdmin("");
            setSelectAllMsg(false);
          }
        });
      } else {
        toast.warning("Please Enter Mesaage for Broker");
      }
    } else {
      toast.warning("Please Check Select All box");
    }
  };

  // location option

  // location onChange
  const locationOnChange = (e) => {
    dispatch(
      updateFilterData({
        locationText: e?.label,
        area: e?.value?.area_id ? e?.value?.area_id : null,
        city: e?.value?.city_id,
        state: e?.value?.state_id,
        country: e?.value?.country_id,
      })
    );
  };

  let SearchByLoaction = () => {
    if (
      FilterDataInStore.property_listing &&
      FilterDataInStore.category &&
      FilterDataInStore.city &&
      FilterDataInStore.state
    ) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...FilterDataInStore };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      ApplySearch(searchSchema, (success) => {
        dispatch(
          updateFilterData({
            Filter_responce: success.property,
            Neighborhood: success.Neighborhood,
          })
        );
        if (success?.page === 2) {
          localStorage.setItem(
            "FilterSearchSchema",
            JSON.stringify({
              ...searchSchema,
              locationText: FilterDataInStore.locationText,
            })
          );
          navigate(`/dashboard/property-details`);
        }
      });
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div
        className="main-sec d-flex flex-column flex-grow-1"
        style={{ position: "relative" }}
      >
        {/* main-top-section */}
        <div className="filter-sec d-flex justify-content-between gap-1">
          {/* chech box */}
          <div className="d-flex flex-row gap-3">
            <div className="buy-rent gap-3 d-flex align-items-center">
              {buyRentFilter?.map((type, i) => (
                <div className="d-flex" key={`filetr${i}`}>
                  <input
                    type="checkbox"
                    name="PropType"
                    className="Prop pointer"
                    id={type.user_listing_type}
                    value={type.user_listing_type}
                    onChange={handleCheckFilter}
                  />
                  <label htmlFor={type.user_listing_type} className="pointer">
                    {type.user_listing_type}
                  </label>
                </div>
              ))}
            </div>

            {/* Search box */}
            <div className="search-box d-flex flex-grow-1 align-items-center">
              <div className="search d-flex">
                {}
                <Autocomplete
                  className="hide-downBtn"
                  id="combo-box-demo"
                  disablePortal
                  options={optionsList}
                  value={getCategoriesVal[0]?.location?.label}
                  onChange={(e, value) => {
                    locationOnChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  style={{ minWidth: "250px", width: "100%", zIndex: 9999 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  )}
                />
                <div
                  className="search-icon pointer"
                  onClick={() => SearchByLoaction()}
                >
                  {" "}
                  <img src={search} alt="" />
                </div>
              </div>
              <div
                className="filter-icon pointer d-flex align-items-center justify-content-center"
                onClick={() => setShowFilter(true)}
              >
                {" "}
                <img src={filter} alt="" />
              </div>
            </div>
          </div>

          {/* quik-view map-view */}
          <div className="quik-map center gap-1">
            <p>Quick View</p>
            <div className="form-check-switch pt-0">
              <PinkSwitch
                {...label}
                onChange={(e) => {
                  props.setViewsetting(e.target.checked);
                }}
                checked={props.viewsetting}
              />
            </div>
            <p>Map View</p>
          </div>
        </div>

        {/* button row */}
        <div className="button-row  d-flex justify-content-between">
          <div className="button-row-left d-flex ">
            <Button
              variant="outline-primary"
              className={`box-shodow text-capitalize ${
                removeUnsaveBtn ? "active" : ""
              }`}
              onClick={() => RemoveUnsave()}
            >
              {removeUnsaveBtn ? "Show All Listing" : "Remove Unsaved Listing"}
            </Button>
            <Button
              variant="outline-primary"
              className="box-shodow "
              onClick={handelAllSaveListingProperty}
            >
              Save All Listing
            </Button>
          </div>
          <div className="sort-by center gap-2">
            <p className=" text-nowrap">Sort by :</p>
            <select
              aria-label="Default select example"
              className="box-shodow "
              onChange={handleDropdownChange}
            >
              <option className=" d-none">Price</option>
              <option value="lowToHigh">Price: Low to High</option>
              <option value="highToLow">Price: High to Low</option>
            </select>
          </div>
        </div>

        <div className=" d-flex flex-column gap-2 ">
          {/* feed section */}
          <div
            className="property-feed"
            ref={containerRef}
            style={{
              maxHeight: "50vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {FilterDataInStore &&
              FilterDataInStore.Filter_responce?.map((city, id) => (
                <PropertyCard
                  key={`mainpropertyd${id}`}
                  agentListingData={city}
                  handelOpenPopUp={propertDetails}
                />
              ))}
          </div>

          {/* message box */}
          <div className="message-sec buy-rent d-flex flex-column gap-2">
            <div
              style={{ position: "absolute", width: "100%", bottom: "20px" }}
            >
              <div className="d-flex " style={{ marginBottom: "10px" }}>
                <input
                  type="checkbox"
                  name="PropType"
                  className="Prop"
                  id="Select"
                  // value="Select"
                  onChange={(e) => {
                    setSelectAllMsg(e.target.checked);
                  }}
                  checked={selectAllMsg}
                />
                <label htmlFor="Select" className="pointer">
                  {" "}
                  Select All{" "}
                </label>
              </div>
              <div className="message-box d-flex justify-content-between ">
                <input
                  type="text"
                  className="trans-input"
                  name=""
                  id=""
                  placeholder="Add your message"
                  onChange={(e) => setMessageForAdmin(e.target.value)}
                  value={messageForAdmin}
                />
                <Button variant="primary" onClick={() => SendSmgToagent()}>
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* property detail pop up  */}
      {propertyDetailInStore.setshowPropertyPopup &&
        !loading &&
        propertyDetailInfo?.propertDetails?.property_address_1 !==
          undefined && (
          <PropertyDetailsPopUp
            setPropertyDetailInfo={setPropertyDetailInfo}
            propertyDetailInfo={propertyDetailInfo} // passing info about
            showTravelview={true}
          />
        )}

      {/* property save pop up  */}
      {propertyDetailInStore.setSavePropertyPopup && <PropertySavePopUp />}

      {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}

      {/* <SaveSearchpopup /> */}
      {saveSearchPopupVisible && <SaveSearchModel />}

      {/* when guest user come first time then this popup show to select his acount type */}
      {showSetAccPopup && (
        <SelectGeustAccType setshowSetAccPopup={setshowSetAccPopup} />
      )}
    </>
  );
};

//hello ankush
export default Main;
