import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";


export function AgentRating(tempObj, success) {
    let token = getToken();
    axios.post(`${backendURL}/api/property/Rating_Review/${token.userId}`, tempObj, {
        headers: {
            Authorization: `Bearer ${token?.idToken}`,
        },
    })
        .then((response) => {
            success(response)
        })
        .catch((error) => {
            // console.log(error);
        });
}

export function GetAgentRating(success) {
    let token = getToken()
    axios.get(`${backendURL}/api/property/Rating_Review/${token.userId}`, { headers: { Authorization: `Bearer ${token?.idToken}`, }, })
        .then((response) => { success(response) })
        .catch((error) => {
            // console.log(error);
        });
}


export function GetMemebersForReview(success) {
    let token = getToken()
    let userId = token?.userId;
    let EndPointAsperUser = token?.user_info?.usertypeobj == 1 ? "GuestReviewAgentProfile" : "AgentReviewGuestProfile"
    axios.get(`${backendURL}/api/virtual_office/${EndPointAsperUser}/${userId}`, { headers: { Authorization: `Bearer ${token?.idToken}`, }, })
        .then((response) => { success(response) })
        .catch((error) => {
            // console.log(error);
        });
}