// import { Autocomplete, TextField } from "@mui/material";
// import Select from "react-select";
// import EditIcon from '@mui/icons-material/Edit';
// import PetModal from "./components/PetModal";
// import MinModal from "./components/MinModal";
// import { getFullAddress } from "./server";
// import { getAllzipcode } from "../personal/personalInformation/Server";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import {
  AddProperty, // GetAreaList, GetCityList, getStateList,
  getTearmsOffers,
  getagentaminities,
} from "./components/server";
import { getToken } from "../../../appVariable/variable";
import ImageModal from "./components/ImageModal";
import moment from "moment";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate, useOutletContext } from "react-router-dom";
import { blockKeywords } from "../profile/components/personal/Personal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustumeFileInput from "./components/custumeFileInput/CustumeFileInput";
import OPenHouseTimeModal from "./components/open-house-time/OPenHouseTimeModal";
import AddressFeild from "./components/Address-feild/AddressFeild";
import calendar from "./image/calenderIcon.svg";
import { useDispatch } from "react-redux";
import { updateTotalListing } from "../dashboard/DashboardSlice";
let btnGroup = [
  { name: "Bedrooms", title: "Bedrooms" },
  { name: "Bathrooms", title: "Bathrooms" },
  { name: "SquareSqft", title: "Square Sqft" },
  { name: "ExteriorSqft", title: "Exterior Sqft" },
  { name: "MaintenanceFee", title: "Maintenance Fee" },
  { name: "RealEstateTax", title: "RealEstate Tax" },
  { name: "Financing", title: "Financing" },
  { name: "MinimumDown", title: "Minimum Down" },
];
let commertialInput = [
  { name: "Units", title: "Units" },
  { name: "Rooms", title: "Rooms" },
  { name: "BathRooms", title: "Bath Rooms" },
  { name: "Block", title: "Block" },
  { name: "Lot", title: "Lot" },
  { name: "Zone", title: "Zone" },
  { name: "SquareFt", title: "Square Ft" },
  { name: "BuildingSqft", title: "Building Sqft" },
  { name: "LotDimensions", title: "Lot Dimensions" },
  { name: "BuildingDimensions", title: "Building Dimensions" },
  { name: "Stories", title: "Stories" },
  { name: "FAR", title: "FAR" },
  { name: "Assessment", title: "Assessment" },
  { name: "AnnualTaxes", title: "Annual Taxes" },
  { name: "AvailableAirRights", title: "Available Air Rights" },
];

export const handleAmountChange = (value) => {
  //remove character from value
  let amount = value.replace(/[^0-9.,]/g, "");
  // Remove non-digit characters  and comma from the input
  const cleanedValue = amount.toString().replace(/,/g, "");
  // Split the value into whole and decimal parts
  const [wholePart, decimalPart] = cleanedValue.split(".");
  const decimalPt = /\./.test(cleanedValue);
  // Format the whole part by adding commas
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Combine the whole and decimal parts
  let formattedValue = formattedWholePart;
  if (decimalPt) {
    if (decimalPart) {
      formattedValue += "." + decimalPart.slice(0, 2);
    } else {
      formattedValue += ".";
    }
  }
  // Update the state with the formatted value
  return formattedValue;
};

const CommonForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = getToken();
  let userId = token?.userId;
  const petchck = useRef();
  const mainImage = useRef();
  const floreImage = useRef();
  const zipSelect = useRef();
  const [imgfile, setImgfile] = useState(0);
  const [images, setImages] = useState([]);
  const [imagemodalpopup, setImagemodalpopup] = useState(false);

  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const path = url.pathname;
  const segments = path.split("/"); // Split the path by slashes
  let currentUser = "";
  if (segments.length > 1) {
    currentUser = segments[1];
  }

  // const listingdataref = useRef();
  const listDate = useRef();
  // const listingEndDate = useRef();
  // const userInputNumber = useRef();
  // const [startDate, setStartDate] = useState("");
  // const [pdffile, setPdffile] = useState(0);
  // const [address, setAddress] = useState("");
  // const [areaId, setAreaId] = useState(null)
  // const [cityId, setCityId] = useState(null)
  // const [stateId, setStateId] = useState(null)
  // const [countryId, setCountryId] = useState(null)
  // const [addressOptions, setAddressOptions] = useState([]);
  // const [displayAutocomplete, setDisplayAutocomplete] = useState(true);
  // const [allZipcodes, setAllZipcodes] = useState();
  // const [zipcodeid, setZipcodeid] = useState();
  // const [allStateList, setAllStateList] = useState([])
  // const [allCityList, setAllCityList] = useState([])
  // const [allAreaList, setAllAreaList] = useState([])
  // const [fileimages, setFileimages] = useState([]);
  // const [imageURLS, setImageURLs] = useState([]);
  // const [fileResponses, setFileResponses] = useState([]);
  // const [prppertylistingDate, setPrppertylistingDate] = useState("")
  // const [petModalValue, setPetModalValue] = useState(false)
  // const [petModalResponce, setPetModalResponce] = useState([])
  // const [minModalResponce, setMinModalResponce] = useState(null)
  // const [minModalValue, setMinModalValue] = useState(false)
  const [allaminities, setAllaminities] = useState([]);
  const [termsOffers, setTermsOffers] = useState({});
  const [nofeeBlock, setNofeeBlock] = useState(true);
  let [loading, setLoading] = useState(false);
  const [leisureSelect, setLeisureSelect] = useState(false);
  const [commertialInputes, setCommertialInputes] = useState(1);
  const [propertyAmount, setPropertyAmount] = useState();
  const [openHouseModal, setOpenHouseModal] = useState(false);
  const [openHouseData, setOpenHouseData] = useState({
    sunday_open_house: [],
    monday_open_house: [],
    tuesday_open_house: [],
    wednesday_open_house: [],
    thursday_open_house: [],
    friday_open_house: [],
    saturday_open_house: [],
  });
  const context = useOutletContext();

  const fileselect = (event, position) => {
    if (position === "img") {
      setImgfile(event.target.files.length);
      setImages(Object.values(event.target.files));
    } else {
      // setPdffile(event.target.files.length);
    }
  };

  // useEffect(() => {
  //     let timerOut = setTimeout(() => {
  //         getFullAddress(address, (success) => {
  //             let newArr1 = success[0]?.map((item) => {
  //                 return {
  //                     label: `${item?.area_name}, ${item?.city_master.city_name}, ${item?.city_master.state_master.state_name}`,
  //                     area: item?.area_name,
  //                     area_id: item?.id,
  //                     city: item?.city_master.city_name,
  //                     city_id: item?.city_master.id,
  //                     state: item?.city_master.state_master.state_name,
  //                     state_id: item?.city_master.state_master.id,
  //                     country: item?.city_master.state_master.country_master.country_name,
  //                     country_id: item?.city_master.state_master.country_master.id,
  //                 };
  //             });
  //             //for-city
  //             let newArr2 = success[1]?.map((item) => {
  //                 return {
  //                     label: `${item?.city_name}, ${item?.state_master.state_name} `,
  //                     area: "- ",
  //                     area_id: null,
  //                     city: item?.city_name,
  //                     city_id: item?.id,
  //                     state: item?.state_master.state_name,
  //                     state_id: item?.state_master.id,
  //                 };
  //             });
  //             setAddressOptions([...newArr1, ...newArr2]);
  //         });
  //     }, 1000);
  //     return () => clearTimeout(timerOut);
  // }, [address]);

  useEffect(() => {
    setLoading(true);
    getagentaminities((success) => {
      setAllaminities(success);
    });
    getTearmsOffers((success) => {
      setTermsOffers(success);
      setLoading(false);
    });
    // getAllzipcode((success) => {
    //     let newarr = success.map((item) => { return { label: `${item.Zipcode}`, id: item.id, }; });
    //     setAllZipcodes([...newarr]);
    // })
    // getStateList((success) => {
    //     let newarr = success.map((item) => { return { label: `${item.state_name}`, id: item.id, }; });
    //     setAllStateList([...newarr]);
    // })
  }, []);

  const handleChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("amenities", [...formik.values.amenities, name]);
    } else {
      formik.setFieldValue(
        "amenities",
        formik.values.amenities.filter((v) => v !== name)
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      address: "",
      appartment: "",
      zipcodeid: "",
      zipcode: "",
      areaId: null,
      cityId: null,
      stateId: null,
      countryId: null,
      // areaId: areaId,
      // cityId: cityId,
      // stateId: stateId,
      // countryId: countryId,
      term: "",
      offer: "",
      listingDate: "",
      endDate: "",
      listingType: "",
      noFees: 0,
      fee: "",
      BuyerAgency: "",
      SellerAgency: "",
      petNotSure: "",
      Open_House: 0,
      petFriendly: 0,
      // petFriendly: "",
      min_30_shows: 0,
      // input type file
      propertyImage: [],
      propertymainimage: null,
      propertymainfloarplan: null,
      propertyPdf: [],
      mainCategory: "",
      // input field
      Bedrooms: 0,
      Bathrooms: 0,
      SquareSqft: 0,
      ExteriorSqft: 0,
      MaintenanceFee: 0,
      RealEstateTax: 0,
      Financing: 0,
      MinimumDown: 0,
      // input field end
      // commercial inputs
      Units: 0,
      Rooms: 0,
      BathRooms: 0,
      Block: 0,
      Lot: 0,
      Zone: 0,
      SquareFt: 0,
      BuildingSqft: 0,
      LotDimensions: 0,
      BuildingDimensions: 0,
      Stories: 0,
      FAR: 0,
      Assessment: 0,
      AnnualTaxes: 0,
      AvailableAirRights: 0,
      // commercial inputs end

      // commertial other inputs
      otherCommertialInputs: [
        { space: "", size: "", term: "", rate: "", type: "" },
      ],

      amenities: [],
      Title: "",
      message: "",
      listingAmount: "",
      listDate: "",
      listTime: "",
      propertysubcategoryid: 0,
    },
    validationSchema: Yup.object({
      listingType: Yup.string().required("*required"),
      Title: Yup.string().required("*required"),
      // if type is residential then bedroom bathroom requiire
      Bedrooms:
        props.residentialUser &&
        Yup.number()
          .min(0, "Bedrooms not be negative")
          .required("Bedrooms Required"),
      Bathrooms:
        props.residentialUser &&
        Yup.number()
          .min(0, "Bathrooms not be negative")
          .required("Bathrooms Required"),

      // if type is Commertial then Units Rooms requiire
      Units:
        !props.residentialUser &&
        Yup.number().min(0, "Units not be negative").required("Units Required"),
      Rooms:
        !props.residentialUser &&
        Yup.number().min(0, "Rooms not be negative").required("Rooms Required"),

      propertymainimage: Yup.mixed().required("Upload Image").nullable(),
      // propertymainfloarplan: Yup.mixed().required("Upload File").nullable(),
      listingAmount: Yup.string().required("required"),
      mainCategory: Yup.string().required("*required"),
      listingDate: Yup.date()
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        })
        .required("*required"),
      endDate: Yup.date()
        .min(Yup.ref("listingDate"), "End date can't be before Start date")
        .test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        }),
      // .required("*required"),

      // endDate: !(formik.values.listingType === "open") && Yup.date().required("*required"),

      listDate:
        props.residentialUser &&
        Yup.date().test("four-digits", "Invalid Date", function (value) {
          if (!value) {
            return true; // Let Yup handle empty values
          }
          const year = new Date(value).getFullYear();
          return year.toString().length === 4;
        }),
      // .required("*required"),

      address: Yup.string().required("*required"),
      // listTime: props.residentialUser && Yup.string().required("*required"),
      // areaId: Yup.string().required("*required"),
      zipcode: Yup.string().required("*required"),
      areaId: Yup.number().required("*required").nullable(),
      cityId: Yup.number().required("*required").nullable(),
      stateId: Yup.number().required("*required").nullable(),
      propertysubcategoryid:
        leisureSelect && Yup.string().required("*required"),
      fee: nofeeBlock && Yup.string().required("*required").nullable(),
      // BuyerAgency: nofeeBlock && Yup.string().required("*required").nullable(),
      // SellerAgency: nofeeBlock && Yup.string().required("*required").nullable(),

      // otherCommertialInputs: Yup.array(
      //     Yup.object({
      //         space: Yup.string()
      //             .required('start time needed'),
      //         size: Yup.string()
      //             .required('End time  needed'),
      //         term: Yup.string()
      //             .required('End time  needed'),
      //         rate: Yup.string()
      //             .required('End time  needed'),
      //         type: Yup.string()
      //             .required('End time  needed'),
      //     })
      // )
    }),
    onSubmit: (values, action) => {
      setLoading(true);
      let formData = new FormData();
      formData.append("userid", userId);
      formData.append("propertytitle", formik.values.Title);
      formData.append("propertydescription", formik.values.message);
      // formData.append("propertymainimage", formik.values.propertymainimage);
      formik.values.propertymainimage.forEach((image) => {
        formData.append("propertymainimage", image);
      });
      formData.append(
        "propertymainfloarplan",
        formik.values.propertymainfloarplan
      );
      // formData.append("propertysubcategoryid", formik.values.message); leser select  done
      formData.append(
        "propertysubcategoryid",
        formik.values.propertysubcategoryid
      );
      formData.append("propertymaincategoryid", formik.values.mainCategory);
      formData.append("property_address_1", formik.values.address);
      formData.append("propertycity", formik.values.cityId);
      formData.append("propertystate", formik.values.stateId);
      formData.append("propertyarea", formik.values.areaId);
      formData.append("property_zip", formik.values.zipcode);
      formData.append("property_terms", formik.values.term);
      formData.append("property_offer", formik.values.offer);
      formData.append("is_property_fee", !formik.values.noFees ? 1 : 0);
      formData.append("property_listing_amount", formik.values.listingAmount);
      // formData.append("property_listing_amount", parseInt(formik.values.listingAmount));
      formData.append("appartment", formik.values.appartment);
      // formData.append("", formik.values.countryId);
      formData.append(
        "property_listing_start_date",
        moment(formik.values.listingDate).format("YYYY-MM-DD")
      );
      formData.append(
        "property_listing_end_date",
        moment(formik.values.endDate).format("YYYY-MM-DD") === "Invalid date"
          ? ""
          : moment(formik.values.endDate).format("YYYY-MM-DD")
      );
      formData.append(
        "is_property_exclusive",
        formik.values.listingType === "exclusive" ? 1 : 0
      );
      formData.append("property_pet_friendly", formik.values.petFriendly);
      formData.append("min_30_shows", formik.values.min_30_shows);
      !formik.values.noFees && formData.append("fees", formik.values.fee);
      !formik.values.noFees &&
        formData.append("SellerAgency", formik.values.SellerAgency);
      !formik.values.noFees &&
        formData.append("BuyerAgency", formik.values.BuyerAgency);
      // residential input
      formData.append("Bedrooms", formik.values.Bedrooms);
      formData.append("Bathrooms", formik.values.Bathrooms);
      formData.append("Square_sqft", formik.values.SquareSqft);
      formData.append("Exterior_Sqft", formik.values.ExteriorSqft);
      formData.append("Maintence_fee", formik.values.MaintenanceFee);
      formData.append("Real_Estate_Tax", formik.values.RealEstateTax);
      formData.append("Financing", formik.values.Financing);
      formData.append("Minimum_Down", formik.values.MinimumDown);
      // residential input end
      // commertai input
      formData.append("Units", formik.values.Units);
      formData.append("Rooms", formik.values.Rooms);
      formData.append("Block", formik.values.Block);
      formData.append("Lot", formik.values.Lot);
      formData.append("Zone", formik.values.Zone);
      formData.append("Building_Sqft", formik.values.BuildingSqft);
      formData.append("Lot_Dimensions", formik.values.LotDimensions);
      formData.append("Building_Dimension", formik.values.BuildingDimensions);
      formData.append("Stories", formik.values.Stories);
      formData.append("FAR", formik.values.FAR);
      formData.append("Assessment", formik.values.Assessment);
      formData.append("Annual_Taxes", formik.values.AnnualTaxes);
      formData.append("Available_Air_Rights", formik.values.AvailableAirRights);
      // commertai input end
      let aminityarray = formik.values.amenities.map(Number);
      formData.append("aminity", JSON.stringify(aminityarray));
      formData.append("No_sure_Pet_allowed", formik.values.petNotSure ? 1 : 0);
      // formData.append("property_cost_per_sq", formik.values.petNotSure);
      formData.append("property_listing_type", props.propertyListingTypeId);
      // formData.append("created_date", moment(formik.values.listDate).format("YYYY-MM-DD"));
      // formData.append("created_time", formik.values.listTime);
      // formData.append("agentuserprofileid", formik.values.listTime);

      //pet modal input
      // formData.append("pets", JSON.stringify(petModalResponce));
      // formData.append("pets", minModalResponce ? JSON.stringify(petModalResponce) : JSON.stringify([]));
      //min Modal data
      // formData.append("sunday", minModalResponce?.sunday.length > 0 ? JSON.stringify(minModalResponce.sunday) : JSON.stringify([]));
      // formData.append("monday", minModalResponce?.monday.length > 0 ? JSON.stringify(minModalResponce.monday) : JSON.stringify([]));
      // formData.append("tuesday", minModalResponce?.tuesday.length > 0 ? JSON.stringify(minModalResponce.tuesday) : JSON.stringify([]));
      // formData.append("wednesday", minModalResponce?.wednesday.length > 0 ? JSON.stringify(minModalResponce.wednesday) : JSON.stringify([]));
      // formData.append("thursday", minModalResponce?.thrusday.length > 0 ? JSON.stringify(minModalResponce.thrusday) : JSON.stringify([]));
      // formData.append("friday", minModalResponce?.friday.length > 0 ? JSON.stringify(minModalResponce.friday) : JSON.stringify([]));
      // formData.append("saturday", minModalResponce?.saturday.length > 0 ? JSON.stringify(minModalResponce.saturday) : JSON.stringify([]));
      formData.append(
        "Space_avaliable",
        JSON.stringify(formik.values.otherCommertialInputs)
      );
      formData.append(
        "agentuserprofileid",
        JSON.stringify(props.selectedTeamMember)
      );
      // formData.append("agentuserprofileid", props.selectedTeamMember ? minModalResponce.saturday : JSON.stringify([]));
      //open house data
      formData.append("Open_House", formik.values.Open_House ? 1 : 0);
      formData.append(
        "sunday_open_house",
        JSON.stringify(openHouseData?.sunday_open_house)
      );
      formData.append(
        "monday_open_house",
        JSON.stringify(openHouseData?.monday_open_house)
      );
      formData.append(
        "tuesday_open_house",
        JSON.stringify(openHouseData?.tuesday_open_house)
      );
      formData.append(
        "wednesday_open_house",
        JSON.stringify(openHouseData?.wednesday_open_house)
      );
      formData.append(
        "thursday_open_house",
        JSON.stringify(openHouseData?.thursday_open_house)
      );
      formData.append(
        "friday_open_house",
        JSON.stringify(openHouseData?.friday_open_house)
      );
      formData.append(
        "saturday_open_house",
        JSON.stringify(openHouseData?.saturday_open_house)
      );
      // console.table([...formData])

      AddProperty(formData, (success) => {
        if (success.data.code === 200 && success.data.status === "success") {
          toast.success("Property add Successfully", "success");
          setLoading(false);
          token.profile.listing_count = success.data.data[1].count;
          localStorage.setItem("userDetails", JSON.stringify(token));
          // navigate("/agent-dashboard/current-listing")
          navigate(`/${currentUser}/current-listing`);
          dispatch(updateTotalListing(success.data.data[1]?.count));
          // context.setAgentTotaListing(success.data.data[1]?.count);
          action.resetForm();
          ResetCommonForm();
          formik.resetForm();
        }
        if (success.data.code === 400 && success.data.status === "failed") {
          toast.error("Something Went Wrong");
          setLoading(false);
        }
      });
    },
  });

  let ResetCommonForm = () => {
    // setDisplayAutocomplete(true)
    mainImage.current.value = "";
    floreImage.current.value = "";
    zipSelect.current.clearValue();
    petchck.current.checked = false;
    const activeElement = document.getElementsByClassName("userInputNumber");
    Array.prototype.forEach.call(activeElement, function (slide, index) {
      slide.value = "";
    });
    // setPetModalValue(false)
    // setDisplayAutocomplete(true)
    // setMinModalResponce(null)
    // setPetModalResponce([])
    props.setSelectedTeamMember([]);
  };

  function handleUploadBannerImg(e) {
    let fileSize = e.target.files[0]?.size;
    if (fileSize <= 5250000) {
      const files = e.target.files;
      let formData = new FormData();

      const fileResponsesArray = [];
      let ImgObj = {};
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        fileResponsesArray.push(files[i]);
        formData.append("images", file);
        ImgObj[i] = file;
      }
      formik.setFieldValue("propertymainimage", fileResponsesArray);
    } else {
      swal("", "Image size must be under 5mb", "warning");
    }
  }

  function handleUploadPdf(e) {
    let fileSize = e.target.files[0]?.size;
    if (fileSize <= 5250000) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        formik.setFieldValue("propertymainfloarplan", file);
      };
    } else {
      swal("", "Image size must be under 5mb", "warning");
    }
  }

  let checkLeisure = (e) => {
    if (e.target.id === "Leisure") {
      setLeisureSelect(true);
    } else {
      setLeisureSelect(false);
    }
  };

  let FormatAmount = (e) => {
    const result = handleAmountChange(e.target.value);
    setPropertyAmount(result);
    const AmtWithoutComma = result.replace(/,/g, "");
    formik.setFieldValue("listingAmount", AmtWithoutComma);
  };

  const MAX_Title_CHARACTERS = 60;
  let remainingCharacters = MAX_Title_CHARACTERS - formik.values.Title.length;

  return (
    <>
      {loading ? <Loader /> : null}
      <form className=" d-flex flex-grow-1 ">
        <div className="d-flex flex-grow-1 flex-column gap-4">
          {/* uppper-sectio */}
          <div className="rent-inner-section sale-middle-sec d-flex flex-column">
            <div className="upper-section d-flex justify-content-between gap-2">
              <div className="upper-section-left d-flex flex-column gap-2 flex-grow-1">
                <div className=" d-flex justify-content-between">
                  <div className="d-flex align-items-center w-75 inputbox ">
                    <p className={`formtext inputLable`}>
                      Address<span>*</span>
                    </p>
                    <input
                      type="text"
                      id=""
                      placeholder=""
                      required
                      className=" flex-grow-1"
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    {formik.errors.address && formik.touched.address ? (
                      <div className="validation-text">
                        {formik.errors.address}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`d-flex align-items-center inputbox`}
                    style={{ width: "63px" }}
                  >
                    <input
                      type="text"
                      className="w-100 formtext"
                      id=""
                      placeholder="Apt#"
                      name="appartment"
                      onChange={formik.handleChange}
                      value={formik.values.appartment}
                      required
                    />
                  </div>
                </div>

                <div className=" position-relative pt-2">
                  {" "}
                  <AddressFeild formik={formik} />{" "}
                </div>
                {/* <div className=" position-relative pt-2">
                                    <Row className="second-row mx-0 align-items-center">
                                        <Col sm className="second-row-in me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>State</p>
                                                <Select options={allStateList} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        GetCityByState(e.id)
                                                        formik.setFieldValue("stateId", e.id);

                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm className="second-row-in me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>city</p>
                                                <Select options={allCityList} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        GetAreaByCity(e.id)
                                                        formik.setFieldValue("cityId", e.id);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm className="second-row-in  me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>Area</p>
                                                <Select options={allAreaList} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        formik.setFieldValue("areaId", e.id);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm className="second-row-in">
                                            <div className="d-flex align-items-center inputbox ">
                                                <p className={`formtext inputLable`}>Zipcode</p>
                                                <Select options={allZipcodes} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                    ref={zipSelect}
                                                    classNamePrefix="select"
                                                    value={[{ label: zipcodeid?.label, id: zipcodeid?.id }]}
                                                    onChange={(e) => {
                                                        setZipcodeid(e);
                                                        formik.setFieldValue("zipcodeid", e.id);
                                                        formik.setFieldValue("zipcode", e.label);
                                                    }}
                                                />
                                            </div>
                                            {formik.errors.zipcode && formik.touched.zipcode ? (
                                                <div className='validation-text'>{formik.errors.zipcode}</div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div> */}

                {/* condtional row */}
                {/* {!displayAutocomplete ?
                                    <>
                                        <div className=" position-relative pt-2">
                                            <Row className="second-row mx-0 align-items-center">
                                                <Col sm className="second-row-in  me-2">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>Area</p>
                                                        <p className="w-75 formtext">{area}</p>
                                                    </div>
                                                </Col>
                                                <Col sm className="second-row-in me-2">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>Town</p>
                                                        <p className="w-75 formtext">{city}</p>
                                                    </div>
                                                </Col>
                                                <Col sm className="second-row-in me-2">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>State</p>
                                                        <p className="w-75 formtext">{state}</p>
                                                    </div>
                                                </Col>
                                                <Col sm className="second-row-in">
                                                    <div className="d-flex align-items-center inputbox ">
                                                        <p className={`formtext inputLable`}>Zipcode</p>
                                                        <Select options={allZipcodes} className=' w-100 ps-0 custum-react-select select-wrapper'
                                                            ref={zipSelect}
                                                            classNamePrefix="select"
                                                            value={[{ label: zipcodeid?.label, id: zipcodeid?.id }]}
                                                            onChange={(e) => {
                                                                setZipcodeid(e);
                                                                formik.setFieldValue("zipcodeid", e.id);
                                                                formik.setFieldValue("zipcode", e.label);
                                                            }}
                                                        />
                                                    </div>
                                                    {formik.errors.zipcode && formik.touched.zipcode ? (
                                                        <div className='validation-text'>{formik.errors.zipcode}</div>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <span className="pointer position-absolute "
                                                style={{ left: '-12px', top: '8px' }}
                                                onClick={() => setDisplayAutocomplete(!displayAutocomplete)}><EditIcon style={{ width: '15px', height: '15px' }} /></span>
                                        </div>
                                    </> :
                                    <div className=" d-flex">
                                        <div className="d-flex align-items-center w-100 inputbox ">
                                            <p className={`formtext inputLable`}>Address<span>*</span></p>
                                            <Autocomplete
                                                className="hide-downBtn  w-100 area-autocomplete"
                                                disablePortal
                                                placeholder="hhghrtg"
                                                id="combo-box-demo"
                                                options={addressOptions}
                                                onChange={(event, value) => {
                                                    setArea(value?.area);
                                                    setCity(value?.city);
                                                    setState(value?.state);
                                                    setAreaId(value?.area_id);
                                                    setCityId(value?.city_id);
                                                    setStateId(value?.state_id);
                                                    setCountryId(value?.country_id);
                                                    setDisplayAutocomplete(false);
                                                    formik.setFieldValue("areaId", value?.area_id);
                                                    formik.setFieldValue("cityId", value?.city_id);
                                                    formik.setFieldValue("stateId", value?.state_id);
                                                    formik.setFieldValue("countryId", value?.country_id);
                                                    // formik.setFieldValue("location",value)
                                                }}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.id === value.id
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e) => { setAddress(e.target.value); }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                            {formik.errors.areaId && formik.touched.areaId ? (
                                                <div className='validation-text'>{formik.errors.areaId}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                } */}

                {/* zipcode  Select style*/}
                {/* styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        boxShadow: "none",
                                        border: "none",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused && "lightgray",
                                        color: state.isFocused && "black",
                                        backgroundColor: 'red'

                                    }),
                                }}
                                classNamePrefix="custom-select" */}

                {/* <Row className="second-row mx-0 justify-content-end">
                                    <Col sm className="second-row-in me-2">
                                        <div className="d-flex align-items-center inputbox ">
                                            <input type="text" id="" className="calenderIcon bg-transparent border-0 w-100"
                                                ref={listingdataref}
                                                placeholder="Listing Date"
                                                onFocus={() => { (listingdataref.current.type = "date"); formik.setFieldTouched("listingDate", true) }}
                                                onBlur={() => { (listingdataref.current.type = "text"); formik.setFieldTouched("listingDate", true) }}
                                                // pattern="\d{2}\/\d{2}\/\d{4}"
                                                name="listingDate"
                                                onChange={formik.handleChange}
                                                value={formik.values.listingDate}
                                                required />
                                        </div>
                                        {formik.errors.listingDate && formik.touched.listingDate ? (
                                            <div className='validation-text'>{formik.errors.listingDate}</div>
                                        ) : null}

                                    </Col>

                                    {formik.values.listingType === "open" ?
                                        null
                                        :
                                        <Col sm className="second-row-in   me-2">
                                            <div className="d-flex align-items-center inputbox ">
                                                <input type="text" id="" placeholder="End Date" className="calenderIcon bg-transparent border-0 w-100" required
                                                    ref={listingEndDate}
                                                    onFocus={() => (listingEndDate.current.type = "date")}
                                                    onBlur={() => (listingEndDate.current.type = "text")}
                                                    name="endDate"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.endDate}
                                                />
                                            </div>
                                            {formik.errors.endDate && formik.touched.endDate ? (
                                                <div className='validation-text'>{formik.errors.endDate}</div>
                                            ) : null}
                                        </Col>

                                    }

                                    <Col sm className="second-row-in align-self-end d-flex flex-column gap-2 h-100 type-radio">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center gap-1">
                                                <input type="radio" name="type" id="Exclusive" value="exclusive"
                                                    onChange={(e) => { formik.setFieldValue("listingType", e.target.value) }}
                                                    checked={formik.values?.listingType === 'exclusive'}
                                                />
                                                <label htmlFor="Exclusive" className="formtext pointer">
                                                    Exclusive
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center gap-1">
                                                <input type="radio" name="type" id="Open" value="open"
                                                    onChange={(e) => { formik.setFieldValue("listingType", e.target.value) }}
                                                    checked={formik.values?.listingType === 'open'}
                                                />
                                                <label htmlFor="Open" className="formtext pointer">
                                                    Open
                                                </label>
                                            </div>
                                        </div>
                                        {formik.errors.listingType && formik.touched.listingType ? (
                                            <div className='validation-text'>{formik.errors.listingType}</div>
                                        ) : null}
                                    </Col>
                                </Row> */}

                <Row className="second-row mx-0 justify-content-start flex-row">
                  <Col
                    sm
                    className="second-row-in me-2"
                    style={{ maxWidth: "200px" }}
                  >
                    <div className="d-flex align-items-center inputbox ">
                      <DatePicker
                        showIcon
                        dateFormat="MM/dd/yyyy"
                        // minDate={new Date()}
                        // selected={startDate}
                        selected={formik.values.listingDate}
                        onChange={(date) => {
                          // setStartDate(date);
                          formik.setFieldValue("listingDate", date);
                        }}
                        placeholderText="Listing Date"
                      />
                      <img
                        src={calendar}
                        alt="calendar"
                        style={{ width: "12px", height: "14px" }}
                      ></img>
                    </div>

                    {formik.errors.listingDate && formik.touched.listingDate ? (
                      <div className="validation-text">
                        {formik.errors.listingDate}
                      </div>
                    ) : null}
                  </Col>

                  {formik.values.listingType === "open" ? null : (
                    <Col sm className="second-row-in   me-2">
                      <div className="d-flex align-items-center inputbox ">
                        <DatePicker
                          showIcon
                          dateFormat="MM/dd/yyyy"
                          minDate={formik.values.listingDate || new Date()}
                          selected={formik.values.endDate}
                          onChange={(date) => {
                            formik.setFieldValue("endDate", date);
                          }}
                          placeholderText="End Date"
                        />
                        <img
                          src={calendar}
                          alt="calendar"
                          style={{ width: "12px", height: "14px" }}
                        ></img>
                      </div>
                      {formik.errors.endDate && formik.touched.endDate ? (
                        <div className="validation-text">
                          {formik.errors.endDate}
                        </div>
                      ) : null}
                    </Col>
                  )}

                  <Col
                    sm
                    className="second-row-in align-self-end d-flex flex-column gap-1 h-100 type-radio justify-content-center"
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-1">
                        <input
                          type="radio"
                          name="type"
                          id="Exclusive"
                          value="exclusive"
                          onChange={(e) => {
                            formik.setFieldValue("listingType", e.target.value);
                          }}
                          checked={formik.values?.listingType === "exclusive"}
                        />
                        <label htmlFor="Exclusive" className="formtext pointer">
                          Exclusive
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <input
                          type="radio"
                          name="type"
                          id="Open"
                          value="open"
                          onChange={(e) => {
                            formik.setFieldValue("listingType", e.target.value);
                          }}
                          checked={formik.values?.listingType === "open"}
                        />
                        <label htmlFor="Open" className="formtext pointer">
                          Open
                        </label>
                      </div>
                    </div>
                    {formik.errors.listingType && formik.touched.listingType ? (
                      <div className="validation-text">
                        {formik.errors.listingType}
                      </div>
                    ) : null}
                  </Col>
                </Row>

                <Row style={{ minHeight: "24px" }}>
                  {props.noFee && (
                    <Col xs={3}>
                      <div className="d-flex align-items-center gap-1 h-100">
                        <input
                          type="checkbox"
                          name="noFee"
                          id="fee"
                          onChange={(e) => {
                            formik.setFieldValue("noFees", e.target.checked);
                            setNofeeBlock(!e.target.checked);
                          }}
                        />
                        <label htmlFor="fee" className="formtext pointer">
                          No Fees
                        </label>
                      </div>
                    </Col>
                  )}
                  {nofeeBlock && (
                    <Col xs={props.noFee ? 3 : 4}>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          type="number"
                          placeholder="Fees*"
                          id=""
                          className=" flex-grow-1"
                          name="fee"
                          onChange={formik.handleChange}
                          value={formik.values.fee}
                        />
                      </div>
                      {formik.errors.fee && formik.touched.fee ? (
                        <div className="validation-text">
                          {formik.errors.fee}
                        </div>
                      ) : null}
                    </Col>
                  )}
                  {nofeeBlock && (
                    <Col xs={props.noFee ? 3 : 4}>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          type="number"
                          placeholder="Seller Agency"
                          id=""
                          className=" flex-grow-1"
                          name="SellerAgency"
                          onChange={formik.handleChange}
                          value={formik.values.SellerAgency}
                        />
                        {/* {formik.errors.fee && formik.touched.fee ? (
                                                        <div className='validation-text'>{formik.errors.fee}</div>
                                                    ) : null} */}
                      </div>
                    </Col>
                  )}
                  {nofeeBlock && (
                    <Col xs={props.noFee ? 3 : 4}>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          type="number"
                          placeholder="Buyer Agency"
                          id=""
                          className=" flex-grow-1"
                          name="BuyerAgency"
                          onChange={formik.handleChange}
                          value={formik.values.BuyerAgency}
                        />
                        {/* {formik.errors.fee && formik.touched.fee ? (
                                                        <div className='validation-text'>{formik.errors.fee}</div>
                                                    ) : null} */}
                      </div>
                    </Col>
                  )}
                </Row>

                {/* show tearms and offers for listing_type 1 & 3 */}
                {/* (props.radiobuttns[0]?.listing_type == "1" ||
                  props.radiobuttns[0]?.listing_type == "3") */}
                {(props.radiobuttns[0]?.listing_type == "1" ||
                  props.radiobuttns[0]?.listing_type == "4") && (
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center inputbox pb-0 ">
                        <select
                          aria-label="Default select example"
                          className=" w-100"
                          onChange={(e) => {
                            formik.setFieldValue("term", e.target.value);
                          }}
                        >
                          <option className=" d-none">Terms</option>
                          {termsOffers?.Terms?.map((term, i) => (
                            <option key={`terms${i}`} value={term.terms}>
                              {term.terms}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center inputbox pb-0">
                        <select
                          aria-label="Default select example"
                          className=" w-100"
                          onChange={(e) => {
                            formik.setFieldValue("offer", e.target.value);
                          }}
                        >
                          <option className=" d-none">Offer</option>
                          {termsOffers?.Offer?.map((offer, i) => (
                            <option key={`offers${i}`} value={offer.offer}>
                              {offer.offer}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                )}

                <div className="d-flex justify-content-between gap-2">
                  {props.residentialUser && (
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="checkbox"
                        name="sure"
                        id="sure"
                        onChange={(e) => {
                          formik.setFieldValue("petNotSure", e.target.checked);
                        }}
                      />
                      <label htmlFor="sure" className="formtext pointer">
                        Not Sure Pets Allowed
                      </label>
                    </div>
                  )}

                  {props.residentialUser &&
                    //if petNotSure is true then hide pets popup
                    !formik.values.petNotSure && (
                      <div className="d-flex align-items-center gap-1">
                        <input
                          type="checkbox"
                          name="PetFriendly"
                          id="PetFriendly"
                          ref={petchck}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "PetFriendly",
                              e.target.checked ? 1 : 0
                            );
                            // setPetModalValue(e.target.checked)
                          }}
                          style={{ borderRadius: "50%" }}
                          // checked={Boolean(petModalResponce?.length)}
                        />
                        <label
                          htmlFor="PetFriendly"
                          className="formtext pointer"
                        >
                          Pet Friendly
                        </label>
                      </div>
                    )}

                  <div className="d-flex align-items-center gap-1">
                    <input
                      type="checkbox"
                      name="minute"
                      id="minute"
                      onChange={(e) => {
                        // setMinModalValue(e.target.checked)
                        formik.setFieldValue(
                          "min_30_shows",
                          e.target.checked ? 1 : 0
                        );
                        // if (!e.target.checked) { setMinModalResponce(null) }
                      }}
                      style={{ borderRadius: "50%" }}
                      // checked={formik.values.min_30_shows}
                      // checked={Boolean(minModalResponce)}
                    />
                    <label htmlFor="minute" className="formtext pointer">
                      30 Minute Showing
                    </label>
                  </div>
                </div>
              </div>
              <div className="upper-divider d-none d-md-block"></div>
              <div
                className="d-flex flex-column flex-grow-1 gap-2"
                style={{ maxWidth: "45%" }}
              >
                <div className="d-flex align-items-center inputbox ">
                  <p className="formtext inputLable">
                    Listing Amount<span>*</span>
                  </p>
                  <div className=" flex-grow-1 d-flex">
                    <input
                      type="text"
                      className=" w-100"
                      id=""
                      placeholder=""
                      required
                      pattern="[0-9]*"
                      step="any"
                      name="listingAmount"
                      onChange={(e) => {
                        FormatAmount(e);
                      }}
                      value={propertyAmount}
                    />
                    {formik.errors.listingAmount &&
                    formik.touched.listingAmount ? (
                      <div className="validation-text">
                        {formik.errors.listingAmount}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* {props.residentialUser && (
                  <p className=" fw-bold">Open House</p>
                )}
                {props.residentialUser && (
                  <div className="d-flex gap-1">
                    <input
                      type="checkbox"
                      name="sure"
                      id="OpenHouseTime"
                      onChange={(e) => {
                        setOpenHouseModal(e.target.checked);
                        formik.setFieldValue("Open_House", e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="OpenHouseTime"
                      className="formtext pointer text-capitalize"
                    >
                      Open House Timing
                    </label>
                  </div>
                )} */}

                {props.residentialUser && (
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          type="text"
                          id=""
                          className="bg-transparent border-0 w-100"
                          ref={listDate}
                          placeholder="Date"
                          onFocus={() => (listDate.current.type = "date")}
                          onBlur={() => (listDate.current.type = "date")}
                          name="listDate"
                          onChange={formik.handleChange}
                          value={formik.values.listDate}
                        />

                        {/* <DatePicker
                          showIcon
                          dateFormat="MM/dd/yyyy"
                          selected={formik.values.listDate}
                          onChange={(date) => {
                            formik.setFieldValue("listDate", date);
                          }}
                          // placeholderText="Date"
                        /> */}
                        {/* <img
                          src={calendar}
                          alt="calendar"
                          style={{ width: "12px", height: "14px" }}
                        ></img> */}
                        {formik.errors.listDate && formik.touched.listDate ? (
                          <div className="validation-text">
                            {formik.errors.listDate}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center inputbox ">
                        <input
                          type="time"
                          id=""
                          className="calenderIcon bg-transparent border-0 w-100"
                          name="listTime"
                          onChange={formik.handleChange}
                          value={formik.values.listTime}
                          placeholderText="Time"
                        />
                        {formik.errors.listTime && formik.touched.listTime ? (
                          <div className="validation-text">
                            {formik.errors.listTime}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                )}

                {props.commercialUser && (
                  <p className=" fw-bold">Space Available</p>
                )}
                {props.commercialUser &&
                  Array.from(Array(commertialInputes)).map((time, index) => (
                    <div key={`commertiaIm${index}`}>
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="text"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Space"
                              name={`otherCommertialInputs.${index}.space`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialSpace &&
                            formik.touched.commertialSpace ? (
                              <div className="validation-text">
                                {formik.errors.commertialSpace}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="number"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Size"
                              name={`otherCommertialInputs.${index}.size`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialSize &&
                            formik.touched.commertialSize ? (
                              <div className="validation-text">
                                {formik.errors.commertialSize}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="text"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Term"
                              name={`otherCommertialInputs.${index}.term`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialTerm &&
                            formik.touched.commertialTerm ? (
                              <div className="validation-text">
                                {formik.errors.commertialTerm}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="number"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Rate"
                              name={`otherCommertialInputs.${index}.rate`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialRate &&
                            formik.touched.commertialRate ? (
                              <div className="validation-text">
                                {formik.errors.commertialRate}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center inputbox ">
                            <input
                              type="text"
                              id=""
                              className="calenderIcon bg-transparent border-0 w-100"
                              placeholder="Type"
                              name={`otherCommertialInputs.${index}.type`}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.commertialType &&
                            formik.touched.commertialType ? (
                              <div className="validation-text">
                                {formik.errors.commertialType}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <div className="add-remove-btn d-flex align-items-end">
                          {commertialInputes - 1 === index ? (
                            <AddCircleOutlineIcon
                              className="pointer add-btn"
                              onClick={() =>
                                setCommertialInputes((pre) => pre + 1)
                              }
                            />
                          ) : (
                            <RemoveCircleOutlineIcon
                              className="pointer remove-btn"
                              onClick={() =>
                                setCommertialInputes((pre) => pre - 1)
                              }
                            />
                          )}
                        </div>
                      </Row>
                    </div>
                  ))}
              </div>
            </div>

            <div className=" bottom-sec">
              <div className="d-flex flex-column bottom-box gap-2">
                {/* file upload-sec */}
                {/* <div className=" d-flex flex-wrap  gap-3 justify-content-between">
                                    <div className="d-flex flex-column file-input ">
                                        <div className="d-flex justify-content-between">
                                            <p className="formtext">File Pictures</p>
                                            <p className="file-number pointer" onClick={() => setImagemodalpopup(true)} >
                                                {`${imgfile} Image Upload`}
                                            </p>
                                        </div>
                                        <input type="file" name="propertymainimage" id="Firstimg" className="img-upload" multiple accept="image/*"
                                            ref={mainImage}
                                            onChange={(e) => {
                                                fileselect(e, "img");
                                                handleUploadBannerImg(e)
                                            }}
                                        />
                                        {formik.errors.propertymainimage ?
                                            (<div className='validation-text'>{formik.errors.propertymainimage}</div>) : null}

                                    </div>

                                    <div className="d-flex flex-column file-input ">
                                        <div className="d-flex justify-content-between">
                                            <p className="formtext">Upload FL Plans (pdf) Or Upload OM</p>
                                            <p className="file-number pointer">{`${pdffile} pdf Upload`}</p>
                                        </div>
                                        <input type="file" name="myImage" id="Firstimg" className="img-upload"
                                            multiple={false}
                                            ref={floreImage}
                                            accept="application/pdf"
                                            onChange={(e) => { fileselect(e, "pdf"); handleUploadPdf(e) }}
                                        />
                                        {formik.errors.propertymainfloarplan ?
                                            (<div className='validation-text'>{formik.errors.propertymainfloarplan}</div>) : null}
                                    </div>
                                </div> */}

                <div className=" d-flex flex-wrap  gap-3 justify-content-between">
                  <div className="d-flex flex-column file-input ">
                    <div className="d-flex justify-content-between">
                      <p className="formtext inputLable border-0">
                        File Pictures<span>*</span>
                      </p>
                      <p
                        className="file-number pointer"
                        onClick={() => setImagemodalpopup(true)}
                      >
                        {`${imgfile} Image Upload`}
                      </p>
                    </div>
                    <CustumeFileInput
                      id="MainImg"
                      multiple={true}
                      accept="image/*"
                      onChange={(e) => {
                        fileselect(e, "img");
                        handleUploadBannerImg(e);
                      }}
                    />
                    {formik.errors.propertymainimage ? (
                      <div className="validation-text">
                        {formik.errors.propertymainimage}
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex flex-column file-input ">
                    <div className="d-flex justify-content-between">
                      <p className="formtext">
                        Upload FL Plans (pdf) Or Upload OM
                      </p>
                      {/* <p className="file-number pointer">{`${pdffile} pdf Upload`}</p> */}
                    </div>
                    <CustumeFileInput
                      id="floorPlan"
                      multiple={false}
                      accept="application/pdf"
                      onChange={(e) => {
                        handleUploadPdf(e); //;fileselect(e, "img");
                      }}
                    />
                    {formik.errors.propertymainfloarplan ? (
                      <div className="validation-text">
                        {formik.errors.propertymainfloarplan}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Radio buttons group*/}
                <div className="d-flex flex-wrap radio-group justify-content-center justify-content-md-between mt-1">
                  {props.radiobuttns?.map((radiobtn, index) => (
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "7px" }}
                      key={index}
                    >
                      <input
                        type="radio"
                        name="mainCategory"
                        id={radiobtn.Main_category}
                        value={radiobtn.id}
                        onChange={formik.handleChange}
                        onClick={(e) => {
                          checkLeisure(e);
                        }}
                        checked={formik.values?.mainCategory == radiobtn.id}
                      />
                      <label
                        htmlFor={radiobtn.Main_category}
                        className="formtext pointer"
                      >
                        {radiobtn.Main_category}
                      </label>
                    </div>
                  ))}
                </div>

                {leisureSelect && (
                  <Form.Select
                    aria-label="Default select example"
                    className="Leisure-subcat border-1"
                    style={{ maxWidth: "150px" }}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "propertysubcategoryid",
                        e.target.value
                      );
                    }}
                  >
                    <option className=" d-none">Sub Type</option>
                    <option value="10">Industrial</option>
                    <option value="20">Specialty</option>
                  </Form.Select>
                )}
                {formik.errors.mainCategory && formik.touched.mainCategory ? (
                  <div className="validation-text ">
                    {formik.errors.mainCategory}
                  </div>
                ) : null}

                {/* input Group  */}
                {props.residentialUser &&
                  // if residential rental then show first 4 input if sale then show all inputs
                  (props.radiobuttns[0]?.listing_type == "1" ? (
                    <div div className="d-flex flex-wrap  btn-grp-gap ">
                      {btnGroup?.slice(0, 4)?.map((singlebtn, index) => (
                        <input
                          type="number"
                          key={`input${index}`}
                          className="userInputNumber"
                          placeholder={singlebtn.title}
                          // ref={userInputNumber}
                          name={singlebtn.name}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                      ))}
                    </div>
                  ) : (
                    <div div className="d-flex flex-wrap  btn-grp-gap ">
                      {btnGroup?.map((singlebtn, index) => (
                        <input
                          type="number"
                          key={`input${index}`}
                          className="userInputNumber"
                          placeholder={singlebtn.title}
                          // ref={userInputNumber}
                          name={singlebtn.name}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                      ))}
                    </div>
                  ))}

                {props.commercialUser && (
                  <div className="d-flex flex-wrap  btn-grp-gap ">
                    {commertialInput?.map((singlebtn, index) => (
                      <input
                        type="number"
                        key={`commertialInput${index}`}
                        className="userInputNumber"
                        placeholder={singlebtn.title}
                        // ref={userInputNumber}
                        name={singlebtn.name}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    ))}
                  </div>
                )}

                <div className="d-flex gap-3">
                  {formik.errors.Bedrooms || formik.touched.Bedrooms ? (
                    <div className="validation-text">
                      {formik.errors.Bedrooms}
                    </div>
                  ) : null}
                  {formik.errors.Bathrooms || formik.touched.Bathrooms ? (
                    <div className="validation-text">
                      {formik.errors.Bathrooms}
                    </div>
                  ) : null}
                  {formik.errors.Units || formik.touched.Units ? (
                    <div className="validation-text">{formik.errors.Units}</div>
                  ) : null}
                  {formik.errors.Rooms || formik.touched.Rooms ? (
                    <div className="validation-text">{formik.errors.Rooms}</div>
                  ) : null}
                </div>

                {/* {Amenities Buttons} */}
                {props.residentialUser && (
                  <div className=" ">
                    <p
                      className="formtext mb-2"
                      style={{ fontWeight: "500", marginBottom: "6px" }}
                    >
                      Amenities
                    </p>
                    <div className="Amenities d-flex  flex-wrap ">
                      {allaminities?.map((aminiti, i) => (
                        <div
                          className="select-aminity position-relative"
                          key={`aminity${i}`}
                        >
                          <input
                            type="checkbox"
                            className="Prop aminity-input visually-hidden"
                            id={`filteraminity${i}`}
                            name={`${aminiti.id}`}
                            checked={formik.values.amenities.includes(
                              `${aminiti.id}`
                            )}
                            onChange={handleChange}
                          />
                          <label
                            htmlFor={`filteraminity${i}`}
                            className="pointer aminity"
                          >
                            {aminiti.amenities_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Bottom-sectio */}
          <div
            className={`d-flex flex-column rent-inner-section d-flex flex-column mb-3`}
          >
            <p className="formtext fw-bold ">Description</p>
            <div className="d-flex align-items-end flex-wrap gap-3 descBox ">
              <div
                className="d-flex  flex-column flex-grow-1"
                style={{ gap: "12px" }}
              >
                <div className="d-flex align-items-center  inputbox">
                  <p className="formtext inputLable" style={{ width: "9%" }}>
                    Title<span>*</span>
                  </p>
                  <input
                    type="text"
                    id=""
                    placeholder=""
                    required
                    className="w-100"
                    name="Title"
                    onChange={formik.handleChange}
                    value={formik.values.Title.replace(blockKeywords, "").slice(
                      0,
                      MAX_Title_CHARACTERS - 1
                    )}
                  />
                  {formik.errors.Title && formik.touched.Title ? (
                    <div className="validation-text">{formik.errors.Title}</div>
                  ) : null}
                </div>
                <p className=" text-end">
                  Remaining characters : {remainingCharacters}/
                  {MAX_Title_CHARACTERS}
                </p>

                <div className="d-flex inputbox">
                  <p className="formtext inputLable" style={{ width: "9%" }}>
                    Message
                  </p>
                  <textarea
                    className="formtext bg-transparent border-0 w-100 "
                    placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit quae assumenda officiis ab error officia."
                    required
                    name="message"
                    onChange={formik.handleChange}
                    value={formik.values.message.replace(blockKeywords, "")}
                    cols="30"
                    rows="4"
                  />
                </div>
              </div>
              <Button
                style={{ borderRadius: "20px" }}
                onClick={formik.handleSubmit}
              >
                Submit
              </Button>
              {/* <Button onClick={formik.handleSubmit} disabled={!props.licenseApprove}>Submit</Button> */}
            </div>
          </div>
        </div>
      </form>

      {imagemodalpopup && (
        <ImageModal
          modalShow={imagemodalpopup}
          setModalShow={setImagemodalpopup}
          images={images}
          formik={formik}
        />
      )}
      {/* <MinModal minModalValue={minModalValue} setMinModalValue={setMinModalValue} setMinModalResponce={setMinModalResponce} />
            <PetModal setPetModalValue={setPetModalValue} petModalValue={petModalValue} setPetModalResponce={setPetModalResponce} /> */}
      <OPenHouseTimeModal
        openHouseData={openHouseData}
        setOpenHouseData={setOpenHouseData}
        openHouseModal={openHouseModal}
        setOpenHouseModal={setOpenHouseModal}
      />
    </>
  );
};

export default CommonForm;
