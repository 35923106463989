import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import { Button } from "@mui/material";
import { getPDFForCustomer } from "../server";
import { useSelector, useDispatch } from "react-redux";
import "../documents.scss";
import ShareModal from "../components/shareModal/ShareModal";
import { backendURL, getToken } from "../../../../appVariable/variable";
import Loader from "../../Loader/Loader";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { docSign } from "../server";
import Tooltip from "@mui/material/Tooltip";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { toast } from "react-toastify";
import Badge from "@mui/material/Badge";
import download from "./images/download.svg";
import DocSign from "./docSign/DocSign";
import { Modal } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { guestSignDoc } from "../../guestVirtualOffice/GuestVirtualOfficeDocsignSlice";

export default function ShareDocument() {
  const dispatch = useDispatch();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signedStatus, setSignedStatus] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const OfficeId = useSelector((state) => state.virtualOfficeInStore.officeId);
  const virtualofficeid = useSelector(
    (state) => state.GuestVirtualOfficeSliceInStore.guestOfficeId
  );

  const teamList = useSelector(
    (state) => state.virtualOfficeInStore.teamMemberList
  );
  const regex = /^(\d{4}-\d{2}-\d{2})/;
  const navigate = useNavigate();

  const handleSign = async (backendURL, file, filename, id) => {
    const regexAll = /^(.*[\\\/])?(.+)\.(.+)$/; // eslint-disable-line no-useless-escape
    const outsideRes = await fetch(backendURL + file);
    const ext = file.match(regexAll)[3];
    const blob = await outsideRes.blob();
    const url = window.URL.createObjectURL(blob);

    dispatch(guestSignDoc([url, id]));
  };

  const handleDownload = async (backendURL, file, filename) => {
    const regexAll = /^(.*[\\\/])?(.+)\.(.+)$/; // eslint-disable-line no-useless-escape
    const ext = file.match(regexAll)[3];

    const outsideRes = await fetch(backendURL + file);
    const blob = await outsideRes.blob();
    const url = window.URL.createObjectURL(blob);
    // const url = backendURL + file;
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filename}.${ext}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (!virtualofficeid) return setDocuments([]);
    setLoading(true);
    getPDFForCustomer(virtualofficeid, (success) => {
      setDocuments(success);
    });
    setLoading(false);
  }, [virtualofficeid, signedStatus]);

  return (
    <>
      <div className="d-flex flex-column" style={{ width: "1600px" }}>
        {loading === true ? <Loader /> : null}
        {/* <div className="d-flex justify-content-end">
          <Button
            style={{
              margin: "8px",
              fontFamily: "Poppins",
              color: "rgba(49, 94, 251, 0.9)",
            }}
            variant="outlined"
            size="middle"
          >
            Upload Document
          </Button>
          <Button
            style={{
              margin: "8px",
              fontFamily: "Poppins",
              color: "rgba(49, 94, 251, 0.9)",
            }}
            variant="outlined"
            size="middle"
            onClick={() => setShowShareDialog(true)}
          >
            Shared Document
          </Button>
        </div> */}

        <div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead
                style={{ backgroundColor: "rgba(49, 94, 251, 0.9)" }}
                className="table__head"
              >
                <TableRow>
                  <TableCell>DOCUMENT</TableCell>
                  <TableCell align="center">AGENT</TableCell>
                  <TableCell align="center">CREATED_DATE</TableCell>
                  <TableCell align="center">ACTION</TableCell>
                  <TableCell align="center">STATUS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ textAlign: "center" }}>
                {documents.map((doc, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell role="th">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "200px",
                        }}
                      >
                        <Tooltip
                          title={doc.filename.toString()}
                          placement="bottom"
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: "170px",
                            }}
                          >
                            {doc.filename}
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={"Click to Download Original Document"}
                          placement="bottom"
                        >
                          <div
                            className="pointer"
                            onClick={() =>
                              handleDownload(backendURL, doc.file, doc.filename)
                            }
                          >
                            {/* <ArticleRoundedIcon style={{ color: "blue" }} /> */}
                            <div>
                              <img
                                src={download}
                                style={{ height: "25px", width: "25px" }}
                                alt="website logo"
                              />
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell align="center">{`${doc?.agentprofile?.first_name} ${doc?.agentprofile?.last_name}`}</TableCell>
                    <TableCell align="center">
                      <p style={{ margin: "auto" }}>
                        {doc?.updated_at.match(regex)[1]}
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      {doc?.signed_document?.signed === true ? (
                        <Tooltip title={"Click to Download"} placement="bottom">
                          <ArticleRoundedIcon
                            className="pointer"
                            style={{ color: "red" }}
                            onClick={() =>
                              handleDownload(
                                backendURL,
                                doc.signed_document.file,
                                doc.signed_document.filename
                              )
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={"Click For Document Sign"}
                          placement="bottom"
                        >
                          <EditNoteIcon
                            className="pointer"
                            style={{ color: "red" }}
                            onClick={(e) => {
                              // setModalShow(true);]
                              handleSign(
                                backendURL,
                                doc.file,
                                doc.filename,
                                doc?.id
                              );
                              navigate("sign");
                              // handleEdit(doc?.id);
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {doc?.signed_document?.signed === true ? (
                        <span
                          style={{
                            backgroundColor: "rgb(46, 125, 50)",
                            color: "white",
                            padding: "4px 8px",
                            textAlign: "center",
                            borderRadius: "5px",
                          }}
                        >
                          Signed
                        </span>
                      ) : (
                        <span
                          style={{
                            backgroundColor: "rgb(156, 39, 176)",
                            color: "white",
                            padding: "4px 8px",
                            textAlign: "center",
                            borderRadius: "5px",
                          }}
                        >
                          Not Signed
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* {showShareDialog ? <ShareModal sendNote={sendNote} setshownote={setshownote} data={currentproperty} /> : null} */}
      {showShareDialog ? (
        <ShareModal setShowShareDialog={setShowShareDialog} />
      ) : null}
      {modalShow ? (
        <Modal show={true} center>
          <Modal.Header className="w-100" closeButton>
            <Modal.Title
              className="w-100"
              id="example-custom-modal-styling-title"
            >
              <p className="custom-heading-note text-capitalize">
                PDF Document Sign
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DocSign />
          </Modal.Body>
        </Modal>
      ) : (
        <Modal show={false} center>
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title
              className="w-100"
              id="example-custom-modal-styling-title"
            >
              <p className="custom-heading-note text-capitalize">
                Share Document
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
        </Modal>
      )}
    </>
  );
}
