import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MenuIcon from "@mui/icons-material/Menu";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import "../documents.scss";
import { useState } from "react";
import { updatePDFShowStatus } from "../server";
import { useEffect } from "react";
import { changeDocTitle } from "../server";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { deleteList } from "../server";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const rows = [
  {
    name: "Document 01:",
    Signed: "Signed by ",
  },
];

const DocumentItemSigned = (props) => {
  const [doc, setDoc] = useState(props.document);
  const [title, setTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  useEffect(() => {
    setDoc(props.document);
  }, [props.document]);
  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ margin: "auto", marginTop: "0.5rem", paddingTop: "0.2rem" }}
      >
        <div>
          <TableContainer className="raise pointer" component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow></TableRow>
              </TableHead>
              <TableBody
                style={{ backgroundColor: "rgba(241, 245, 248, 0.7)" }}
              >
                <TableRow
                  key={rows.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div>{doc?.filename}</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ marginLeft: "5%", whiteSpace: "nowrap" }}>
                        Property Detail
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <div style={{ marginRight: "5%" }}>
                          {new Date(
                            doc.updated_at.toLocaleString()?.toString()
                          ).toLocaleDateString("en-US", options)}
                        </div>
                        <div style={{ marginRight: "5%", minWidth: "200px" }}>
                          <p
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: "180px",
                            }}
                          >
                            {rows[0].Signed}
                            {doc?.userprofile?.first_name?.length > 0 ||
                            doc?.userprofile?.last_name?.length > 0
                              ? ` ${
                                  doc.userprofile.first_name?.length > 0
                                    ? doc.userprofile.first_name
                                    : ""
                                }  ${
                                  doc.userprofile.last_name_name?.length > 0
                                    ? doc.userprofile.last_name
                                    : ""
                                }`
                              : doc.userprofile.email.split("@")[0]}
                          </p>
                        </div>
                        <span className="menuIcon">
                          <div
                            onMouseLeave={(e) => props.setSelected(true)}
                            onMouseMove={(e) => props.setSelected(false)}
                          >
                            <MenuIcon />
                          </div>
                        </span>
                        <span
                          style={{
                            marginRight: "5%",
                          }}
                        >
                          <div onClick={(e) => setShowModal(true)}>
                            <DriveFileRenameOutlineIcon />
                          </div>
                        </span>
                        <span
                          style={{
                            marginRight: "5%",
                          }}
                        >
                          <div
                            onClick={(e) =>
                              deleteList(doc.id, (success) => {
                                props.setDeletedList(success);
                              })
                            }
                          >
                            <DeleteForeverIcon />
                          </div>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Modal
          className="dashboard-filter-popup"
          size="lg"
          centered
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header className=" pb-0" closeButton>
            {" "}
          </Modal.Header>
          <Modal.Body>
            <formik>
              <div className="dashborad-filter-body d-flex flex-column gap-4">
                <div className="price-sec  filter-row ">
                  <div className="d-flex flex-row"></div>
                </div>
                <div className="price-sec  filter-row ">
                  <div className="d-flex justify-content-between">
                    <div className="validation-text text-capitalize">
                      Change Document Title
                    </div>
                  </div>
                  <div className="price-input-box justify-content-between">
                    <div
                      className="enter-input inner-shadow center flex-grow-1"
                      style={{ maxWidth: "none" }}
                    >
                      <input
                        type="textarea"
                        id=""
                        placeholder="Write Here..."
                        name="filtername"
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        value={title}
                      />
                    </div>
                    <div
                      className="d-flex justify-content-end "
                      style={{ marginTop: "12px" }}
                    >
                      {/* <p>{editSchema?.notes?.length}/180charater</p> */}
                    </div>
                  </div>
                </div>

                <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                  <button
                    className="btn"
                    style={{ width: "135px", borderRadius: "20px" }}
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn"
                    style={{ width: "135px", borderRadius: "20px" }}
                    onClick={() => {
                      changeDocTitle(doc.id, title, (success) => {
                        try {
                          if (success.code === 200) {
                            setShowModal(false);
                            toast.success("Successfully updated title");
                            props.setTitleStatus(title);
                          }
                        } catch (error) {
                          console.log(error);
                          toast.success("There is an error");
                        }
                      });
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default DocumentItemSigned;
