import axios from "axios"
import { backendURL, getToken } from "../../../../appVariable/variable"

export function changepassword(passwordObj, success) {
    let token = getToken()
    axios.post(`${backendURL}/api/user/changepassword/`, passwordObj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            console.log(response);
            success(response)
            // if (response.data.code == 200 && response.data.status == "success") {
            //     console.log(response.data.data);
            //     success(response.data.data);
            // }
        })
        .catch((error) => {
            console.log(error);
        });
}