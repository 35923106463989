import React, { useState, useEffect } from "react";
import "../documents.scss";
import DocumentGroups from "./DocumentItem";
import DocumentItemSigned from "./DocumentItemSigned";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ViewerWrapper from "./pdfViewer/ViewerWrapper";
import { Worker } from "@react-pdf-viewer/core";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { Draggable } from "react-drag-reorder";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { redirect } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShareModal from "../components/shareModal/ShareModal";
import { getPDFList } from "../server";
import { backendURL, getToken } from "../../../../appVariable/variable";
import Loader from "../../Loader/Loader";
import { Modal } from "react-bootstrap";
import { changeOrderDoc } from "../server";
const Documentlist = () => {
  const [pdfDisable, setpdfDisable] = useState(true);
  const [characters, updateCharacters] = useState([]);
  const [selectedTab, setSelectedTab] = useState("home");
  const [selectedDocId, setSelectedDocId] = useState();
  const [isOriginType, setSelectedDocType] = useState(true);
  const [docs, setDocs] = useState([]);
  const [signDocs, setsignDocs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [show, setShow] = useState(null);
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [titleStatus, setTitleStatus] = useState(null);
  const [deletedlist, setDeletedList] = useState(null);
  const [allowDragging, setAllowDragging] = useState(true);
  useEffect(() => {
    setLoading(true);
    getPDFList((success) => {
      const tempShared = success.documents.filter((doc) => {
        if (doc?.shared === true) return doc;
      });
      const shared = tempShared.map((temp) => temp.id);
      setSharedDocs(shared);
      setDocs(success.documents);
      setCustomers(success.customers);
    });
  }, []);
  // useEffect(() => {
  //   const handleMouseUp = () => {
  //     setAllowDragging(true);
  //   };

  //   window.addEventListener("mouseup", handleMouseUp);

  //   return () => {
  //     window.removeEventListener("mouseup", handleMouseUp);
  //   };
  // }, []);
  useEffect(() => {
    getPDFList((success) => {
      console.log("success", success);
      const tempShared = success.documents.filter((doc) => {
        if (doc?.shared === true) return doc;
      });
      const shared = tempShared.map((temp) => temp.id);
      setSharedDocs(shared);
      setDocs(success.documents);
      setCustomers(success.customers);
    });
  }, [show, titleStatus]);

  useEffect(() => {
    if (showShareDialog === false)
      getPDFList((success) => {
        setDocs(success.documents);
        setsignDocs(success.signed_documents);
        setCustomers(success.customers);
        setLoading(false);
      });
  }, [showShareDialog, titleStatus, deletedlist]);

  const navigate = useNavigate();
  const doubleClickDoc = (event, id, docType) => {
    if (event.detail === 2) {
      docType !== "sign" ? setSelectedDocType(true) : setSelectedDocType(false);
      // setpdfDisable(false);
      setSelectedDocId(id);
      // setSelectedTab("pdfview");
      setShowModal(true);
    }
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = Array.from(docs);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    setDocs(items);
    setDocs((state) => {
      return state;
    });
    changeOrderDoc(items, (success) => {
      console.log(success);
    });
  };
  const handleOnDragEnd_Signed = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const items = Array.from(signDocs);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    setsignDocs(items);
    setsignDocs((state) => {
      return state;
    });
    changeOrderDoc(items, (success) => {
      console.log(success);
    });
  };
  const handleUpload = (event) => {
    navigate("upload");
  };
  const handleShare = (event) => {
    setShowShareDialog(true);
  };
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="">
        {loading === true ? <Loader /> : null}
        <div className="main_selperson_tab">
          <div className="d-flex justify-content-end">
            <Button
              className="blue__btn"
              variant="outlined"
              size="middle"
              style={{
                marginRight: "20px",
                borderRadius: "20px",
                minWidth: "64px",
                padding: "10px 20px 10px 20px",
                fontSize: "0.8rem",
              }}
              onClick={handleShare}
            >
              Share
            </Button>
            <Button
              className="blue__btn"
              variant="outlined"
              size={{}}
              onClick={handleUpload}
            >
              Upload
            </Button>
          </div>
          <div>
            <Tabs
              defaultActiveKey="home"
              activeKey={selectedTab}
              id="justify-tab-example"
              className="mb-3 flex-row"
              style={{ justifyContent: "flex-start" }}
              onSelect={(key) => setSelectedTab(key)}
            >
              <Tab
                eventKey="home"
                title="Documentation"
                className="scrollS"
                style={{ height: "50vh" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    margin: "auto",
                    position: "relative",
                  }}
                >
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    {docs.map((doc, index) => (
                      <Droppable
                        key={doc.id}
                        droppableId={`droppable${docs[index].id}`}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <div
                              onClick={(e) => {
                                doubleClickDoc(e, index, "origin");
                              }}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <Draggable
                                isDragDisabled={allowDragging && index !== 0}
                                draggableId={`draggable-${doc.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  let transform =
                                    provided.draggableProps.style.transform;

                                  if (snapshot.isDragging && transform) {
                                    transform = transform.replace(
                                      /\(.+\,/,
                                      "(0,"
                                    );
                                  }

                                  const style = {
                                    ...provided.draggableProps.style,
                                    transform,
                                  };
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={style}
                                    >
                                      <DocumentGroups
                                        document={docs[index]}
                                        setShow={setShow}
                                        index={index}
                                        setTitleStatus={setTitleStatus}
                                        setDeletedList={setDeletedList}
                                        setSelected={setAllowDragging}
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    ))}
                  </DragDropContext>
                </div>
              </Tab>
              <Tab
                eventKey="marksign"
                title="Signed Documents"
                className="scrollS"
                style={{ height: "50vh" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    margin: "auto",
                  }}
                >
                  {/* <Draggable> */}
                  <DragDropContext onDragEnd={handleOnDragEnd_Signed}>
                    {signDocs.map((signdoc, index) => (
                      <Droppable
                        key={`signDoc${index}`}
                        droppableId={`droppable${signDocs[index].id}`}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <div
                              onClick={(e) => {
                                doubleClickDoc(e, index, "origin");
                              }}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <Draggable
                                isDragDisabled={allowDragging && index !== 0}
                                draggableId={`draggable-${signdoc.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  let transform =
                                    provided.draggableProps.style.transform;

                                  if (snapshot.isDragging && transform) {
                                    transform = transform.replace(
                                      /\(.+\,/,
                                      "(0,"
                                    );
                                  }

                                  const style = {
                                    ...provided.draggableProps.style,
                                    transform,
                                  };
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={style}
                                    >
                                      <DocumentItemSigned
                                        document={signDocs[index]}
                                        setShow={setShow}
                                        index={index}
                                        setTitleStatus={setTitleStatus}
                                        setDeletedList={setDeletedList}
                                        setSelected={setAllowDragging}
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    ))}
                  </DragDropContext>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {showShareDialog ? (
        <ShareModal
          setShowShareDialog={setShowShareDialog}
          customers={customers}
          sharedDocs={sharedDocs}
        />
      ) : null}
      {showModal ? (
        <Modal
          className=""
          //   show={modalShow}
          //   onHide={() => setModalShow(false)}
          show={showModal}
          center
          size="xl"
          ria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClose}
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 contact_modal_header"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title">
                <p>PDF View</p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="contact_body">
            <div style={{ margin: "auto" }}>
              <div
                style={{
                  margin: "auto",
                  overflow: "hidden",
                  height: "70vh",
                  borderRadius: "20px",
                }}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
                  {(() => {
                    const fileUrl =
                      isOriginType === true
                        ? docs[selectedDocId]?.file
                        : signDocs[selectedDocId]?.file;
                    return <ViewerWrapper fileUrl={backendURL + fileUrl} />;
                  })()}
                </Worker>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default Documentlist;
