import axios from "axios";
import { backendURL, getToken } from "../../../appVariable/variable";

export function expireListingProperty(success) {
    let token = getToken()
    let userId = token?.userId;
    axios.get(`${backendURL}/api/property/ExpiredListingProperty/${userId}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            } else {
                success([])
            }
        })
        .catch((error) => { console.log(error); });
};

export function agentProfileExpireListingProperty(paramObj, success) {
    let token = getToken()
    // let userId = token?.userId;
    axios.get(`${backendURL}/api/property/closelisting/${paramObj}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response.data.data);
            }
        })
        .catch((error) => { console.log(error); });
}

export function expireListingPropertyCount(paramObj, success) {
    let token = getToken()
    axios.get(`${backendURL}/api/property/closelistingcount/${paramObj}`, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        })
        .catch((error) => { console.log(error); });
}

export function GetAllCityAndLanguaage(paramObj, success) {
    // let token = getToken()
    // let userId = token?.userId;
    axios.get(`${backendURL}/api/user/Teamemeberlanguageandcity/${paramObj}`)
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") { success(response.data.data); }
        })
        .catch((error) => { console.log(error); });
}

export const totalListingcount = (paramObj, success) => {
    let token = getToken();
    // let userid = token?.userId;
    axios.get(`${backendURL}/api/property/totallistingcount/${paramObj}`, {
        header: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        }).catch((error) => { console.log(error); });
}

export function closeExpireListingProperty(accountobj, success) {
    let token = getToken()
    axios.put(`${backendURL}/api/property/ispropertyopen/`, accountobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        })
        .catch((error) => { console.log(error); });
}
export async function deleteExpireListingProperty(accountobj, success) {
    let token = getToken()
    await axios.post(`${backendURL}/api/property/deleteproperty/`, accountobj, {
        headers: { 'Authorization': `Bearer ${token?.idToken}` }
    })
        .then((response) => {
            if (response.data.code === 200 && response.data.status === "success") {
                success(response);
            }
        })
        .catch((error) => { console.log(error); });
}