import React, { useEffect, useState } from "react";
import filter from "../../pages/dashboard/components/mainSection/images/filter.svg";
import search from "../../pages/dashboard/components/mainSection/images/search.svg";
import { useDispatch, useSelector } from "react-redux";
import "./PropertyDetails.scss";
import PropertyCard from "../dashboard/components/mainSection/PropertyCard";
import AdCardGuest from "../dashboard/components/advertise/AdCardGuest";
import {
  getAllpropertyInfo,
  // getAgentTime, getDefaultPropertyAmenityDetailPopup, getDefaultPropertyDetailPopup, getDefaultPropertyDetailPopupWithOutLogin, getDefaultPropertyImagesDetailPopup, getDefaultPropertySalesFeatures, getDefaultPropertySpacesDetailPopup, getTeamMemberShow, removeDefaultPropertyShow, saveDefaultPropertyShow
} from "../dashboard/components/mainSection/server";
import PropertySavePopUp from "../dashboard/components/mainSection/PropertySavePopUp";
import PopUpFilter from "../dashboard/components/filter-popup/PopUpFilter";
import {
  ApplySearch,
  getCategories,
  getLocations,
} from "../homepage/components/hero/server";
import SaveSearchModel from "../dashboard/components/filter-popup/SaveSearchModel";
// import { getNblSpecial } from '../agent-dash-sales/server';
import { Autocomplete, TextField } from "@mui/material";
import PropertyInfoComponent from "../dashboard/propperty-datails/component/PropertyInfoComponent";
import { updatePropertyDatail } from "../dashboard/propperty-datails/PropertySlice";
import { updateFilterData } from "../homepage/components/hero/FilterSlice";
import { useNavigate } from "react-router-dom";

const PropertyDetails = () => {
  // const token = getToken()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const saveSearchPopupVisible = useSelector(
    (state) => state.search.saveSearchPopupVisible
  );
  // const [selectedCheckFilters, setSelectedCheckFilters] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [allProperties, setAllProperties] = useState([]);
  // storing responce for chckbox to show
  const [buyRentFilter, setbuyRentFilter] = useState([]);
  const [checkBoxActiveFiletr, setCheckBoxActiveFiletr] = useState({});

  useEffect(() => {
    let timerOut = setTimeout(() => {
      getLocations(searchQuery, (success) => {
        let newarr = success.area.map((item) => {
          return {
            label: `${
              item.area_name +
              ", " +
              item.city_master.city_name +
              ", " +
              item.city_master.state_master.state_name +
              "," +
              item.city_master.state_master.country_master.country_name
            }`,
            value: {
              area_id: item.id,
              city_id: item.city_master.id,
              state_id: item.city_master.state_master.id,
              country_id: item.city_master.state_master.country_master.id,
            },
          };
        });
        let newarr1 = success.city.map((item) => {
          return {
            label: `${
              item.city_name +
              "," +
              item.state_master.state_name +
              "," +
              item.state_master.country_master.country_name
            }`,
            value: {
              city_id: item.id,
              state_id: item.state_master.id,
              country_id: item.state_master.country_master.id,
            },
          };
        });
        let newarr2 = success.state.map((item) => {
          return {
            label: `${
              item.state_name + "," + item.country_master.country_name
            }`,
            value: {
              state_id: item.id,
              country_id: item.country_master.id,
            },
          };
        });
        setOptionsList([...newarr, ...newarr1, ...newarr2]);
      });
    }, 1000);
    return () => clearTimeout(timerOut);
  }, [searchQuery]);

  const locationOnChange = (e) => {
    dispatch(
      updateFilterData({
        locationText: e?.label,
        area: e?.value?.area_id,
        city: e?.value?.city_id,
        state: e?.value?.state_id,
        country: e?.value?.country_id,
      })
    );
    console.log("++++++++++++++");
    SearchByLoaction();
    console.log("-------------");
  };

  let SearchByLoaction = () => {
    if (
      FilterDataInStore.property_listing &&
      FilterDataInStore.category &&
      FilterDataInStore.city &&
      FilterDataInStore.state
    ) {
      const blockedKeys = [
        "locationText",
        "Filter_responce",
        "filter_name",
        "Neighborhood",
      ];
      const searchSchema = { ...FilterDataInStore };
      blockedKeys.forEach((key) => delete searchSchema[key]);
      ApplySearch(searchSchema, (success) => {
        propertDetails(success.property[0]?.slug);
        dispatch(
          updateFilterData({
            Filter_responce: success.property,
            Neighborhood: success.Neighborhood,
          })
        );
        localStorage.setItem(
          "FilterSearchSchema",
          JSON.stringify({
            ...searchSchema,
            locationText: FilterDataInStore.locationText,
          })
        );
        // if (success?.page === 2) {
        //     localStorage.setItem("FilterSearchSchema",
        //         JSON.stringify({ ...searchSchema, locationText: FilterDataInStore.locationText })
        //     );
        //     // navigate(`/dashboard/property-details`)
        // }
        if (success?.page === 1) {
          navigate("/dashboard/quik-view");
        }
        // if (success?.page === 2) { navigate(`/dashboard/property-details`) }
      });
    }
  };

  useEffect(() => {
    if (FilterDataInStore.Filter_responce.length) {
      propertDetails(FilterDataInStore.Filter_responce[0]?.slug);
      CheckFilterValue(FilterDataInStore?.property_listing);
      setAllProperties(FilterDataInStore.Filter_responce);
    } else {
      GetPrpertyies();
    }
  }, [showFilter]);

  const CheckFilterValue = (id) => {
    getCategories(id, (success) => {
      setbuyRentFilter(success);
    });
  };

  const GetPrpertyies = () => {
    let FilterSearchSchema = JSON.parse(
      localStorage.getItem("FilterSearchSchema")
    );
    const blockedKeys = [
      "locationText",
      "Filter_responce",
      "filter_name",
      "Neighborhood",
    ];
    const searchSchema = { ...FilterSearchSchema };
    blockedKeys.forEach((key) => delete searchSchema[key]);
    ApplySearch(searchSchema, (success) => {
      propertDetails(success.property[0]?.slug);
      dispatch(
        updateFilterData({
          Filter_responce: success.property,
          Neighborhood: success.Neighborhood,
        })
      );
      dispatch(updateFilterData(FilterSearchSchema));
      setAllProperties(success.property);
    });
    CheckFilterValue(FilterSearchSchema?.property_listing);
  };

  let propertDetails = async (id) => {
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    await getAllpropertyInfo(id, (success) => {
      // getting proprty datails
      setPropertyDetailInfo(success);
    });
  };

  const handleCheckFilter = (event) => {
    const { checked, value } = event.target;
    let chechboxvalue = checkBoxActiveFiletr;
    if (checked) {
      // if checkbox check add value in array
      const newState = { ...chechboxvalue, [value]: checked };
      chechboxvalue = newState;
    } else {
      // if checkbox uncheck remove value in array
      const newState = { ...chechboxvalue, [value]: checked };
      delete newState[value];
      chechboxvalue = newState;
    }
    // update array of selected check box
    setCheckBoxActiveFiletr(chechboxvalue);
    const checkedTypes = Object.keys(chechboxvalue);
    if (checkedTypes.length === 0) {
      // if No checkboxes are checked, show all
      dispatch(updateFilterData({ Filter_responce: allProperties }));
    } else {
      // if checkboxes are checked, show filter  properties
      let properties = allProperties;
      let filterProperty = properties.filter((property) =>
        checkedTypes.includes(property?.propertylisting_type?.user_listing_type)
      );
      dispatch(updateFilterData({ Filter_responce: filterProperty }));
    }
  };

  return (
    <>
      <div className="main-sec d-flex flex-column flex-grow-1">
        <div className="filter-search">
          {/* <p>Filter-search</p> */}
          <div className="filter-sec  d-flex " style={{ gap: "16px" }}>
            {/* CheckBox Buy Sell */}
            <div className="buy-rent gap-3 d-flex align-items-center">
              {buyRentFilter?.map((type, i) => (
                <div className="d-flex" key={`filetr${i}`}>
                  <input
                    type="checkbox"
                    name="PropType"
                    className="Prop pointer"
                    id={type.user_listing_type}
                    value={type.user_listing_type}
                    onChange={handleCheckFilter}
                  />
                  <label htmlFor={type.user_listing_type} className="pointer">
                    {type.user_listing_type}
                  </label>
                </div>
              ))}
            </div>

            {/* Search box */}
            <div className="search-box d-flex flex-grow-1">
              <div className="search d-flex align-items-center justify-content-between flex-grow-1">
                <Autocomplete
                  className="hide-downBtn"
                  disablePortal
                  id="combo-box-demo"
                  options={optionsList}
                  // value={getCategoriesVal[0]?.location?.label}
                  onChange={(e, value) => {
                    locationOnChange(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  // sx={{ width: 250 }}
                  style={{ minWidth: "250px", width: "100%", zIndex: "5" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      // label="selected"
                      variant="standard"
                    />
                  )}
                />
                <div
                  className="search-icon pointer"
                  onClick={() => SearchByLoaction()}
                >
                  <img src={search} alt="" />
                </div>
              </div>
              <div
                className="filter-icon pointer d-flex align-items-center justify-content-center"
                onClick={() => setShowFilter(true)}
              >
                {" "}
                <img src={filter} alt="filterIcon" />
              </div>
            </div>
            {/* quik-view map-view */}
          </div>
        </div>

        <div className="main-second-sec d-flex gap-3">
          <div
            className={`propertys-card ${
              FilterDataInStore.Filter_responce.length ? "d-flex" : "d-none"
            }`}
          >
            <p>{FilterDataInStore.locationText.replace(/,/g, "")}</p>
            {/* <div className="card-sec hide-scrollbar "> */}
            <div className="card-sec hide-scrollbar ">
              {FilterDataInStore &&
                FilterDataInStore.Filter_responce?.map((data, i) => (
                  <PropertyCard
                    key={`property${i}`}
                    agentListingData={data}
                    handelOpenPopUp={propertDetails}
                  />
                ))}
            </div>
          </div>

          <div
            className="property-detail-sec flex-grow-1 position-relative hide-scrollbar"
            // style={{ maxHeight: "550px", overflowY: "auto" }}
          >
            <PropertyInfoComponent
              propertyDetailInfo={propertyDetailInfo}
              setPropertyDetailInfo={setPropertyDetailInfo}
            />
          </div>

          <div className="nbls-sec">
            <p className=" text-center">Neighborhood Specialist</p>
            <div className="nbls-card">
              {FilterDataInStore?.Neighborhood?.map((memberinfo, i) => (
                <AdCardGuest key={`neiber${i}`} Memberinfo={memberinfo} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {showFilter && <PopUpFilter setShowFilter={setShowFilter} />}
      {saveSearchPopupVisible && <SaveSearchModel />}

      {/* property save pop up  */}
      {propertyDetailInStore.setSavePropertyPopup && <PropertySavePopUp />}
    </>
  );
};

export default PropertyDetails;
