import React, { useEffect, useState } from "react";
import Footer from "../homepage/components/footer/Footer";
import Navbar from "../homepage/components/navbar/Navbar";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Validation from "./Validation";
import { Passcheck } from "../profile/components/password/Password";
import axios from "axios";
import { backendURL } from "../../../appVariable/variable";
import { toast } from "react-toastify";
import { getGeolocationData } from "./server";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const TeamLogin = () => {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [password, setPassword] = useState("");
  const [uid, setUid] = useState();
  // const [token, setToken] = useState()
  const [extraData, setExtraData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let urlString = window.location.href;
    let paramString = urlString.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    for (let pair of queryString.entries()) {
      if (pair[0] === "uid") {
        setUid(pair[1]);
      }
      // if (pair[0] === "token") {
      //     setToken(pair[1])
      // }
      // console.log("Key is:" + pair[0]);
      // console.log("Value is:" + pair[1]);
    }
  }, []);

  let onsubmit = () => {
    let date = new Date();
    let userlog = {
      action_type: "True",
      date_time: moment(date).format("YYYY-MM-DD HH:MM:ss"),
      ip_address: extraData.IPv4,
      mac_address: "00-14-7B-EE-19-F8",
      latitude: extraData.latitude,
      longitude: extraData.longitude,
      location: `${extraData.city} ${extraData.country_name}`,
    };

    let data = {
      uid: uid,
      password: password,
    };
    let tempObj = { ...data, user_log: userlog };
    setLoading(true);
    axios
      .post(`${backendURL}/api/property/agentregister/`, tempObj)
      .then((response) => {
        if (response.data.code == 200 && response.data.status == "success") {
          console.log(response.data.data);
          // toast.success("Property add Successfully", "success")
          let tempObj = {};
          tempObj["userId"] = response.data.data.user_info.id;
          tempObj["user_info"] = response.data.data.user_info;
          tempObj["profile"] = response.data.data.profile;
          tempObj["expiresIn"] = "96000";
          tempObj["idToken"] = response.data.data.token.access;
          tempObj["refreshToken"] = response.data.data.token.refresh;
          tempObj["accessToken"] = response.data.data.token.access;
          socialLoginSaveTokenInLocalStorage(tempObj);
          let add_on = { add_on: response.data.data.user_info.add_on };
          localStorage.setItem("add_on", JSON.stringify(add_on));

          let sidebar = { sidebar: response.data.data.user_info.agent_profile };
          localStorage.setItem("sidebar", JSON.stringify(sidebar));
          let details = response.data.data;
          // navigate("/")
          setLoading(false);

          if (details.user_info.usertypeobj == 1) {
            navigate("/");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === false
          ) {
            navigate("/subscription");
            console.log("/subscription");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === true &&
            details.user_info.billing == false
          ) {
            navigate("/subscription");
            console.log("/subscription");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === true &&
            details.user_info.billing == true &&
            details.user_info.agent_profile == false
          ) {
            navigate("/agent-dashboard/profile-settings");
            console.log("/agent-dashboard/profile-settings");
          }
          if (
            details.user_info.usertypeobj === 2 &&
            details.user_info.agentsubscription === true &&
            details.user_info.billing == true &&
            details.user_info.agent_profile == true
          ) {
            navigate("/agent-dashboard/add-property");
            console.log("/agent-dashboard/add-property");
          }
        }
        if (response.data.code == 400 && response.data.status == "failed") {
          toast.error("User already registered ");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const socialLoginSaveTokenInLocalStorage = (tokenDetails) => {
    try {
      console.log("save");
      let currentDate = new Date();
      tokenDetails.expireDate = currentDate;
      tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000
      );
      localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
    } catch (err) {
      console.log("err", err);
    }
  };

  function getLoginExtraData() {
    getGeolocationData((success) => {
      console.log("geoData", success);
      setExtraData(success);
    });
  }

  useEffect(() => {
    getLoginExtraData();
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}

      <div>
        <Navbar />
        <div className="loginpage_body forget-page">
          <div className="loginpage_form center flex-column">
            <div className=" w-100">
              <div className="loginpage_text">
                <div className="loginpage_text_head">Enter Password</div>
              </div>
              <div
                className="loginpage_inputs"
                style={{ maxWidth: "500px", margin: "auto" }}
              >
                <div className="input-container">
                  <i className="fa fa-key icon"></i>
                  <span className="login-divider"></span>
                  <input
                    className="input-field"
                    placeholder="Password"
                    name="password"
                    type={isRevealPwd ? "text" : "password"}
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                    // // onBlur={formik.handleBlur}
                    value={password}
                  />

                  {/* view icon of password */}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  >
                    {isRevealPwd ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </div>
                </div>
                <Validation formikPass={password} />
              </div>

              <div className="loginpage_help_button justify-content-center mb-3 mx-auto">
                <div className="loginpage_signin">
                  <button
                    className="loginpage_signin_btn"
                    type="submit"
                    onClick={() => onsubmit()}
                    disabled={!Passcheck.test(password)}
                  >
                    <span className="loginpage_signin_btn_text">Register</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TeamLogin;
