import React, { useEffect, useState } from "react";
import "./dashfilterpop.scss";
import { Select, MenuItem, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getToken } from "../../../../../appVariable/variable";
import {
  ApplySearch,
  getCategories,
  getTypeOfProperties,
  getTypesBasedOnCategory,
  getTypesBasedOnTypeLeisure,
} from "../../../homepage/components/hero/server";
import {
  showSaveSearchPopup,
  updateComBedRoom,
  updateComRoom, // hideSaveSearchPopup, updateSeacrhSchema,  updateMyValue,hideCommercialPopup
} from "../../../homepage/components/hero/HeroSlice";
import { numberWithCommas } from "../../../homepage/components/hero/Hero";
import { updateFilterData } from "../../../homepage/components/hero/FilterSlice";
import { useNavigate } from "react-router-dom";
import { getamminity } from "./server/server";
import { updateFilterOptions } from "../../../homepage/components/hero/FilterOptionSlice";
import { getAccounttype } from "../../../personal/typeOfAccount/server";

export const CustumSelect = styled(Select)({
  padding: "0px",
  width: "100%",
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0, padding: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "MuiInputBase-input": { padding: 0 },
});

const PopUpFilter = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  const FilterOptionsInStore = useSelector(
    (state) => state.FilterOptionsInStore
  );
  const commercialPopupVisible = useSelector(
    (state) => state.search.commercialPopupVisible
  );
  const getComBedRoom = useSelector((state) => state.search.comBedRoom);
  const getComRoom = useSelector((state) => state.search.comRoom);
  const token = getToken();
  const userId = token?.userId;
  console.log("token:", userId);
  const [minPrice, setMinPrice] = useState(
    numberWithCommas(
      Boolean(FilterDataInStore.pricemin) ? FilterDataInStore.pricemin : 0
    )
  );
  const [maxPrice, setMaxPrice] = useState(
    numberWithCommas(
      Boolean(FilterDataInStore.pricemax) ? FilterDataInStore.pricemax : 0
    )
  );
  const [leisureType, setLeisureType] = useState([]);
  const [allAminities, setAllAminities] = useState([]);
  const [resBedroom, setResBedroom] = useState(
    FilterDataInStore.Bedrooms ? FilterDataInStore.Bedrooms : 0
  );
  const [resBathroom, setResBathroom] = useState(
    FilterDataInStore.Bathrooms ? FilterDataInStore.Bathrooms : 0
  );
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [typesBasedOnCategory, setTypesBasedOnCategory] = useState([]);
  const [leisureTypeShow, setLeisureTypeShow] = useState(false);
  let CommertialInputs = [
    { name: "block", placeholder: "Block" },
    { name: "lot", placeholder: "Lot" },
    { name: "Zone", placeholder: "Zone" },
    { name: "lot_diamensions", placeholder: "Lot Dimensions" },
    { name: "building_diamensions", placeholder: "Building Dimensions" },
    { name: "stories", placeholder: "Stories" },
    { name: "far", placeholder: "FAR" },
    { name: "Assessment", placeholder: "Assessment" },
    { name: "Annual_Taxes", placeholder: "Annual Taxes" },
    { name: "Available_Air_Rights", placeholder: "Available Air Rights" },
  ];
  const [errors, setErrors] = useState({
    category: "",
    type: "",
    pricemin: "",
    pricemax: "",
    Squftmax: "",
    Squftmin: "",
  });
  const AcceptOnlyNumber = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    const name = event.target.name;
    let userinput = {};
    userinput[name] = +result;
    dispatch(updateFilterData(userinput));
  };

  const getTypeDefault = (id) => {
    getTypesBasedOnCategory(id, (success) => {
      let newarr = success.map((item) => {
        return { label: `${item?.Main_category}`, id: item.id };
      });
      setTypesBasedOnCategory([...newarr]);
      dispatch(updateFilterOptions({ typeOptions: newarr }));
    });
  };

  const subTypeValue = (id) => {
    getTypesBasedOnTypeLeisure(Number(id), (sucess) => {
      if (sucess.data.code === 200) {
        setLeisureTypeShow(true);
        let newarr = sucess?.data?.data?.map((item) => {
          return { label: `${item.property_sub_category_Name}`, id: item.id };
        });
        setLeisureType([...newarr]);
      } else {
        setLeisureTypeShow(false);
        dispatch(updateFilterData({ Sub_category: null }));
      }
    });
  };

  const minPriceHandle = (event) => {
    const result = numberWithCommas(event.target.value);
    setMinPrice(result);
    dispatch(updateFilterData({ pricemin: +result.replace(/[^0-9]/g, "") }));
  };

  const maxPriceHandle = (event) => {
    const result = numberWithCommas(event.target.value);
    setMaxPrice(result);
    dispatch(updateFilterData({ pricemax: +result.replace(/[^0-9]/g, "") }));
  };

  const handleChange = (e) => {
    const { checked, name } = e.target;
    let prevousVal = FilterDataInStore.Amenities_filter;
    if (checked) {
      dispatch(updateFilterData({ Amenities_filter: [...prevousVal, +name] }));
    } else {
      dispatch(
        updateFilterData({
          Amenities_filter: prevousVal?.filter((v) => v !== +name),
        })
      );
    }
  };

  useEffect(() => {
    let type_of_listing = token?.user_info?.type_of_listing;
    getamminity((success) => {
      setAllAminities(success);
    });
    let FilterSearchSchema = JSON.parse(
      localStorage.getItem("FilterSearchSchema")
    );
    if (userId) {
      getTypeOfProperties((success) => {
        GetPropertyCategory(success[type_of_listing].id);
        dispatch(
          updateFilterData({ property_listing: success[type_of_listing].id })
        );
      });
    } else {
      if (FilterDataInStore.property_listing === null) {
        dispatch(updateFilterData(FilterSearchSchema));
        GetPropertyCategory(FilterSearchSchema.property_listing);
      } else {
        GetPropertyCategory(FilterDataInStore.property_listing);
      }
    }
  }, []);

  let GetPropertyCategory = (property_id) => {
    getCategories(property_id, (success) => {
      let newarr = success.map((item) => {
        return { label: `${item?.listing_type}`, id: item?.id };
      });
      setCategoryListOptions(newarr);
      dispatch(updateFilterOptions({ categotyOptions: newarr }));
      getTypeDefault(newarr[0]?.id);
    });
  };

  const saveSearchPopComm = () => {
    if (
      FilterDataInStore.property_listing &&
      FilterDataInStore.category &&
      FilterDataInStore.type
    ) {
      props.setShowFilter(false);
      dispatch(showSaveSearchPopup());
      setErrors((prevObject) => ({ ...prevObject, type: "", category: "" }));
    } else {
      setErrors((prevObject) => ({
        ...prevObject,
        type: "Select type",
        category: "select categoty",
      }));
    }
  };

  let searchFilter = () => {
    if (
      FilterDataInStore.property_listing &&
      FilterDataInStore.category &&
      FilterDataInStore.type
    ) {
      if (
        FilterDataInStore.pricemax >= FilterDataInStore.pricemin &&
        FilterDataInStore.Squft_max >= FilterDataInStore.Squft_min
      ) {
        const blockedKeys = [
          "locationText",
          "Filter_responce",
          "filter_name",
          "Neighborhood",
        ];
        const searchSchema = { ...FilterDataInStore };
        blockedKeys.forEach((key) => delete searchSchema[key]);
        ApplySearch(searchSchema, (success) => {
          dispatch(
            updateFilterData({
              Filter_responce: success.property,
              Neighborhood: success.Neighborhood,
            })
          );
          localStorage.setItem(
            "FilterSearchSchema",
            JSON.stringify(searchSchema)
          );
          if (success?.page === 1) {
            navigate("/dashboard/quik-view");
          }
          if (success?.page === 2) {
            navigate(`/dashboard/property-details`);
          }
          props.setShowFilter(false);
        });
      } else {
        alert("PLease Enetr valid Inputs");
      }
    } else {
      setErrors((prevObject) => ({
        ...prevObject,
        type: "Select type",
        category: "select categoty",
      }));
    }
  };

  const clearSearch = () => {
    localStorage.setItem("FilterSearchSchema", null);
  };

  return (
    <>
      <div>
        <Modal
          className={`${
            commercialPopupVisible ? "popupssss" : "popup hidden"
          } dashboard-filter-popup`}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={() => props.setShowFilter(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="dashborad-filter-body d-flex flex-column gap-4">
              <div className="select-property-type filter-row">
                <div className="d-flex gap-3 justify-content-between">
                  <p className={"filter-heading"}>Category</p>
                  <div className="validation-text text-capitalize">
                    {errors.category}
                  </div>
                </div>
                <div className="select-box inner-shadow">
                  <CustumSelect
                    className="hide-downBtn hero-select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    value={FilterDataInStore.category}
                    label="Category"
                    onChange={(e) => {
                      getTypeDefault(e.target.value);
                      dispatch(updateFilterData({ category: e.target.value }));
                      setErrors((prevObject) => ({
                        ...prevObject,
                        category: "",
                      }));
                    }}
                  >
                    {FilterOptionsInStore.categotyOptions.length > 0 ? (
                      FilterOptionsInStore.categotyOptions?.map(
                        (item, index) => (
                          <MenuItem key={`category${index}`} value={item.id}>
                            {" "}
                            {item.label}{" "}
                          </MenuItem>
                        )
                      )
                    ) : (
                      <MenuItem value={""}>No Option</MenuItem>
                    )}
                  </CustumSelect>
                </div>
              </div>

              <div className="select-property-type filter-row">
                <div className="d-flex gap-3 justify-content-between">
                  <p className="filter-heading">Property Type</p>
                  <div className="validation-text text-capitalize">
                    {errors.type}
                  </div>
                </div>
                <div className="select-box inner-shadow">
                  <CustumSelect
                    className="hide-downBtn hero-select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={FilterDataInStore.type}
                    label="type"
                    onChange={(e) => {
                      subTypeValue(e.target.value);
                      dispatch(updateFilterData({ type: e.target.value }));
                      setErrors((prevObject) => ({ ...prevObject, type: "" }));
                    }}
                  >
                    {FilterOptionsInStore.typeOptions.length > 0 > 0 ? (
                      FilterOptionsInStore.typeOptions?.map((item, index) => (
                        <MenuItem key={`type${index}`} value={item.id}>
                          {item?.label}{" "}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""}>Select Category</MenuItem>
                    )}
                  </CustumSelect>
                </div>
              </div>

              {leisureTypeShow && (
                <div className="select-property-type filter-row">
                  <p className="filter-heading">Sub Type</p>
                  <div className="select-box inner-shadow">
                    <CustumSelect
                      className="hide-downBtn hero-select"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={FilterDataInStore.Sub_category}
                      label="subtype"
                      onChange={(e) => {
                        dispatch(
                          updateFilterData({ Sub_category: e.target.value })
                        );
                      }}
                    >
                      {FilterOptionsInStore?.SubTypeOptions.length > 0 ? (
                        FilterOptionsInStore?.SubTypeOptions?.map(
                          (item, index) => (
                            <MenuItem key={`l${index}`} value={item.id}>
                              {item.label}{" "}
                            </MenuItem>
                          )
                        )
                      ) : (
                        <MenuItem value={""}>No Option</MenuItem>
                      )}
                    </CustumSelect>
                  </div>
                </div>
              )}

              <div className="unit-qty d-flex gap-3 justify-content-between">
                {/* residential input start */}
                {FilterDataInStore.property_listing === 1 && (
                  <div className="unit-box filter-row">
                    <p className="filter-heading">Bedroom</p>
                    <div className="update-unit inner-shadow center justify-content-evenly">
                      <RemoveIcon
                        className="pointer"
                        onClick={() => {
                          setResBedroom((pre) => {
                            return pre < 1 ? 0 : pre - 1;
                          });
                          dispatch(
                            updateFilterData({
                              Bedrooms:
                                resBedroom - 1 < 1 ? null : resBedroom - 1,
                            })
                          );
                        }}
                      />
                      {resBedroom}
                      <AddIcon
                        className="pointer"
                        onClick={() => {
                          dispatch(
                            updateFilterData({ Bedrooms: resBedroom + 1 })
                          );
                          setResBedroom((pre) => {
                            return pre + 1;
                          });
                        }}
                      />
                    </div>
                  </div>
                )}

                {FilterDataInStore.property_listing === 1 && (
                  <div className="unit-box filter-row">
                    <p className="filter-heading">Bathroom</p>
                    <div className="update-unit inner-shadow center justify-content-evenly">
                      <RemoveIcon
                        className="pointer"
                        onClick={() => {
                          setResBathroom((pre) => {
                            return pre < 1 ? 0 : pre - 1;
                          });
                          dispatch(
                            updateFilterData({
                              Bathrooms:
                                resBathroom - 1 < 1 ? null : resBathroom - 1,
                            })
                          );
                        }}
                      />
                      {resBathroom}
                      <AddIcon
                        className="pointer"
                        onClick={() => {
                          dispatch(
                            updateFilterData({ Bathrooms: resBathroom + 1 })
                          );
                          setResBathroom((pre) => {
                            return pre + 1;
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* residential input end */}

                {/* commertial input start */}
                {FilterDataInStore.property_listing === 2 && (
                  <div className="unit-box filter-row">
                    <p className="filter-heading">Units</p>
                    <div className="update-unit inner-shadow center justify-content-evenly">
                      <RemoveIcon
                        className="pointer"
                        onClick={() => {
                          dispatch(
                            updateComBedRoom(
                              getComBedRoom > 0 ? getComBedRoom - 1 : 0
                            )
                          );
                          dispatch(
                            updateFilterData({
                              units:
                                getComBedRoom > 0 ? getComBedRoom - 1 : null,
                            })
                          );
                        }}
                      />
                      {getComBedRoom}
                      <AddIcon
                        className="pointer"
                        onClick={() => {
                          dispatch(
                            updateFilterData({ units: getComBedRoom + 1 })
                          );
                          dispatch(updateComBedRoom(getComBedRoom + 1));
                        }}
                      />
                    </div>
                  </div>
                )}
                {FilterDataInStore.property_listing === 2 && (
                  <div className="unit-box filter-row">
                    <p className="filter-heading">Room</p>
                    <div className="update-unit inner-shadow center justify-content-evenly">
                      <RemoveIcon
                        className="pointer"
                        onClick={() => {
                          dispatch(
                            updateComRoom(getComRoom > 0 ? getComRoom - 1 : 0)
                          );
                          dispatch(
                            updateFilterData({
                              room: getComRoom > 0 ? getComRoom - 1 : 0,
                            })
                          );
                        }}
                      />
                      {getComRoom}
                      <AddIcon
                        className="pointer"
                        onClick={() => {
                          dispatch(updateComRoom(getComRoom + 1));
                          dispatch(updateFilterData({ room: getComRoom + 1 }));
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* commertial input end */}

                <div className="unit-box filter-row">
                  <p className="filter-heading">Sqft</p>
                  <div className="d-flex gap-4">
                    <div className="update-unit inner-shadow center justify-content-evenly">
                      <input
                        type="number"
                        name="Squftmin"
                        id=""
                        placeholder="Sqft Min"
                        onChange={(e) => {
                          dispatch(
                            updateFilterData({ Squft_min: e.target.value })
                          );
                        }}
                        value={FilterDataInStore.Squft_min}
                      />
                      {errors.Squftmin && (
                        <div className="validation-text">{errors.Squftmin}</div>
                      )}
                    </div>
                    <div className="update-unit inner-shadow center justify-content-evenly">
                      <input
                        type="number"
                        name="Squftmax"
                        id=""
                        placeholder="Sqft Max"
                        onChange={(e) => {
                          dispatch(
                            updateFilterData({ Squft_max: e.target.value })
                          );
                        }}
                        value={FilterDataInStore.Squft_max}
                      />
                      {errors.Squftmax && (
                        <div className="validation-text">{errors.Squftmax}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="price-sec  filter-row ">
                <p className="filter-heading">Price</p>
                <div className="filter-radio d-flex gap-3">
                  <div className="d-flex gap-2">
                    <input
                      type="radio"
                      name="Price"
                      className="Prop"
                      id="monthly-price"
                      value="1"
                    />
                    <label htmlFor="monthly-price" className="pointer">
                      Total Monthly Cost
                    </label>
                  </div>
                  <div className="d-flex gap-2">
                    <input
                      type="radio"
                      name="Price"
                      className="Prop"
                      id="sqft-price"
                      value="2"
                    />
                    <label htmlFor="sqft-price" className="pointer">
                      Cost Per Sqft
                    </label>
                  </div>
                </div>

                <div className="price-input-box d-flex justify-content-between">
                  <div className="enter-input inner-shadow center flex-grow-1">
                    <input
                      type="text"
                      id=""
                      placeholder="Price Min"
                      name="pricemin"
                      value={minPrice}
                      onChange={(e) => minPriceHandle(e)}
                    />
                    {errors.pricemin && (
                      <div className="validation-text">{errors.pricemin}</div>
                    )}
                  </div>

                  <div className="enter-input inner-shadow  center flex-grow-1 ">
                    <input
                      name="pricemax"
                      type="text"
                      id=""
                      placeholder="Price Max"
                      value={maxPrice}
                      onChange={(e) => maxPriceHandle(e)}
                    />
                    {errors.pricemax && (
                      <div className="validation-text">{errors.pricemax}</div>
                    )}
                  </div>
                </div>
              </div>

              {/*other commertial input start */}
              {FilterDataInStore.property_listing === 2 && (
                <div className="user-multi-inputs d-flex">
                  {CommertialInputs?.map((input) => (
                    <div
                      className="one-input  inner-shadow  center"
                      key={`comminput${input.name}`}
                    >
                      <input
                        type="number"
                        placeholder={input.placeholder}
                        name={input.name}
                        onChange={(e) => {
                          AcceptOnlyNumber(e);
                        }}
                        value={FilterDataInStore[input.name]}
                        // value={FilterDataInStore[input.name]?.toString()}
                      />
                    </div>
                  ))}
                </div>
              )}
              {/*other commertial input end */}

              {/*Residential aminity */}
              {FilterDataInStore.property_listing === 1 && (
                <div className="filter-row">
                  <p className="filter-heading">Amenities</p>
                  <div className="Amenities d-flex  flex-wrap ">
                    {allAminities?.map((aminiti, i) => (
                      <div
                        className="select-aminity position-relative"
                        key={`aminity${i}`}
                      >
                        <input
                          type="checkbox"
                          className="Prop aminity-input visually-hidden"
                          id={`filteraminity${i}`}
                          name={`${aminiti.id}`}
                          onChange={handleChange}
                          checked={FilterDataInStore.Amenities_filter?.includes(
                            aminiti.id
                          )}
                        />
                        <label
                          htmlFor={`filteraminity${i}`}
                          className="pointer aminity"
                        >
                          {aminiti.amenities_name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                {userId ? (
                  <button className="btn" onClick={clearSearch}>
                    Clear Search
                  </button>
                ) : null}
                {userId ? (
                  <button className="btn" onClick={saveSearchPopComm}>
                    Save Search
                  </button>
                ) : null}
                <button className="btn" onClick={searchFilter}>
                  Apply
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PopUpFilter;
