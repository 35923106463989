import React from 'react'
// import GuestVirtualCard from './Components/GuestVirtualCard'
import GuestVirtualPopup from './guestVirtualOfficePop/GuestVirtualPopup'
import AgentCustomerCards from '../agentCustomerVirtualOffice/AgentCustomerCards'


 function GuestVirtualOffice() {
  return (
    <div>
      {/* <GuestVirtualCard/> */}
      <GuestVirtualPopup/>
    </div>
  )
}
export default GuestVirtualOffice
