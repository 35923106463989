import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import "./virtualOfficemain.scss";
import createnew from "./images/createVirtualOfice.svg";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { backendURL, getToken } from "../../../appVariable/variable";
import axios from "axios";
import VirtualCard from "./VirtualCard";
import { useSelector } from "react-redux";
import GuestVirtualCard from "./GuestVirtualCard";

// import { Passcheck } from '../profile/components/password/Password';
// const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const VirtualOffice = () => {
  let token = getToken();
  // const [depedancy, setDepedancy] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [userEmails, setUserEmails] = useState([]);
  const [officeName, setOfficeName] = useState("");
  const [loading, setLoading] = useState(false);
  const [agentVirtualOffice, setAgentVirtualOffice] = useState([]);
  let userInfo = useSelector((state) => state.DashboardSliceInStore.userInfo);
  const AddVirtualOffice = () => {
    let schemaObj = {
      userid: token.userId,
      Virtual_Office_Name: officeName,
      Invite_Member: userEmails,
    };
    if (officeName?.length > 5 && userEmails?.length > 0) {
      setLoading(true);
      // AgentRating
      axios
        .post(
          `${backendURL}/api/virtual_office/CreateVirtualOffice/`,
          schemaObj,
          {
            headers: {
              Authorization: `Bearer ${token?.idToken}`,
            },
          }
        )
        .then((response) => {
          if (
            response.data.code === 200 &&
            response.data.status === "success"
          ) {
            setLoading(false);
            setModalShow(false);
            // toast.success(response.data.data, "success")
            setUserEmails([]);
            setOfficeName("");
            GetVirtualOffice();
          }
          if (response.data.code === 400 && response.data.status === "failed") {
            // toast.error(response.data.data, "error")
            setLoading(false);
            setModalShow(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      // console.log("enter experiance")
    }
  };

  const GetVirtualOffice = () => {
    let localdata = JSON.parse(localStorage.getItem("userDetails"));
    let userInfo = localdata.user_info;
    let userTypeUrl =
      userInfo.usertypeobj != 1
        ? "virtual_office/CreateVirtualOffice"
        : "virtual_office/ShowAgentProfileOnVirtualOffice";

    let userId = token?.userId;
    axios
      .get(`${backendURL}/api/${userTypeUrl}/${userId}`, {
        headers: { Authorization: `Bearer ${token?.idToken}` },
      })
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
          setAgentVirtualOffice(response.data.data);
          setLoading(false);
        } else {
          // console.log(response.data.data)
          setAgentVirtualOffice([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  };

  const DeleteVirtualOffice = (officeId) => {
    setLoading(true);
    axios
      .delete(
        `${backendURL}/api/virtual_office/CreateVirtualOffice/${officeId}`,
        { headers: { Authorization: `Bearer ${token?.accessToken}` } }
      )
      .then((response) => {
        if (response.data.code === 200 && response.data.status === "success") {
          GetVirtualOffice();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false); // console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    GetVirtualOffice();
  }, []);
  // }, [depedancy])

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="virtual_office_page  hide-scrollbar">
        <div className="virtualCard-container d-flex flex-wrap">
          {userInfo.usertypeobj != 1
            ? agentVirtualOffice?.map((info, index) => (
                <VirtualCard
                  key={`VirtualCard${index}`}
                  info={info}
                  DeleteVirtualOffice={DeleteVirtualOffice}
                />
              ))
            : agentVirtualOffice?.map((info, index) => {
                return (
                  <GuestVirtualCard
                    key={"vo" + index}
                    info={info}
                    GetVirtualOffice={GetVirtualOffice}
                    setLoading={setLoading}
                  />
                );
              })}

          {userInfo?.usertypeobj != 1 ? (
            <div
              className="virtualoffice-card p-4 card_border left_card center"
              style={{ width: "226px" }}
            >
              <div className="image_center center">
                <div
                  className="center flex-column"
                  onClick={() => setModalShow(true)}
                >
                  <img
                    src={createnew}
                    className="pointer"
                    alt=""
                    style={{ width: "40px" }}
                  />
                  <p className="text-center pt-3">Create New Virtual Office</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/*start modal vertiul office */}
      <div className="virtual_ofice_modal">
        <Modal
          className="virtual_ofice_modal"
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          ria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 virtual_modals_header gdgyg"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title"></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="virtual_modals_body">
            <div className="">
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad">
                  Office Name<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <input
                    type="text"
                    className="form-control border-0 pb-0"
                    onChange={(e) => {
                      setOfficeName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex main_input">
                <div className="input_name grow-1 text-end input_namepad align-items-center">
                  Invite Member<span className="red-star">*</span>
                </div>
                <div className="input-sec">
                  <Autocomplete
                    multiple
                    freeSolo={true}
                    name="showonpages"
                    value={userEmails}
                    onChange={(e, newValue) => {
                      var expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      var regex = new RegExp(expression);
                      var t =
                        newValue.length > 0
                          ? newValue[newValue.length - 1]
                          : "";
                      if (t.match(regex)) {
                        setUserEmails(newValue);
                      } else {
                        if (newValue.length === 0) {
                          setUserEmails([]);
                        }
                      }
                    }}
                    id="checkboxes-tags-demo"
                    options={[]}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Enter Email & Press Enter"
                        name="showonpages"
                        sx={{
                          "& legend": { display: "none" },
                          // "& fieldset": { top: 0 },
                          "& fieldset": { border: "none" },
                        }}
                        {...params}
                        InputLabelProps={{ shrink: false }}
                        label=""
                      />
                    )}
                  />
                </div>
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  className="create_btn blue__btn"
                  onClick={() => AddVirtualOffice()}
                >
                  Create & Send
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/*end modal vertiul office */}
    </>
  );
};

export default VirtualOffice;
