import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./commertialMapFilter.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { getRadioButtons } from "../../agent-dash-sales/server";
import { useSelector } from "react-redux";
let Allaminity = [
  "Penthouse",
  "Triplex",
  "Duplex",
  "Doorman",
  "Storage",
  "Elevator",
  "Parking",
  "Laundry",
];
const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    display: "none",
  },
  "&:before": {
    display: "none",
  },
  "&:after": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    height: "14px",
    minHeight: "unset",
    color: "#315efb",
    padding: "0px 8px 0px 0px",
    ".MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "end",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CommertialMapFilter = ({ groupByFilter, virtualOfficeId }) => {
  const [unit, setUnit] = useState(1);
  const [room, setRoom] = useState(1);
  const [Type, setType] = useState([]);
  const [minSqft, setminSqft] = useState(null);
  const [maxSqft, setmaxSqft] = useState(null);
  const [minPrice, setminPrice] = useState(null);
  const [maxPrice, setmaxPrice] = useState(null);
  const [allradios, setAllRadios] = useState([]);
  const [category, setCategory] = useState(10);
  const [subType, setSubType] = useState(1);
  const [priceType, setPriceType] = useState("Total");
  const [expanded, setExpanded] = useState("panel1");
  const [leaseType, setLeaseType] = useState("");
  const [groundLease, setGroundLease] = useState("");
  const [minCap, setMinCap] = useState(null);
  const [maxCap, setMaxCap] = useState(null);
  const [minOccup, setMinOccup] = useState(null);
  const [maxOccup, setMaxOccup] = useState(null);
  const [airRights, setAirRights] = useState("");
  const [viewsetting, setViewsetting] = useState(false);
  const virtualofficeid = useSelector(
    (state) => state.GuestVirtualOfficeSliceInStore.guestOfficeId
  );
  const handleCategory = (event) => {
    setCategory(event.target.value);
  };
  const handleLeaseType = (event) => {
    setLeaseType(event.target.value);
  };
  const handleSubType = (event) => {
    setSubType(event.target.value);
  };
  const handleGroundLease = (event) => {
    setGroundLease(event.target.value);
  };
  const handlePriceType = (event) => {
    setPriceType(event.target.value);
  };
  let radioObj = { Property_listing_type_id: 3 };
  useEffect(() => {
    getRadioButtons(radioObj, (success) => {
      setAllRadios(success);
    });
  }, []);
  const handleRadioChange = (e, category_position) => {
    const tempType = [...Type];
    const index = tempType.indexOf(category_position);
    e.target.checked
      ? tempType.push(category_position)
      : index > -1 && tempType.splice(index, 1);
    setType(tempType);
    setType((state) => {
      return state;
    });
  };

  const sendFormdata = () => {
    let propertylisting_type_id = null;
    propertylisting_type_id = category === 10 ? 3 : 4;

    const filterGroup = {
      propertylisting_type_id: propertylisting_type_id,
      property_main_category_id: Type,
      units: unit,
      rooms: room,
      property_sub_category_id: subType === 0 ? null : subType,
      priceType: priceType,
      minCap: minCap,
      maxCap: maxCap,
      minOccup: minOccup,
      maxOccup: maxOccup,
      min_sqft: minSqft,
      max_sqft: maxSqft,
      min_price: minPrice,
      max_price: maxPrice,
      available_air_right: airRights === "y" ? true : false,
      leaseType: leaseType,
      groundLease: groundLease,
      is_this_week: viewsetting,
      virtualoffice_id: virtualOfficeId ? virtualOfficeId : virtualofficeid,
    };
    groupByFilter(filterGroup);
  };

  const handleViewMoreChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div
        className=" d-flex flex-column filter-body scrollS"
        style={{ width: "220px", height: "100%" }}
      >
        <div className="filter-by filter-form-row">
          <p className="filter-heading">Filter By :</p>
        </div>

        <div className="filter-form d-flex flex-column">
          <div className="type filter-form-row">
            <p className="small-heading">Category</p>
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={category}
                className="select-category"
                onChange={(e) => handleCategory(e)}
              >
                <MenuItem value={10}>Lease</MenuItem>
                <MenuItem value={20}>Buy</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="aminity-type filter-form-row  ">
            <p className="small-heading">Type</p>
            <div className="d-flex justify-content-between">
              <div className="checkbox-column d-flex flex-column">
                <div className="checkbox-column d-flex flex-row flex-wrap">
                  {allradios.map((checkbox, index) => {
                    return (
                      <div className="aminity-radio" key={`radio${index}`}>
                        <input
                          type="checkbox"
                          id={`radio${index}`}
                          name="aminity"
                          value={checkbox.Main_category}
                          onChange={(e) =>
                            handleRadioChange(e, checkbox.category_position)
                          }
                        />
                        <label htmlFor="Room" className="aminity-radio-label">
                          {checkbox.Main_category}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="type filter-form-row">
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50, marginLeft: "0px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={subType}
                className="select-category"
                onChange={(e) => handleSubType(e)}
                placeholder="Sub Type"
              >
                <MenuItem value={0}>Sub Type</MenuItem>
                <MenuItem value={1}>Industry</MenuItem>
                <MenuItem value={2}>Sepciality</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="bedroom-bathroom">
            <div className=" small-in-box-div">
              <div className="filter-form-row ">
                <p className="small-heading">Unit</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p onClick={() => setUnit(unit + 1)} className="pointer">
                    +
                  </p>
                  <p>{unit}</p>
                  <p onClick={() => setUnit(unit - 1)} className="pointer">
                    -
                  </p>
                </div>
              </div>
              <div className="filter-form-row">
                <p className="small-heading">Room</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p onClick={() => setRoom(room + 1)} className="pointer">
                    +
                  </p>
                  <p>{room}</p>
                  <p onClick={() => setRoom(room - 1)} className="pointer">
                    -
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sqft-price filter-form-row">
            <p className="small-heading">Sqft</p>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Sqft Min"
                  onChange={(e) => {
                    setminSqft(Number(e.target.value));
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Sqft Max"
                  onChange={(e) => {
                    setmaxSqft(Number(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
          {/* price section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Price</p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1">
                {category === 10 ? (
                  <>
                    <div className="d-flex gap-1">
                      <input
                        type="radio"
                        name="PriceType"
                        className="Prop"
                        id="monthly-price"
                        value="1"
                        onClick={(e) => {
                          handlePriceType(e);
                        }}
                      />
                      <label
                        htmlFor="monthly-price"
                        className="price-type-text"
                      >
                        Total Monthly Cost
                      </label>
                    </div>
                    <div className="d-flex gap-1">
                      <input
                        type="radio"
                        name="PriceType"
                        className="Prop"
                        id="sqft-price"
                        value="2"
                        onClick={(e) => {
                          handlePriceType(e);
                        }}
                      />
                      <label htmlFor="sqft-price" className="price-type-text">
                        Cost Per Sqft
                      </label>
                    </div>
                  </>
                ) : (
                  <div className="d-flex gap-1">
                    <input
                      type="radio"
                      name="CostPerSqft"
                      className="Prop"
                      id="sqft-price"
                      value="2"
                      onClick={(e) => {
                        handlePriceType(e);
                      }}
                    />
                    <label htmlFor="sqft-price" className="price-type-text">
                      + Cost Per Sqft (if provided)
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Price Min"
                  onChange={(e) => {
                    setminPrice(Number(e.target.value));
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="number"
                  placeholder="Price Max"
                  onChange={(e) => {
                    setmaxPrice(Number(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
          {category === 10 ? null : (
            <>
              <div className="sqft-price  filter-form-row flex-row gap-4">
                <div className="sqft-price filter-form-row">
                  <p className="small-heading">Cap Rate</p>
                  <div className=" d-flex small-in-box-div">
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="0%"
                        onChange={(e) => {
                          setMinCap(Number(e.target.value));
                        }}
                      />
                    </div>
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="100%"
                        onChange={(e) => {
                          setMaxCap(Number(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="sqft-price filter-form-row">
                  <p className="small-heading">Occupancy</p>
                  <div className=" d-flex small-in-box-div">
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="0%"
                        onChange={(e) => {
                          setMinOccup(Number(e.target.value));
                        }}
                      />
                    </div>
                    <div className="input-sec small-in-box-cap">
                      <input
                        type="number"
                        placeholder="100%"
                        onChange={(e) => {
                          setMaxOccup(Number(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* lease type section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">
              {category === 10 ? "Lease Type" : "Investment of Lease Type"}
            </p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1 flex-wrap">
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="new-lease"
                    value="newlease"
                    onClick={(e) => handleLeaseType(e)}
                  />
                  <label htmlFor="new-lease" className="price-type-text">
                    New Lease
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="triple"
                    value="triple"
                    onClick={(e) => handleLeaseType(e)}
                  />
                  <label htmlFor="triple" className="price-type-text">
                    Triple Net
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="absolute"
                    value="absolute"
                    onClick={(e) => handleLeaseType(e)}
                  />
                  <label htmlFor="absolute" className="price-type-text">
                    Absolute Net
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="gross"
                    value="gross"
                    onClick={(e) => handleLeaseType(e)}
                  />
                  <label
                    htmlFor="gross"
                    className="price-type-text"
                    style={{ width: "40px" }}
                  >
                    Gross
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="modified"
                    value="modified"
                    onClick={(e) => handleLeaseType(e)}
                  />
                  <label htmlFor="modified" className="price-type-text">
                    Modified Gross
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* property condition section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Property Conditions&Class</p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1">
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="ground"
                    value="ground"
                    onClick={(e) => handleGroundLease(e)}
                  />
                  <label htmlFor="ground" className="price-type-text">
                    Ground Lease
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="a"
                    value="a"
                    onClick={(e) => handleGroundLease(e)}
                  />
                  <label htmlFor="a" className="price-type-text">
                    A
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="b"
                    value="b"
                    onClick={(e) => handleGroundLease(e)}
                  />
                  <label htmlFor="b" className="price-type-text">
                    B
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="c"
                    value="c"
                    onClick={(e) => handleGroundLease(e)}
                  />
                  <label htmlFor="c" className="price-type-text">
                    C
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="d"
                    value="d"
                    onClick={(e) => handleGroundLease(e)}
                  />
                  <label htmlFor="d" className="price-type-text">
                    D
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* available air rights section */}
          <div className="sqft-price  filter-form-row d-flex flex-row">
            <p className="small-heading">Available Air Rights</p>
            <input
              type="text"
              placeholder="Y/N"
              className="air-right-input"
              onChange={(e) => {
                setAirRights(e.target.value);
              }}
              maxLength="1"
            />
          </div>
          {/* new this week */}
          <div className="sqft-price  filter-form-row d-flex flex-row justify-content-between">
            <div className="small-heading new-week">New This Week</div>
            <div className="d-flex justify-content-end">
              <PinkSwitch
                {...label}
                onChange={(e) => {
                  setViewsetting(e.target.checked);
                  setViewsetting((state) => {
                    return state;
                  });
                }}
                checked={viewsetting}
                style={{ color: "#315EFB" }}
              />
            </div>
          </div>
          <div
            className="buttons pointer blue__btn adfasdfsad"
            onClick={(e) => sendFormdata()}
            style={{ margin: "auto" }}
          >
            {" "}
            Update{" "}
          </div>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleViewMoreChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p>View More</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>this is content for view more</div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default CommertialMapFilter;
