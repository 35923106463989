import React, { useState } from "react";
import PropertyInfoComponent from "./component/PropertyInfoComponent";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updatePropertyDatail } from "./PropertySlice";

const PropertyDetailsPopUp = (props) => {
  const {
    propertyDetailInfo,
    setPropertyDetailInfo,
    showLocationButton,
    showTravelview,
  } = props;
  const [modalShow, setModalShow] = useState(true);
  const dispatch = useDispatch();

  const hidepopup = () => {
    dispatch(
      updatePropertyDatail({
        setshowPropertyPopup: false,
        setSavePropertyPopup: false,
        propertyId: "",
        PropertyDetailInfo: {},
      })
    );
    setModalShow(false);
  };
  return (
    <>
      <Modal
        // dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={modalShow}
        onHide={() => hidepopup()}
      >
        <Modal.Header closeButton>
          <p className="proprty-head">Property Details</p>
        </Modal.Header>
        <Modal.Body>
          <PropertyInfoComponent
            showTravelView={showTravelview}
            propertyDetailInfo={propertyDetailInfo}
            setPropertyDetailInfo={setPropertyDetailInfo}
            showLocationButton={showLocationButton}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PropertyDetailsPopUp;
