// import Agentbio from "./components/pages/agentbio/Agentbio";
// import Advertise from "./components/pages/dashboard/components/advertise/Advertise";
// import Main from "./components/pages/dashboard/components/mainSection/Main";
// import SmsMarketing from "./components/pages/boost-marketing/components/sms-marketing/SmsMarketing";
// import DisplayAd from "./components/pages/boost-marketing/components/display-ads/DisplayAd";
// import StreamingAd from "./components/pages/boost-marketing/components/streaming-ad/StreamingAd";
// import SocialAd from "./components/pages/boost-marketing/components/social-media/SocialAd";
// import PodcastAd from "./components/pages/boost-marketing/components/podcast-ad/PodcastAd";
// import CommercialAd from "./components/pages/boost-marketing/components/commercial-ad/CommercialAd";
// import Remarketing from "./components/pages/boost-marketing/components/remarketing/Remarketing";
// import DigitalBillboard from "./components/pages/boost-marketing/components/digital-billboard/DigitalBillboard";
// import PaidMedia from "./components/pages/boost-marketing/components/paid-media/PaidMedia";
// import DigitalMarketing from "./components/pages/boost-marketing/components/digital-marketing/DigitalMarketing";
// import NativeAd from "./components/pages/boost-marketing/components/native-ad/NativeAd";
// import PodcastForm from "./components/pages/boost-marketing/components/podcast-ad/PodcastForm";
// import RemarketingForm from "./components/pages/boost-marketing/components/remarketing/RemarketingForm";
// import DigitalBillboardForm from "./components/pages/boost-marketing/components/digital-billboard/DigitalBillboardForm";
// import MiddleSection from "./components/pages/main-virtual-office/components/middle-section/MiddleSection";
// import DigitalMarketingForm from "./components/pages/boost-marketing/components/digital-marketing/DigitalMarketingForm";
// import AdvPrtal_Form from "./components/pages/advertisement-flow/component/Portal_Page/AdvPrtal_Form";
// import AdvPrtal_Form from "./components/pages/advertisement-flow/component/Portal_Page/AdvPrtal_Form";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Homepage from "./components/pages/homepage/Homepage";
import Message from "./components/pages/Message/Message";
import Profile from "./components/pages/profile/Profile";
import Reviews from "./components/pages/reviews/Reviews";
import SavedListings from "./components/pages/saved-listings/SavedListings";
import SaveSearch from "./components/pages/saveSearch/SaveSearch";
import Subscription from "./components/pages/Subscription/Subscription";
import Support from "./components/pages/support/Support";
import VirtualOffice from "./components/pages/virtual-office/VirtualOffice";
import Login from "./components/pages/Auth/Login";
import Signup from "./components/pages/Auth/Signup";
import Terms from "./components/pages/term&policy/Terms";
import Policy from "./components/pages/term&policy/Policy";
import BoostMarketing from "./components/pages/boost-marketing/BoostMarketing";
import CurrentListing from "./components/pages/currentListing/CurrentListing";
import ExpiredListing from "./components/pages/expiredListing/ExpiredListing";
import AllSelPerson from "./components/pages/allSelPerson/AllSelPerson";
import CommonmarketingForm from "./components/pages/boost-marketing/components/marketing-form/CommonmarketingForm";
import SocialAdForm from "./components/pages/boost-marketing/components/social-media/SocialAdForm";
import PaidMediaForm from "./components/pages/boost-marketing/components/paid-media/PaidMediaForm";
import PersonalInfoPage from "./components/pages/personal/PersonalInfoPage";
import AgentDashboard from "./components/pages/dashboard/agent-dashboard/AgentDashboard";
import PropertyDetails from "./components/pages/PropertyDetails/PropertyDetails";
import MapviewFilter from "./components/pages/mapview-filter/MapviewFilter";
import CheckOut from "./components/pages/check-out-page/CheckOut";
import Neighbourhoodmains from "./components/pages/neighbourhoodspecialist/Neighbourhoodmains";
import OpenListing from "./components/pages/open-listing/OpenListing";
import Agentprofile from "./components/pages/agentbio/Agentprofile";
import Analytics from "./components/pages/analytics/Analytics";
import MapViewCommfilter from "./components/pages/mapview-filter/components/commertial/MapViewCommfilter";
import QuikView from "./components/pages/dashboard/quickView/QuikView";
import AdvertisingMain from "./components/Advertising-Marketing-Pages/AdvHome-Pages/AdvertisingMain";
import ForgetPass from "./components/pages/Auth/ForgetPass";
import NotFound from "./components/pages/NotFound/NotFound";
import {
  AgentProfileRoute,
  AgentProfileSetting,
  AgentRoute,
  OepnRoute,
  SignRoute,
  SubscriptionRoute,
} from "./components/protected-route/ProtectedRoute";
import AgentAddProperty from "./components/pages/agent-dash-sales/AgentAddProperty";
import ResetPass from "./components/pages/Auth/ResetPass";
import ProfileSetResetPass from "./components/pages/profile/components/password/ProfileSetResetPass";
import TeamLogin from "./components/pages/Auth/TeamLogin";
import EditProperty from "./components/pages/agent-dash-sales/EditProperty";
import VirtualContact from "./components/pages/agentVirtualOffice/components/VirtualContact";
import EditContact from "./components/pages/agentVirtualOffice/components/editContact/EditContact";
import OfficeDashboard from "./components/pages/agentVirtualOffice/OfficeDashboard";
import OfficeMapView from "./components/pages/agentVirtualOffice/components/officeMapView/OfficeMapView";
import PropertyDetailsPopUp from "./components/pages/dashboard/propperty-datails/PropertyDetailsPopUp";
import ShareProperty from "./components/pages/shareProperty/ShareProperty";
import MapViewCurrentListing from "./components/pages/agentbio/current-MapListing/MapViewCurrentListing";
// --------------------------------Virtual-Office-----------------------------------------------------------------
import VirtualDocument from "./components/pages/agentVirtualOffice/components/document/VirtualDocument";
import GuestVirtualOffice from "./components/pages/guestVirtualOffice/GuestVirtualOffice";
import AgentCustomerCards from "./components/pages/agentCustomerVirtualOffice/AgentCustomerCards";
// ------------------------------------Advertisement_Flow------------------------------------------------
import AdvertisementHomePage from "./components/pages/advertisement-flow/Home_Page/AdvertisementHomePage";
import Adv_Reg_Page from "./components/pages/advertisement-flow/component/Registration_Page/Adv_Reg_Page";
import Adv_Login_Page from "./components/pages/advertisement-flow/component/Login_Page/Adv_Login_Page";
import Create_ADS_page from "./components/pages/advertisement-flow/component/ADS_Page/Create_ADS_page";
import checkOut_Pop from "./components/pages/advertisement-flow/component/CheckOut_Page/checkOut_Pop";
// import AdvPrtal_Form from "./components/pages/advertisement-flow/component/Portal_Page/AdvPrtal_Form";
import Adv_Lead_Page from "./components/pages/advertisement-flow/component/Lead_page/Adv_Lead_Page";
// import Fsbho_Subcription from "./components/pages/advertisement-flow/component/FSBHO_FLOW/Fsbho_Subcription";
// import AdvPrtal_Form from "./components/pages/advertisement-flow/component/Portal_Page/AdvPrtal_Form";
// import Fsbho_card from "./components/pages/advertisement-flow/component/FSBHO_FLOW/Fsbho_card";
// import Fsbho_plan from "./components/pages/advertisement-flow/component/FSBHO_FLOW/Fsbho_plan";
import Adv_Invoice_Table from "./components/pages/advertisement-flow/component/Invois_Page/Adv_Invoice_Table";
import AgentVirtualDashboard from "./components/pages/agentVirtualOffice/components/dashboard/AgentVirtualDashboard";
import GuestVirtualDashboard from "./components/pages/dashboard/guest-virtual-dashboard/GuestVirtualDashboard";
import GuestVirtualOfficeAgent from "./components/pages/guestVirtualOffice/components/GuestVirtualOfficeAgentList";
import GuestVirtualOfficeDocument from "./components/pages/Documents/components/Sharedocuments";
// import GuestVirtualOfficeDocument from "./components/pages/guestVirtualOffice/components/GuestVirtualOfficeDocument";
import GuestVirtualOfficeMyMessages from "./components/pages/guestVirtualOffice/components/GuestVirtualOfficeMyMessages";
import GuestVirtualOfficeInterested from "./components/pages/guestVirtualOffice/components/GuestVirtualOfficeInterested";
import GuestVirtualOfficeNotInterested from "./components/pages/guestVirtualOffice/components/GuestVirtualOfficeNotInterested";
import GuestVirtualOfficeDashboard from "./components/pages/guestVirtualOffice/components/GuestVirtualOfficeDashboard";
import AgentInterestedProperty from "./components/pages/agentVirtualOffice/components/interested/AgentInterestedProperty";
import AgentNotInterestedProperty from "./components/pages/agentVirtualOffice/components/dontlike/AgentNotInterestedProperty";
import MarketingPlans from "./components/pages/boost-marketing/components/marketing-plans/MarketingPlans";
import MarketingFormWithDescription from "./components/pages/boost-marketing/components/marketing-form/MarketingFormWithDescription";
import AgentDocument from "./components/pages/agentVirtualOffice/components/agentDocuments/AgentDocument";
import CreateDocument from "./components/pages/agentVirtualOffice/components/agentDocuments/CreateDocument";
import CreateTemplate from "./components/pages/agentVirtualOffice/components/agentDocuments/CreateTemplate";
import LandLoadDashboard from "./components/pages/LandloadDashboard/LandLoadDashboard";
import LandoadSetting from "./components/pages/LandloadDashboard/components/LandoadSetting";
import Documents from "./components/pages/Documents/Documents";
import DocumentUpload from "./components/pages/Documents/components/DocumentUpload";
import DocSign from "./components/pages/Documents/components/docSign/DocSign";
import DocumentSign from "./components/pages/Documents/components/DocumentSign";

const Routing = () => {
  const mainRoutes = [
    { url: "", component: Homepage },
    { url: "forget-password", component: ForgetPass, protected: OepnRoute },
    { url: "Terms&Conditions", component: Terms, protected: OepnRoute },
    { url: "PrivacyPolicy", component: Policy, protected: OepnRoute },
    { url: "property/:id", component: ShareProperty, protected: OepnRoute },
    { url: "advertising", component: AdvertisingMain, protected: OepnRoute },
    { url: "Agentprofile", component: Agentprofile },
    { url: "advertisement", component: AdvertisementHomePage },
    { url: "ad-signup", component: Adv_Reg_Page },
    { url: "ad-login", component: Adv_Login_Page },
    { url: "checkOut_Pop", component: checkOut_Pop },
    { url: "agents/:id", component: Agentprofile },
    // { url: "login", component: Login, protected: SignRoute },
    // { url: "signup", component: Signup, protected: SignRoute },
    // { url: "reset-password", component: ResetPass, protected: OepnRoute },
    // { url: "dashboard", component: Dashboard ,protected:OepnRoute},
    // { url: "agent-dashboard", component: AgentDashboard,protected:OepnRoute },
    // { url: "property-details", component: PropertyDetails },
  ];
  const DashboardRoutes = [
    { url: "saved-listings", component: SavedListings },
    { url: "expire-listing", component: ExpiredListing },
    { url: "saved-search", component: SaveSearch },
    { url: "message", component: Message },
    { url: "reviews", component: Reviews },
    { url: "support", component: Support },
    { url: "virtual-office", component: VirtualOffice },
    { url: "profile-setting", component: PersonalInfoPage },
    { url: "quik-view", component: QuikView },
    { url: "mapview-filter", component: MapviewFilter },
    { url: "mapview-commertial-filter", component: MapViewCommfilter },
    { url: "open-listing", component: OpenListing },
    { url: "current-listing", component: CurrentListing },
    { url: "all-salesperson", component: AllSelPerson },
    { url: "personal-information", component: PersonalInfoPage },
    { url: "property-details", component: PropertyDetails },
    // { url: "MiddleSection", component: MiddleSection },
    { url: "PropertyDetailsPopUp", component: PropertyDetailsPopUp },
  ];

  const AgentdashboardRoutes = [
    { url: "*", component: NotFound },
    { url: "Agentprofile", component: Agentprofile },
    { url: "add-property", component: AgentAddProperty },
    { url: "edit-property/:id", component: EditProperty },
    { url: "virtual-office", component: VirtualOffice },
    { url: "saved-listings", component: SavedListings },
    { url: "expire-listing", component: ExpiredListing },
    { url: "all-salesperson", component: AllSelPerson },
    { url: "analytic", component: Analytics },
    { url: "review", component: Reviews },
    { url: "support", component: Support },
    { url: "profile-settings", component: Profile },
    {
      url: "profile-settings/reset-password",
      component: ProfileSetResetPass,
      protected: OepnRoute,
    },
    { url: "plan&billing", component: Neighbourhoodmains },
    { url: "check-out", component: CheckOut },
    { url: "message", component: Message },
    { url: "document", component: Documents },
    { url: "document/upload", component: DocumentUpload },
    { url: "neighbourhood", component: Neighbourhoodmains },

    { url: "boost-marketing", component: BoostMarketing },
    { url: "boost-marketing/plans/:id", component: MarketingPlans },

    { url: "marketing-form/:id", component: CommonmarketingForm },
    {
      url: "boost-marketing-form/:id",
      component: MarketingFormWithDescription,
    },

    { url: "boost-marketing/social-media/form/:id", component: SocialAdForm },
    { url: "boost-marketing/paid-media/form/:id", component: PaidMediaForm },
    // { url: "boost-marketing/sms-marketing/:id", component: SmsMarketing },
    // { url: "boost-marketing/display-ads/:id", component: DisplayAd },
    // { url: "boost-marketing/native-ad/:id", component: NativeAd },
    // { url: "boost-marketing/steaming-ad/:id", component: StreamingAd },
    // { url: "boost-marketing/social-media/:id", component: SocialAd },
    // { url: "boost-marketing/podcast-ad/:id", component: PodcastAd },
    // { url: "boost-marketing/podcast-ad/form", component: PodcastForm },
    // { url: "boost-marketing/commercial-ad/:id", component: CommercialAd },
    // { url: "boost-marketing/remarketing/:id", component: Remarketing },
    // { url: "boost-marketing/remarketing/form", component: RemarketingForm },
    // { url: "boost-marketing/digital-billboard", component: DigitalBillboard },
    // { url: "boost-marketing/digital-billboard/form", component: DigitalBillboardForm },
    // { url: "boost-marketing/paid-media", component: PaidMedia },
    // { url: "boost-marketing/digital-marketing", component: DigitalMarketing },
    // { url: "boost-marketing/digital-marketing/form", component: DigitalMarketingForm },

    { url: "current-listing", component: CurrentListing },
    { url: "current-listing-mapview", component: MapViewCurrentListing },
    { url: "mapview-filter", component: MapviewFilter },
    { url: "mapview-commertial-filter", component: MapViewCommfilter },
  ];

  const LandLoadDashboardRoutes = [
    { url: "*", component: NotFound },
    { url: "add-property", component: AgentAddProperty },
    { url: "edit-property/:id", component: EditProperty },
    { url: "current-listing", component: CurrentListing },
    { url: "current-listing-mapview", component: MapViewCurrentListing },
    { url: "expire-listing", component: ExpiredListing },
    { url: "message", component: Message },

    { url: "boost-marketing", component: BoostMarketing },
    { url: "boost-marketing/plans/:id", component: MarketingPlans },

    { url: "marketing-form/:id", component: CommonmarketingForm },
    {
      url: "boost-marketing-form/:id",
      component: MarketingFormWithDescription,
    },

    { url: "boost-marketing/social-media/form/:id", component: SocialAdForm },
    { url: "boost-marketing/paid-media/form/:id", component: PaidMediaForm },

    { url: "virtual-office", component: VirtualOffice },
    { url: "analytic", component: Analytics },
    { url: "review", component: Reviews },
    { url: "support", component: Support },

    // { url: "Agentprofile", component: Agentprofile },
    { url: "profile-setting", component: LandoadSetting },
    {
      url: "profile-settings/reset-password",
      component: ProfileSetResetPass,
      protected: OepnRoute,
    },
    { url: "plan&billing", component: Neighbourhoodmains },

    { url: "mapview-filter", component: MapviewFilter },
    { url: "mapview-commertial-filter", component: MapViewCommfilter },
  ];

  const AgentVirtualOfficeRoutes = [
    { url: "*", component: NotFound },
    { url: "contact", component: VirtualContact },
    { url: "contact/:id", component: VirtualContact },
    { url: "contact/edit", component: EditContact },
    { url: "map-view", component: OfficeMapView },
    { url: "customer-info", component: AgentCustomerCards },
    { url: "message", component: Message },
    // ----------------------------Vertual_Office-------------------------------------
    { url: "document", component: VirtualDocument },
    { url: "document/upload", component: DocumentUpload },
    // { url: "GuestVirtualOffice", component: GuestVirtualOffice },
    // ----------------------------Advertisement_Flow---------------------------------
    { url: "Create_ADS_page", component: Create_ADS_page },
    // { url: "AdvPrtal_Form", component: AdvPrtal_Form},
    { url: "Adv_Invoice_Table", component: Adv_Invoice_Table },
    { url: "Adv_Lead_Page", component: Adv_Lead_Page },
    // { url: "AdvPrtal_Form", component: AdvPrtal_Form},
    { url: "dashboard", component: AgentVirtualDashboard },
    // { url: "Fsbho_Subcription", component: Fsbho_Subcription},
    // { url: "Fsbho_card", component: Fsbho_card},
    // { url: "Fsbho_plan", component: Fsbho_plan},
    { url: "Create_ADS_page", component: Create_ADS_page },
    { url: "Adv_Invoice_Table", component: Adv_Invoice_Table },
    { url: "Adv_Lead_Page", component: Adv_Lead_Page },
    { url: "dashboard", component: AgentVirtualDashboard },
    { url: "interested-property", component: AgentInterestedProperty },
    { url: "dislike-property", component: AgentNotInterestedProperty },
  ];
  const GuestVirtualOfficeRoutes = [
    { url: "*", component: NotFound },
    { url: "home", component: GuestVirtualOfficeDashboard },
    { url: "dashboard", component: GuestVirtualOfficeDashboard },
    { url: "agents", component: GuestVirtualOfficeAgent },
    { url: "document", component: GuestVirtualOfficeDocument },
    { url: "document/sign", component: DocumentSign },
    { url: "my-message", component: GuestVirtualOfficeMyMessages },
    { url: "interested-property", component: GuestVirtualOfficeInterested },
    { url: "dislike-property", component: GuestVirtualOfficeNotInterested },
    { url: "agents/details/:id", component: Agentprofile },
  ];

  return (
    <Routes>
      <Route path={`/login`} element={<SignRoute component={<Login />} />} />
      <Route path={`/signup`} element={<SignRoute component={<Signup />} />} />
      <Route
        path={`/reset-password`}
        element={<SignRoute component={<ResetPass />} />}
      />
      <Route
        path={`/team-login`}
        element={<SignRoute component={<TeamLogin />} />}
      />
      <Route
        path={`/subscription`}
        element={<SubscriptionRoute component={<Subscription />} />}
      />
      <Route path={"check-out"} element={<CheckOut />} />

      {mainRoutes.map((data, i) => (
        <Route
          path={`/${data.url}`}
          key={`route${i}`}
          element={<data.component />}
        />
      ))}

      {/* guest user routing */}
      <Route path="/dashboard/*" element={<Dashboard />}>
        <Route index element={<Navigate to="quik-view" replace />} />
        <Route exact path="quik-views" element={<QuikView />} />
        <Route exact path="quik-view/:id" element={<QuikView />} />
        {DashboardRoutes.map((data, i) => (
          <Route
            exact
            path={`${data.url}`}
            key={`dashroute${i}`}
            element={<data.component />}
          />
        ))}
      </Route>

      {/* agent user routing */}
      <Route
        path="/agent-dashboard/*"
        element={<AgentRoute component={<AgentDashboard />} />}
      >
        <Route index element={<Navigate to="add-property" replace />} />
        <Route path={"check-out"} element={<CheckOut />} />
        {/* <Route path={'check-out'} element={<SubscriptionRoute component={<CheckOut />} />} /> */}
        <Route
          path={"profile-settings"}
          element={<AgentProfileSetting component={<Profile />} />}
        />

        {AgentdashboardRoutes.map((data, i) => (
          <Route
            exact
            path={`${data.url}`}
            key={`agentdashlinks${i}`}
            element={<AgentProfileRoute component={<data.component />} />}
          />
        ))}
      </Route>

      {/* agent  user virtual office */}
      <Route path="/agent-virtual-office/*" element={<OfficeDashboard />}>
        <Route index element={<Navigate to="home" replace />} />
        {AgentVirtualOfficeRoutes?.map((data, i) => (
          <Route
            exact
            path={`${data.url}`}
            key={`agentVirtualroute${i}`}
            element={<data.component />}
          />
        ))}
      </Route>

      <Route path="/guest-virtual-office/*" element={<GuestVirtualDashboard />}>
        <Route index element={<Navigate to="home" replace />} />
        {GuestVirtualOfficeRoutes?.map((data, i) => (
          <Route
            exact
            path={`${data.url}`}
            key={`agentVirtualroute${i}`}
            element={<data.component />}
          />
        ))}
      </Route>

      <Route path="/LandLoadDashboard/" element={<LandLoadDashboard />}>
        {LandLoadDashboardRoutes?.map((data, i) => (
          <Route
            exact
            path={`${data.url}`}
            key={`LandLoadDashboard${i}`}
            element={<data.component />}
          />
        ))}
        {/* <Route index element={<Navigate to="home" replace />} />
        {GuestVirtualOfficeRoutes?.map((data, i) => (
          <Route exact path={`${data.url}`} key={`agentVirtualroute${i}`} element={<data.component />} />
        ))} */}
      </Route>

      <Route path={`*`} element={<NotFound />} />
    </Routes>
  );
};

export default Routing;
